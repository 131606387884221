import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Header from '../../components/Header'
import { Logo } from '../../assets/Logo'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBanks } from '../../slices/banks'
import BankRechargeDialog from '../../components/BankRechargeDialog'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    backgroundColor: '#003f62'
  },
  content: {
    flex: '1',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    paddingInline: 15,
    color: '#fff',
    position: 'relative'
  },
  selectDetails: {
    paddingInline: 40
  },
  input: {
    backgroundColor: '#fff',
    borderRadius: 10,
    color: '#003f62',
    fontSize: 16,
    fontWeight: 600
  },
  verifyTransaction: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 60,
    backgroundColor: '#ff7c46',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
  }
})

function NewAccount () {
  const history = useHistory()
  const classes = useStyles()

  const [expandedBanks, setExpandedBanks] = useState(true)
  const [bank, setSelectedBank] = useState({})
  const [NameAccount, setNameAccount] = useState('')
  const [NumberAccount, setNumberAccount] = useState('')
  const [IDtype, setIDtype] = useState('')
  const [IDNumber, setIDNumber] = useState('')
  const [Accountype, setAccountype] = useState('')

  const dispatch = useDispatch()
  const banks = useSelector(state => state.banks.banks)

  const selectedaBank = (e) => {
    setSelectedBank(e)
    setExpandedBanks(false)
  }

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  const onSubmit = () => {
    const data = {
      Accountype,
      bank,
      name: NameAccount,
      number: NumberAccount,
      IDtype,
      IDNumber
    }
    history.push({ pathname: '/transactions/new', state: { contact: data } })
  }

  return (
        <Grid className={classes.container}>
            <Header text="Nueva Cuenta" />

            <Grid container className={classes.content}>
                <div style={{ alignSelf: 'center', marginLeft: -150, marginTop: 20 }}>
                    {Logo}
                </div>
                <div style={{ marginTop: 80, marginBottom: 80 }}>
                    <Accordion expanded={expandedBanks} style={{ borderRadius: 10 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                            style={{ height: 56 }}
                        >
                            <div>
                                <Typography className={classes.input}>Selecciona el banco a transeferir</Typography>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails className={classes.selectDetails}>
                            <Grid container spacing={3}>
                                {banks.map(bank => (
                                    <Grid key={JSON.stringify(bank)}
                                    onClick={() => selectedaBank(bank)} item xs={6} sm={3}>
                                        <IconButton>
                                            <BankRechargeDialog notExpand={false} bank={bank} />
                                        </IconButton>
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <TextField
                        id="nro-cuenta"
                        placeholder="Número de Cuenta"
                        style={{ marginTop: 12 }}
                        onChange={(e) => setNumberAccount(e.target.value)}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          className: classes.input
                        }}
                        variant="outlined"
                        fullWidth
                    />

                    <TextField
                        id="nombre"
                        placeholder="Nombre y Apellido"
                        style={{ marginTop: 12 }}
                        onChange={(e) => setNameAccount(e.target.value)}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          className: classes.input
                        }}
                        variant="outlined"
                        fullWidth
                    />

                    <div style={{ display: 'flex', height: 56, marginTop: 12 }}>
                        <Select
                            native
                            onChange={(e) => setIDtype(e.target.value)}
                            variant="outlined"
                            style={{ width: '25%', marginRight: '2%', height: '100%' }}
                            className={classes.input}
                            label="Doc"
                            inputProps={{
                              name: 'doc',
                              id: 'doc'
                            }}
                        >
                            <option value="">DOC</option>
                        </Select>
                        <TextField
                            id="nro-documento"
                            placeholder="Número de Documento"
                            style={{ width: '73%', height: '100%' }}
                            onChange={(e) => setIDNumber(e.target.value)}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              className: classes.input
                            }}
                            variant="outlined"
                        />
                    </div>

                    <div style={{ display: 'flex', height: 56, marginTop: 12 }}>
                        <Select
                            native
                            onChange={(e) => setAccountype(e.target.value)}
                            variant="outlined"
                            style={{ width: '25%', marginRight: '2%', height: '100%' }}
                            className={classes.input}
                            label="Tipo"
                            inputProps={{
                              name: 'tipo',
                              id: 'tipo'
                            }}
                        >
                            <option value="">Tipo</option>
                        </Select>
                        <TextField
                            id="tipo-cuenta"
                            placeholder="Tipo de cuenta"
                            style={{ width: '73%', height: '100%' }}
                            onChange={(e) => setAccountype(e.target.value)}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              className: classes.input
                            }}
                            variant="outlined"
                        />
                    </div>
                </div>
            </Grid>

            <div onClick={onSubmit} className={classes.verifyTransaction}>
                <Typography style={{ fontWeight: 500 }}>
                    Realizar Transferencia
                </Typography>
                <ArrowForwardIosIcon style={{ position: 'absolute', right: 10 }} />
            </div>
        </Grid>
  )
}

export default NewAccount
