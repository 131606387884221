import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MainLayout from '../../layouts/main'

function UserItem (props) {
  const history = useHistory()

  const goToUser = () => {
    history.push({ pathname: 'users/details', user: props.user })
  }

  return (
    <div
    onClick={goToUser}
    style={{
      background: 'white',
      display: 'grid',
      gridTemplateColumns: '17% 70% auto',
      alignItems: 'center',
      borderRadius: '5px',
      padding: '3vw 3vw',
      marginBottom: '4vw'
    }}>
      <div style={{
        background: '#003353',
        width: '10vw',
        height: '10vw',
        borderRadius: '50%',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '6vw'
      }}>
        <i className="fa-solid fa-user"></i>
      </div>

      <div>
        <h1 style={{
          fontSize: '5vw',
          fontWeight: '400'
        }}>{props.user.name}</h1>
      </div>

      <div style={{
        textAlign: 'right',
        paddingRight: '2vw'
      }}>
        <i
          style={{
            color: '#f96123',
            fontSize: '7vw',
            marginLeft: '2vw'
          }}
          className="fa-solid fa-chevron-right"
        ></i>
      </div>
    </div>
  )
}

function UserDetailsComponent (props) {
  return (
    <MainLayout text="Detalles de usuario">
      <div >
        <h3
          style={{
            marginBottom: '5vw',
            color: '#ff7c46',
            fontSize: '5vw',
            marginTop: '1vw'
          }}
        >
          Selecciona un usuario
        </h3>

        {props.users?.data?.rows &&
          props.users.data.rows.map((user) => (
            <UserItem key={user.id} user={user}></UserItem>
          ))}
      </div>
    </MainLayout>
  )
}

export default UserDetailsComponent
