import React, { useState, useEffect } from 'react'
import MainLayout from '../../layouts/main'
import Screenloading from './../loaders/screenLoading'
import { Fab } from 'react-tiny-fab'
import Loading from './../loaders/loading'
import Checkbox from '@mui/material/Checkbox'
import { useHistory, useLocation } from 'react-router-dom'
import {
  TextField,
  InputAdornment,
  Typography,
  Grid,
  Button,
  Container,
  Switch
} from '@material-ui/core'
import Select from 'react-select'
import { customStyles } from './../../assets/styles/select'

import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  container: {
    height: '100vh',
    overflow: 'auto'
  },
  row: {
    display: 'block',
    width: '100%'
  },
  image: {
    width: '100%',
    borderRadius: '10px',
    marginBottom: '15px'
  },
  header: {
    height: 62,
    width: '100%',
    color: '#eee',
    paddingTop: '13px',
    paddingBottom: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    position: 'fixed',
    backgroundColor: '#003353',
    zIndex: 3
  },
  headerPhoto: {
    height: 70,
    width: '100vw',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1vw 3vw 1vw',
    backgroundColor: '#003f62',
    color: '#fff',
    zIndex: 3,
    marginTop: '10px'
  },
  responsiveSvg: {
    width: '6vw',
    maxWidth: '40px',
    overflow: 'visible'
  }
})

function ButtonSubmit ({ onSubmit }) {
  return (
    <Button
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 60,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 0,
        '&:hover, &:focus': {
          backgroundColor: '#ff7c46'
        },
        backgroundColor: '#ff7c46'
      }}
      type="submit"
    >
      <Typography
        style={{
          color: '#fff',
          fontWeight: 500,
          textTransform: 'capitalize'
        }}
      >
        Crear
      </Typography>
    </Button>
  )
}

function CheckboxInput ({ name, onChange, val }) {
  return (
    <h5
      style={{
        color: '#003f62'
      }}
    >
      <Checkbox
        checked={val}
        onClick={(e, d) => onChange(e, name)}
        inputProps={{ 'aria-label': 'controlled' }}
      ></Checkbox>
      {name}
    </h5>
  )
}

function CheckboxsContainer (props) {
  let ahorroActive = props.formData.typeAccount == 'Ahorro'
  const [val, setVal] = useState({
    Ahorro: ahorroActive,
    Corriente: !ahorroActive
  })

  useEffect(() => {
    ahorroActive = props.formData.typeAccount == 'Ahorro'
    setVal({ Ahorro: ahorroActive, Corriente: !ahorroActive })
  }, [props.formData])

  const setFormValue = (e) => {
    const newValue = { typeAccount: e }
    props.setFormData({ ...props.formData, ...newValue })
  }

  const onChange = (e, value) => {
    setVal({
      Ahorro: value == 'Ahorro',
      Corriente: value != 'Ahorro'
    })
    setFormValue(value)
  }

  return (
    <div style={{ marginLeft: '3vw' }}>
      <h5
        style={{
          marginLeft: '4vw'
        }}
      >
        Tipo de cuenta
      </h5>
      <CheckboxInput
        dataForm={props.formData}
        onChange={onChange}
        val={val.Ahorro}
        name="Ahorro"
      ></CheckboxInput>
      <CheckboxInput
        dataForm={props.formData}
        onChange={onChange}
        val={val.Corriente}
        name="Corriente"
      ></CheckboxInput>
    </div>
  )
}

function Fields ({
  banks,
  numberFourBank,
  bankSelected,
  onSelectBank,
  setAllowPagoMovil,
  formData,
  setFormData,
  setdocumentTypeSelected,
  register,
  isEditing,
  documentTypeSelected,
  allowPagoMovil,
  pagoMovil,
  setpagoMovil,
  getValues,
  errors,
  onChangeInputNumberAccount
}) {
  const location = useLocation()

  const [menuIsOpen, setmenuIsOpen] = useState(false)

  const DOCTypes = [
    { value: '1', title: 'V', origin: 'Venezolano' },
    { value: '1', title: 'CI', origin: 'Venezolano' },
    { value: '2', title: 'E', origin: 'Extranjero' },
    { value: '3', title: 'J', origin: 'RIF' },
    { value: '4', title: 'G', origin: 'Gubernamental' }
  ]

  const selectOperators = (provied) => {
    setmenuIsOpen(false)
  }

  const Control = () => {
    return (
      <div
        onClick={() => {
          console.log('eo')
          setmenuIsOpen(!menuIsOpen)
        }}
        style={{
          display: 'grid',
          backgroundColor: 'white',
          gridTemplateColumns: '15% 70%',
          padding: '1.5vw 4vw 1vw 3vw',
          borderRadius: '6px',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.16)'
        }}
      >
        {banks != null && banks[0] != null
          ? (
          <>
            <img
              src={bankSelected.Logo || banks[0].Logo}
              style={{ width: '100%' }}
            ></img>
            <h1
              style={{ fontSize: 15, marginLeft: '3vw', fontWeight: 'normal' }}
            >
              {bankSelected.alias || banks[0].alias}
            </h1>
          </>
            )
          : (
          <></>
            )}
      </div>
    )
  }

  const OptionComponent = ({ innerRef, innerProps, data }) => {
    return (
      <div
        onClick={() => {
          setmenuIsOpen(!menuIsOpen)
          onSelectBank(data)
        }}
        style={{
          display: 'grid',
          gridTemplateColumns: '15% 70%',
          padding: '1.5vw 4vw 1vw 3vw',
          alignItems: 'center',
          borderRadius: '6px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.16)'
        }}
      >
        <img src={data.Logo} style={{ width: '100%' }}></img>
        <h1 style={{ fontSize: 15, marginLeft: '3vw', fontWeight: 'normal' }}>
          {data.alias}
        </h1>
      </div>
    )
  }

  return (
    <div
      style={{
        background: 'white',
        padding: '6vw 5vw',
        borderRadius: '7px'
      }}
    >
      {allowPagoMovil == false && (
        <div style={{ marginBottom: '3vw' }}>
          <Select
            placeholder="Selecciona el banco"
            menuIsOpen={menuIsOpen}
            components={{ Option: OptionComponent, Control }}
            options={banks}
            styles={customStyles}
            getOptionLabel={(e) => e.alias}
          />
        </div>
      )}

      {allowPagoMovil == false && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '25% auto',
            gridGap: '2vw'
          }}
        >
          <TextField
            style={{
              width: '100%',
              color: 'white',
              marginBottom: '6vw'
            }}
            label="Banco"
            variant="outlined"
            value={numberFourBank}
          />
          <div>
            <TextField
              style={{
                width: '100%',
                color: 'white'
              }}
              label="Número de cuenta"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={onChangeInputNumberAccount}
              inputProps={{
                ...{ ...register('numberAccount') }
              }}
            />
            <p
              style={{
                marginTop: '2vw',
                fontSize: '3vw',
                marginBottom: '6vw',
                color: '#c82f2f'
              }}
            >
              {errors.numberAccount?.message}
            </p>
          </div>
        </div>
      )}
      <div>
        <TextField
          style={{
            width: '100%',
            color: 'white'
          }}
          label="Nombre de la cuenta"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...{ ...register('nombre_del_titular') }
          }}
        />
        <p
          style={{
            marginTop: '2vw',
            fontSize: '3vw',
            marginBottom: '6vw',
            color: '#c82f2f'
          }}
        >
          {errors.nombre_del_titular?.message}
        </p>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '50% auto'
        }}
      >
        <div>
          <div style={{ marginBottom: '4vw' }}>
            <Select
              placeholder="Tipo"
              options={DOCTypes}
              styles={customStyles}
              onChange={setdocumentTypeSelected}
              value={documentTypeSelected}
              getOptionLabel={(e) => e.origin}
              defaultValue={(e) => {
                return { origin: 'Venezolano' }
              }}
            />
          </div>
          <div>
            <TextField
              style={{
                width: '100%',
                color: 'white'
              }}
              label="Documento"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              type="number"
              inputProps={{
                ...{ ...register('documento') },
                inputMode: 'number',
                maxLength: 10
              }}
            />
            <p
              style={{
                marginTop: '2vw',
                fontSize: '3vw',
                marginBottom: '6vw',
                color: '#c82f2f'
              }}
            >
              {errors.documento?.message}
            </p>
          </div>
        </div>
        <CheckboxsContainer formData={formData} setFormData={setFormData} />
      </div>

      {allowPagoMovil && (
        <div>
          <TextField
            style={{
              width: '100%',
              color: 'white'
            }}
            label="Pago movíl"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={pagoMovil}
            onChange={(e) => setpagoMovil(e.target.value)}
            inputProps={{
              ...{ ...register('phone') },
              inputMode: 'number',
              maxLength: 11
            }}
          />
          <p
            style={{
              marginTop: '2vw',
              fontSize: '3vw',
              marginBottom: '6vw',
              color: '#c82f2f'
            }}
          >
            {errors.phone?.message}
          </p>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Switch
          checked={allowPagoMovil}
          onChange={(e) => setAllowPagoMovil(!allowPagoMovil)}
          inputProps={{
            'aria-label': 'ant design'
          }}
        />
        <h3 style={{ fontWeight: 'bold' }}>Pago movíl</h3>
      </div>
    </div>
  )
}

function Index (props) {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const user = useSelector((state) => state.auth.user)

  const [isEditing, setisEditing] = useState(false)

  useEffect(() => {
    if (location.pathname == '/contacts-transferences/edit') {
      setisEditing(true)
    }
  }, [])

  return (
    <Grid className={classes.container} style={{ backgroundColor: '#003353' }}>
      <Loading />
      <div className={classes.row}>
        <Grid className={classes.header}>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <i
                onClick={() => history.goBack()}
                style={{ fontSize: '8vw', marginRight: '3vw' }}
                className="fas fa-chevron-left"
              ></i>

              <h3>{isEditing ? 'Editar cuenta' : 'Crear cuenta'}</h3>
            </div>
            <i
              style={{
                fontSize: '6vw',
                marginRight: '1vw'
              }}
              onClick={() => {
                if (user.roleId != '2') {
                  history.push('/dashboard')
                } else {
                  history.push('/operator-dashboard')
                }
              }}
              className="fa-solid fa-house"
            ></i>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row} style={{ marginTop: '60px' }}>
        <Container style={{ padding: '1vw 3vw' }}>
          {props.loading && <Screenloading></Screenloading>}

          <form onSubmit={props.onSubmit}>
            <Fields {...{ ...props }} isEditing={isEditing}></Fields>
            <ButtonSubmit onSubmit={props.onSubmit} />
          </form>
        </Container>
      </div>
    </Grid>
  )
}

export default Index
