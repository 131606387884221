import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const fetchRanges = createAsyncThunk(
  'range',
  async (data) => {
    return Axios.get(process.env.REACT_APP_API_URL + 'range').then((response) => response.data.data)
  }
)

export const createRanges = createAsyncThunk(
  'createRanges',
  async data => {
    return Axios.post(process.env.REACT_APP_API_URL + 'range/store', data).then((response) => response.data)
  }
)

export const updateRanges = createAsyncThunk(
  'updateRanges',
  async data => {
    return Axios.put(process.env.REACT_APP_API_URL + 'range/update/' + data.id, data).then((response) => response.data)
  }
)

export const deleteRanges = createAsyncThunk(
  'deleteRanges',
  async data => {
    return Axios.delete(process.env.REACT_APP_API_URL + 'range/delete/' + data.id).then((response) => response.data)
  }
)

export const rangesSlice = createSlice({
  name: 'ranges',
  initialState: {
    ranges: []
  },
  extraReducers: {
    [fetchRanges.fulfilled]: (state, action) => {
      state.ranges = action.payload
    }
  }
})

export default rangesSlice.reducer
