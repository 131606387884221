import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, SvgIcon } from "@material-ui/core";
import Header from "../components/Header";

const notifications = [
  {
    title: "Transferencia realizada con éxito",
    code: "#0000123",
    date: "26/06/20",
    time: "08:00 AM",
    seen: false,
    icon: (
      <SvgIcon
        viewBox="0 0 25 25"
        style={{ overflow: "visible", marginTop: -5, marginLeft: -5 }}
      >
        <defs>
          <filter
            id="Ellipse_12"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_5346"
          data-name="Group 5346"
          transform="translate(-32 -284)"
        >
          <g
            id="Group_5344"
            data-name="Group 5344"
            transform="translate(0 153)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 32, 131)"
              filter="url(#Ellipse_12)"
            >
              <g
                id="Ellipse_12-2"
                data-name="Ellipse 12"
                transform="translate(9 6)"
                fill="#fff"
                stroke="rgba(0,63,98,0.1)"
                strokeWidth="1"
              >
                <circle cx="22" cy="22" r="22" stroke="none" />
                <circle cx="22" cy="22" r="21.5" fill="none" />
              </g>
            </g>
          </g>
          <path
            id="FontAwsome_hand-holding-usd_"
            data-name="FontAwsome (hand-holding-usd)"
            d="M10.193,5.426l2.041.538a.323.323,0,0,1,.249.3.331.331,0,0,1-.343.316H10.8a1.128,1.128,0,0,1-.421-.083.539.539,0,0,0-.575.075l-.714.658a.439.439,0,0,0-.085.1.429.429,0,0,0,.146.592,3.15,3.15,0,0,0,1.3.432v.666a.631.631,0,0,0,.653.6h.653a.631.631,0,0,0,.654-.6V8.363a2.159,2.159,0,0,0,2.012-2.369A2.281,2.281,0,0,0,12.671,4.2L10.63,3.663a.323.323,0,0,1-.249-.3.331.331,0,0,1,.343-.316h1.339a1.128,1.128,0,0,1,.422.083.539.539,0,0,0,.575-.075l.714-.658a.425.425,0,0,0,.082-.1.43.43,0,0,0-.144-.593,3.152,3.152,0,0,0-1.3-.432V.6A.631.631,0,0,0,11.76,0h-.653a.631.631,0,0,0-.653.6v.662A2.161,2.161,0,0,0,8.442,3.633,2.283,2.283,0,0,0,10.193,5.426Zm11.064,6.912a1.245,1.245,0,0,0-1.6,0L16.18,15.117a2.393,2.393,0,0,1-1.5.526H10.228a.6.6,0,0,1,0-1.2h2.944a1.248,1.248,0,0,0,1.25-1,1.173,1.173,0,0,0,.017-.205,1.2,1.2,0,0,0-1.2-1.2H7.22a4.425,4.425,0,0,0-2.786.989L2.685,14.44H.6a.6.6,0,0,0-.6.6v3.61a.6.6,0,0,0,.6.6H14.018a2.407,2.407,0,0,0,1.5-.526l5.687-4.55a1.2,1.2,0,0,0,.048-1.839Z"
            transform="translate(52.17 301)"
            fill="#FA7D4A"
          />
        </g>
      </SvgIcon>
    ),
  },
  {
    title: "Transferencia en proceso",
    code: "#123445",
    date: "26/06/20",
    time: "07:00 AM",
    seen: false,
    icon: (
      <SvgIcon
        viewBox="0 0 25 25"
        style={{ overflow: "visible", marginTop: -5, marginLeft: -5 }}
      >
        <defs>
          <filter
            id="Ellipse_12"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_5339"
          data-name="Group 5339"
          transform="translate(-32 -131)"
        >
          <g transform="matrix(1, 0, 0, 1, 32, 131)" filter="url(#Ellipse_12)">
            <g
              id="Ellipse_12-2"
              data-name="Ellipse 12"
              transform="translate(9 6)"
              fill="#fff"
              stroke="rgba(0,63,98,0.1)"
              strokeWidth="1"
            >
              <circle cx="22" cy="22" r="22" stroke="none" />
              <circle cx="22" cy="22" r="21.5" fill="none" />
            </g>
          </g>
          <path
            id="FontAwsome_exchange-alt_"
            data-name="FontAwsome (exchange-alt)"
            d="M0,62.7v-.96A1.44,1.44,0,0,1,1.44,60.3h21.6v-2.88A1.441,1.441,0,0,1,25.5,56.4l4.8,4.8a1.44,1.44,0,0,1,0,2.036l-4.8,4.8a1.441,1.441,0,0,1-2.458-1.018v-2.88H1.44A1.44,1.44,0,0,1,0,62.7Zm29.277,9.119H7.679v-2.88a1.441,1.441,0,0,0-2.458-1.018l-4.8,4.8a1.44,1.44,0,0,0,0,2.036l4.8,4.8a1.441,1.441,0,0,0,2.458-1.018v-2.88h21.6a1.44,1.44,0,0,0,1.44-1.44v-.96A1.44,1.44,0,0,0,29.277,71.816Z"
            transform="translate(47.642 91.024)"
            fill="#FA7D4A"
          />
        </g>
      </SvgIcon>
    ),
  },
  {
    title: "Venetasa",
    code: "0.001356",
    date: "25/06/20",
    time: "10:00 PM",
    seen: true,
    icon: (
      <SvgIcon
        viewBox="0 0 25 25"
        style={{ overflow: "visible", marginTop: -5, marginLeft: -5 }}
      >
        <defs>
          <filter
            id="Ellipse_12"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_5340"
          data-name="Group 5340"
          transform="translate(-32 -131)"
        >
          <g transform="matrix(1, 0, 0, 1, 32, 131)" filter="url(#Ellipse_12)">
            <g
              id="Ellipse_12-2"
              data-name="Ellipse 12"
              transform="translate(9 6)"
              fill="#fff"
              stroke="rgba(0,63,98,0.1)"
              strokeWidth="1"
            >
              <circle cx="22" cy="22" r="22" stroke="none" />
              <circle cx="22" cy="22" r="21.5" fill="none" />
            </g>
          </g>
          <path
            id="FontAwsome_bullhorn_"
            data-name="FontAwsome (bullhorn)"
            d="M27,11.25a2.986,2.986,0,0,0-1.5-2.584V1.5A1.515,1.515,0,0,0,24,0a1.5,1.5,0,0,0-.937.329L19.078,3.518A11.377,11.377,0,0,1,12,6H3A3,3,0,0,0,0,9v4.5a3,3,0,0,0,3,3H4.58a11.391,11.391,0,0,0-.1,1.5,11.875,11.875,0,0,0,1.2,5.2,1.471,1.471,0,0,0,1.331.8h3.482A1.49,1.49,0,0,0,11.7,21.63,5.969,5.969,0,0,1,10.477,18a5.672,5.672,0,0,1,.207-1.5H12a11.375,11.375,0,0,1,7.077,2.482l3.986,3.189A1.5,1.5,0,0,0,25.5,21V13.834A2.987,2.987,0,0,0,27,11.25Zm-4.5,6.629L20.951,16.64A14.381,14.381,0,0,0,12,13.5V9a14.381,14.381,0,0,0,8.951-3.14L22.5,4.621Z"
            transform="translate(50 147)"
            fill="#FA7D4A"
          />
        </g>
      </SvgIcon>
    ),
  },
  {
    title: "Datos de cuenta erronea",
    code: "#123443",
    date: "25/06/20",
    time: "07:00 AM",
    seen: true,
    icon: (
      <SvgIcon
        viewBox="0 0 25 25"
        style={{ overflow: "visible", marginTop: -5, marginLeft: -5 }}
      >
        <defs>
          <filter
            id="Ellipse_12"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_5345"
          data-name="Group 5345"
          transform="translate(-32 -284)"
        >
          <g
            id="Group_5344"
            data-name="Group 5344"
            transform="translate(0 153)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 32, 131)"
              filter="url(#Ellipse_12)"
            >
              <g
                id="Ellipse_12-2"
                data-name="Ellipse 12"
                transform="translate(9 6)"
                fill="#fff"
                stroke="rgba(0,63,98,0.1)"
                strokeWidth="1"
              >
                <circle cx="22" cy="22" r="22" stroke="none" />
                <circle cx="22" cy="22" r="21.5" fill="none" />
              </g>
            </g>
          </g>
          <path
            id="FontAwsome_ban_"
            data-name="FontAwsome (ban)"
            d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm6.3,5.7a8.9,8.9,0,0,1,1,11.4l-12.4-12.4a8.9,8.9,0,0,1,11.4,1ZM13.7,26.3a8.9,8.9,0,0,1-1-11.4l12.4,12.4A8.9,8.9,0,0,1,13.7,26.3Z"
            transform="translate(43 292)"
            fill="#FA7D4A"
          />
        </g>
      </SvgIcon>
    ),
  },
  {
    title: "Transferencia realizada con éxito",
    code: "#0000123",
    date: "24/06/20",
    time: "11:00 AM",
    seen: true,
    icon: (
      <SvgIcon
        viewBox="0 0 25 25"
        style={{ overflow: "visible", marginTop: -5, marginLeft: -5 }}
      >
        <defs>
          <filter
            id="Ellipse_12"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_5346"
          data-name="Group 5346"
          transform="translate(-32 -284)"
        >
          <g
            id="Group_5344"
            data-name="Group 5344"
            transform="translate(0 153)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 32, 131)"
              filter="url(#Ellipse_12)"
            >
              <g
                id="Ellipse_12-2"
                data-name="Ellipse 12"
                transform="translate(9 6)"
                fill="#fff"
                stroke="rgba(0,63,98,0.1)"
                strokeWidth="1"
              >
                <circle cx="22" cy="22" r="22" stroke="none" />
                <circle cx="22" cy="22" r="21.5" fill="none" />
              </g>
            </g>
          </g>
          <path
            id="FontAwsome_hand-holding-usd_"
            data-name="FontAwsome (hand-holding-usd)"
            d="M10.193,5.426l2.041.538a.323.323,0,0,1,.249.3.331.331,0,0,1-.343.316H10.8a1.128,1.128,0,0,1-.421-.083.539.539,0,0,0-.575.075l-.714.658a.439.439,0,0,0-.085.1.429.429,0,0,0,.146.592,3.15,3.15,0,0,0,1.3.432v.666a.631.631,0,0,0,.653.6h.653a.631.631,0,0,0,.654-.6V8.363a2.159,2.159,0,0,0,2.012-2.369A2.281,2.281,0,0,0,12.671,4.2L10.63,3.663a.323.323,0,0,1-.249-.3.331.331,0,0,1,.343-.316h1.339a1.128,1.128,0,0,1,.422.083.539.539,0,0,0,.575-.075l.714-.658a.425.425,0,0,0,.082-.1.43.43,0,0,0-.144-.593,3.152,3.152,0,0,0-1.3-.432V.6A.631.631,0,0,0,11.76,0h-.653a.631.631,0,0,0-.653.6v.662A2.161,2.161,0,0,0,8.442,3.633,2.283,2.283,0,0,0,10.193,5.426Zm11.064,6.912a1.245,1.245,0,0,0-1.6,0L16.18,15.117a2.393,2.393,0,0,1-1.5.526H10.228a.6.6,0,0,1,0-1.2h2.944a1.248,1.248,0,0,0,1.25-1,1.173,1.173,0,0,0,.017-.205,1.2,1.2,0,0,0-1.2-1.2H7.22a4.425,4.425,0,0,0-2.786.989L2.685,14.44H.6a.6.6,0,0,0-.6.6v3.61a.6.6,0,0,0,.6.6H14.018a2.407,2.407,0,0,0,1.5-.526l5.687-4.55a1.2,1.2,0,0,0,.048-1.839Z"
            transform="translate(52.17 301)"
            fill="#FA7D4A"
          />
        </g>
      </SvgIcon>
    ),
  },
  {
    title: "Venetasa",
    code: "0.001345",
    date: "24/06/20",
    time: "07:00 AM",
    seen: true,
    icon: (
      <SvgIcon
        viewBox="0 0 25 25"
        style={{ overflow: "visible", marginTop: -5, marginLeft: -5 }}
      >
        <defs>
          <filter
            id="Ellipse_12"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_5340"
          data-name="Group 5340"
          transform="translate(-32 -131)"
        >
          <g transform="matrix(1, 0, 0, 1, 32, 131)" filter="url(#Ellipse_12)">
            <g
              id="Ellipse_12-2"
              data-name="Ellipse 12"
              transform="translate(9 6)"
              fill="#fff"
              stroke="rgba(0,63,98,0.1)"
              strokeWidth="1"
            >
              <circle cx="22" cy="22" r="22" stroke="none" />
              <circle cx="22" cy="22" r="21.5" fill="none" />
            </g>
          </g>
          <path
            id="FontAwsome_bullhorn_"
            data-name="FontAwsome (bullhorn)"
            d="M27,11.25a2.986,2.986,0,0,0-1.5-2.584V1.5A1.515,1.515,0,0,0,24,0a1.5,1.5,0,0,0-.937.329L19.078,3.518A11.377,11.377,0,0,1,12,6H3A3,3,0,0,0,0,9v4.5a3,3,0,0,0,3,3H4.58a11.391,11.391,0,0,0-.1,1.5,11.875,11.875,0,0,0,1.2,5.2,1.471,1.471,0,0,0,1.331.8h3.482A1.49,1.49,0,0,0,11.7,21.63,5.969,5.969,0,0,1,10.477,18a5.672,5.672,0,0,1,.207-1.5H12a11.375,11.375,0,0,1,7.077,2.482l3.986,3.189A1.5,1.5,0,0,0,25.5,21V13.834A2.987,2.987,0,0,0,27,11.25Zm-4.5,6.629L20.951,16.64A14.381,14.381,0,0,0,12,13.5V9a14.381,14.381,0,0,0,8.951-3.14L22.5,4.621Z"
            transform="translate(50 147)"
            fill="#FA7D4A"
          />
        </g>
      </SvgIcon>
    ),
  },
  {
    title: "Transferencia en proceso",
    code: "#123489",
    date: "23/06/20",
    time: "07:00 AM",
    seen: true,
    icon: (
      <SvgIcon
        viewBox="0 0 25 25"
        style={{ overflow: "visible", marginTop: -5, marginLeft: -5 }}
      >
        <defs>
          <filter
            id="Ellipse_12"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_5346"
          data-name="Group 5346"
          transform="translate(-32 -284)"
        >
          <g
            id="Group_5344"
            data-name="Group 5344"
            transform="translate(0 153)"
          >
            <g
              transform="matrix(1, 0, 0, 1, 32, 131)"
              filter="url(#Ellipse_12)"
            >
              <g
                id="Ellipse_12-2"
                data-name="Ellipse 12"
                transform="translate(9 6)"
                fill="#fff"
                stroke="rgba(0,63,98,0.1)"
                strokeWidth="1"
              >
                <circle cx="22" cy="22" r="22" stroke="none" />
                <circle cx="22" cy="22" r="21.5" fill="none" />
              </g>
            </g>
          </g>
          <path
            id="FontAwsome_hand-holding-usd_"
            data-name="FontAwsome (hand-holding-usd)"
            d="M10.193,5.426l2.041.538a.323.323,0,0,1,.249.3.331.331,0,0,1-.343.316H10.8a1.128,1.128,0,0,1-.421-.083.539.539,0,0,0-.575.075l-.714.658a.439.439,0,0,0-.085.1.429.429,0,0,0,.146.592,3.15,3.15,0,0,0,1.3.432v.666a.631.631,0,0,0,.653.6h.653a.631.631,0,0,0,.654-.6V8.363a2.159,2.159,0,0,0,2.012-2.369A2.281,2.281,0,0,0,12.671,4.2L10.63,3.663a.323.323,0,0,1-.249-.3.331.331,0,0,1,.343-.316h1.339a1.128,1.128,0,0,1,.422.083.539.539,0,0,0,.575-.075l.714-.658a.425.425,0,0,0,.082-.1.43.43,0,0,0-.144-.593,3.152,3.152,0,0,0-1.3-.432V.6A.631.631,0,0,0,11.76,0h-.653a.631.631,0,0,0-.653.6v.662A2.161,2.161,0,0,0,8.442,3.633,2.283,2.283,0,0,0,10.193,5.426Zm11.064,6.912a1.245,1.245,0,0,0-1.6,0L16.18,15.117a2.393,2.393,0,0,1-1.5.526H10.228a.6.6,0,0,1,0-1.2h2.944a1.248,1.248,0,0,0,1.25-1,1.173,1.173,0,0,0,.017-.205,1.2,1.2,0,0,0-1.2-1.2H7.22a4.425,4.425,0,0,0-2.786.989L2.685,14.44H.6a.6.6,0,0,0-.6.6v3.61a.6.6,0,0,0,.6.6H14.018a2.407,2.407,0,0,0,1.5-.526l5.687-4.55a1.2,1.2,0,0,0,.048-1.839Z"
            transform="translate(52.17 301)"
            fill="#FA7D4A"
          />
        </g>
      </SvgIcon>
    ),
  },
];

const useStyles = makeStyles({
  container: {
    flex: "1",
    flexDirection: "column",
    minHeight: "100vh",
    flexWrap: "nowrap",
    backgroundColor: "#003F62",
    color: "#fff",
  },
  content: {
    marginInline: 15,
    marginBottom: 15,
    padding: 15,
    width: "90vw",
    alignSelf: "center",
    backgroundColor: "#fff",
    borderRadius: 15,
    position: "relative",
  },
  notification: {
    display: "flex",
    padding: "10px 5px 15px 5px",
    width: "100%",
    marginBottom: 15,
    borderRadius: 10,
    position: "relative",
  },
});

function Notifications() {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Header text="Notificaciones (2)" />

      <Grid container className={classes.content}>
        <SvgIcon
          viewBox="0 0 22 22"
          style={{ position: "absolute", top: -18, left: 40 }}
        >
          <path
            id="Polygon_1"
            data-name="Polygon 1"
            d="M11,0,22,22H0Z"
            fill="#fff"
          />
        </SvgIcon>

        {notifications.map((notification) => (
          <Paper
            key={JSON.stringify(notification)}
            className={classes.notification}
            elevation={notification.seen ? 0 : 3}
            style={notification.seen ? { backgroundColor: "#F9FAFB" } : {}}
          >
            {notification.icon}
            <div style={{ marginLeft: 45 }}>
              <Typography
                style={{ fontSize: 16, fontWeight: 500, color: "#003F62" }}
              >
                {notification.title}
              </Typography>
              <Typography style={{ fontSize: 12, color: "#003F62" }}>
                {notification.code}
              </Typography>
            </div>
            <div style={{ position: "absolute", right: 10, bottom: 10 }}>
              <Typography
                style={{ fontSize: 7, fontWeight: 600, color: "#003F62" }}
              >
                {notification.date}
              </Typography>
              <Typography style={{ fontSize: 7, color: "#003F62" }}>
                {notification.time}
              </Typography>
            </div>
          </Paper>
        ))}
      </Grid>
    </Grid>
  );
}

export default Notifications;
