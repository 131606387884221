import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isUndefined, isArray } from "lodash";

const MySwal = withReactContent(Swal);
export const validateResponse = (responseDTO) => {
  console.log("validateResponse", responseDTO);
  if (
    responseDTO.code != 50 &&
    responseDTO.code != 6 &&
    responseDTO.code != 49
  ) {
    if (!isUndefined(responseDTO.errors) && isArray(responseDTO.errors)) {
      const htmlErrors = responseDTO.errors.map((error) => {
        return `<div className="station">${error.msg}</div>`;
      });

      MySwal.fire({
        html: htmlErrors.join("<br>"),
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
    } else {
      MySwal.fire({
        text: responseDTO.description,
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
    }
  }
};
