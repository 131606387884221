import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ImageIcon from "@material-ui/icons/Image";
import { Link } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import { Button, Zoom } from "@material-ui/core";

import Input from "@material-ui/core/Input";
import PaymentModalState from "./paymentModalState";
import PaymentModalDetail from "./paymentModalDetail";
import { Dialog, Typography } from "@mui/material";
import { formatNumber } from "./../../helpers/numbers";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "8px",
    borderRadius: "10px",
    width: "100%",
    backgroundColor: "#fff",
    padding: "10px",
    fontSize: "12px",
  },
  amount: {
    paddingTop: "0px",
    verticalAlign: "middle",
  },
  logo: {
    width: "14vw",
    margin: "auto",
  },
  logoContainer: {
    width: "62%",
    textAlign: "center",
    position: "relative",
    overflow: "hidden",
  },
  state: {
    borderRadius: "3px",
    verticalAlign: "middle",
    textAlign: "center",
    padding: "6px",
    color: "#fff",
  },
  buttonLogo: {
    textDecoration: "underline",
    position: "absolute",
    bottom: "1px",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "5vw 5vw",
    width: "80vw",
    border: "none",
    borderRadius: "22px",

    display: "flex",
    justifyContent: "space-evenly",
    "@media (min-width: 700px)": {
      padding: "5vw 5vw",
    },
  },
  modalLogo: {
    width: "100px",
    position: "relative",
    top: "-30px",
  },
  toggleButtonState: {
    color: "#eee",
    marginRight: "15px",
    marginLeft: "15px",
    borderRadius: "5px",
  },
}));

export const stateColor = Object.freeze({
  Approved: "#67bb4c",
  Pending: "#f2af3b",
  Rejected: "#d74f4d",
});

export const statePayment = Object.freeze({
  Approved: 1,
  Pending: 2,
  Rejected: 3,
});

function getStateColor(approved) {
  switch (true) {
    case approved:
      return "#67bb4c";
    case isNull(approved):
      return "#f2af3b";
    case !approved:
      return "#d74f4d";
  }
}

function getStateLabel(approved, all) {
  if (approved) {
    return "Aprobado";
  } else if (approved == null) {
    return "Pendiente";
  } else {
    return "Rechazado";
  }
}

const Index = ({
  payment,
  handleSendData,
  detail = false,
  canChange,
  showModal,
  setShowModal,
  paymentSelected,
  setpaymentSelected,
}) => {
  const classes = useStyles();
  const [showImage, setshowImage] = useState(false);

  console.log("====================================");
  console.log(payment);
  console.log("====================================");

  return (
    <>
      {showImage ? (
        <div>
          <Dialog
            onClose={() => setshowImage(false)}
            aria-labelledby="customized-dialog-title"
            open={showImage}
          >
            {payment.capture ? (
              <Zoom>
                <img
                  src={payment.capture}
                  style={{
                    visibility: "visible",
                    width: "100%",
                    transform: "none",
                    maxHeight: "78vh",
                    marginBottom: "4vw",
                  }}
                />
              </Zoom>
            ) : (
              <ImageIcon style={{ width: "100%", height: "100%" }} />
            )}

            <Button
              className={classes.button}
              style={{
                backgroundColor: "#ff7c46",
              }}
              type="submit"
              onClick={() => {
                setshowImage(false);
              }}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                Cerrar
              </Typography>
            </Button>
          </Dialog>
        </div>
      ) : (
        <></>
      )}
      {detail ? (
        <PaymentModalDetail
          payment={paymentSelected}
          setShowModal={setShowModal}
          showModal={showModal}
          getStateColor={getStateColor}
          getStateLabel={getStateLabel}
        />
      ) : (
        <PaymentModalState
          payment={paymentSelected}
          handleSendData={handleSendData}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
      <div
        className={classes.card}
        style={{
          display: "grid",
          gridTemplateColumns: "20% 31% auto",
          alignItems: "center",
        }}
      >
        <div onClick={() => setshowImage(true)}>
          <img
            style={{
              borderRadius: "100%",
              height: "20vw",
              width: "20vw",
              border: "1px solid #ff7c46",
              padding: "0.3vw",
              background: "#ff7c46",
            }}
            src={payment.capture}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div rowSpan="3" className={classes.logoContainer}>
            {payment.appAccount.parentMethod.logo ? (
              <div>
                <img
                  src={payment.appAccount.parentMethod.logo}
                  alt=""
                  className={classes.logo}
                />
                <div
                  style={{
                    top: "57%",
                    width: "100%",
                  }}
                >
                  <h3
                    style={{
                      color: "#00529a",
                      fontWeight: "normal",
                      fontSize: "2vw",
                    }}
                  >
                    {payment.user.name}
                  </h3>
                  <h5
                    style={{
                      color: "#00529a",
                      fontSize: "2.5vw",
                      fontWeight: "lighter",
                    }}
                  >
                    Recargas aprobadas: {payment.countApproved}
                  </h5>
                </div>
              </div>
            ) : (
              <ImageIcon style={{ width: "100%" }} />
            )}
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="">
            <div className={classes.amount}>
              <b style={{ color: "#29627e" }}>Monto: </b>
              {formatNumber(payment.amount)} {payment.currency.abbr}
            </div>
            <div className={classes.amount} style={{ marginTop: "2.7vw" }}>
              <b style={{ color: "#29627e" }}>Fecha: </b>
              {dayjs(payment.createdAt).format("DD/MM HH:mm")}
            </div>
            <div style={{ position: "relative", marginTop: "2vw" }}>
              <div
                colSpan={12}
                style={{
                  width: "100%",
                  paddingBottom: "9px",
                  verticalAlign: "middle",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <b style={{ color: "#29627e", marginTop: "0.8vw" }}>
                    Comprobante:
                  </b>{" "}
                  <div style={{ width: "24px" }}>
                    <Link
                      to="#"
                      onClick={() => setshowImage(true)}
                      className={classes.buttonLogo}
                    >
                      <ImageIcon />
                    </Link>
                  </div>
                  {payment.user ? (
                    <div>
                      <a
                        href={"https://wa.me/" + payment.user.phone}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fab fa-whatsapp"
                          style={{
                            marginTop: "1vw",
                            fontSize: "4vw",
                            marginRight: "3vw",
                            color: "green",
                          }}
                        ></i>
                      </a>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={classes.state}
              style={{ backgroundColor: getStateColor(payment.approved) }}
              onClick={() => {
                if (canChange) {
                  setpaymentSelected(payment);
                  setShowModal(true);
                }
              }}
            >
              <b>{getStateLabel(payment.approved)}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
