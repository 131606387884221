import React from 'react'
import Header from '../components/admin/HeaderDashboard'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@mui/material/Grid'
import Loading from '../components/loaders/loading'
import Logo from '../assets/imagedash.svg'

const useStyles = makeStyles({
  container: {
    height: '100vh',
    overflow: 'auto'
  },
  row: {
    display: 'block',
    width: '100%'
  },
  image: {
    width: '100%',
    borderRadius: '10px',
    marginBottom: '15px'
  }
})

const Index = ({ children, colorMode = '#003f62', withoutPhoto = false, breadcrumbs = [], text = '', skipPadding }) => {
  const classes = useStyles()
  return (
    <Grid className={classes.container} style={{ backgroundColor: '#003353' }}>
      <Loading />
      <div className={ classes.row }>
        <Header text={text} withoutPhoto={withoutPhoto} breadcrumbs={breadcrumbs} />
      </div>
      <div className={ classes.row } style={{ marginTop: '60px' }}>
        <Container style={{ padding: skipPadding ? '0' : '1vw 3vw' }}>

          {children}
        </Container>
      </div>
    </Grid>
  )
}

export default Index
