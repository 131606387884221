import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { validateResponse } from "../helpers/index";
import { isNull } from "lodash";
import moment from "moment";
import { Axios } from "../helpers/axios";
import { toast } from "react-toastify";

const MySwal = withReactContent(Swal);
export const TransferencesSlice = createSlice({
  name: "transactions",
  initialState: {
    Transferences: [],
    lastTransactions: {
      assignedToday: [],
      pendings: [],
    },
    transactionHistory: {},
  },
  reducers: {
    setTransferences: (state, action) => {
      state.Transferences = action.payload ? action.payload.reverse() : [];
    },
    setLastTransactions: (state, action) => {
      state.lastTransactions.assignedToday =
        action.payload.assignedToday.reverse();
      state.lastTransactions.pendings = action.payload.pendings.reverse();
    },
    setTransactionHistory: (state, action) => {
      // state.transactionHistory = action.payload ? action.payload.reverse() : []
      state.transactionHistory = action.payload;
    },
  },
});

const { setTransferences, setLastTransactions, setTransactionHistory } =
  TransferencesSlice.actions;

export const fetchTransferences = (params) => async (dispatch) => {
  try {
    // console.log('params', params)
    await Axios.get(process.env.REACT_APP_API_URL + "transaction", {
      params,
    }).then((response) => {
      dispatch(setTransferences(response.data.data.rows.reverse()));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchTransferencesFunct = async (params) => {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_API_URL + "transaction",
      {
        params,
      }
    );

    return response.data.data.rows.reverse();
  } catch (e) {
    return [];
  }
};
export const createTransferenceStatus =
  (transId, statusId, issue) => async (dispatch) => {
    try {
      await Axios.post(
        process.env.REACT_APP_API_URL + "transactionstatus/store",
        {
          statusId: statusId,
          transactionId: transId,
          issue,
        }
      ).then((response) => {
        console.log(
          "%cMyProject%cline:52%cresponse",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(161, 23, 21);padding:3px;border-radius:2px",
          response.data
        );
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const createTransference = async (data) => {
  try {
    const response = await Axios.post(
      process.env.REACT_APP_API_URL + "transaction/store",
      data
    );
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const cancelTransference = async (id) => {
  try {
    const response = await Axios.put(
      process.env.REACT_APP_API_URL + "transaction/cancel/" + id
    );
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
// export const getBankBalancesByUser =  async dispatch => {

export const getAllLastTransactions = createAsyncThunk(
  "getBankBalancesByUser",
  async (dispatch) => {
    try {
      const { data: responseDTO } = await Axios.get(
        `${process.env.REACT_APP_API_URL}transaction/last`
      );

      console.log("==========responseDTO.data");
      console.log(responseDTO.data);

      dispatch(setLastTransactions(responseDTO.data));
    } catch (error) {
      if (error.response != undefined) {
        validateResponse(error.response.data);
      } else {
        MySwal.fire({
          text: error.message,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
  }
);

export const updateOperatorId =
  async (transactionId, operatorId) => async (dispatch) => {
    try {
      const { data: responseDTO } = await Axios.put(
        `${process.env.REACT_APP_API_URL}transaction/updateOperatorId/${transactionId}`,
        { operatorId: Number(operatorId) }
      );

      if (responseDTO.code === 0) {
        toast.success(responseDTO.description, {
          toastId: "success9",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (responseDTO.response != undefined) {
          validateResponse(responseDTO.response.data);
        } else {
          if (responseDTO.errors) {
            const messages = responseDTO.errors.map((e) => e.msg).join(", ");
            MySwal.fire({
              text: messages,
              customClass: {
                confirmButton: "swal2-blue-confirm-button",
              },
            });
          }
        }
      }
    } catch (error) {
      if (error.response != undefined) {
        validateResponse(error.response.data);
      } else {
        MySwal.fire({
          text: error.message,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
  };
export const finishTransaction = async (
  transactionId,
  capture,
  userBankId,
  History
) => {
  try {
    console.log({ transactionId, capture, userBankId });
    const { data } = await Axios.put(
      `${process.env.REACT_APP_API_URL}transaction/finishtransaction/${transactionId}`,
      { capture, userBankId }
    );
    console.log(data);
    History.push("/operator-dashboard");
    MySwal.fire({
      text: "Transacción finalizada con éxito!",
      customClass: {
        confirmButton: "swal2-blue-confirm-button",
      },
    });
  } catch (error) {
    if (error.response != undefined) {
      validateResponse(error.response.data);
    } else {
      MySwal.fire({
        text: error.message,
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
    }
  }
};

export const getTransactionHistory =
  async (start = null, end = null) =>
  async (dispatch) => {
    try {
      const dateStart = isNull(start)
        ? moment().format("YYYY-MM-DD")
        : moment(start).format("YYYY-MM-DD");
      const dateEnd = isNull(end)
        ? moment().format("YYYY-MM-DD")
        : moment(end).format("YYYY-MM-DD");

      const { data: responseDTO } = await Axios.get(
        `${process.env.REACT_APP_API_URL}transaction/history`,
        {
          params: {
            start: dateStart,
            end: dateEnd,
          },
        }
      );

      dispatch(setTransactionHistory(responseDTO.data));
    } catch (error) {
      if (error.response != undefined) {
        validateResponse(error.response.data);
      } else {
        MySwal.fire({
          text: error.message,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
  };

export default TransferencesSlice.reducer;
