import { formatNumber } from './../../../helpers/numbers'
import { useHistory } from 'react-router-dom'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

function PendingItem (props) {
  const History = useHistory()
  return (
    <p
    onClick={() => {
      History.push({ pathname: '/dashboard', state: { transaction: props.transaction } })
    }}
      style={{
        fontWeight: 'lighter',
        fontSize: '3vw',
        marginBottom: '1vw',
        color: 'rgb(227, 227, 227)',
        width: '92%',
        display: 'grid',
        gridTemplateColumns: '75% 25%'
      }}
    >
      <span>{props.name}</span>{' '}
      <span style={{ textAlign: 'right' }}>${formatNumber(props.amount)}</span>
    </p>
  )
}

export function Transactions ({ pendings, assignedToday }) {
  const percentaje =
    ((assignedToday.length / (pendings.length + assignedToday.length)) * 100).toFixed()

  const History = useHistory()

  return (
    <div
      className="handInHover"
      style={{
        background: '#e64808',
        padding: '3vw 4vw',
        marginTop: '4vw',
        borderRadius: '9px',
        boxShadow:
          '2.8 2.8 2.2 rgba(0, 0, 0, 0.02),   6.7 6.7 5.3 rgba(0, 0, 0, 0.028),   12.5 12.5 10 rgba(0, 0, 0, 0.035),   22.3 22.3 17.9 rgba(0, 0, 0, 0.042),   41.8 41.8 33.4 rgba(0, 0, 0, 0.05),   100 100 80 rgba(0, 0, 0, 0.07)'
      }}
    >
      <h3 style={{ fontSize: '4.4vw' }}>
      <b>{pendings ? pendings.length : 0}</b> Transaccion{ pendings.length == 1 ? '' : 'es'} sin asignar.
      </h3>

      {
        pendings.length != 0 &&
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '70% 30%',
          alignItems: 'center',
          marginTop: '3vw'
        }}
      >
        <div>
          {pendings.map((pending) => (
            <PendingItem
            key={JSON.stringify(pending)}
            transaction={pending}
              name={pending.bssAccount.name}
              amount={pending.amount}
            ></PendingItem>
          ))}
        </div>

        <div style={{ position: 'relative' }}>
          <CircularProgressbar
            value={percentaje}
            text={''}
            styles={buildStyles({
              textColor: '#f000',
              pathColor: '#86fb82',
              trailColor: 'black'
            })}
          />
          <h3
            style={{
              position: 'absolute',
              top: '17%',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <span style={{ fontSize: '2vw', fontWeight: 'lighter' }}>
               Asignadas:
            </span>

            <br />
            {percentaje}%
          </h3>
        </div>
      </div>
      }
    </div>
  )
}
