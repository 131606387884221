export const itemsMenu = [
  {
    label: 'Pendientes',
    icon: 'fas fa-exclamation-triangle',
    path: '/verifications'
  },
  {
    label: 'Aprobadas',
    icon: 'fas fa-check-double',
    path: '/verifications/approved'
  },
  {
    label: 'Rechazados',
    icon: 'fas fa-ban',
    path: '/verifications/rejected'
  }
]
