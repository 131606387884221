import React, { useCallback, useContext, useEffect, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { createBalances } from '../../../slices/balance'
import { uploadFile, uploadImage } from './../../../slices/files'
import { useDispatch } from 'react-redux'
import { BalanceForm } from './BalanceForm'
import { CustomBalanceContext } from '../../../context/CustomBalanceContext'
import Swal from 'sweetalert2'

import { Field, Formik } from 'formik'
import * as Yup from 'yup'

const useStyles = makeStyles({
  input: {
    borderRadius: 5,
    padding: '3vw 2vw 3vw 2vw',
    width: '75vw',
    fontSize: '5vw',
    border: 'none',
    marginLeft: '-1vw'
  },
  UploadImageContainer: {
    background: '#F4F4F4',
    marginTop: '5vw',
    padding: '8vw 4vw 8vw 4vw',
    borderRadius: 5,
    marginBottom: '6vw',
    position: 'relative'
  },
  UploadImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h3': {
      fontSize: '4vw',
      fontWeight: 'lighter'
    },
    '& svg': {
      width: '20%',
      height: '20%',
      marginBottom: '2vw'
    }
  },
  file: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: 3
  },
  trashIcon: {
    position: 'absolute',
    right: '5%',
    top: '5%',
    zIndex: 5
  },
  currencyList: {
    listStyle: 'none',
    width: '100%',
    minHeight: '130px',
    maxHeight: '130px',
    overflowY: 'scroll',
    border: '1px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '5px',
    color: 'rgba(0, 0, 0, 0.55)',
    textTransform: 'capitalize'
  },
  closeButton: {}
})

export const CustomBalanceItem = (props) => {
  const { children, title } = props

  return (
    <div style={{ margin: '4vw 0 8vw' }}>
      <h3 style={{ marginBottom: '2vw' }}>{title}</h3>
      {children}
  </div>
  )
}

export const FirstStep = (props) => {
  const classes = useStyles()

  const { handleNextStep } = props

  const
    {
      userCurrencies,
      balance,
      setBalance,
      currency,
      userSelected
    } = useContext(CustomBalanceContext)

  const initialValues = {
    currencyId: ''
  }

  return (
    <Formik
    initialValues={initialValues}
    onSubmit={(values) => {
      setBalance({ ...balance, currencyId: values.currencyId })
      handleNextStep()
    }}
    validationSchema={Yup.object().shape({
      currencyId: Yup.number().required('Selecciona una divisa')
    })}>
        {({ errors, touched }) => (
                <BalanceForm userName={userSelected?.name}>
                <div
                  style={{
                    background: '#F4F4F4',
                    margin: '4vw 0',
                    borderRadius: 5,
                    position: 'relative'
                  }}
                >
                <CustomBalanceItem title="Divisas actuales del cliente">
                  <ul className={classes.currencyList}>
                    {userCurrencies.map((currency) => {
                      return <li key={currency.id} style={{ margin: '2vw 5vw' }}>{currency.name}</li>
                    }
                    )}
                  </ul>
                </CustomBalanceItem>
                <CustomBalanceItem title="Selecciona una divisa">
                  <Field
                    as="select"
                    className={classes.input}
                    name="currencyId"
                    style={{
                      borderRadius: 5,
                      padding: '3vw 2vw',
                      width: '100%',
                      fontSize: '5vw',
                      border: '1px solid #00000021',
                      textTransform: 'capitalize'
                    }}
                  >
                    <option value="">
                      Selecciona una divisa
                    </option>
                      {currency.map((curr) => (
                      <option
                        key={curr.id}
                        value={parseInt(curr.id, 10)}
                      >
                        {curr?.name}
                      </option>
                      ))}
                  </Field>
                  {touched.currencyId && errors.currencyId && <span style={{ color: 'red' }}>{errors.currencyId}</span>}
                </CustomBalanceItem>
                </div>
              </BalanceForm>
        )}
    </Formik>

  )
}

export const SecondStep = (props) => {
  const { handleNextStep } = props

  const
    {
      setStep
    } = useContext(CustomBalanceContext)

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '300px',
      height: '300px',
      justifyContent: 'space-around'
    }}>
      <button
        style={{
          background: 'rgb(255, 124, 70) none repeat scroll 0% 0%',
          width: '100%',
          padding: '1vw 2vw',
          fontSize: '5vw',
          fontWeight: 'lighter',
          color: 'white',
          border: 'none',
          borderRadius: '5px'
        }}
        onClick={() => handleNextStep()}
      >
        Crear balance en 0
      </button>
      <button
        style={{
          background: 'rgb(255, 124, 70) none repeat scroll 0% 0%',
          width: '100%',
          padding: '1vw 2vw',
          fontSize: '5vw',
          fontWeight: 'lighter',
          color: 'white',
          border: 'none',
          borderRadius: '5px'
        }}
        onClick={() => setStep(3)}
      >
        Crear balance manualmente
      </button>
    </div>
  )
}

export const ThirdStep = (props) => {
  const classes = useStyles()

  const { handleSubmit } = props

  const
    {
      setPath,
      path,
      userSelected,
      setBalance,
      balance
    } = useContext(CustomBalanceContext)

  const handleFile = (file) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onloadend = function () {
      setPath(reader.result)
    }
  }

  const initialValues = {
    amount: '',
    capture: undefined
  }

  return (
      <Formik
        initialValues={initialValues}
        onSubmit={(e) => {
          // setBalance({
          //   ...balance,
          //   capture: e.capture,
          //   amount: e.amount
          // })
          handleSubmit({
            ...balance,
            capture: e.capture,
            amount: e.amount
          })
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number().required('Monto inválido'),
          capture: Yup.mixed().required('Selecciona una captura')
        })}
        >
        {({ errors, touched, setFieldValue, values }) => (
            <BalanceForm userName={userSelected?.name}>
              <CustomBalanceItem title="Ingrese un monto"/>
              <div>
              <Field
                style={{
                  borderRadius: 5,
                  padding: '3vw 2vw 3vw 2vw',
                  width: '100%',
                  fontSize: '5vw',
                  border: '1px solid #00000021',
                  marginBottom: '5px'
                }}
                  type="number"
                  placeholder="Monto"
                  name="amount"
              />
              {touched.amount && errors.amount && <span style={{ color: 'red' }}>{errors.amount}</span>}
              </div>
              <div className={classes.UploadImageContainer}>
              {path == '' && (
                <div className={classes.UploadImage}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35.286"
                    height="24"
                    viewBox="0 0 35.286 24"
                  >
                    <path
                      id="FontAwsome_cloud-upload-alt_"
                      data-name="FontAwsome (cloud-upload-alt)"
                      d="M28.8,42.425a5.149,5.149,0,0,0-4.8-7,5.117,5.117,0,0,0-2.855.868,8.572,8.572,0,0,0-16,4.275c0,.145.005.289.011.434A7.716,7.716,0,0,0,7.714,56H27.429A6.857,6.857,0,0,0,28.8,42.425Zm-7.725,3.289h-3.5v6a.86.86,0,0,1-.857.857H14.143a.86.86,0,0,1-.857-.857v-6h-3.5a.856.856,0,0,1-.605-1.463l5.646-5.646a.86.86,0,0,1,1.211,0l5.646,5.646A.857.857,0,0,1,21.075,45.714Z"
                      transform="translate(0 -32)"
                      fill="#707070"
                    />
                  </svg>
                  <h3>Subir captura de la transferencia</h3>
                </div>
              )}
              {path != '' && (
                <div>
                  <div className={classes.trashIcon} onClick={() => {
                    setPath('')
                    setFieldValue('capture', null)
                    document.getElementById('capture').value = null
                  }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.056"
                      height="23.778"
                      viewBox="0 0 12.056 13.778"
                    >
                      <path
                        id="FontAwsome_trash-alt_"
                        data-name="FontAwsome (trash-alt)"
                        d="M7.212,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H7.212a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,7.212,11.194Zm4.413-9.042H9.407L8.492.627A1.292,1.292,0,0,0,7.384,0H4.671A1.292,1.292,0,0,0,3.564.627L2.648,2.153H.431A.431.431,0,0,0,0,2.583v.431a.431.431,0,0,0,.431.431H.861v9.042a1.292,1.292,0,0,0,1.292,1.292H9.9a1.292,1.292,0,0,0,1.292-1.292V3.444h.431a.431.431,0,0,0,.431-.431V2.583A.431.431,0,0,0,11.625,2.153Zm-7-.783a.161.161,0,0,1,.139-.078h2.53a.161.161,0,0,1,.139.078l.47.783H4.155ZM9.9,12.486H2.153V3.444H9.9ZM4.2,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H4.2a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,4.2,11.194Z"
                      />
                    </svg>
                  </div>
                  <img src={path} style={{ width: '100%' }} />
                </div>
              )}
              <Field
                className={classes.file}
                type="file"
                accept="image/*"
                name="capture"
                value={undefined}
                onChange={(e) => {
                  handleFile(e.target.files[0])
                  setFieldValue('capture', e.target.files[0])
                }}
                id="capture"
              />
              {touched.capture && errors.capture &&
                <span style={{ color: 'red', position: 'absolute', bottom: '0', left: '0' }}>
                  {errors.capture}
                </span>
              }
            </div>
          </BalanceForm>
        )}
      </Formik>
  )
}

const CreateCustomBalance = ({ handleClose, openCustom }) => {
  const dispatch = useDispatch()

  const { step, setStep, userSelected, balance } = useContext(CustomBalanceContext)
  let { handleNextStep } = useContext(CustomBalanceContext)

  handleNextStep = () => {
    if (step === 2) handleSubmit({ ...balance, userId: userSelected.id, amount: 0 })
    else if (step === 3) handleSubmit()
    else setStep(step + 1)
  }

  const handleSubmit = async (balance) => {
    // Create the balance without a capture
    if (balance.capture === '' || !balance.capture) {
      createBalances({
        userId: userSelected.id,
        amount: parseInt(balance.amount, 10),
        currencyId: parseInt(balance.currencyId, 10),
        capture: null
      })
        .then((res) => {
          handleClose()
          Swal.fire({
            text: res.description,
            customClass: {
              confirmButton: 'swal2-blue-confirm-button'
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
    // If the capture is set, create a balance with a capture url
      dispatch(uploadImage(balance.capture))
        .then(async (e) => {
        // Upload the capture

          // Capture url
          let pathURL = null

          if (e?.payload?.original) pathURL = e.payload.original.location

          createBalances({
            userId: userSelected.id,
            amount: parseInt(balance.amount, 10),
            currencyId: parseInt(balance.currencyId, 10),
            capture: pathURL
          })
            .then((res) => {
              handleClose()
              Swal.fire({
                text: res.description,
                customClass: {
                  confirmButton: 'swal2-blue-confirm-button'
                }
              })
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openCustom}
      PaperProps={{
        style: { width: '95vw', padding: '5vw', minHeight: '20em' }
      }}
    >
      {step === 1 ? <FirstStep handleNextStep={handleNextStep}/> : null}
      {step === 2 ? <SecondStep handleNextStep={handleNextStep}/> : null}
      {step === 3 ? <ThirdStep handleSubmit={handleSubmit}/> : null}
    </Dialog>
  )
}

export default CreateCustomBalance
