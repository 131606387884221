import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography
} from '@material-ui/core'
import Header from '../components/Header'
import TransactionCard from '../components/transactions/transactionCard'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTransferences } from '../slices/transactions'

const useStyles = makeStyles({
  container: {
    flex: '1',
    flexDirection: 'column',
    minHeight: '100vh',
    flexWrap: 'nowrap',
    color: '#fff'
  },
  content: {
    padding: 15,
    backgroundColor: '#fff',
    borderRadius: '5px 0 0 0',
    color: '#464646'
  },
  divider: {
    width: '100%',
    height: 1,
    border: 0,
    borderTop: '1px solid #c9cace',
    marginTop: 10,
    marginBottom: 15
  }
})

function HistoryTransactions () {
  const classes = useStyles()

  const dispatch = useDispatch()
  const transactions = useSelector(state => state.transactions.Transferences)

  useEffect(() => {
    dispatch(fetchTransferences())
  })

  return (
    <Grid container className={classes.container}>
        <Header text="Historial de Saldos" />

        <Grid container className={classes.content}>

            <Typography style={{ fontSize: 18, fontWeight: 800 }}>
                Últimas transacciones
            </Typography>
            <hr className={classes.divider} />

            {transactions.map(transaction => (
                    <TransactionCard key={JSON.stringify(transaction)} transaction={transaction}/>
            ))}

        </Grid>
    </Grid>
  )
}

export default HistoryTransactions
