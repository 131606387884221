import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { isEmpty } from "lodash";
import { Typography, Button } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import TransfiriendoStatus from "../status/transfiriendo";
import DatosErroneos from "../status/datoserroneos";
import EnEsperaStatus from "../status/enespera";
import BottomNavigator from "../../layouts/bottomBar/index";
import { useHistory } from "react-router-dom";
import PendienteStatus from "./../status/pendiente";
import FinalizadoStatus from "./../status/finalizada";
import { formatNumber } from "./../../helpers/numbers";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "8px",
    borderRadius: "10px",
    width: "100%",
    backgroundColor: "#fff",
    height: "90px",
    padding: "10px",
    fontSize: "12px",
  },
  amount: {
    paddingTop: "0px",
    verticalAlign: "middle",
  },
  logo: {
    width: "170px",
    position: "absolute",
    top: "-52px",
    left: "0px",
  },
  logoContainer: {
    width: "62%",
    textAlign: "center",
    position: "relative",
    overflow: "hidden",
  },
  state: {
    borderRadius: "3px",
    verticalAlign: "middle",
    textAlign: "center",
    padding: "6px",
    color: "#fff",
  },
  buttonLogo: {
    textDecoration: "underline",
    position: "absolute",
    bottom: "1px",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "5vw 5vw",
    width: "80vw",
    border: "none",
    display: "flex",
    justifyContent: "space-evenly",
    "@media (min-width: 700px)": {
      padding: "5vw 5vw",
    },
  },
  modalLogo: {
    width: "100px",
    position: "relative",
    top: "-30px",
  },
  toggleButtonState: {
    color: "#eee",
    marginRight: "15px",
    marginLeft: "15px",
    borderRadius: "5px",
  },
  container: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    backgroundColor: "#FA7D4A",
  },
  content: {
    padding: 30,
    paddingTop: 15,
  },
  flex: {
    display: "grid",
    padding: "10px 20px 10px 20px",
    background: "white",
    alignItems: "center",
    borderRadius: "5px",
    marginBottom: "2vw",
    justifyContent: "space-between",
    gridTemplateColumns: "50% 50%",
  },
  bankLogo: {
    width: "25vw",
    padding: 12,
    borderRadius: 10,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 60,
    backgroundColor: "#003F62",
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

export const stateColor = Object.freeze({
  Approved: "#67bb4c",
  Pending: "#f2af3b",
  Rejected: "#d74f4d",
});

export const stateTransaction = Object.freeze({
  Approved: 1,
  Pending: 2,
  Rejected: 3,
});

export default function Card({ transaction, finished, onClick }) {
  const classes = useStyles();
  const history = useHistory();

  const [messaage, setMessaage] = useState("");

  const createdAt = new Date(transaction.transactionStatus[0].createdAt);
  const now = new window.Date();

  const difference = now.getTime() - createdAt.getTime(); // This will give difference in milliseconds
  const resultInMinutes = Math.round(difference / 60000);

  useEffect(() => {
    if (resultInMinutes < 2) {
      setMessaage("Justo ahora");
    }
    if (resultInMinutes > 60) {
      setMessaage(
        `Hace ${(resultInMinutes / 60).toFixed(0)} ${
          (resultInMinutes / 60).toFixed(0) == "1" ? "hora" : "horas"
        } `
      );
    }
    if (resultInMinutes > 1440) {
      setMessaage(
        `Hace ${(resultInMinutes / 1440).toFixed(0)} ${
          (resultInMinutes / 1440).toFixed(0) == "1" ? "día" : "días"
        }`
      );
    }
    if (resultInMinutes < 60 && resultInMinutes > 2) {
      setMessaage(`Hace ${resultInMinutes} minutos`);
    }
  }, [transaction]);

  return (
    <>
      <div className={classes.container}>
        <div
          onClick={() => {
            if (!finished) {
              localStorage.setItem("transactionIdDetails", transaction.id);
              history.push({
                pathname: "/operador/" + transaction.id,
                state: {
                  transaction: transaction,
                },
              });
            }
          }}
          className={classes.flex}
          style={{ background: finished ? "#e8e8e8" : "white" }}
        >
          <div>
            <Typography
              style={{
                color: "#003F62",
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 1.2,
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              Monto: {formatNumber(transaction.bssAmount)}
            </Typography>
            <Typography
              style={{
                color: "#003F62",
                fontSize: 14,
                lineHeight: 1.2,
              }}
            >
              {transaction.bssAccount.name}
            </Typography>
            <Typography
              style={{
                color: "#003F62",
                fontSize: 14,
                lineHeight: 1.2,
              }}
            >
              V {transaction.bssAccount.identification}
            </Typography>
            <Typography
              style={{
                color: "#003F62",
                fontSize: 14,
                lineHeight: 1.2,
              }}
            >
              Cta - {transaction.bssAccount.bssAccountType.name}
            </Typography>
            {transaction.transactionStatus[0].statusId == 1 && (
              <PendienteStatus></PendienteStatus>
            )}
            {transaction.transactionStatus[0].statusId == 2 && (
              <EnEsperaStatus></EnEsperaStatus>
            )}
            {transaction.transactionStatus[0].statusId == 3 && (
              <TransfiriendoStatus></TransfiriendoStatus>
            )}
            {transaction.transactionStatus[0].statusId == 4 && (
              <DatosErroneos></DatosErroneos>
            )}
            {transaction.transactionStatus[0].statusId == 5 && (
              <FinalizadoStatus></FinalizadoStatus>
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            <img
              className={classes.bankLogo}
              src={transaction.bssAccount.bank.Logo}
            />
            <h5
              style={{
                textAlign: "right",
                color: "#afa8a8",
                fontWeight: "lighter",
              }}
            >
              {messaage}
            </h5>
          </div>
        </div>
        <BottomNavigator></BottomNavigator>
      </div>
    </>
  );
}
