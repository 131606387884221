import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'
import Swal from 'sweetalert2'

export const uploadFile = createAsyncThunk('uploadFile', async (image) => {
  const file = document.getElementById('file').files[0]
  const formData = new FormData()
  formData.append('file', file)
  try {
    const response = await Axios.post(
      process.env.REACT_APP_API_URL + 'upload/image?directory=test2',
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    )

    console.log(response)

    return response.data.data
  } catch (error) {
    Swal.fire({
      text: 'La imagen no puede superar los 15 MB!',
      icon: 'error',
      customClass: {
        confirmButton: 'swal2-blue-confirm-button'
      }
    })
    return false
  }
})

export const uploadImage = createAsyncThunk('uploadFile', async (image) => {
  const formData = new FormData()
  formData.append('file', image)
  try {
    const response = await Axios.post(
      process.env.REACT_APP_API_URL + 'upload/image?directory=test2',
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    )
    return response.data.data
  } catch (error) {
    Swal.fire({
      text: 'La imagen no puede superar los 15 MB!',
      icon: 'error',
      customClass: {
        confirmButton: 'swal2-blue-confirm-button'
      }
    })
    return false
  }
})

export const filesSlice = createSlice({
  name: 'filesSlice',
  initialState: {},
  extraReducers: {}
})

export default filesSlice.reducer
