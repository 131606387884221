import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'
import Select from 'react-select'
import AppContext from './../../../context/AppContext'
import { formatNumber } from './../../../helpers/numbers'

const useStyles = makeStyles({
  container: {
    margin: '0vw 5vw 0 5vw'
  },
  row: {
    margin: '2vw',
    marginBottom: '3vw',
    marginLeft: 0,
    display: 'flex',
    '& > div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      '& .orange': {
        color: '#FA7D4A',
        fontWeight: 'bolder'
      }
    },
    '& svg': {
      marginRight: 15,
      marginTop: '-0.1vw',
      width: 20,
      height: 20
    }
  },
  UploadImageContainer: {
    background: '#F4F4F4',
    marginTop: '5vw',
    padding: '8vw 4vw 8vw 4vw',
    borderRadius: 5,
    position: 'relative'
  },
  UploadImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h3': {
      fontSize: '4vw',
      fontWeight: 'lighter'
    },
    '& svg': {
      width: '20%',
      height: '20%',
      marginBottom: '2vw'
    }
  },
  subtitle: {
    marginTop: '5vw',
    marginBottom: '5vw',
    marginLeft: 0,
    color: '#FA7D4A'
  },
  InputContainer: {
    display: 'grid',
    gridTemplateColumns: '72% 25%',
    marginBottom: '5vw',
    gridGap: '2vw',
    '& button': {
      borderRadius: 3,
      border: 'none',

      color: 'white',
      fontWeight: 'bold',
      fontSize: '3vw'
    }
  },
  Input: {
    border: '3px solid #ECECEC',
    padding: '2vw 1vw 2vw 1vw',
    borderRadius: 3,
    '& input': {
      border: 'none',
      '&:focus': {
        border: 'none',
        outline: 'none'
      }
    },
    '& span': {
      fontWeight: 'bold',
      color: '#969696'
    }
  },
  file: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: 3
  },
  trashIcon: {
    position: 'absolute',
    right: '5%',
    top: '5%',
    zIndex: 5
  }
})

export default function ModalTransferirDetails ({
  setimage,
  setBssBalance,
  update,
  bssBalances,
  loadingBalanceChange
}) {
  const classes = useStyles()
  const [bssBalance, setbssBalanceState] = useState({})
  const { addEvent } = useContext(AppContext)
  const [hideFiles, sethideFiles] = useState(true)
  const [menuIsOpen, setmenuIsOpen] = useState(false)

  addEvent('changeBssBalanceTotal', (e) => {
    setbssBalanceState({
      ...bssBalance,
      total: e
    })
  })

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 20,
      background: state.isSelected ? 'white' : 'white'
    }),
    control: (provied) => ({
      ...provied,
      background: 'white',
      border: 'none'
    })
  }

  const Control = () => {
    return (
      <div
        onClick={() => {
          setmenuIsOpen(true)
          sethideFiles(false)
        }}
        style={{
          display: 'grid',
          backgroundColor: 'white',
          padding: '2.5vw 4vw 2vw 3vw',
          borderRadius: '6px',
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.16)',
          marginTop: '4vw'

        }}
      >

            <h1 style={{ fontSize: 15, fontWeight: 'normal' }}>
              {bssBalance?.userBank?.name || 'Selecciona una cuenta'}
            </h1>

      </div>
    )
  }

  function OptionComponent ({ innerRef, innerProps, data }) {
    return (
      <div
        style={{
          display: 'grid',
          padding: '1.5vw 4vw 1vw 3vw',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
          gridTemplateColumns: '19% auto'

        }}
        onClick={() => {
          console.log(data)
          setbssBalanceState(data)
          sethideFiles(true)
          setmenuIsOpen(false)
          setBssBalance(data)
          document.activeElement.blur()
        }}
      >
        <img style={{ width: '100%' }} src={data.userBank.bank.Logo}/>
        <h1
          style={{
            padding: '2vw 0vw',
            fontSize: 15,
            marginLeft: '3vw',
            fontWeight: 'normal'
          }}
        >
          {data.userBank.name}
        </h1>
      </div>
    )
  }

  return (
    <div className={classes.container}>

      <div style={{ marginBottom: '5vw', marginTop: '3vw' }}>
        {
          bssBalances &&
        <Select
          onFocus={() => sethideFiles(!hideFiles)}
          onBlur={() => sethideFiles(true)}
          onChange={(e) => {
            setbssBalanceState(e)
            setBssBalance(e)
            document.activeElement.blur()
          }}
          getOptionLabel={(e) => e.userBank.name}
          placeholder="Seleccionar cuenta"
          menuIsOpen={menuIsOpen}
          components={{ Option: OptionComponent, Control }}
          styles={customStyles}
          onMenuClose={() => sethideFiles(true)}
          options={bssBalances.filter(bss => bss.total != null)}
        />
      }

      </div>

      <Rows bssBalance={bssBalance}></Rows>
      <Divider variant="large" />

        <div className={hideFiles ? ' ' : 'hideThis'}>
        <UploadImage setimage={setimage}></UploadImage>
        </div>
        {
          hideFiles == false && <div style={{ height: '20vh' }}></div>
        }

    </div>
  )
}

function Rows ({ bssBalance }) {
  const classes = useStyles()

  return (
    <>
      <div className={classes.row}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.625"
          height="12.625"
          viewBox="0 0 12.625 12.625"
        >
          <path
            id="FontAwsome_coins_"
            data-name="FontAwsome (coins)"
            d="M0,9.994v1.053c0,.87,2.121,1.578,4.734,1.578s4.734-.708,4.734-1.578V9.994a8.967,8.967,0,0,1-4.734,1.053A8.967,8.967,0,0,1,0,9.994ZM7.89,3.156c2.614,0,4.734-.708,4.734-1.578S10.5,0,7.89,0,3.156.708,3.156,1.578,5.277,3.156,7.89,3.156ZM0,7.407V8.679c0,.87,2.121,1.578,4.734,1.578S9.468,9.55,9.468,8.679V7.407A7.94,7.94,0,0,1,4.734,8.679,7.94,7.94,0,0,1,0,7.407Zm10.257.271C11.67,7.4,12.625,6.9,12.625,6.312V5.259a6.055,6.055,0,0,1-2.367.851ZM4.734,3.945C2.121,3.945,0,4.828,0,5.918S2.121,7.89,4.734,7.89s4.734-.883,4.734-1.973S7.348,3.945,4.734,3.945Zm5.407,1.388c1.479-.266,2.483-.789,2.483-1.388V2.892A8.027,8.027,0,0,1,8.662,3.923,2.761,2.761,0,0,1,10.142,5.333Z"
            fill="#f96123"
          />
        </svg>

        <div>
          <span>Saldo actual:</span>
          {bssBalance.total && (
            <span
              className="orange"
              style={{
                fontSize:
                  bssBalance.total.toString().length > 10 ? '4vw' : '5vw'
              }}
            >
              {formatNumber(bssBalance.total)} BS
            </span>
          )}
        </div>
      </div>

      <div className={classes.row}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.058"
          height="14.054"
          viewBox="0 0 15.058 14.054"
        >
          <path
            id="FontAwsome_university_"
            data-name="FontAwsome (university)"
            d="M31.058,35.011v.5a.251.251,0,0,1-.251.251h-.753v.376a.376.376,0,0,1-.376.376H17.38A.376.376,0,0,1,17,36.141v-.376h-.753A.251.251,0,0,1,16,35.513v-.5a.251.251,0,0,1,.155-.232l7.278-2.761a.251.251,0,0,1,.192,0L30.9,34.779A.251.251,0,0,1,31.058,35.011ZM30.3,44.548H16.753A.753.753,0,0,0,16,45.3v.5a.251.251,0,0,0,.251.251H30.807a.251.251,0,0,0,.251-.251v-.5A.753.753,0,0,0,30.3,44.548Zm-11.8-7.529v6.023H17.38a.376.376,0,0,0-.376.376v.627h13.05v-.627a.376.376,0,0,0-.376-.376H28.548V37.019H26.54v6.023H24.533V37.019H22.525v6.023H20.517V37.019Z"
            transform="translate(-16 -32)"
            fill="#f96123"
          />
        </svg>

        <div>
          <span>Cuenta:</span>{' '}
          <span className="orange">{bssBalance?.userBank?.name}</span>
        </div>
      </div>
    </>
  )
}
function UploadImage ({ setimage }) {
  const classes = useStyles()

  const [path, setpath] = useState('')

  const fileSelected = () => {
    const file = document.getElementById('file').files[0]
    const reader = new FileReader()
    reader.onload = function (e) {
      const image = document.createElement('img')
      image.src = e.target.result
      setimage(e.target.result)
      setpath(e.target.result)
    }
    reader.readAsDataURL(file)
  }
  return (
    <>
      <div className={classes.UploadImageContainer}>
        {path == '' && (
          <div className={classes.UploadImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34.286"
              height="24"
              viewBox="0 0 34.286 24"
            >
              <path
                id="FontAwsome_cloud-upload-alt_"
                data-name="FontAwsome (cloud-upload-alt)"
                d="M28.8,42.425a5.149,5.149,0,0,0-4.8-7,5.117,5.117,0,0,0-2.855.868,8.572,8.572,0,0,0-16,4.275c0,.145.005.289.011.434A7.716,7.716,0,0,0,7.714,56H27.429A6.857,6.857,0,0,0,28.8,42.425Zm-7.725,3.289h-3.5v6a.86.86,0,0,1-.857.857H14.143a.86.86,0,0,1-.857-.857v-6h-3.5a.856.856,0,0,1-.605-1.463l5.646-5.646a.86.86,0,0,1,1.211,0l5.646,5.646A.857.857,0,0,1,21.075,45.714Z"
                transform="translate(0 -32)"
                fill="#707070"
              />
            </svg>
            <h3>Subir captura de la transferencia</h3>
          </div>
        )}
        {path != '' && (
          <div>
            <div className={classes.trashIcon} onClick={() => setpath('')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.056"
                height="23.778"
                viewBox="0 0 12.056 13.778"
              >
                <path
                  id="FontAwsome_trash-alt_"
                  data-name="FontAwsome (trash-alt)"
                  d="M7.212,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H7.212a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,7.212,11.194Zm4.413-9.042H9.407L8.492.627A1.292,1.292,0,0,0,7.384,0H4.671A1.292,1.292,0,0,0,3.564.627L2.648,2.153H.431A.431.431,0,0,0,0,2.583v.431a.431.431,0,0,0,.431.431H.861v9.042a1.292,1.292,0,0,0,1.292,1.292H9.9a1.292,1.292,0,0,0,1.292-1.292V3.444h.431a.431.431,0,0,0,.431-.431V2.583A.431.431,0,0,0,11.625,2.153Zm-7-.783a.161.161,0,0,1,.139-.078h2.53a.161.161,0,0,1,.139.078l.47.783H4.155ZM9.9,12.486H2.153V3.444H9.9ZM4.2,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H4.2a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,4.2,11.194Z"
                />
              </svg>
            </div>
            <img src={path} style={{ width: '100%' }} />
          </div>
        )}
        <input
          className={classes.file}
          type="file"
          accept="image/*"
          id="file"
          onChange={fileSelected}
        />
      </div>
    </>
  )
}
function Input ({ update, bssBalance, loadingBalanceChange }) {
  const classes = useStyles()
  const [amount, setamount] = useState(0)
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Input}>
        <input
          placeholder="0"
          onChange={(e) => setamount(e.target.value)}
          type="number"
        />
        <span>BS</span>
      </div>
      <button
        style={{
          background:
            bssBalance.total == null || loadingBalanceChange
              ? '#b8b8b8'
              : '#FA7D4A'
        }}
        disabled={bssBalance.total == null}
        onClick={() => update(amount, bssBalance)}
      >
        {' '}
        {loadingBalanceChange ? 'Actualizando' : 'Actualizar'}{' '}
      </button>
    </div>
  )
}
