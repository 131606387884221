import { createSlice } from "@reduxjs/toolkit";
import { isArray, isUndefined } from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Axios } from "../helpers/axios";

export const CodeType = Object.freeze({
  Register: 1,
  Recovery: 2,
});

const CodeAction = Object.freeze({
  Send: 1,
  Verify: 2,
});

const MySwal = withReactContent(Swal);
export const codeSlice = createSlice({
  name: "code",
  initialState: {
    code: "",
    email: "",
    type: null,
    errors: "",
  },
  reducers: {
    setData: (state, action) => {
      state.email = action.payload.email;
      state.type = action.payload.type;
    },
    setEmail(state, action) {
      state.email = action.payload.email;
    },
    setCode(state, action) {
      state.code = action.payload.code;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
  },
});

const { setData, setErrors, setEmail, setCode } = codeSlice.actions;

const baseUrl = String(process.env.REACT_APP_API_URL);

const getEndPointByType = (type, action) => {
  let endpoint = "";
  switch (type) {
    case CodeType.Recovery:
      endpoint =
        action == CodeAction.Send
          ? "code/recovery-send"
          : "code/recovery-verify";
      break;
    case CodeType.Register:
      endpoint =
        action == CodeAction.Send
          ? "code/register-send"
          : "code/register-verify";
      break;
  }
  return endpoint;
};
export const validateResponse = (responseDTO) => {
  if (!isUndefined(responseDTO.errors) && isArray(responseDTO.errors)) {
    const htmlErrors = responseDTO.errors.map((error) => {
      return `<div className="station">${error.msg}</div>`;
    });

    MySwal.fire({
      html: htmlErrors.join("<br>"),
      customClass: {
        confirmButton: "swal2-blue-confirm-button",
      },
    });
  }
};

export const setEmailAndType = (email, type) => (dispatch) => {
  dispatch(setData({ email, type }));
};

export const verifyCode =
  async (email, code, type, history) => async (dispatch) => {
    try {
      const endpoint = getEndPointByType(type, CodeAction.Verify);
      const responseDTO = await Axios.post(`${baseUrl}${endpoint}`, {
        email,
        code,
      });
      console.log("type ", type);

      switch (type) {
        case CodeType.Register:
          history.push("login");
          break;
        case CodeType.Recovery:
          console.log("responseDTO.data", responseDTO.data);
          if (responseDTO?.data?.code == 0) {
            localStorage.setItem("codeRecovery", code);
            dispatch(setCode({ code: code }));
            history.push("change-password");
          }
          break;
      }
    } catch (error) {
      if (error.response != undefined) {
        switch (error.response.data.code) {
          case 20:
            dispatch(setErrors(error.response.data.description));
            break;
          default:
            validateResponse(error.response.data);
            break;
        }
      } else {
        MySwal.fire({
          text: error.message,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
  };

export const sendCode = async (email, type, history) => async (dispatch) => {
  try {
    const endpoint = getEndPointByType(type, CodeAction.Send);
    const response = await Axios.post(`${baseUrl}${endpoint}`, { email });
    if (!isUndefined(response.data.errors) && isArray(response.data.errors)) {
      const htmlErrors = response.data.errors.map((error) => {
        return `<div className="station">${error.msg}</div>`;
      });

      MySwal.fire({
        html: htmlErrors.join("<br>"),
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
      return false;
    }

    MySwal.fire({
      text: "¡Código enviado!",
      icon: "success",
      customClass: {
        confirmButton: "swal2-blue-confirm-button",
      },
    });

    dispatch(setData({ email: email, type: type }));

    switch (type) {
      case CodeType.Recovery:
        history.push("/code-recovery");
        break;
      case CodeType.Register:
        break;
    }
  } catch (error) {
    console.log(error);
    MySwal.fire({
      text: "Este correo no exíste!",
      icon: "error",
      customClass: {
        confirmButton: "swal2-blue-confirm-button",
      },
    });
  }
};

export default codeSlice.reducer;
