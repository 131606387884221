import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchVerifications, updateVerification } from '../../slices/verifications.js'
import Verifications from './index.jsx'
import VerificationsRejected from './rejected/index'
import VerificationsAprobed from './aprobed/index'
import { useLocation } from 'react-router-dom'

const Index = (status) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const verificationsList = useSelector((state) => state.verifications.verifications)

  const [open, setModalSetStatus] = useState(false)
  const [userSelected, setUserSelected] = useState({})

  const [statusPage, setstatusPage] = useState('')

  const handleSubmit = async (form, motive) => {
    setModalSetStatus(false)

    const data = {
      phone: userSelected.phone,
      id: userSelected.id,
      verificationStateId: form,
      cause: motive != null && motive != '' ? motive : '   '
    }

    await updateVerification(data)

    dispatch(fetchVerifications()) // lint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    let status = location.pathname.split('/')
    status = status[status.length - 1]
    setstatusPage(status)
    // console.log({ statusPage })
    dispatch(fetchVerifications())
  }, [])

  useEffect(() => {
    // console.log('location')
    // console.log(location)
    if (location.state?.verification) {
      console.log('verification', location.state.verification)
      setUserSelected(location.state.verification)
      setModalSetStatus(true)
    }
  }, [])

  const props = {
    handleSubmit, modalSetStatus: open, setModalSetStatus, userSelected, setUserSelected, open
  }

  if (statusPage == 'verifications') {
    return (
            <Verifications {...props} verificationsList={verificationsList.filter(e => e.verificationStateId == 3)}/>
    )
  } else if (statusPage == 'approved') {
    return (
            <VerificationsAprobed {...props} verificationsList={verificationsList.filter(e => e.verificationStateId == 1)}/>
    )
  } else if (statusPage == 'rejected') {
    return (
            <VerificationsRejected {...props} verificationsList={verificationsList.filter(e => e.verificationStateId == 2)}/>
    )
  } else {
    return <div></div>
  }
}

export default Index
