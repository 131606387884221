import { formatNumber } from '../../../../helpers/numbers'

export default function Balance () {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '5vw',
      alignItems: 'center'
    }}><div>
      <img style={{
        width: '25vw',
        borderRadius: '50%',
        marginRight: '3vw'
      }} src="https://www.biography.com/.image/t_share/MTc5OTk2ODUyMTMxNzM0ODcy/gettyimages-1229892983-square.jpg" />
    </div><div
      style={{
        textAlign: 'center',
        background: '#053752',
        width: '62vw',
        margin: 'auto',
        borderRadius: '5px',
        height: '30vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
        <h3
          style={{
            color: '#f96123'
          }}
        >
          Balance actual:
        </h3>
        <h1
          style={{
            color: 'white',
            marginTop: '2vw',
            fontSize: '7vw'
          }}
        >
          {formatNumber(45000)} BS
        </h1>

        <h5 style={{
          marginTop: '2vw',
          color: 'wheat'
        }}>Envios finalizados: <span>5</span></h5>
      </div></div>
  )
}
