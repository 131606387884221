import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import {
  getAllLastTransactions,
  updateOperatorId,
  createTransferenceStatus,
  cancelTransference,
} from "../../../slices/transactions";
import { getBankBalancesByUser } from "../../../slices/bssbalance";
import Typography from "@material-ui/core/Typography";
import TransactionCard from "../../../components/admin/transactionCard";
import { isEmpty } from "lodash";
import MainLayout from "../../../layouts/mainDashboard";
import Ilustration from "../../../assets/ilustrations/dashboard ilustracion.svg";
import IlustrationTransactionEmpty from "../../../assets/ilustrations/ilustraciondashsTransactions.svg";
import "../../../assets/styles/dashboard.scss";
import "../../../assets/styles/transference.scss";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import AppContext from "./../../../context/AppContext";
import Swal from "sweetalert2";
import Screenloading from "./../../../components/loaders/screenLoading";
import SectionLayout from "../../../layouts/section_layout/section_layout";
import SummaryNewDashboard from "../../../components/dashboard/summary_new_dashboard";

function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { emitEvent, addEvent } = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [pendingsArray, setpendingsArray] = useState([]);
  const [assignedTodayArray, setassignedTodayArray] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [transactionSelected, settransactionSelected] = useState({});

  const { pendings, assignedToday } = useSelector(
    (state) => state.transactions.lastTransactions
  );
  const rows = useSelector((state) => state.bssbalance.rows);

  const sendForm = async (transactionId, operatorId) => {
    await dispatch(await updateOperatorId(transactionId, operatorId)).then(
      () => {
        dispatch(getAllLastTransactions(dispatch));
      }
    );
  };

  const getData = () => {
    setloading(true);

    try {
      dispatch(getBankBalancesByUser(dispatch)).then(() => {
        dispatch(getAllLastTransactions(dispatch)).then(() => {
          setloading(false);
        });
      });
    } catch (error) {
      setloading(false);
    }
  };

  useEffect(() => {
    setpendingsArray(pendings.slice());
  }, [pendings]);

  useEffect(() => {
    setassignedTodayArray(assignedToday.slice());
  }, [assignedToday]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (location && emitEvent) {
      emitEvent("closeSockets");
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (location.state?.transaction) {
      console.log("verification", location.state.transaction);
      settransactionSelected(location.state.transaction);
      setShowModal(true);
    }
  }, []);

  const breadcrumbs = [
    {
      text: "Inicio",
      href: "/dashboard",
    },
  ];

  const removeOperatorFromTransference = async (transference) => {
    dispatch(createTransferenceStatus(transference.id, 1, "")).then((e) => {
      dispatch(getAllLastTransactions(dispatch));
    });
  };

  const cancelTransaction = async () => {
    const confirmation = await Swal.fire({
      icon: "info",
      showDenyButton: true,
      confirmButtonText: "Sí",
      text: "Seguro de cancelar la transacción?",
      customClass: {
        confirmButton: "swal2-blue-confirm-button",
      },
    });

    if (confirmation.isConfirmed) {
      setloading(true);
      setShowModal(false);
      try {
        const { data } = await cancelTransference(transactionSelected.id);
        console.log("response", data);
        if (data.code == 0) {
          toast.success("Transferencia cancelada!", {
            toastId: "success2",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        toast.error("Algo salio mal.", {
          toastId: "success2",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      dispatch(getAllLastTransactions(dispatch));
      setloading(false);
    }
  };

  return (
    <SectionLayout title="Hola David!" returnHeader={false}>
      <ToastContainer />
      {loading && <Screenloading label="Cargando"></Screenloading>}

      <SummaryNewDashboard
        assignedTodayArray={assignedTodayArray}
        pendingsArray={pendingsArray}
      />
      <div
        style={{ padding: "0 3vw 0 3vw" }}
        className="hidemakeStyles-modalRate-46"
      >
        <Typography
          style={{
            color: "#003F62",
            marginBottom: "5vw",
            marginTop: "2vh",
            fontSize: "4vw",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Asigna las transacciones </span>
          <i
            onClick={getData}
            className="fa-solid fa-rotate-right"
            style={{ color: "#f96123", fontSize: "5vw" }}
          ></i>
        </Typography>

        {isEmpty(pendingsArray) ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={IlustrationTransactionEmpty} style={{ width: "75%" }} />
            <h5
              style={{
                width: "100%",
                textAlign: "center",
                color: "#61504f",
                marginBottom: "6vw",
                marginTop: "-6vw",
                fontSize: "4vw",
              }}
            >
              No tienes transacciones pendientes por asignar
            </h5>
          </div>
        ) : (
          pendingsArray.map((transaction) => {
            return (
              <TransactionCard
                transaction={transaction}
                message={""}
                cancelTransaction={cancelTransaction}
                settransactionSelected={settransactionSelected}
                transactionSelected={transactionSelected}
                key={transaction.id}
                operators={rows}
                sendForm={sendForm}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            );
          })
        )}
        <br />
        {/*   {isEmpty(assignedTodayArray) ? (
          <div></div>
        ) : (
          <>
            <Typography style={{ color: "#fff", marginBottom: "2vw" }}>
              Transacciones Asignadas Hoy
            </Typography>
            {assignedTodayArray.map((transaction) => {
              return (
                <TransactionCard
                  transaction={transaction}
                  message={""}
                  key={transaction.id}
                  operators={rows}
                  removeOperatorFromTransference={
                    removeOperatorFromTransference
                  }
                  sendForm={sendForm}
                  assigned={true}
                />
              );
            })}
          </>
        )} */}
      </div>
    </SectionLayout>
  );
}

export default Dashboard;
