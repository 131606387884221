// Dependencies
import { makeStyles } from '@material-ui/core'
import { Button } from '@mui/material'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import * as Yup from 'yup'

// Slices
import {
  createCurrency,
  createCurrencyValues,
  deleteCurrency,
  fetchCurrencies,
  getCurrencyDetails,
  updateCurrencyValues
} from '../../slices/currencies'

// Components
import { FormikMuiTextField } from '../form/Formik/TextField'
import { FormikCurrencyInput } from '../form/Formik/CurrencyInput'
import CountrySelect from '../country-select'

// Custom Styles
const useStyles = makeStyles({
  screenTitle: {
    color: 'white',
    margin: '2% 0 5.5%'
  }
})

// Component used for edit the currency properties
export const CurrencyForm = (props) => {
  const { currency = [], isEdit = false, countries, onSubmit } = props

  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: currency?.name || '',
        abbr: currency?.abbr || '',
        country: {
          name: currency?.country?.name || 'Chile',
          image: currency?.country?.image || 'https://flagcdn.com/w160/cl.png',
          id: currency?.country?.id || 1
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('¡Campo requerido!')
          .matches(
            /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
            'Solo se permiten letras y espacios'
          ),
        abbr: Yup.string()
          .required('¡Campo requerido!')
          .matches(/^[A-Za-z]+$/)
      })}
      onSubmit={onSubmit}
    >
      <Form
        style={{
          backgroundColor: '#FFF',
          borderRadius: '5px',
          marginBottom: '4%'
        }}
      >
        <div
          style={{
            backgroundColor: '#FFF',
            padding: '6% 5% 3%',
            borderRadius: 'inherit'
          }}
        >
          <FormikMuiTextField
            label="Nombre de la moneda"
            variant="outlined"
            name="name"
          />
          <FormikMuiTextField
            label="Abreviación de la moneda"
            variant="outlined"
            name="abbr"
          />
          <CountrySelect
            name="country"
            placeholder="País de la moneda"
            countries={countries}
          ></CountrySelect>
        </div>
        <Button
          type="submit"
          style={{
            backgroundColor: 'rgb(255, 124, 70)',
            fontSize: '4vw',
            width: '100%',
            color: '#FFF',
            outline: 'none',
            textTransform: 'none',
            fontWeight: 'bold',
            borderRadius: '0',
            borderBottomLeftRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
            padding: '14px 0'
          }}
        >
          {isEdit ? 'Actualizar' : 'Crear nueva moneda'}
        </Button>
      </Form>
    </Formik>
  )
}

// Component used for edit the detailed currency properties
export const CurrencyValuesForm = (props) => {
  const { currency } = props

  const classes = useStyles()

  const currencyValueValidator = Yup.object({
    value: Yup.number('Solo se pueden ingresar números!')
      .required('Campo requerido!')
      .min(0, 'Ingresa un número mayor a 0!')
  })

  const findField = useCallback(
    (key) => {
      return currency?.fields.find(
        (el) => el.key.toLowerCase() === key.toLowerCase()
      )
    },
    [currency]
  )

  const initialValues = {
    MinAmountToSend: {
      id: findField('MinAmountToSend')?.currencyValue.id,
      value: parseFloat(findField('MinAmountToSend')?.value) || undefined,
      currencyFieldId:
        parseFloat(findField('MinAmountToSend')?.id) || undefined
    },
    MaxAmountToSendWithOutVerification: {
      id: findField('MaxAmountToSendWithOutVerification')?.currencyValue.id,
      value:
        parseFloat(findField('MaxAmountToSendWithOutVerification')?.value) ||
        undefined,
      currencyFieldId:
        parseFloat(findField('MaxAmountToSendWithOutVerification')?.id) ||
        undefined
    },
    MaxAmountToSendWithVerification: {
      id: findField('MaxAmountToSendWithVerification')?.currencyValue.id,
      value:
        parseFloat(findField('MaxAmountToSendWithVerification')?.value) ||
        undefined,
      currencyFieldId:
        parseFloat(findField('MaxAmountToSendWithVerification')?.id) ||
        undefined
    },
    CostRate: {
      id: findField('CostRate')?.currencyValue.id,
      value: parseFloat(findField('CostRate')?.value) || undefined,
      currencyFieldId: parseFloat(findField('CostRate')?.id) || undefined
    },
    MaxNotVerifiedAppUserBalance: {
      id: findField('MaxNotVerifiedAppUserBalance')?.currencyValue.id,
      value:
        parseFloat(findField('MaxNotVerifiedAppUserBalance')?.value) ||
        undefined,
      currencyFieldId:
        parseFloat(findField('MaxNotVerifiedAppUserBalance')?.id) || undefined
    },
    MaxNotVerifiedMiddlewareBalance: {
      id: findField('MaxNotVerifiedMiddlewareBalance')?.currencyValue.id,
      value:
        parseFloat(findField('MaxNotVerifiedMiddlewareBalance')?.value) ||
        undefined,
      currencyFieldId:
        parseFloat(findField('MaxNotVerifiedMiddlewareBalance')?.id) ||
        undefined
    },
    MaxAppUserBalance: {
      id: findField('MaxAppUserBalance')?.currencyValue.id,
      value: parseFloat(findField('MaxAppUserBalance')?.value) || undefined,
      currencyFieldId:
        parseFloat(findField('MaxAppUserBalance')?.id) || undefined
    },
    MaxMiddlewareBalance: {
      id: findField('MaxMiddlewareBalance')?.currencyValue.id,
      value: parseFloat(findField('MaxMiddlewareBalance')?.value) || undefined,
      currencyFieldId:
        parseFloat(findField('MaxMiddlewareBalance')?.id) || undefined
    },
    BlueDollar: {
      id: findField('BlueDollar')?.currencyValue.id,
      value: parseFloat(findField('BlueDollar')?.value) || undefined,
      currencyFieldId: parseFloat(findField('BlueDollar')?.id) || undefined
    },
    OfficialDollar: {
      id: findField('OfficialDollar')?.currencyValue.id,
      value: parseFloat(findField('OfficialDollar')?.value) || undefined,
      currencyFieldId: parseFloat(findField('OfficialDollar')?.id) || undefined
    }
  }

  const handleSubmit = useCallback(
    async (formValues) => {
      const editRequests = []
      const createRequests = []

      // Fetch the latest currency values before making a request
      const { fields: fetchedValues } = await getCurrencyDetails(currency.id)

      //  Detect the changes that were done
      Object.keys(formValues).forEach((key) => {
        // Find the currency value from the currency field's key
        const result = fetchedValues.find((el) => el.key === key)

        // Compare, if the currency value is null, then we prepare a creation request
        if (!result.value) {
          createRequests.push({
            currencyFieldId: formValues[key].currencyFieldId,
            currencyId: currency.id,
            value: formValues[key].value
          })
          // If the currency value is not null and the value from the form is different, we prepare a edit request
        } else if (Number(result.value) !== Number(formValues[key].value)) {
          editRequests.push({
            id: formValues[key].id,
            value: formValues[key].value
          })
        }
      })
      // Update the currency values
      if (editRequests.length > 0) await updateCurrencyValues(editRequests)

      // Create the currency values
      if (createRequests.length > 0) await createCurrencyValues(createRequests)
    },
    [currency]
  )

  return (
    <div>
      {currency
        ? (
        <Formik
          enableReinitialize
          validationSchema={Yup.object().shape({
            MinAmountToSend: currencyValueValidator,
            MaxAmountToSendWithOutVerification: currencyValueValidator,
            MaxAmountToSendWithVerification: currencyValueValidator,
            MaxNotVerifiedAppUserBalance: currencyValueValidator,
            MaxNotVerifiedMiddlewareBalance: currencyValueValidator,
            MaxAppUserBalance: currencyValueValidator,
            MaxMiddlewareBalance: currencyValueValidator,
            BlueDollar: currencyValueValidator,
            OfficialDollar: currencyValueValidator
          })}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          <Form
            style={{
              backgroundColor: '#FFF',
              padding: '6% 5%',
              borderRadius: '5px',
              marginBottom: '10vh'
            }}
          >
            <div>
              {currency?.fields.map((field) => (
                <FormikCurrencyInput
                  key={field.key}
                  className={classes.fieldContainer}
                  label={field.description}
                  name={field.key}
                  abbr={currency.abbr}
                />
              ))}
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: 'rgb(255, 124, 70)',
                fontSize: '4vw',
                width: '100%',
                color: '#FFF',
                outline: 'none',
                textTransform: 'none',
                fontWeight: 'bold',
                borderRadius: '0',
                borderBottomLeftRadius: 'inherit',
                borderBottomRightRadius: 'inherit',
                padding: '14px 0',
                position: 'fixed',
                bottom: '0',
                left: '0'
              }}
            >
              Guardar
            </Button>
          </Form>
        </Formik>
          )
        : null}
    </div>
  )
}
