import React, { useState } from "react";
import MainLayout from "../../layouts/main";
import Screenloading from "./../loaders/screenLoading";
import {
  TextField,
  InputAdornment,
  Typography,
  Button,
  Switch,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useCopyToClipboard } from "react-use-copy-to-clipboard";
import Select from "react-select";
import { customStyles } from "./../../assets/styles/select";
import CurrencyInput from "react-currency-input-field";
import CopyIcon from "../../assets/copy.svg";
import RateIcon from "../../assets/rate.svg";
import CopyWhiteIcon from "../../assets/copywhite.svg";
import { formatNumber } from "./../../helpers/numbers";
function Fields({
  rate,
  amountPesos,
  amountBolivares,
  onChangeAmountBolivares,
  onChangeAmountPesos,
  onChangeRate,
  costrate,
  errorRate,
  user,
  dollarPreviewBss,
  operatorSelected,
  onSelectOperator,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h5
        style={{
          marginBottom: "2vw",
          color: "white",
        }}
      >
        Monto en bolívares según la tasa
      </h5>

      <div
        style={{
          width: "100%",
          color: "black",
          fontSize: "3vw",
          marginBottom: "4vw",
          borderRadius: "25px",
          background: "white",
          display: "flex",
          alignItems: "center",
          padding: "1vh 5vw",
        }}
      >
        <p>$</p>
        <CurrencyInput
          name="Monto en bolívares según la tasa"
          id="validationCustom01"
          value={amountBolivares}
          onValueChange={onChangeAmountBolivares}
          placeholder=""
          step={1}
          style={{
            width: "100%",
            color: "black",
            fontSize: "4vw",
            padding: "2.1vw 2vw",
            borderRadius: "5px",
            border: "none",
          }}
        />
        <p>BS</p>
      </div>
      <h5
        style={{
          marginBottom: "2vw",
          color: "white",
        }}
      >
        Monto en pesos
      </h5>

      <div
        style={{
          width: "100%",
          color: "black",
          fontSize: "3vw",
          marginBottom: "4vw",
          borderRadius: "25px",
          background: "white",
          display: "flex",
          alignItems: "center",
          padding: "1vh 5vw",
        }}
      >
        <p>$</p>
        <CurrencyInput
          id="validationCustom01"
          name="Monto en pesos"
          value={amountPesos}
          onValueChange={onChangeAmountPesos}
          placeholder=""
          step={1}
          style={{
            width: "100%",
            color: "black",
            fontSize: "4vw",
            padding: "2.1vw 2vw",
            borderRadius: "5px",
            border: "none",
          }}
        />
        <p>CLP</p>
      </div>

      <p
        style={{
          marginBottom: "6vw",
          borderRadius: "25px",
          color: "white",
          padding: "1vh 5vw",
          background: "green",
        }}
      >
        Monto aprox. en USD: {formatNumber(dollarPreviewBss)}
      </p>

      <h5
        style={{
          marginTop: "2vw",
          marginBottom: "2vw",
          color: "white",
        }}
      >
        Tasa actual
      </h5>

      <div
        className="createtransferenceinputrate"
        style={{
          width: "100%",
          color: "black",
          fontSize: "3vw",
          marginBottom: "4vw",
          borderRadius: "25px",
          background: "white",
          display: "flex",
          alignItems: "center",
          padding: "1vh 5vw",
        }}
      >
        <NumberFormat
          customInput={TextField}
          onValueChange={(values) => onChangeRate(values)}
          value={rate}
          thousandSeparator=","
          decimalSeparator="."
          style={{
            width: "100%",
            color: "white",
            background: "white",
          }}
        />

        <img src={RateIcon} />
      </div>
      {errorRate && (
        <p style={{ color: "#E91919" }}>
          La tasa no puede ser mayor a la tasa de costo. ({costrate})
        </p>
      )}
      {user.roleId != "2" && (
        <SelectOperator
          onChange={onSelectOperator}
          operatorSelected={operatorSelected}
        ></SelectOperator>
      )}
    </div>
  );
}

function ButtonSubmit({ onSubmit, errorRate, operatorSelected }) {
  return (
    <Button
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 60,
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: 0,
        "&:hover, &:focus": {
          backgroundColor: "#ff7c46",
        },
        backgroundColor: errorRate ? "grey" : "#ff7c46",
      }}
      type="submit"
      onClick={errorRate ? () => {} : onSubmit}
    >
      <Typography
        style={{
          color: "#fff",
          fontWeight: 500,
          textTransform: "capitalize",
        }}
      >
        Enviar transferencia
      </Typography>
    </Button>
  );
}

const ButtonCopy = ({ text }) => {
  const copyvalue = () => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    toast.success("Copiado!", {
      position: "top-right",
      toastId: "success",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <button
        onClick={copyvalue}
        style={{
          border: "none",
          background: "#003F62",
          color: "white",
          padding: "1vw 3vw",
          borderRadius: "5px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "2vw",
        }}
      >
        <img
          src={CopyWhiteIcon}
          style={{ color: "#FFFFFF", paddingRight: "2vw" }}
        />
        <span>Copiar</span>
      </button>
    </>
  );
};

function ItemAccountData({ text }) {
  const copyvalue = () => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    toast.success("Copiado!", {
      position: "top-right",
      toastId: "success",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <p
      style={{
        marginBottom: "1vw",
      }}
    >
      <span>{text}</span>
      <img
        src={CopyIcon}
        onClick={copyvalue}
        style={{ color: "#003353", paddingLeft: "2vw" }}
      />
    </p>
  );
}

function AccountData() {
  const location = useLocation();
  const { user } = location.state;

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% auto",
          alignItems: "center",
          marginBottom: "4vw",
          background: "white",
        }}
      >
        <img src={user.bank.Logo} />
        <div
          style={{
            padding: "4vw 2vw",
            borderRadius: "5px",
            color: "#003F62",
          }}
        >
          <ItemAccountData text={user.bank.name} bank />
          <ItemAccountData text={user.name} />
          <ItemAccountData
            text={user.idType.name + " " + user.identification}
          />
          <ItemAccountData
            text={
              user.number != "1234567898745632"
                ? user.bank.prefix + "" + user.number
                : user.phone
            }
          />
          <div>
            <ButtonCopy
              text={[
                user.number != "1234567898745632"
                  ? "Número de cuenta: " + user.bank.prefix + "" + user.number
                  : "Pago movíl: " + user.phone,
                "Nombre: " + user.name,
                "Documento: " + user.identification,
                "Tipo de cuenta: Cta-" + user.bssAccountType.name,
              ].join(", ")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectOperator(props) {
  return (
    <>
      <Button
        variant="contained"
        onClick={props.onChange}
        style={{
          background: "#ff7c46 none repeat scroll 0% 0%",
          color: "white",
          fontWeight: "bold",
          width: "100%",
          marginTop: "1vw",
          textTransform: "none",
          fontSize: "4vw",
        }}
      >
        Asignar operador
      </Button>

      {props.operatorSelected?.id && (
        <div style={{ marginTop: "3vw", color: "white" }}>
          <p>
            Operador seleccioando: <b>{props.operatorSelected.name}</b>
          </p>
        </div>
      )}
    </>
  );
}

function Index({
  loading,
  rate,
  amountPesos,
  amountBolivares,
  onChangeAmountBolivares,
  onChangeAmountPesos,
  onChangeRate,
  dollarPreviewBss,
  onSubmit,
  costrate,
  onSelectOperator,
  user,
  bssbalances,
  errorRate,
  operatorSelected,
  ModalAssignOperator,
  showAssignModal,
}) {
  const selectOperators = (provied) => {};

  return (
    <MainLayout
      house={true}
      text="Crear transferencia"
      skipPadding
      goBack={true}
    >
      <div style={{ background: "#00304A", height: "90vh" }}>
        {loading && <Screenloading></Screenloading>}
        <ToastContainer />
        <AccountData></AccountData>

        {showAssignModal && <ModalAssignOperator />}

        <div
          style={{
            padding: "6vw 5vw",
          }}
        >
          <Fields
            {...{
              rate,
              amountPesos,
              amountBolivares,
              onChangeAmountBolivares,
              onChangeAmountPesos,
              onChangeRate,
              dollarPreviewBss,
              costrate,
              bssbalances,
              errorRate,
              user,
              operatorSelected,
              onSelectOperator,
            }}
          ></Fields>

          <ButtonSubmit
            {...{ onSubmit, errorRate, operatorSelected }}
          ></ButtonSubmit>
        </div>
      </div>
    </MainLayout>
  );
}

export default Index;
