// Dependencies
import React, { useCallback, useEffect, useState } from "react";

// Slices
import { getAllUsers, getUserCurrencies } from "../../../slices/users";

// Components
import { AllUserScreen } from "../../../components/balances/all-users";
import CreateCustomBalance from "../../../components/balances/modals/create-custom-balance";
import Screenloading from "../../../components/loaders/screenLoading";

// Context
import { CustomBalanceContext } from "../../../context/CustomBalanceContext";

export function AllUsers() {
  const [loading, setloading] = useState(false);
  const [search, setsearch] = useState("");
  const [userSelected, setuserSelected] = useState({});
  const [showModalCustom, setShowModalCustom] = useState(false);
  const [usersApi, setUsersApi] = useState([]);
  const [users, setUsers] = useState(usersApi);
  const [step, setStep] = useState(1);
  const [path, setPath] = useState("");
  const [image, setImage] = useState("");
  const [currency, setCurrency] = useState([]);
  const [userCurrencies, setUserCurrencies] = useState([]);
  const [balance, setBalance] = useState({
    amount: "",
    currencyId: 0,
    userId: userSelected.id,
    capture: "",
  });

  // Fetch all the users
  useEffect(() => {
    getAllUsers()
      .then((res) => {
        setUsersApi(res);
        setUsers(res);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Method to get the details from the selected user
  const selectUser = useCallback(async (user) => {
    try {
      // Get the user currencies
      const currencies = await getUserCurrencies(user.id);

      setCurrency(currencies.availableCurrencies);
      setUserCurrencies(currencies.userCurrencies);

      // Show modal
      setShowModalCustom(true);

      // Set the selected user
      setuserSelected(user);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleSearch = async (e) => {
    setloading(true);

    setUsers(
      usersApi.filter((user) => user.name.toString().toLowerCase().includes(e))
    );
    setloading(false);
  };

  const props = {
    openCustom: setShowModalCustom,
    search: handleSearch,
    selectUser: selectUser,
    users: users,
    setsearch,
  };

  const handleClose = () => {
    setShowModalCustom(false);
    setuserSelected({});
    setStep(1);
    setImage("");
    setPath("");
    setBalance({
      amount: 0,
      currencyId: 0,
      userId: 0,
      capture: null,
    });
  };

  return (
    <div>
      <div>
        {loading && <Screenloading></Screenloading>}
        <AllUserScreen {...props} />
        <CustomBalanceContext.Provider
          value={{
            step,
            setStep,
            userCurrencies,
            userSelected,
            balance,
            setBalance,
            image,
            setImage,
            path,
            setPath,
            currency,
          }}
        >
          <CreateCustomBalance
            handleClose={handleClose}
            openCustom={showModalCustom}
          />
        </CustomBalanceContext.Provider>
      </div>
    </div>
  );
}
