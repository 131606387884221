import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getAllLastPayments, checkPayment } from "../../slices/payments";
import PaymentCard from "../../components/payments/paymentCard";
import Typography from "@material-ui/core/Typography";
import { Link, useLocation } from "react-router-dom";

import MainLayout from "../../layouts/main";
import { isEmpty } from "lodash";
import Ilustration from "../../assets/ilustrations/pagosilustracion.svg";
import ilustracionpagos from "../../assets/ilustrations/ilustracionpagos.svg";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import AppContext from "./../../context/AppContext";
import Screenloading from "../../components/loaders/screenLoading";

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    minHeight: "100vh",
    backgroundColor: "#003F62",
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  card: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "13px",
    paddingBottom: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
});

const Index = () => {
  const dispatch = useDispatch();
  const { pendings, receivedToday } = useSelector((state) => state.payments);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const { addEvent, emitEvent } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [pendingsArray, setpendingsArray] = useState([]);
  const [assignedTodayArray, setreceivedTodayArray] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [paymentSelected, setpaymentSelected] = useState({});
  const location = useLocation();

  useEffect(() => {
    setpendingsArray(pendings);
  }, [pendings]);

  useEffect(() => {
    setreceivedTodayArray(receivedToday);
  }, [receivedToday]);

  const handleSend = async (paymentId, state, text) => {
    dispatch(await checkPayment(paymentId, state, text));
  };

  const fectchgetAllLastPayments = async () => {
    setLoading(true);

    try {
      toast;
      dispatch(await getAllLastPayments()).then(() => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fectchgetAllLastPayments();
  }, []);

  useEffect(() => {
    if (location) {
      // console.log('location payment')
      emitEvent("closeSockets");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state?.payment) {
      console.log("payment", location.state.payment);
      setpaymentSelected(location.state.payment);
      setShowModal(true);
    }
  }, []);

  const classes = useStyles();

  return (
    <MainLayout text="Pagos" skipPadding={true}>
      {loading && <Screenloading label="Cargando"></Screenloading>}
      <ToastContainer />
      <img
        src={Ilustration}
        alt=""
        style={{
          width: "90vw",
          marginLeft: "4vw",
        }}
      />
      <div style={{ padding: "0 3vw 0 3vw" }}>
        <div className={classes.row} style={{ textAlign: "center" }}></div>
        <Typography
          style={{ color: "#fff", marginTop: "8px", marginBottom: "8px" }}
        >
          Pagos Pendientes
          <i
            onClick={fectchgetAllLastPayments}
            className="fa-solid fa-rotate-right"
            style={{ color: "#f96123", fontSize: "5vw", marginLeft: "4vw" }}
          ></i>
          <Link
            to={{ pathname: "/payments/history" }}
            style={{
              float: "right",
              textDecoration: "underline",
              color: "#008cff",
            }}
          >
            Ver más
          </Link>
        </Typography>
        {!isEmpty(pendingsArray) ? (
          pendingsArray.map((payment) => {
            return (
              <PaymentCard
                canChange={true}
                payment={payment}
                paymentSelected={paymentSelected}
                setpaymentSelected={setpaymentSelected}
                showModal={showModal}
                setShowModal={setShowModal}
                key={payment.id}
                handleSendData={handleSend}
              />
            );
          })
        ) : (
          <div style={{ textAlign: "center" }}>
            <img
              src={ilustracionpagos}
              style={{ width: "75%", marginTop: "-5vw" }}
            ></img>
            <h5
              style={{
                textAlign: "center",
                color: "#61504f",
                marginBottom: "10vw",
                marginTop: "-6vw",
                fontSize: "4vw",
              }}
            >
              Sin pagos pendientes aún
            </h5>
          </div>
        )}
        {!isEmpty(assignedTodayArray) ? (
          <>
            <Typography
              style={{ color: "#fff", marginTop: "5vw", marginBottom: "8px" }}
            >
              Historial de pagos
            </Typography>
            {assignedTodayArray.map((payment) => {
              return (
                <PaymentCard
                  canChange
                  payment={payment}
                  paymentSelected={paymentSelected}
                  setpaymentSelected={setpaymentSelected}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  key={payment.id}
                  handleSendData={handleSend}
                />
              );
            })}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </MainLayout>
  );
};

export default Index;
