import { makeStyles } from "@material-ui/core";
import { useField } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const useStyles = makeStyles({
  selectContainer: {
    margin: "4%",
    marginBottom: "10%",
  },
  selectInput: {
    marginBottom: "4%",
  },
  inputFlag: {
    width: "8%",
    marginRight: "3%",
  },
  optionContainer: {
    fontSize: "4vw",
  },
});

const CountrySelect = (props) => {
  const classes = useStyles();
  const [field, meta, { setValue }] = useField(props);
  const { countries, ...values } = props;

  //   const countries = useSelector(store => store.countries.rows)

  useEffect(() => {}, []);

  return (
    <Select
      options={countries}
      formatOptionLabel={(country) => {
        return (
          <div className={classes.optionContainer}>
            <img src={country.image} className={classes.inputFlag} />
            <span>{country.name}</span>
          </div>
        );
      }}
      isSearchable={false}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      {...field}
      {...values}
      onChange={(value) => {
        setValue({
          name: value.name,
          image: value.image,
          id: value.id,
        });
      }}
      className={classes.selectInput}
    />
  );
};

export const CountrySelectWithoutForm = (props) => {
  const classes = useStyles();
  const { countries, ...values } = props;

  useEffect(() => {}, []);

  return (
    <Select
      options={countries}
      formatOptionLabel={(country) => {
        return (
          <div className={classes.optionContainer}>
            <img src={country.image} className={classes.inputFlag} />
            <span>{country.name}</span>
          </div>
        );
      }}
      isSearchable={false}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      {...values}
      onChange={props.onChange}
      className={classes.selectInput}
    />
  );
};

export default CountrySelect;
