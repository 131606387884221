import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#eb4034',
    textAlign: 'center',
    padding: '1vw 3vw 1vw 3vw',
    marginTop: '4vw',
    borderRadius: 5
  },
  title: {
    fontSize: '4vw',
    color: 'white'
  }
}))

export default function PendienteStatus () {
  const classes = useStyles()

  return (
        <div className={classes.container}>
            <h1 className={classes.title}>Pendiente</h1>
        </div>
  )
}
