import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { isArray, isUndefined } from "lodash";
import setAuthToken, { Axios } from "../helpers/axios.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { validateResponse } from "../helpers/index";
import { RoleId } from "../App.js";

const MySwal = withReactContent(Swal);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    errors: [],
    token: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});
const { setToken, setUser } = authSlice.actions;

export const logInUser = async (data, history) => async (dispatch) => {
  try {
    const { data: responseDTO } = await axios.post(
      `${process.env.REACT_APP_API_URL}auth/login`,
      data
    );
    dispatch(setUser(responseDTO.data.user));
    dispatch(setToken(responseDTO.data.accessToken));

    localStorage.setItem("refreshToken", responseDTO.data.refreshToken);
    localStorage.setItem("accessToken", responseDTO.data.accessToken);
    localStorage.setItem("user", JSON.stringify(responseDTO.data.user));

    setAuthToken(
      responseDTO.data.accessToken,
      JSON.stringify(responseDTO.data.user)
    );

    switch (responseDTO.data.user.roleId) {
      case RoleId.SuperAdmin:
        window.location.href = "/dashboard";
        break;
      case RoleId.AdminUser:
        window.location.href = "/dashboard";
        break;
      case RoleId.AppUser:
      case RoleId.OperatorUser:
      case RoleId.MiddlewareUser:
        window.location.href = "/operator-dashboard";
        break;
      case 5:
        window.location.href = "/dashboard";
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("error", error);
    if (error.response != undefined) {
      validateResponse(error.response.data);
    } else {
      MySwal.fire({
        text: error.message,
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
    }
  }
};
export const signUpUser = async (data, history) => async (dispatch) => {
  try {
    const { data: responseDTO } = await axios.post(
      `${process.env.REACT_APP_API_URL}user/store`,
      data
    );
    if (!isUndefined(responseDTO.errors) && isArray(responseDTO.errors)) {
      const htmlErrors = responseDTO.errors.map((error) => {
        return `<div className="station">${error.msg}</div>`;
      });
      console.log({ htmlErrors });
      MySwal.fire({
        html: htmlErrors.join("<br>"),
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
      return false;
    }
    if (responseDTO.code == !0) throw responseDTO.description;

    dispatch(setUser(data));
    history.push("/code-register");
  } catch (error) {
    if (error.response != undefined) {
      validateResponse(error.response.data);
    } else {
      MySwal.fire({
        text: error.message,
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
    }
  }
};
export const logOut = (history) => async (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
  dispatch(setToken(""));
  dispatch(setUser({}));
  history.push("/login");
};
export const setUserData = (data) => async (dispatch) => {
  dispatch(setUser(data));
};

export const activateOperatorFunct = (payload, history) => async (dispatch) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}user/activate-operator`,
    payload
  );

  history.push("/login");
};

export const changePassword =
  async (email, code, password, history) => async (dispatch) => {
    try {
      const { data: responseDTO } = await Axios.post(
        `${process.env.REACT_APP_API_URL}user/change-password`,
        { email: email.trim(), code, password }
      );

      if (!isUndefined(responseDTO.errors) && isArray(responseDTO.errors)) {
        MySwal.fire({
          text: "¡Algo salio mal!",
          icon: "error",
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });

        return false;
      }
      if (responseDTO.code == !0) {
        MySwal.fire({
          text: "¡Algo salio mal!",
          icon: "error",
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });

        throw responseDTO.description;
      } else {
        MySwal.fire({
          text: "¡Contraseña actualizada correctamente!",
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
        history.push("/login");
      }
    } catch (error) {
      if (error.response != undefined) {
        MySwal.fire({
          text: error.response.data.description,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      } else {
        MySwal.fire({
          text: error.message,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
  };

export default authSlice.reducer;
