import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Zoom from "react-medium-image-zoom";
import { Transference } from "../../../models/transference";

function NewTransactionReceipt(props: any) {
  const transference: Transference = props.transaction;
  const hasReceipt = transference?.capture ?? false;

  const open = () => {
    if (!hasReceipt) return;

    withReactContent(Swal).fire({
      html: (
        <div style={{ width: "100%" }}>
          <Zoom>
            <img
              src={transference?.capture ?? ""}
              style={{ width: "100%" }}
              alt="image"
            />
          </Zoom>
        </div>
      ),
    });
  };

  return (
    <div
      onClick={open}
      style={{ backgroundColor: hasReceipt ? "#013F62" : "#7070707D" }}
      className="transaction-status"
    >
      Comprobante
    </div>
  );
}

export default NewTransactionReceipt;
