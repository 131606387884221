import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography
} from '@material-ui/core'
import Header from '../components/Header'
import BankRechargeDialog from '../components/BankRechargeDialog'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBanks } from '../slices/banks'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    backgroundColor: '#003f62'
  },
  content: {
    flex: '1',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    paddingInline: 15,
    color: '#fff',
    position: 'relative'
  },
  banks: {
    marginTop: 80,
    marginBottom: 80
  }
})

function RechargeBalance () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const banks = useSelector((state) => state.banks.banks)

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  return (
    <Grid className={classes.container}>
        <Header text="Historial de saldo" />

        <Grid container className={classes.content}>
            <div style={{ marginTop: 20 }}>
                <Typography style={{ textAlign: 'center', fontSize: 20, fontWeight: 600 }}>
                    Selecciona el banco donde realizaras tu recarga
                </Typography>
            </div>

            <Grid container spacing={5} justify="center" className={classes.banks}>
                {banks.map(bank => (
                    <Grid item key={JSON.stringify(bank)}>
                        <BankRechargeDialog
                            bank={bank}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
  )
}

export default RechargeBalance
