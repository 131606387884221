import { useEffect, useState } from "react";
import Layout from "../../layouts/main";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteAppaccount,
  fetchParentMethods,
  getFechParentMethod,
  setActiveAppaccount,
} from "../../slices/parentmethods";
import { useHistory, useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import { DialogContent } from "@mui/material";
import {
  createAppAccountData,
  createAppAccount,
  updateAppAccountData,
  updateAppAccountDataWarning,
  deleteAppAccountData,
  deleteAppAccountDataWarning,
  createAppAccountDataWarning,
  updateAppAccount,
} from "../../slices/appAccount";
import Screenloading from "../loaders/screenLoading";
import { Fab } from "react-tiny-fab";

import { v4 } from "uuid"; // there are 5 versions. You can use any.
import Warnings from "./warnings/index";
import { uploadFile } from "./../../slices/files";

const useStyles = makeStyles({
  containerInput: {
    display: "flex",
    background: "white",
    justifyContent: "space-between",
    padding: "1vw 2vw 1vw 2vw",
    borderRadius: 5,
    "& input": {
      width: "90%",
      border: "none",
    },
  },
  GridBank: {
    marginTop: "6vw",
  },
  ContainerAccount: {
    background: "white",
    borderRadius: 10,
    display: "grid",
    position: "relative",
    gridTemplateColumns: "30% 70%",
    marginBottom: "4vw",
    alignItems: "center",
    "& img": {
      width: "100%",
      marginBottom: "6vw",
    },
    "& h4": {
      fontWeight: "lighter",
      color: "#003353",
    },
  },
  ContainerIcons: {
    position: "absolute",
    bottom: "5%",
    display: "flex",
    right: "5%",
    "& svg": {
      marginLeft: "2vw",
    },
  },
  ContainerSwitch: {
    position: "relative",
    textAlign: "center",
  },
  Switch: {
    top: "60%",
    width: "100%",
    zIndex: 4,
    position: "absolute",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& span": {
      fontSize: "2.8vw",
      color: "#003E60",
    },
  },
  footerModal: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "2vw",
    "& button": {
      fontWeight: "700",
      fontSize: "5vw",
      border: "none",
      borderRadius: "5px",
      padding: "2vw 5vw",
      "@media (min-width: 700px)": {
        padding: "1vw 3vw",
        fontSize: "1.2vw",
      },
    },
  },
  btnSolid: {
    color: "white",
    background: "#003f62",
  },
  root: {
    width: "75vw",
  },
  NewField: {
    display: "grid",
    gridTemplateColumns: "28% 63% 5%",
    gridGap: "2vw",
    marginBottom: "3vw",
    alignItems: "center",
    "& input": {
      border: "2 solid #003F62",
      borderRadius: 5,
      padding: "1vw",
      paddingLeft: "2vw",
    },
  },
  AddNewField: {
    display: "flex",
    marginTop: "3vw",
    marginBottom: "3vw",
    "& span": {
      marginLeft: "2vw",
    },
  },
  UploadImageContainer: {
    background: "#F4F4F4",
    marginTop: "3vw",
    padding: "8vw 4vw 8vw 4vw",
    borderRadius: 5,
    marginBottom: "6vw",
    position: "relative",
  },
  UploadImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h3": {
      fontSize: "3.5vw",
      fontWeight: "lighter",
      marginTop: "2vw",
    },
    "& svg": {
      width: "20%",
      height: "20%",
      marginBottom: "2vw",
    },
  },
  file: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    zIndex: 3,
  },
  trashIcon: {
    position: "absolute",
    padding: "2vw",
    background: "#ffffffb0",
    borderRadius: "0px 0px 5px 0px",
  },
});

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [showModalAdd, setshowModalAdd] = useState(false);
  const [loading, setloading] = useState(false);
  const [wordToSearch, setwordToSearch] = useState("");

  const [parentMethod, setparentMethod] = useState({ accounts: [] });
  const [parentMethods, setParentMethods] = useState([]);

  const getAllParentMethods = async () => {
    const response = await getFechParentMethod();
    setParentMethods(response.data.data);
  };

  useEffect(() => {
    setparentMethod(
      parentMethods.find((prnt) => prnt.id == location.state.parentMethod.id)
    );
  }, [parentMethods]);

  useEffect(() => {
    getAllParentMethods();
  }, []);

  const getParentMethodFunction = async () => {
    dispatch(fetchParentMethods());
  };

  useEffect(() => {
    dispatch(fetchParentMethods());
  }, []);

  const onSubmitAdd = async (fields, fieldsWarning, image) => {
    console.log("fields", fields);
    console.log("warnings", fieldsWarning);
    if (
      (fields && fields[0]?.field && fields[0]?.value) ||
      (fieldsWarning && fieldsWarning[0]?.field && fieldsWarning[0]?.value)
    ) {
      setloading(true);

      const responseImage = await dispatch(uploadFile(image));

      const appAccountCreated = await createAppAccount(
        location.state.parentMethod.id,
        responseImage?.payload?.original?.location || "  "
      );

      const mapFields = fields.map(async (field) => {
        await createAppAccountData(
          appAccountCreated.data.id,
          field.field,
          field.value
        );
      });

      const mapWarnings = fieldsWarning.map(async (field) => {
        await createAppAccountDataWarning(
          appAccountCreated.data.id,
          field.value
        );
      });

      await Promise.all(mapFields).then(async (e) => {
        await Promise.all(mapWarnings).then((e) => {
          setloading(false);
          history.push("/accounts");
        });
      });
    }
    setshowModalAdd(false);
  };

  const filterList = () => {
    try {
      const filted = parentMethod.accounts.filter((account) => {
        const values = JSON.stringify(
          account.appAccountData.map((v) => v.value)
        );
        return values.toLowerCase().includes(wordToSearch.toLowerCase());
      });
      return filted || [];
    } catch (e) {
      return [];
    }
  };

  return (
    <Layout text={`Cuentas ${parentMethod?.name ?? ""}`}>
      <InputSearch
        onChange={setwordToSearch}
        containerInput={classes.containerInput}
      ></InputSearch>

      <div>
        {loading ? (
          <Screenloading label="Agregando método de pago"></Screenloading>
        ) : (
          <div></div>
        )}
        <div className={classes.GridBank}>
          {filterList().map((e) => {
            return (
              <Account
                key={JSON.stringify(e)}
                {...{
                  showModalAdd,
                  setshowModalAdd,
                  parentMethod,
                  getParentMethodFunction,
                }}
                account={e}
              ></Account>
            );
          })}
          <Fab
            icon={<i className="fas fa-plus"></i>}
            alwaysShowTitle={true}
            mainButtonStyles={{ background: "#f96123", right: "-31%" }}
            onClick={() => setshowModalAdd(true)}
          ></Fab>

          <Dialog
            onClose={() => setshowModalAdd(false)}
            aria-labelledby="customized-dialog-title"
            open={showModalAdd}
            PaperProps={{
              style: { maxWidth: "900px", width: "75vw", margin: 0 },
            }}
          >
            <ModalEdit
              handleClose={() => setshowModalAdd(false)}
              create
              onSubmit={onSubmitAdd}
              {...{ account: { parentMethod, appAccountData: [{ id: v4() }] } }}
            ></ModalEdit>
          </Dialog>
        </div>
      </div>
    </Layout>
  );
}

function InputSearch({ containerInput, onChange }) {
  return (
    <div className={containerInput}>
      <input type="text" onChange={(e) => onChange(e.target.value)} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.996"
        height="24"
        viewBox="0 0 23.996 24"
      >
        <path
          id="FontAwsome_search_"
          data-name="FontAwsome (search)"
          d="M23.67,20.75,19,16.077a1.124,1.124,0,0,0-.8-.328h-.764a9.745,9.745,0,1,0-1.687,1.687V18.2a1.124,1.124,0,0,0,.328.8L20.75,23.67a1.12,1.12,0,0,0,1.589,0l1.326-1.326A1.13,1.13,0,0,0,23.67,20.75Zm-13.921-5a6,6,0,1,1,6-6A6,6,0,0,1,9.749,15.748Z"
          fill="#FA7D4A"
        />
      </svg>
    </div>
  );
}

const Account = ({
  parentMethod,
  account,
  showModalAdd,
  getParentMethodFunction,
  setshowModalAdd,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [showModalConfirm, setshowModalConfirm] = useState(false);
  const [showModalDelete, setshowModalDelete] = useState(false);
  const [showModalEdit, setshowModalEdit] = useState(false);
  const [active, setactive] = useState(account.active);

  const handleClose = () => setshowModalConfirm(false);
  const handleCloseDelete = () => setshowModalDelete(false);
  const handleCloseEdit = () => setshowModalEdit(false);

  const onSubmitDelete = async () => {
    await DeleteAppaccount(account.id);
    history.push("/accounts");
  };

  const onDeleteItem = async (field) => {
    if (field.appAccountId) {
      setloading(true);

      await deleteAppAccountData(field.id);
      getParentMethodFunction();

      setloading(false);
    }
  };
  const onDeleteWarning = async (field) => {
    if (field.appAccountId) {
      setloading(true);

      await deleteAppAccountDataWarning(field.id);
      getParentMethodFunction();

      setloading(false);
    }
  };
  const onSubmitEdit = async (fields, fieldsWarning, path, account) => {
    setloading(true);

    console.log(account);
    console.log(path);

    if (path && path.includes("data:image")) {
      const urlS3 = await dispatch(uploadFile(path));

      await updateAppAccount(
        account.id,
        urlS3?.payload?.original?.location || "  ",
        account.methodId
      );
    }

    const mapFields = fields.map(async (field) => {
      if (field.appAccountId) {
        await updateAppAccountData(
          account.id,
          field.id,
          field.field,
          field.value
        );
      } else {
        await createAppAccountData(account.id, field.field, field.value);
      }
    });

    const mapWarning = fieldsWarning.map(async (field) => {
      if (field.appAccountId) {
        await updateAppAccountDataWarning(account.id, field.id, field.value);
      } else {
        await createAppAccountDataWarning(account.id, field.value);
      }
    });

    await Promise.all(mapFields).then(async (e) => {
      await Promise.all(mapWarning).then((e) => {
        setloading(false);
        history.push("/accounts");
      });
    });
  };

  const onSubmit = async () => {
    await setActiveAppaccount(account.id, active);
    history.push("/accounts");
  };

  return (
    <>
      {loading ? <Screenloading></Screenloading> : <div></div>}

      <div className={classes.ContainerAccount}>
        <div className={classes.ContainerSwitch}>
          <img
            style={{
              width: "24vw",
              margin: "auto",
              marginBottom: "6vw",
            }}
            src={parentMethod.Logo}
          />
          <div className={classes.Switch}>
            <Switch
              defaultChecked={account.active}
              size="small"
              checked={active}
              style={{ width: "65%", display: "flex" }}
              onChange={(e) => {
                setshowModalConfirm(true);
                setactive(!active);
              }}
            />
            <span>{active ? "Activado" : "Desactivado"}</span>
          </div>
        </div>

        <div style={{ margin: "3vw 0 3vw" }}>
          {account.appAccountData.map((e) => {
            return (
              <h4 key={JSON.stringify(e)}>
                <span>{e.field}:</span>{" "}
                <span style={{ fontWeight: "bold" }}>{e.value}</span>
              </h4>
            );
          })}
        </div>
        <Icons
          setshowModalDelete={setshowModalDelete}
          setshowModalEdit={setshowModalEdit}
        ></Icons>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModalConfirm}
        PaperProps={{
          style: { maxWidth: "900px", width: "75vw", margin: 0 },
        }}
      >
        <ModalConfirm
          handleClose={handleClose}
          onSubmit={onSubmit}
          active={active}
          onCancel={() => setactive(!active)}
        ></ModalConfirm>
      </Dialog>

      <Dialog
        onClose={handleCloseDelete}
        aria-labelledby="customized-dialog-title"
        open={showModalDelete}
        PaperProps={{
          style: { maxWidth: "900px", width: "75vw", margin: 0 },
        }}
      >
        <ModalDelete
          handleClose={handleCloseDelete}
          onSubmit={onSubmitDelete}
        ></ModalDelete>
      </Dialog>

      <Dialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={showModalEdit}
        PaperProps={{
          style: { maxWidth: "900px", width: "75vw", margin: 0 },
        }}
      >
        <ModalEdit
          handleClose={handleCloseEdit}
          onSubmit={onSubmitEdit}
          onDeleteItem={onDeleteItem}
          onDeleteWarning={onDeleteWarning}
          {...{ account }}
        ></ModalEdit>
      </Dialog>
    </>
  );
};

const Icons = ({ setshowModalDelete, setshowModalEdit }) => {
  const classes = useStyles();

  return (
    <div className={classes.ContainerIcons}>
      <div onClick={() => setshowModalEdit(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.038"
          height="18.022"
          viewBox="0 0 18.038 18.022"
        >
          <path
            id="edit"
            d="M15.8,18.021H2.27A2.174,2.174,0,0,1,.675,17.36a2.168,2.168,0,0,1-.66-1.594V2.236A2.144,2.144,0,0,1,.676.651,2.187,2.187,0,0,1,2.27,0H12.417L10.162,2.236H3.4a1.075,1.075,0,0,0-.8.335,1.1,1.1,0,0,0-.326.792V14.637a1.075,1.075,0,0,0,.335.8,1.1,1.1,0,0,0,.792.326H14.671A1.113,1.113,0,0,0,15.8,14.637V7.874l2.255-2.255V15.766A2.255,2.255,0,0,1,15.8,18.021ZM5.652,9.847l2.537,2.537L4.525,13.511Zm3.664,1.691L6.5,8.737,14.935.281a1.043,1.043,0,0,1,1.426,0l1.392,1.409a.977.977,0,0,1,.291.7.942.942,0,0,1-.291.713Z"
            transform="translate(-0.015 0.001)"
            fill="#f96123"
          />
        </svg>
      </div>
      <div onClick={() => setshowModalDelete(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.604"
          height="17.143"
          viewBox="0 0 13.604 17.143"
        >
          <path
            id="FontAwsome_trash-alt_"
            data-name="FontAwsome (trash-alt)"
            d="M.972,15.536a1.538,1.538,0,0,0,1.458,1.607h8.745a1.538,1.538,0,0,0,1.458-1.607V4.286H.972ZM9.231,6.964a.488.488,0,1,1,.972,0v7.5a.488.488,0,1,1-.972,0Zm-2.915,0a.488.488,0,1,1,.972,0v7.5a.488.488,0,1,1-.972,0Zm-2.915,0a.513.513,0,0,1,.486-.536.513.513,0,0,1,.486.536v7.5A.513.513,0,0,1,3.887,15a.513.513,0,0,1-.486-.536Zm9.717-5.893H9.474L9.189.445A.725.725,0,0,0,8.536,0H5.065a.717.717,0,0,0-.65.445l-.285.626H.486A.513.513,0,0,0,0,1.607V2.678a.513.513,0,0,0,.486.536H13.118a.513.513,0,0,0,.486-.536V1.607A.513.513,0,0,0,13.118,1.071Z"
            transform="translate(0 0)"
            fill="#003e60"
          />
        </svg>
      </div>
    </div>
  );
};

const ModalConfirm = ({ handleClose, onSubmit, active, onCancel }) => {
  const classes = useStyles();

  return (
    <>
      <MuiDialogTitle
        disableTypography
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "0",
        }}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <SvgIcon viewBox="0 0 24 24">
            <path
              id="FontAwsome_times-circle_"
              data-name="FontAwsome (times-circle)"
              d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm5.884,15.15a.581.581,0,0,1,0,.823l-1.916,1.911a.581.581,0,0,1-.823,0L20,22.71l-3.15,3.174a.581.581,0,0,1-.823,0l-1.911-1.916a.581.581,0,0,1,0-.823L17.29,20l-3.174-3.15a.581.581,0,0,1,0-.823l1.916-1.916a.581.581,0,0,1,.823,0L20,17.29l3.15-3.174a.581.581,0,0,1,.823,0l1.916,1.916a.581.581,0,0,1,0,.823L22.71,20Z"
              transform="translate(-8 -8)"
              fill="#003f62"
            />
          </SvgIcon>
        </IconButton>
      </MuiDialogTitle>

      <DialogContent
        style={{
          paddingBottom: "0",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <path
            id="off"
            d="M18,36A17.944,17.944,0,0,1,2.408,27.035,17.672,17.672,0,0,1,0,18,17.754,17.754,0,0,1,4.641,5.977q.105-.105.4-.422t.492-.51a2.18,2.18,0,0,1,.527-.369A1.455,1.455,0,0,1,6.75,4.5a2.162,2.162,0,0,1,1.582.668A2.162,2.162,0,0,1,9,6.75a3.524,3.524,0,0,1-.123.984,1.4,1.4,0,0,1-.264.563l-.1.176A13.4,13.4,0,0,0,5.555,12.8,13.067,13.067,0,0,0,4.5,18a13.221,13.221,0,0,0,1.811,6.768,13.4,13.4,0,0,0,4.922,4.922,13.553,13.553,0,0,0,13.535,0,13.4,13.4,0,0,0,4.922-4.922,13.508,13.508,0,0,0,.755-11.988,13.607,13.607,0,0,0-2.918-4.341A2.312,2.312,0,0,1,27,6.75a2.162,2.162,0,0,1,.668-1.582A2.162,2.162,0,0,1,29.25,4.5a1.616,1.616,0,0,1,.475.07,2.364,2.364,0,0,1,.369.141,1.853,1.853,0,0,1,.369.281,3.149,3.149,0,0,1,.3.3q.07.089.316.369t.281.316A17.9,17.9,0,0,1,34.576,25,17.613,17.613,0,0,1,25,34.576,17.657,17.657,0,0,1,18,36Zm0-18a2.22,2.22,0,0,1-2.25-2.25V2.25a2.147,2.147,0,0,1,.668-1.6,2.208,2.208,0,0,1,1.6-.65A2.158,2.158,0,0,1,19.6.65a2.172,2.172,0,0,1,.65,1.6v13.5A2.22,2.22,0,0,1,18,18Z"
            transform="translate(0 0)"
            fill="#f96123"
          />
        </svg>

        <h4
          style={{
            color: "#003F62",
            fontWeight: "lighter",
            marginTop: "2vw",
          }}
        >
          {active
            ? "La cuenta se activará"
            : "Los pagos a esta cuenta se desactivara temporalmente."}
        </h4>
      </DialogContent>

      <MuiDialogActions style={{ justifyContent: "center" }}>
        <footer className={classes.footerModal}>
          <button
            onClick={() => {
              handleClose();
              onCancel();
            }}
            className={classes.btnLigth}
          >
            Cancelar
          </button>
          <button onClick={onSubmit} className={classes.btnSolid}>
            Aceptar
          </button>
        </footer>
      </MuiDialogActions>
    </>
  );
};

const ModalDelete = ({ handleClose, onSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <MuiDialogTitle
        disableTypography
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "0",
        }}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <SvgIcon viewBox="0 0 24 24">
            <path
              id="FontAwsome_times-circle_"
              data-name="FontAwsome (times-circle)"
              d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm5.884,15.15a.581.581,0,0,1,0,.823l-1.916,1.911a.581.581,0,0,1-.823,0L20,22.71l-3.15,3.174a.581.581,0,0,1-.823,0l-1.911-1.916a.581.581,0,0,1,0-.823L17.29,20l-3.174-3.15a.581.581,0,0,1,0-.823l1.916-1.916a.581.581,0,0,1,.823,0L20,17.29l3.15-3.174a.581.581,0,0,1,.823,0l1.916,1.916a.581.581,0,0,1,0,.823L22.71,20Z"
              transform="translate(-8 -8)"
              fill="#003f62"
            />
          </SvgIcon>
        </IconButton>
      </MuiDialogTitle>

      <DialogContent
        style={{
          paddingBottom: "0",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29.44"
          height="35.099"
          viewBox="0 0 29.44 35.099"
        >
          <path
            id="FontAwsome_trash-alt_"
            data-name="FontAwsome (trash-alt)"
            d="M2.1,31.808A3.225,3.225,0,0,0,5.257,35.1H24.183a3.225,3.225,0,0,0,3.154-3.291V8.775H2.1Zm17.874-17.55a1.052,1.052,0,1,1,2.1,0V29.615a1.052,1.052,0,1,1-2.1,0Zm-6.308,0a1.052,1.052,0,1,1,2.1,0V29.615a1.052,1.052,0,1,1-2.1,0Zm-6.308,0a1.052,1.052,0,1,1,2.1,0V29.615a1.052,1.052,0,1,1-2.1,0ZM28.388,2.194H20.5L19.885.912A1.572,1.572,0,0,0,18.472,0H10.961A1.554,1.554,0,0,0,9.555.912L8.937,2.194H1.051A1.075,1.075,0,0,0,0,3.29V5.484a1.075,1.075,0,0,0,1.051,1.1H28.388a1.075,1.075,0,0,0,1.051-1.1V3.29A1.075,1.075,0,0,0,28.388,2.194Z"
            transform="translate(0 0)"
            fill="#003f62"
          />
        </svg>

        <h4
          style={{
            color: "#003F62",
            fontWeight: "lighter",
            marginTop: "2vw",
          }}
        >
          Estas seguro que deseas eliminar esta cuenta?
        </h4>
      </DialogContent>

      <MuiDialogActions style={{ justifyContent: "center" }}>
        <footer className={classes.footerModal}>
          <button onClick={handleClose} className={classes.btnLigth}>
            Cancelar
          </button>
          <button onClick={onSubmit} className={classes.btnSolid}>
            Aceptar
          </button>
        </footer>
      </MuiDialogActions>
    </>
  );
};

const ModalEdit = ({
  handleClose,
  onSubmit,
  create,
  onDeleteItem,
  onDeleteWarning,
  account,
}) => {
  const classes = useStyles();
  const [fields, setfields] = useState(account.appAccountData);
  const [fieldsWarning, setfieldsWarning] = useState(
    account.appAccountWarning || []
  );

  const [path, setpath] = useState(account.helpImage || "");

  const fieldsArray = fields;

  const fileSelected = () => {
    const file = document.getElementById("file").files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const image = document.createElement("img");
      image.src = e.target.result;
      setpath(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <MuiDialogTitle
        disableTypography
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "0",
        }}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <SvgIcon viewBox="0 0 24 24">
            <path
              id="FontAwsome_times-circle_"
              data-name="FontAwsome (times-circle)"
              d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm5.884,15.15a.581.581,0,0,1,0,.823l-1.916,1.911a.581.581,0,0,1-.823,0L20,22.71l-3.15,3.174a.581.581,0,0,1-.823,0l-1.911-1.916a.581.581,0,0,1,0-.823L17.29,20l-3.174-3.15a.581.581,0,0,1,0-.823l1.916-1.916a.581.581,0,0,1,.823,0L20,17.29l3.15-3.174a.581.581,0,0,1,.823,0l1.916,1.916a.581.581,0,0,1,0,.823L22.71,20Z"
              transform="translate(-8 -8)"
              fill="#003f62"
            />
          </SvgIcon>
        </IconButton>
      </MuiDialogTitle>
      <DialogContent
        style={{
          paddingBottom: "0",
        }}
      >
        <h4
          style={{
            color: "#003F62",
            marginTop: "-1vw",
            marginBottom: "3vw",
            fontSize: "5vw",
            textAlign: "center",
          }}
        >
          {create ? "Crear cuenta" : "Agregar campo"}
        </h4>
        {fields.map((e, i) => {
          return (
            <div key={e.id}>
              <NewField
                field={e}
                fieldsArray={fieldsArray}
                setfields={setfields}
                i={i}
                fields={fields}
                create={create}
                onDelete={create ? () => {} : () => onDeleteItem(e)}
              />
            </div>
          );
        })}

        <AddNewField
          add={() => {
            setfields([...fields, { id: v4() }]);
            fieldsArray.push({ order: fieldsArray.length - 1 });
          }}
        />

        <Warnings
          fields={fieldsWarning}
          setfields={setfieldsWarning}
          account={account}
          onDeleteItem={onDeleteWarning}
          fieldsArray={fieldsArray}
        ></Warnings>

        <div className={classes.UploadImageContainer}>
          {path == "" && (
            <div className={classes.UploadImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34.286"
                height="24"
                viewBox="0 0 34.286 24"
              >
                <path
                  id="FontAwsome_cloud-upload-alt_"
                  data-name="FontAwsome (cloud-upload-alt)"
                  d="M28.8,42.425a5.149,5.149,0,0,0-4.8-7,5.117,5.117,0,0,0-2.855.868,8.572,8.572,0,0,0-16,4.275c0,.145.005.289.011.434A7.716,7.716,0,0,0,7.714,56H27.429A6.857,6.857,0,0,0,28.8,42.425Zm-7.725,3.289h-3.5v6a.86.86,0,0,1-.857.857H14.143a.86.86,0,0,1-.857-.857v-6h-3.5a.856.856,0,0,1-.605-1.463l5.646-5.646a.86.86,0,0,1,1.211,0l5.646,5.646A.857.857,0,0,1,21.075,45.714Z"
                  transform="translate(0 -32)"
                  fill="#707070"
                />
              </svg>
              <h3>Subir imagen de ayuda</h3>
            </div>
          )}
          {path != "" && (
            <div>
              <div className={classes.trashIcon} onClick={() => setpath("")}>
                <svg
                  width="26"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z" />
                </svg>
              </div>
              <img src={path} style={{ width: "100%" }} />
            </div>
          )}
          <input
            className={classes.file}
            type="file"
            accept="image/*"
            id="file"
            onChange={fileSelected}
          />
        </div>
      </DialogContent>

      <MuiDialogActions style={{ justifyContent: "center" }}>
        <footer className={classes.footerModal}>
          <button
            onClick={
              path != ""
                ? () => onSubmit(fields, fieldsWarning, path, account)
                : () => {}
            }
            className={classes.btnSolid}
            style={{ background: path == "" ? "gray" : "#003f62" }}
          >
            Guardar
          </button>
        </footer>
      </MuiDialogActions>
    </>
  );
};

const NewField = ({
  create,
  setfields,
  field,
  fields,
  i,
  fieldsArray,
  onDelete,
}) => {
  const classes = useStyles();
  const [valueTemporally, setvalueTemporally] = useState(field.value);
  const [fieldTemporally, setfieldTemporally] = useState(field.field);

  const onChange = (e) => {
    const fieldsCopy = JSON.parse(JSON.stringify(fields));
    fieldsCopy[i][e.target.name] = e.target.value;
    setfields(fieldsCopy);
  };

  const deleteItem = async () => {
    await onDelete();

    if (field.appAccountId) {
      const fieldsArrayUpdated = fieldsArray.filter(
        (fld) => fld.id != field.id
      );
      fieldsArray = fieldsArrayUpdated;
      setfields([...fieldsArrayUpdated]);
    } else {
      fieldsArray.splice(i, 1);
      setfields([...fieldsArray]);
    }
  };

  return (
    <div className={classes.NewField}>
      <input
        type="text"
        value={fieldTemporally}
        placeholder="Campo"
        name="field"
        onChange={(e) => {
          setfieldTemporally(e.target.value);
          onChange(e);
        }}
      />
      <input
        type="text"
        value={valueTemporally}
        placeholder="Valor"
        name="value"
        onChange={(e) => {
          setvalueTemporally(e.target.value);
          onChange(e);
        }}
      />

      <div onClick={deleteItem}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.008"
            height="17.437"
            viewBox="0 0 10.008 11.437"
          >
            <path
              id="FontAwsome_trash-alt_"
              data-name="FontAwsome (trash-alt)"
              d="M.715,10.365a1.072,1.072,0,0,0,1.072,1.072H8.22a1.072,1.072,0,0,0,1.072-1.072V2.859H.715ZM6.791,4.646a.357.357,0,1,1,.715,0v5a.357.357,0,0,1-.715,0Zm-2.144,0a.357.357,0,1,1,.715,0v5a.357.357,0,0,1-.715,0Zm-2.144,0a.357.357,0,1,1,.715,0v5a.357.357,0,0,1-.715,0ZM9.65.715H6.97L6.76.3a.536.536,0,0,0-.48-.3H3.726a.53.53,0,0,0-.478.3l-.21.418H.357A.357.357,0,0,0,0,1.072v.715a.357.357,0,0,0,.357.357H9.65a.357.357,0,0,0,.357-.357V1.072A.357.357,0,0,0,9.65.715Z"
              transform="translate(0 0)"
              fill="#f96123"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

const AddNewField = ({ add }) => {
  const classes = useStyles();

  return (
    <div onClick={add} className={classes.AddNewField}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          id="FontAwsome_plus-square_"
          data-name="FontAwsome (plus-square)"
          d="M21.429,32H2.571A2.572,2.572,0,0,0,0,34.571V53.429A2.572,2.572,0,0,0,2.571,56H21.429A2.572,2.572,0,0,0,24,53.429V34.571A2.572,2.572,0,0,0,21.429,32ZM19.714,45.5a.645.645,0,0,1-.643.643H14.143v4.929a.645.645,0,0,1-.643.643h-3a.645.645,0,0,1-.643-.643V46.143H4.929a.645.645,0,0,1-.643-.643v-3a.645.645,0,0,1,.643-.643H9.857V36.929a.645.645,0,0,1,.643-.643h3a.645.645,0,0,1,.643.643v4.929h4.929a.645.645,0,0,1,.643.643Z"
          transform="translate(0 -32)"
          fill="#003f62"
        />
      </svg>

      <span
        style={{
          fontWeight: "lighter",
          fontSize: "3vw",
        }}
      >
        Agregar <br /> cambio
      </span>
    </div>
  );
};
