import { createSlice } from "@reduxjs/toolkit";
import { Axios } from "../helpers/axios";

export const setActiveAppaccount = async (id, active) => {
  const { data } = await Axios.put(
    process.env.REACT_APP_API_URL + "appaccount/updateActive/" + id,
    {
      active,
    }
  );
  return data;
};

export const getParentMethod = async (id) => {
  const { data } = await Axios.get(
    process.env.REACT_APP_API_URL + "parentmethod/show/" + id
  );
  return data.data;
};

export const getBanks = async (id) => {
  const { data } = await Axios.get(
    process.env.REACT_APP_API_URL + "parentbank"
  );
  return data.data;
};

export const DeleteAppaccount = async (id) => {
  const { data } = await Axios.delete(
    process.env.REACT_APP_API_URL + "appaccount/delete/" + id
  );
  return data;
};

export const parentMethodsSlice = createSlice({
  name: "parentMethods",
  initialState: {
    ParentMethods: [],
  },
  reducers: {
    setParentMethods: (state, action) => {
      state.ParentMethods = action.payload;
    },
  },
});

const { setParentMethods } = parentMethodsSlice.actions;

export const fetchParentMethods = () => async (dispatch) => {
  try {
    await Axios.get(process.env.REACT_APP_API_URL + "parentmethod").then(
      (response) => dispatch(setParentMethods(response.data.data))
    );
  } catch (e) {
    return console.error(e.message);
  }
};

export const getFechParentMethod = () => {
  return Axios.get(process.env.REACT_APP_API_URL + "parentmethod/all");
};

export default parentMethodsSlice.reducer;
