import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const fetchNotifications = createAsyncThunk('notification', async () => {
  return Axios
    .get(process.env.REACT_APP_API_URL + 'notification')
    .then((response) => response.data.data)
})

export const updateNotification = createAsyncThunk(
  'updateNotification',
  async (data) => {
    await Axios
      .put(
        process.env.REACT_APP_API_URL + 'notification/update/' + data.id,
        data
      )
      .then((response) => response.data)
  }
)

export const createNotification = async (dataNotification) => {
  const { data } = await Axios.post(
    process.env.REACT_APP_API_URL + 'notification/send',
    dataNotification
  )

  console.log('%cMyProject%cline:28%cdata', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(251, 178, 23);padding:3px;border-radius:2px', data)
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: []
  },
  extraReducers: {
    [fetchNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload
    }
  }
})

export default notificationsSlice.reducer
