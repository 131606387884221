import React from "react";
import { InputDollarProps } from "../models/rates-of-the-day-dollars-models";

function RatesOfTheDayDollars() {
  const InputDollar = (props: InputDollarProps) => {
    return (
      <div className="InputDollar">
        <p>{props.label}</p>
        <input value={props.value} />
      </div>
    );
  };

  return (
    <div className="rates-of-the-day-dollars">
      <InputDollar label="Dolar blue" value="26.25" />
      <div className="spacer"></div>
      <InputDollar label="Dolar en pesos chilenos" value="750" />
    </div>
  );
}

export default RatesOfTheDayDollars;
