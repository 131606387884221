import React from 'react'
import { formatDate } from '../../../../helpers/dates'
import MainLayout from '../../../../layouts/main'
import { formatNumber } from '../../../../helpers/numbers'

function TransactionItem () {
  return (
    <article
      style={{
        background: 'white',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '20% 54% auto',
        padding: '3vw 2vw',
        borderRadius: '5px',
        marginBottom: '3vw'

      }}
    >
      <div
        style={{
          width: '4vw',
          height: '4vw',
          background: '#f96123',
          marginLeft: '2vw'

        }}
      ></div>
      <div>{formatNumber(10000)} BS</div>
      <div>{formatDate('2022-03-10T20:07:58.000Z')}</div>
    </article>
  )
}

export default function TransactionsContainer () {
  return (
    <div>
      <h3
        style={{
          marginBottom: '3vw',
          color: '#f96123'
        }}
      >
        Transacciones realizadas:
      </h3>

      <TransactionItem></TransactionItem>
      <TransactionItem></TransactionItem>
      <TransactionItem></TransactionItem>
      <TransactionItem></TransactionItem>
    </div>
  )
}
