import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '11vh',
    backgroundColor: '#003F62',
    padding: 10,
    paddingBottom: 10,
    display: 'grid',
    gridTemplateColumns: '30% 30% 30%',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  containerItem: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '3vw',
    '& svg': {
      width: '30%'
    }
  }
}))

export default function BottomNavigator () {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <Item
        route="operator-dashboard"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30.717"
            height="24"
            viewBox="0 0 30.717 24"
          >
            <g
              id="Group_5634"
              data-name="Group 5634"
              transform="translate(-31 -624)"
            >
              <path
                id="FontAwsome_exchange-alt_"
                data-name="FontAwsome (exchange-alt)"
                d="M0,62.7v-.96A1.44,1.44,0,0,1,1.44,60.3h21.6v-2.88A1.441,1.441,0,0,1,25.5,56.4l4.8,4.8a1.44,1.44,0,0,1,0,2.036l-4.8,4.8a1.441,1.441,0,0,1-2.458-1.018v-2.88H1.44A1.44,1.44,0,0,1,0,62.7Zm29.277,9.119H7.679v-2.88a1.441,1.441,0,0,0-2.458-1.018l-4.8,4.8a1.44,1.44,0,0,0,0,2.036l4.8,4.8a1.441,1.441,0,0,0,2.458-1.018v-2.88h21.6a1.44,1.44,0,0,0,1.44-1.44v-.96A1.44,1.44,0,0,0,29.277,71.816Z"
                transform="translate(31 568.024)"
                fill="#fff"
              />
            </g>
          </svg>
        }
        title= 'Transferencias'
      />
      <Item
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.124"
            height="26.832"
            viewBox="0 0 20.124 26.832"
          >
            <g
              id="Group_5647"
              data-name="Group 5647"
              transform="translate(-390 -127)"
            >
              <path
                id="FontAwsome_file-alt_"
                data-name="FontAwsome (file-alt)"
                d="M15.093,13v1.467a.631.631,0,0,1-.629.629H5.66a.631.631,0,0,1-.629-.629V13a.631.631,0,0,1,.629-.629h8.8A.631.631,0,0,1,15.093,13Zm-.629,3.773H5.66a.631.631,0,0,0-.629.629v1.467a.631.631,0,0,0,.629.629h8.8a.631.631,0,0,0,.629-.629V17.4A.631.631,0,0,0,14.464,16.77Zm5.66-9.858v17.4a2.516,2.516,0,0,1-2.516,2.516H2.516A2.516,2.516,0,0,1,0,24.317V2.516A2.516,2.516,0,0,1,2.516,0h10.7a2.516,2.516,0,0,1,1.777.739l4.4,4.4A2.506,2.506,0,0,1,20.124,6.912ZM13.416,2.72V6.708H17.4L13.416,2.72Zm4.193,21.6V9.224h-5.45A1.255,1.255,0,0,1,10.9,7.966V2.516H2.516v21.8Z"
                transform="translate(390 127)"
                fill="#fff"
              />
            </g>
          </svg>
        }
        route='operator/history'
        title= 'Historial'
      />
      <Item
        route="balances"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.492"
            height="24.492"
            viewBox="0 0 24.492 24.492"
          >
            <path
              id="FontAwsome_coins_"
              data-name="FontAwsome (coins)"
              d="M0,19.388v2.043c0,1.689,4.114,3.062,9.185,3.062s9.185-1.373,9.185-3.062V19.388c-1.976,1.392-5.587,2.043-9.185,2.043S1.976,20.78,0,19.388ZM15.308,6.123c5.071,0,9.185-1.373,9.185-3.062S20.378,0,15.308,0,6.123,1.373,6.123,3.062,10.237,6.123,15.308,6.123ZM0,14.37v2.468C0,18.527,4.114,19.9,9.185,19.9s9.185-1.373,9.185-3.062V14.37C16.394,16,12.777,16.839,9.185,16.839S1.976,16,0,14.37Zm19.9.526c2.741-.531,4.592-1.516,4.592-2.65V10.2a11.747,11.747,0,0,1-4.592,1.65ZM9.185,7.654C4.114,7.654,0,9.366,0,11.481s4.114,3.827,9.185,3.827,9.185-1.713,9.185-3.827S14.255,7.654,9.185,7.654Zm10.491,2.693c2.87-.517,4.817-1.531,4.817-2.693V5.611c-1.7,1.2-4.616,1.846-7.687,2A5.357,5.357,0,0,1,19.675,10.347Z"
              fill="#fff"
            />
          </svg>
        }
        title= 'Saldos'
      />
    </div>
  )
}

function Item ({ svg, route, title }) {
  const history = useHistory()
  const classes = useStyles()
  return (
    <div
      className={classes.containerItem}
      onClick={() => {
        history.push('/' + route)
      }}
    >
      {svg}
      <div style={{ height: '10px' }}></div>
      <p>{title}</p>
    </div>
  )
}
