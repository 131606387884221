import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Drawer, SvgIcon } from '@material-ui/core'
import Menu from '../../components/menu/Menu'
import { useHistory, Link } from 'react-router-dom'
import './index.css'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { isEmpty } from 'lodash'

const useStyles = makeStyles({
  header: {
    height: 50,
    width: '100%',
    color: '#eee',
    paddingTop: '13px',
    paddingBottom: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    position: 'fixed',
    backgroundColor: '#003353',
    zIndex: 3
  },
  headerPhoto: {
    height: 70,
    width: '100vw',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1vw 3vw 1vw',
    backgroundColor: '#003f62',
    color: '#fff',
    zIndex: 3,
    marginTop: '10px'
  },
  responsiveSvg: {
    width: '6vw',
    maxWidth: '40px',
    overflow: 'visible'
  }
})

const renderBreadCrumbs = (links = []) => {
  const items = []

  if (!isEmpty(links)) {
    const current = links.pop()
    links.map((link, key) => {
      items.push(
        <Link
          key={key}
          style={{ color: '#888' }}
          color="inherit"
          to={ { pathname: link.href } } >
          {link.text}
        </Link>
      )
      return true
    })

    items.push(
      <Typography key={links.length + 1} style={{ color: '#eee', fontSize: '13px' }}>{ current.text }</Typography>
    )
  }

  return items
}

const Index = ({ text, style, breadcrumbs }) => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
        <>
          <Grid className={ classes.header }>
              <Grid item style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
                  <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                      <Menu setOpenDrawer={setOpenDrawer} />
                  </Drawer>
                  <SvgIcon viewBox="0 0 20.571 18" style={{ marginRight: 15, cursor: 'pointer' }} onClick={() => setOpenDrawer(true)}>
                      <path id="FontAwsome_bars_" data-name="FontAwsome (bars)" d="M.735,63.306h19.1a.735.735,0,0,0,.735-.735V60.735A.735.735,0,0,0,19.837,60H.735A.735.735,0,0,0,0,60.735v1.837A.735.735,0,0,0,.735,63.306Zm0,7.347h19.1a.735.735,0,0,0,.735-.735V68.082a.735.735,0,0,0-.735-.735H.735A.735.735,0,0,0,0,68.082v1.837A.735.735,0,0,0,.735,70.653ZM.735,78h19.1a.735.735,0,0,0,.735-.735V75.429a.735.735,0,0,0-.735-.735H.735A.735.735,0,0,0,0,75.429v1.837A.735.735,0,0,0,.735,78Z" transform="translate(0 -60)" fill="#fff"/>
                  </SvgIcon>

                  <h3>{text}</h3>

                  <Breadcrumbs aria-label="breadcrumb" style={{ color: '#eee', fontSize: '13px' }}>
                    {
                      renderBreadCrumbs(breadcrumbs)
                    }
                  </Breadcrumbs>

              </Grid>
          </Grid>
        </>
  )
}

export default Index
