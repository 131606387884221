import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { formatNumber } from './../../../helpers/numbers'

const useStyles = makeStyles(() => ({
  container: {
    padding: '3vw 5vw 3vw 2vw',
    margin: '3vw 0 3vw 0',
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'center',
    borderRadius: 10,
    border: '2px solid #D2D2D2',
    justifyContent: 'space-between'
  }
}))

export default function Index ({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>
        <h4
          style={{
            color: '#FA7D4A',
            fontWeight: 'bold',
            justifyContent: 'space-between',
            display: 'grid',
            gridTemplateColumns: '39% 61%'
          }}
        >
          <span>{data.user.name} </span>
          <span style={{ color: '#797979', fontWeight: 'bold', textAlign: 'right' }}>
            {' '}
            {formatNumber(data.total)} {data.currency.abbr}
          </span>
        </h4>
      </div>
    </div>
  )
}
