import React, { useEffect, useState } from "react";
import SectionLayout from "../../layouts/section_layout/section_layout";
import TransactionCard from "../../components/admin/transactionCard";
import {
  createTransferenceStatus,
  fetchTransferences,
  fetchTransferencesFunct,
  getAllLastTransactions,
  updateOperatorId,
} from "../../slices/transactions";
import { firstTransferenceWithDate } from "../../utils/transferences/transferences_utils";
import { getMonthAndDayFromDate } from "../../utils/date/date_utils";
import moment from "moment";
import InputSearch from "../../components/form/searchField";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Screenloading from "../../components/loaders/screenLoading";
import { useDispatch, useSelector } from "react-redux";

function HistoryTransfernecesScreen() {
  const [loading, setloading] = useState(false);
  const [wordToSearch, setWordToSearch] = useState("");
  const [usefirstTransferenceWithDate, setusefirstTransferenceWithDate] =
    useState({});
  const [transferencesList, setTransferencesList] = useState([]);

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const dispatch = useDispatch();

  const operators = useSelector((state) => state.bssbalance.rows);

  const getTransferences = async () => {
    setloading(true);
    try {
      const response = await fetchTransferencesFunct({
        start: moment(start["$d"]).format("YYYY-MM-DD"),
        end: moment(end["$d"]).format("YYYY-MM-DD"),
        query: wordToSearch,
      });
      setloading(false);

      setTransferencesList(response);
      setusefirstTransferenceWithDate(firstTransferenceWithDate(response));
    } catch (error) {
      setloading(false);
    }
  };

  useEffect(() => {
    getTransferences();
  }, [start]);

  useEffect(() => {
    getTransferences();
  }, [end]);

  useEffect(() => {
    const currentDate = dayjs();
    const tomorrowDate = currentDate.add(1, "day");
    const threeDaysAgoDate = currentDate.subtract(2, "day");

    setStart(threeDaysAgoDate);
    setEnd(tomorrowDate);
  }, []);

  const sendForm = async (transactionId, operatorId) => {
    dispatch(await updateOperatorId(transactionId, operatorId)).then(() => {
      getTransferences();
    });
  };

  const removeOperatorFromTransference = async (transference) => {
    dispatch(createTransferenceStatus(transference.id, 1, "")).then((e) => {
      getTransferences();
    });
  };

  const DateIndicator = ({ transaction }) => {
    return (
      <p
        style={{
          color: "black",
          marginBottom: "2vh",
          textTransform: "capitalize",
        }}
      >
        {moment(new Date(transaction.createdAt)).format("dddd - DD/MM/YY")}
      </p>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {loading && <Screenloading dark={false} label="Cargando"></Screenloading>}
      <SectionLayout title="Historial" returnHeader>
        {start && end && (
          <Filters
            start={start}
            setStart={setStart}
            end={end}
            setEnd={setEnd}
          ></Filters>
        )}

        <div
          style={{
            marginBottom: "2vh",
          }}
        >
          <InputSearch
            onChange={setWordToSearch}
            onSubmit={(e) => {
              e.preventDefault();
              getTransferences();
            }}
          />
        </div>
        {transferencesList.map((transaction) => {
          return (
            <>
              {usefirstTransferenceWithDate[
                getMonthAndDayFromDate(new Date(transaction.createdAt))
              ] == transaction.id && (
                <DateIndicator transaction={transaction} />
              )}
              <TransactionCard
                transaction={transaction}
                cancelTransaction={() => {}}
                message={""}
                removeOperatorFromTransference={removeOperatorFromTransference}
                settransactionSelected={() => {}}
                transactionSelected={transaction}
                key={transaction.id}
                operators={operators}
                sendForm={sendForm}
                showModal={() => {}}
                setShowModal={() => {}}
              />
            </>
          );
        })}
      </SectionLayout>
    </LocalizationProvider>
  );
}

function Filters(props) {
  const DatePickerComponent = DatePicker;

  return (
    <div
      style={{
        background: "white",
        display: "grid",
        gridTemplateColumns: "45% auto",
        gridGap: "5%",
        alignItems: "center",
        padding: "4vw 2vw 3vw 2vw",
        borderRadius: "5px",
        marginBottom: "2vh",
      }}
    >
      <DatePickerComponent
        onChange={props.setStart}
        label="Fecha inicio"
        value={props.start}
        inputFormat="DD/mm/yy"
        renderInput={(params) => <TextField {...params} />}
      />
      <DatePickerComponent
        onChange={props.setEnd}
        value={props.end}
        label="Fecha final"
        renderInput={(params) => <TextField {...params} />}
        inputFormat="dd/MM/yyyy"
      />
    </div>
  );
}

export default HistoryTransfernecesScreen;
