import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Axios } from "../helpers/axios";
import { validateResponse } from "../helpers/index";
import { statePayment } from "../components/payments/paymentCard";
import { isNull } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { NumberFormat } from "react-number-format";
import { formatNumber } from "../helpers/numbers";

const MySwal = withReactContent(Swal);
export const paymentSlice = createSlice({
  name: "payments",
  initialState: {
    count: 0,
    rows: [],
    pendings: [],
    receivedToday: [],
    history: {},
  },
  reducers: {
    setPayments: (state, action) => {
      state.count = action.payload.count;
      state.rows = action.payload ? action.payload.reverse() : [];
    },
    setLastPayments: (state, action) => {
      state.pendings = action.payload.pendings;
      state.receivedToday = action.payload.receivedToday;
    },
    setPaymentHistory: (state, action) => {
      state.history = action.payload ? action.payload : [];
    },
  },
});

const { setPayments, setLastPayments, setPaymentHistory } =
  paymentSlice.actions;

export const getAllLastPayments = async () => async (dispatch) => {
  try {
    const userData = localStorage.getItem("user");
    if (
      userData &&
      JSON.parse(userData) &&
      (JSON.parse(userData).roleId == 1 || JSON.parse(userData).roleId == 5)
    ) {
      const { data: responseDTO } = await Axios.get(
        `${process.env.REACT_APP_API_URL}payments/last`
      );
      dispatch(setLastPayments(responseDTO.data));
    }
  } catch (error) {
    if (error.response != undefined) {
      validateResponse(error.response.data);
    } else {
      MySwal.fire({
        text: error.message,
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
    }
  }
};

export const checkPayment =
  async (paymentId, state, text) => async (dispatch) => {
    try {
      const data = {};
      switch (state) {
        case statePayment.Approved:
          data.reference = text;
          data.approved = true;
          break;
        case statePayment.Pending:
          data.failed = text;
          data.approved = null;
          break;
        case statePayment.Rejected:
          data.failed = text;
          data.approved = false;
          break;
      }
      const { data: responseDTO } = await Axios.put(
        `${process.env.REACT_APP_API_URL}payments/checkPayment/${paymentId}`,
        data
      );
      console.log("responseDTO", responseDTO);
      if (responseDTO.code == 59) {
        const date = new Date(responseDTO.data.createdAt);
        MySwal.fire({
          html: (
            <div>
              <h3 style={{ fontWeight: "lighter" }}>
                La referencia ya esta en uso.
              </h3>
              <h2
                style={{
                  marginTop: "4vw",
                  color: "#012852",
                }}
              >
                Monto: {formatNumber(responseDTO.data.amount)}
              </h2>
              <img
                style={{
                  width: "69vw",
                  margin: "5vw",
                }}
                src={responseDTO.data.capture}
              />
              <p>
                Fecha:{" "}
                <b style={{ marginRight: "2vw" }}>
                  {date.getDate()}/{date.getMonth() + 1}
                </b>{" "}
                Hora:{" "}
                <b>
                  {date.getHours()}:{date.getMinutes()}
                </b>
              </p>
            </div>
          ),
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      } else {
        const payments = await Axios.get(
          `${process.env.REACT_APP_API_URL}payments/last`
        );

        dispatch(setLastPayments(payments.data.data));

        toast.success(responseDTO.description, {
          position: "top-right",
          toastId: "success8",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      if (error.response != undefined) {
        validateResponse(error.response.data);
      } else {
        MySwal.fire({
          text: error.message,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
  };

export const getPaymentHistory =
  async (start = null, end = null, query = "") =>
  async (dispatch) => {
    try {
      const dateStart = isNull(start)
        ? moment().format("YYYY-MM-DD")
        : moment(start).format("YYYY-MM-DD");
      const dateEnd = isNull(end)
        ? moment().format("YYYY-MM-DD")
        : moment(end).format("YYYY-MM-DD");

      const { data: responseDTO } = await Axios.get(
        `${process.env.REACT_APP_API_URL}payments/history`,
        {
          params: {
            start: dateStart,
            end: dateEnd,
            query,
          },
        }
      );
      dispatch(setPaymentHistory(responseDTO.data));
    } catch (error) {
      if (error.response != undefined) {
        validateResponse(error.response.data);
      } else {
        MySwal.fire({
          text: error.message,
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
  };

export default paymentSlice.reducer;
