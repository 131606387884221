import { useEffect, useCallback, useState } from "react";

const useVariationPercentage = (rate, variation) => {
  const [percentages, setpercetages] = useState(null);
  const [percentagesNumber, setpercetagesNumber] = useState(null);

  useEffect(() => {
    if (rate.rate) {
      const value = rate.rate;
      // eslint-disable-next-line no-useless-return
      if (isNaN(value)) return;
      else {
        const percentage = (value * variation) / 100;

        const percentageMinus = value - percentage;
        const percentagePlus = value + percentage;

        setpercetagesNumber({
          percentageMinus: percentageMinus,
          percentagePlus: percentagePlus,
        });
        setpercetages({
          percentageMinus: percentageMinus.toFixed(4),
          percentagePlus: percentagePlus.toFixed(4),
        });
      }
    }
  }, [rate, variation]);

  return { percentages, percentagesNumber };
};

export default useVariationPercentage;
