import React from "react";
import { makeStyles } from "@material-ui/core";
import { useField } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const useStyles = makeStyles({
  selectContainer: {
    margin: "4%",
    marginBottom: "10%",
  },
  selectInput: {
    marginBottom: "4%",
  },
  inputFlag: {
    width: "8%",
    marginRight: "3%",
  },
  optionContainer: {
    fontSize: "4vw",
  },
});

function CurrencySelector(props) {
  const classes = useStyles();
  const { currencies, ...values } = props;

  return (
    <Select
      options={currencies}
      value={props.value}
      formatOptionLabel={(country) => {
        return (
          <div className={classes.optionContainer}>
            <img src={country.logo} className={classes.inputFlag} />
            <span>{country.name}</span>
          </div>
        );
      }}
      onChange={props.onChange}
      isSearchable={false}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      {...values}
      className={classes.selectInput}
    />
  );
}

export default CurrencySelector;
