import { Form } from 'formik'

export const BalanceForm = (props) => {
  const { userName, isSubmit, children } = props

  return <Form style={{ width: '100%' }}>
      <h1>{userName}</h1>
      {children}
      <button
        type="submit"
        style={{
          background: 'rgb(255, 124, 70) none repeat scroll 0% 0%',
          width: '100%',
          padding: '1vw 2vw',
          fontSize: '5vw',
          fontWeight: 'lighter',
          color: 'white',
          border: 'none',
          borderRadius: '5px'
        }}
      >
        {isSubmit ? 'Crear balance' : 'Siguiente'}
      </button>
    </Form>
}
