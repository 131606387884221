import React, { useState, useEffect, useCallback, useContext } from "react";
import GeneratorComponent, {
  RateOfDayDollarPrice,
} from "./generator/rate_of_day_dollar_price.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getConfig } from "../../../utils/configs/index.js";
import {
  createConfig,
  getConfig as sliceConfig,
} from "./../../../slices/configs";
import { createRate, fetchRates } from "./../../../slices/rates";
import {
  getCurrencyDetails,
  updateCurrencyValues,
} from "../../../slices/currencies.js";
import { GenerateRateContext } from "../../../context/GenerateRateContext.js";

import Swal from "sweetalert2";
import Rate from "../../../components/Rate/Rate.jsx";

function List(props) {
  const { generatedRates, venDollar, curDollar, rateOperationType } = props;

  const reduxRates = useSelector((state) => state.rates.rows.rates);

  const { setLoading, currencyId } = useContext(GenerateRateContext);

  const [variation, setVariation] = useState(null);
  const [rates, setRates] = useState(null);

  const getVariaton = async () => {
    const response = await sliceConfig("MaximumRateVariationPercentage");
    setVariation(parseInt(response.value));
  };

  const filterRates = useCallback(
    async (currencyId) => {
      if (reduxRates && currencyId) {
        const result = await getCurrencyDetails(currencyId);
        const currencyCostRate = result.fields.find(
          (el) => el.key === "CostRate"
        );
        setRates({
          publicRate: reduxRates.find((el) => el.rangeId === 1) || {
            rate: "Sin definir",
            rangeId: 1,
            range: { id: 1, name: "Pública" },
          },
          davidRate: {
            rangeId: 2,
            range: {
              id: 2,
              name: "David",
            },
            rate: Number(currencyCostRate.value),
          },
          vipRate: reduxRates.find((el) => el.rangeId === 3) || {
            rate: "Sin definir",
            rangeId: 3,
            range: { id: 3, name: "Aliado Vip" },
          },
        });
      }
    },
    [reduxRates]
  );

  useEffect(() => {
    // setLoading(true)
    if (!variation) getVariaton();
    filterRates(currencyId);
    setLoading(false);
  }, [reduxRates, generatedRates, currencyId]);

  return (
    <div>
      {variation != 0 && rates && (
        <article className="containerRates">
          {/* David's rate */}
          <Rate
            rate={rates.davidRate}
            variation={variation}
            rateValue={props.davidRate.toFixed(4)} // Invertir lo que dice el endpoint
            expand
            costRate={props.davidRate}
            currencyIsColombia={props.currencyIsColombia}
            name="David"
            rangeName={rates.davidRate.range.name}
          />
          {/* Public rate */}
          <Rate
            variaton={props.variaton}
            rate={rates.publicRate}
            costRate={props.davidRate}
            variation={variation}
            currencyIsColombia={props.currencyIsColombia}
            expand
            name="Pública"
            rangeName={rates.publicRate.range.name}
          />
          {/* VIP rate */}
          <Rate
            variaton={props.variaton}
            rate={rates.vipRate}
            costRate={props.davidRate}
            variation={variation}
            expand
            currencyIsColombia={props.currencyIsColombia}
            name="Aliados VIP"
            rangeName={rates.vipRate.range.name}
          />
        </article>
      )}
    </div>
  );
}

export function Generator(props) {
  const { rows: rates, status: ratesStatus } = useSelector(
    (store) => store.rates
  );
  const dispatch = useDispatch();

  const [rateOperationType, setRateOperationType] = useState(null);
  const [venDollar, setVenDollar] = useState(0);
  const [curDollar, setCurDollar] = useState(0);
  const [currecnySelected, setCurrecnySelected] = useState();
  const [currencyDetails, setCurrencyDetails] = useState(null);

  const { setLoading, currencyId, generatedRates, setGeneratedRates } =
    useContext(GenerateRateContext);

  const calculatePercentage = useCallback(
    (number, variaton) => (number * variaton) / 100,
    []
  );

  const static_rates = Object.freeze({
    PUBLIC_RATE_PERCENTAGE: 6,
    PUBLIC_RATE_PERCENTAGE_COLOMBIA: 20,
    VIP_RATE_PERCENTAGE: 3,
    VIP_RATE_PERCENTAGE_COLOMBIA: 10,
  });

  const currencyIsColombia = () => currecnySelected?.countryId == 2;

  const getPublicRate = () => {
    const costRate = getDavidRate();

    if (currencyIsColombia()) {
      return (
        costRate +
        calculatePercentage(
          costRate,
          static_rates.PUBLIC_RATE_PERCENTAGE_COLOMBIA
        )
      );
    }

    return (
      costRate -
      calculatePercentage(costRate, static_rates.PUBLIC_RATE_PERCENTAGE)
    );
  };

  const getPersonalRate = () => {
    const costRate = getDavidRate();

    let personalRate =
      costRate -
      calculatePercentage(costRate, static_rates.VIP_RATE_PERCENTAGE);

    if (currencyIsColombia()) {
      personalRate =
        costRate +
        calculatePercentage(
          costRate,
          static_rates.VIP_RATE_PERCENTAGE_COLOMBIA
        );
    }

    return personalRate;
  };

  const calculateCostRate = async (number1, number2, saveConfig = false) => {
    //const costRate = Number(number1) / Number(number2);
    const costRate = getDavidRate();

    setGeneratedRates({
      costRate,
      publicRate: getPublicRate().toFixed(5),
      personalRate: getPersonalRate().toFixed(5),
    });

    if (saveConfig) {
      const currencyCostRate = getConfig(currencyDetails, "CostRate");
      const currencyDollarPrice = getConfig(currencyDetails, "BlueDollar");

      const updateCostRate = {
        id: currencyCostRate.currencyValue.id,
        value: costRate.toFixed(4),
      };
      const updateCurrencyPrice = {
        id: currencyDollarPrice.currencyValue.id,
        value: Number(number2),
      };

      await updateCurrencyValues([updateCostRate, updateCurrencyPrice], false);
      await dispatch(
        createConfig({
          key: "DolarBlue",
          name: "Dolar Blue",
          currencyId: 1,
          value: venDollar,
        })
      );

      await Promise.all([
        dispatch(
          createRate({
            rangeId: 2,
            rate: getDavidRate().toFixed(4),
            currencyId,
          })
        ),
      ]);

      Swal.fire({
        html: "<p>Tasas actualizadas!<br/>Los cambios estarán disponibles en el próximo minuto 0</p>",
      });
    }
  };

  const getDavidRate = () => {
    if (currecnySelected?.countryId == 2) {
      return curDollar / venDollar;
    }

    return venDollar / curDollar;
  };

  const fetchCurrencyDetails = useCallback(async () => {
    console.log("fetchCurrencyDetails");
    const result = await getCurrencyDetails(currencyId);
    setCurrecnySelected(result);

    setRateOperationType(result.rateOperationType);

    const curDetails = result.fields.filter((el) => el.id === 9 || el.id === 4);
    setCurrencyDetails(curDetails);
    // Venezuela unofficial dollar price
    const { value: venezuelaDollar } = getConfig(rates.configs, "DolarBlue");
    setVenDollar(venezuelaDollar);

    // Dolar price from the currency stored in the database
    const { value: currencyDollarPrice } = getConfig(curDetails, "BlueDollar");
    setCurDollar(currencyDollarPrice);

    calculateCostRate(venezuelaDollar, currencyDollarPrice);
  }, [currencyId, rates]);

  useEffect(() => {
    if (currencyId && rates) fetchCurrencyDetails();
  }, [currencyId, rates]);

  useEffect(() => {
    setGeneratedRates({
      costRate: getDavidRate(),
      publicRate: getPublicRate().toFixed(5),
      personalRate: getPersonalRate().toFixed(5),
    });
  }, [getDavidRate()]);

  return (
    <>
      <RateOfDayDollarPrice
        number1={venDollar}
        number2={curDollar}
        setCurDollar={setCurDollar}
        setVenDollar={setVenDollar}
        onSubmit={calculateCostRate}
      />
      <List
        rateOperationType={rateOperationType}
        venDollar={venDollar}
        curDollar={curDollar}
        currencyIsColombia={currencyIsColombia()}
        davidRate={getDavidRate()}
        generatedRates={generatedRates}
      ></List>
    </>
  );
}

export default Generator;
