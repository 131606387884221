import React, { useEffect, useState } from "react";
import Layout from "../../layouts/main";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchParentMethods,
  getFechParentMethod,
} from "../../slices/parentmethods";
import { useHistory } from "react-router-dom";
import CountrySelect, { CountrySelectWithoutForm } from "../country-select";
import { getAllCountries } from "../../slices/countries";
import { Form } from "formik";
import { fetchCurrencies } from "../../slices/currencies";
import CurrencySelector from "../currencies/currency_selector";
import Screenloading from "../loaders/screenLoading";

const useStyles = makeStyles({
  GridBank: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 31%)",
    gridGap: "3vw",
    marginTop: "6vw",
  },
  ContainerBank: {
    background: "white",
    borderRadius: 10,
    "& img": {
      width: "100%",
    },
  },
});

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [countries, setCountries] = useState([]);
  const [currencySelected, setCurrencySelected] = useState(null);
  const [countrySelected, setCountrySelected] = useState(null);
  const [parentMethods, setParentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currencies = useSelector((state) => state.currencies.rows);

  const getParentMethodFiltered = () => {
    const countryId = countrySelected?.id ?? 1;
    const currencyId = currencySelected?.id;

    return parentMethods.filter((e) => {
      let currencyIdMatch = e.currencyId == currencyId;

      if (currencyId == null) {
        currencyIdMatch = true;
      }

      return currencyIdMatch && e.currency?.countryId == countryId;
    });
  };

  const getCurrenciesByCountry = () => {
    return currencies.filter((e) => e.countryId == countrySelected?.id);
  };

  const getAllParentMethods = async () => {
    const response = await getFechParentMethod();
    setParentMethods(response.data.data);
  };

  const getAllData = async () => {
    setIsLoading(true);

    try {
      await getAllParentMethods();
      await getAllCountries().then((value) => {
        setCountries(value);
        if (value?.length != 0) {
          setCountrySelected(value[0]);
        }

        dispatch(fetchCurrencies()).then((val) => {
          if (val.data?.length != 0) {
            setCurrencySelected(val.data[0]);
          }
          setIsLoading(false);
        });
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  console.log(getParentMethodFiltered());

  return (
    <Layout text="Cuentas personal">
      {isLoading && <Screenloading />}
      <CountrySelectWithoutForm
        name="country"
        placeholder="País de la moneda"
        value={countrySelected}
        countries={countries}
        onChange={(e) => {
          console.log(e);
          setCountrySelected(e);
          setCurrencySelected(null);
        }}
      />
      <CurrencySelector
        name="country"
        value={currencySelected}
        placeholder="Moneda"
        onChange={setCurrencySelected}
        currencies={getCurrenciesByCountry()}
      />
      <div className={classes.GridBank}>
        {getParentMethodFiltered().map((e) => {
          return (
            <ContainerBank
              key={JSON.stringify(e)}
              parentMethod={e}
            ></ContainerBank>
          );
        })}
      </div>
    </Layout>
  );
}

const ContainerBank = ({ parentMethod }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToBank = () => {
    history.push({
      pathname: `/accounts/${parentMethod.id}`,
      state: {
        parentMethod,
      },
    });
  };

  return (
    <div className={classes.ContainerBank} onClick={goToBank}>
      <div className={"bankClass" + parentMethod.name}>
        <img src={parentMethod.Logo} />
      </div>
    </div>
  );
};
