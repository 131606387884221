import { SvgIcon } from "@material-ui/core";

export const Logo = (
  <SvgIcon viewBox="105 127 22.91 11.25" style={{ overflow: "visible" }}>
    <svg
      width="107"
      height="64"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="163 140 107 64"
      x="163"
      y="140"
    >
      <svg
        width="107"
        height="37"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="163 167 107 37"
        x="163"
        y="167"
      >
        <svg
          width="23"
          height="37"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0.275 0 22.451 37"
          fill="#FA7D4A"
          x="163"
          y="167"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="23"
            height="37"
            viewBox="0.596 0.717 21.598 35.595"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M10.614 25.186c-1.074 0-2.106-.152-3.096-.454a9.989 9.989 0 0 1-1.373-.526c-.544-.258-1.17.159-1.17.76V35.48c0 .46-.372.832-.832.832H1.428a.832.832 0 0 1-.832-.832V1.55c0-.46.372-.833.832-.833h2.17c.392 0 .732.275.813.659l.227 1.061c.125.592.825.862 1.306.495.636-.484 1.32-.921 2.052-1.309 1.14-.604 2.484-.906 4.028-.906 3.155 0 5.639 1.007 7.451 3.021 1.812 2.014 2.719 4.9 2.719 8.66 0 2.214-.328 4.128-.982 5.739-.655 1.611-1.528 2.937-2.618 3.977a10.533 10.533 0 0 1-3.725 2.316 12.44 12.44 0 0 1-4.255.756zm.454-20.844c-1.41 0-2.636.344-3.676 1.032-.925.612-1.665 1.23-2.219 1.855a.817.817 0 0 0-.198.542v11.896c0 .272.13.532.355.683a8.052 8.052 0 0 0 2.112 1.01 8.847 8.847 0 0 0 2.618.401c.907 0 1.813-.158 2.719-.478.906-.318 1.712-.847 2.417-1.585.705-.738 1.275-1.695 1.711-2.87.436-1.174.655-2.618.655-4.33 0-2.517-.528-4.506-1.586-5.966-1.057-1.46-2.693-2.19-4.908-2.19z"
            />
          </svg>
        </svg>
        <svg
          width="16"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0.183 0 15.634 25"
          fill="#FA7D4A"
          x="190"
          y="167"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="16"
            height="25"
            viewBox="0.429 0.717 15.018 24.015"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M.43 23.9V1.9c0-.46.372-.831.832-.831h1.78c.41 0 .758.297.822.7l.263 1.648c.117.733 1.046.945 1.496.354a7.964 7.964 0 0 1 1.704-1.645c1.309-.94 2.82-1.41 4.531-1.41.873 0 1.594.068 2.165.201.303.072.592.153.866.243.401.132.63.554.539.966l-.487 2.205a.832.832 0 0 1-1.086.605 8.254 8.254 0 0 0-.462-.142c-.486-.134-1.066-.202-1.737-.202-.973 0-1.87.169-2.693.504a6.008 6.008 0 0 0-2.165 1.51C6.177 7.28 5.691 8.11 5.338 9.1c-.352.99-.529 2.123-.529 3.398V23.9c0 .46-.372.832-.832.832H1.262a.833.833 0 0 1-.833-.832z"
            />
          </svg>
        </svg>
        <svg
          width="23"
          height="26"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0.025 0 22.95 26"
          fill="#FA7D4A"
          x="206"
          y="167"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="23"
            height="26"
            viewBox="0.591 0.717 21.599 24.469"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M22.19 12.572c0 .46-.373.833-.833.833H5.223v.2c0 2.618.713 4.633 2.14 6.042 1.426 1.41 3.364 2.114 5.814 2.114 1.276 0 2.408-.1 3.399-.301a17.565 17.565 0 0 0 2.406-.688.833.833 0 0 1 1.084.559l.466 1.682a.83.83 0 0 1-.503 1c-.812.306-1.728.563-2.749.77a21.446 21.446 0 0 1-4.254.403c-1.712 0-3.315-.236-4.808-.706a10.976 10.976 0 0 1-3.952-2.19c-1.141-.99-2.039-2.257-2.694-3.8-.654-1.544-.981-3.39-.981-5.54 0-1.844.277-3.523.83-5.033.554-1.51 1.335-2.795 2.342-3.852 1.007-1.057 2.223-1.88 3.65-2.467C8.839 1.011 10.44.717 12.22.717c1.51 0 2.877.252 4.103.755a9.126 9.126 0 0 1 3.146 2.115c.873.906 1.544 1.997 2.015 3.272.469 1.276.704 2.686.704 4.23v1.483zm-5.447-2.332a.832.832 0 0 0 .825-.899 8.341 8.341 0 0 0-.288-1.55 5.712 5.712 0 0 0-1.007-1.989 4.832 4.832 0 0 0-1.736-1.36c-.705-.334-1.561-.503-2.568-.503-1.846 0-3.348.588-4.506 1.762-.938.952-1.584 2.146-1.939 3.583a.837.837 0 0 0 .815 1.04l10.404-.084z"
            />
          </svg>
        </svg>
        <svg
          width="18"
          height="26"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0.039 0 17.922 26"
          fill="#FA7D4A"
          x="231"
          y="167"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="18"
            height="26"
            viewBox="0.915 0.717 16.866 24.469"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M15.989 4.586a.834.834 0 0 1-1.131.523 12.556 12.556 0 0 0-2.163-.717 12.507 12.507 0 0 0-2.718-.302c-1.611 0-2.803.294-3.575.881-.772.588-1.158 1.369-1.158 2.341 0 .705.268 1.318.806 1.838.536.52 1.661 1.032 3.373 1.536l1.51.453c2.248.672 3.952 1.536 5.11 2.592 1.159 1.058 1.738 2.492 1.738 4.305 0 2.149-.798 3.876-2.392 5.186-1.594 1.309-3.819 1.964-6.671 1.964-1.51 0-2.963-.135-4.355-.403-1.08-.209-2.044-.477-2.892-.807a.831.831 0 0 1-.49-1.018l.566-1.86a.828.828 0 0 1 1.107-.526c.735.303 1.515.556 2.339.762 1.14.285 2.298.427 3.474.427 1.51 0 2.71-.268 3.599-.805.889-.536 1.334-1.426 1.334-2.668 0-1.074-.386-1.838-1.158-2.291-.772-.453-1.88-.897-3.323-1.334l-1.56-.503c-2.182-.705-3.801-1.586-4.858-2.644C1.443 10.46.915 9.092.915 7.413c0-1.007.217-1.93.654-2.769A6.224 6.224 0 0 1 3.356 2.53c.755-.57 1.653-1.015 2.694-1.334C7.09.877 8.23.716 9.473.716c1.175 0 2.392.11 3.65.328.996.173 1.954.435 2.876.785.41.157.634.6.508 1.021l-.518 1.736z"
            />
          </svg>
        </svg>
        <svg
          width="18"
          height="26"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0.039 0 17.922 26"
          fill="#FA7D4A"
          x="252"
          y="167"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="18"
            height="26"
            viewBox="0.405 0.717 16.866 24.469"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M15.48 4.586a.834.834 0 0 1-1.131.523 12.567 12.567 0 0 0-2.163-.717 12.513 12.513 0 0 0-2.718-.302c-1.612 0-2.803.294-3.575.881-.772.588-1.158 1.369-1.158 2.341 0 .705.268 1.318.806 1.838.536.52 1.661 1.032 3.373 1.536l1.51.453c2.248.672 3.952 1.536 5.11 2.592 1.158 1.058 1.737 2.492 1.737 4.305 0 2.149-.797 3.876-2.39 5.186-1.595 1.309-3.82 1.964-6.672 1.964-1.51 0-2.963-.135-4.355-.403-1.08-.209-2.044-.477-2.892-.807a.831.831 0 0 1-.49-1.018l.567-1.86a.827.827 0 0 1 1.106-.526c.735.303 1.515.556 2.339.762a14.26 14.26 0 0 0 3.473.427c1.51 0 2.71-.268 3.6-.805.89-.536 1.335-1.426 1.335-2.668 0-1.074-.387-1.838-1.158-2.291-.774-.453-1.881-.897-3.324-1.334l-1.56-.503c-2.183-.705-3.801-1.586-4.859-2.644C.934 10.46.405 9.092.405 7.413c0-1.007.218-1.93.655-2.769A6.216 6.216 0 0 1 2.847 2.53c.755-.57 1.653-1.015 2.694-1.334C6.58.877 7.722.716 8.965.716c1.174 0 2.39.11 3.65.328.995.173 1.953.435 2.875.785.41.157.634.6.508 1.021l-.518 1.736z"
            />
          </svg>
        </svg>
      </svg>
      <svg
        width="70"
        height="25"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="179 140 70 25"
        x="179"
        y="140"
      >
        <svg
          width="23"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0.483 23 24.033"
          fill="#fff"
          x="179"
          y="140"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="23"
            height="25"
            viewBox="0.107 0.952 22.43 23.437"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M.107 1.285c0-.184.149-.333.333-.333h10.546c3.85 0 6.581.851 8.193 2.552 2.238 2.138 3.357 5.205 3.357 9.2 0 5.294-1.992 8.831-5.977 10.61-1.656.718-3.469 1.076-5.439 1.076H.44a.333.333 0 0 1-.333-.334V1.286zm6.698 5.07v11.826c0 .183.15.332.333.332H9.66c2.54 0 4.23-.906 5.07-2.72.492-.94.739-1.992.739-3.156v-.805c0-2.544-1.118-4.33-3.352-5.362a7.99 7.99 0 0 0-2.592-.447H7.138a.333.333 0 0 0-.333.333z"
            />
          </svg>
        </svg>
        <svg
          width="26"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0.044 26 24.912"
          fill="#fff"
          x="201"
          y="140"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="26"
            height="25"
            viewBox="0.928 0.919 24.531 23.504"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M9.05 21.282l-1.082 2.924a.333.333 0 0 1-.312.217H1.261a.333.333 0 0 1-.31-.457l9.136-22.838a.333.333 0 0 1 .31-.21h5.593c.136 0 .258.084.309.21l9.135 22.838a.333.333 0 0 1-.309.457h-6.392a.333.333 0 0 1-.313-.221l-1.035-2.915a.332.332 0 0 0-.314-.222h-7.71a.332.332 0 0 0-.31.217zm6.319-5.698l-1.752-4.936a.333.333 0 0 0-.625-.005L11.16 15.58c-.08.217.08.448.312.448h3.583c.23 0 .39-.227.314-.444z"
            />
          </svg>
        </svg>
        <svg
          width="21"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0.27 21 24.459"
          fill="#fff"
          x="228"
          y="140"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="21"
            height="25"
            viewBox="0.519 0.919 20.18 23.504"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M7.366 17.64a.333.333 0 0 0-.333.333v6.117a.333.333 0 0 1-.333.333H.852a.333.333 0 0 1-.333-.333V1.25c0-.183.149-.332.333-.332h10.512c3.833 0 6.428 1.116 7.784 3.346a.204.204 0 0 0 .013.02C20.186 5.595 20.7 7.18 20.7 9.046v.402c0 3.668-1.877 6.181-5.63 7.541a.332.332 0 0 0-.143.517c2.476 3.165 4.31 5.277 5.502 6.339.227.202.081.579-.224.579H13.38a.321.321 0 0 1-.185-.055c-1.085-.744-2.938-2.94-5.558-6.588a.335.335 0 0 0-.271-.14zM7.033 6.758v4.975c0 .184.149.333.333.333h2.722c2.105 0 3.313-.246 3.627-.739a3.3 3.3 0 0 0 .537-1.812c0-2.06-1.321-3.09-3.962-3.09H7.366a.333.333 0 0 0-.333.333z"
            />
          </svg>
        </svg>
      </svg>
    </svg>
    <svg
      width="72"
      height="73"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="105 127 72 73"
      x="105"
      y="127"
    >
      <svg
        width="66"
        height="73"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="111 127 66 73"
        x="111"
        y="127"
      >
        <svg
          width="66"
          height="73"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0.057 66 72.887"
          fill="#FA7D4A"
          x="111"
          y="127"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="66"
            height="73"
            viewBox="0.323 0.835 64.758 71.515"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M26.311 53.5l-9.702 9.327a2.165 2.165 0 0 1-2.061.533L1.94 59.985C.3 59.545-.23 57.468.997 56.288l21.978-21.119c.59-.567.814-1.418.582-2.205L14.916 3.648C14.433 2.01 15.919.472 17.56.912l12.607 3.375c.733.196 1.311.762 1.527 1.493l3.812 12.945c.462 1.57 2.41 2.092 3.587.961l9.702-9.328a2.164 2.164 0 0 1 2.062-.532L63.464 13.2c1.64.44 2.171 2.518.943 3.698L42.429 38.017a2.2 2.2 0 0 0-.582 2.204l8.641 29.316c.483 1.638-1.003 3.177-2.644 2.737l-12.607-3.376a2.179 2.179 0 0 1-1.526-1.492L29.898 54.46c-.462-1.57-2.41-2.091-3.587-.96z"
            />
          </svg>
        </svg>
      </svg>
      <svg
        width="25"
        height="23"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="105 146 25 23"
        x="105"
        y="146"
      >
        <svg
          width="17"
          height="5"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0.233 17 4.533"
          fill="#fff"
          x="108"
          y="146"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="17"
            height="5"
            viewBox="0.448 0.51 15.671 4.179"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M2.537.51h11.492c1.155 0 2.09.934 2.09 2.09 0 1.155-.935 2.089-2.09 2.089H2.537a2.087 2.087 0 0 1-2.09-2.09c0-1.155.935-2.089 2.09-2.089z"
            />
          </svg>
        </svg>
        <svg
          width="17"
          height="5"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0.233 17 4.533"
          fill="#fff"
          x="113"
          y="155"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="17"
            height="5"
            viewBox="0.369 0.345 15.671 4.179"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M2.458.345H13.95c1.156 0 2.09.934 2.09 2.09 0 1.155-.934 2.089-2.09 2.089H2.458a2.088 2.088 0 0 1-2.09-2.09c0-1.155.935-2.089 2.09-2.089z"
            />
          </svg>
        </svg>
        <svg
          width="17"
          height="5"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0.233 17 4.533"
          fill="#fff"
          x="105"
          y="164"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="5"
            viewBox="0.728 0.06 15.671 4.179"
          >
            <path
              paintOrder="stroke fill markers"
              fillRule="evenodd"
              d="M2.817.06h11.492c1.156 0 2.09.934 2.09 2.09 0 1.155-.934 2.089-2.09 2.089H2.817a2.087 2.087 0 0 1-2.09-2.09C.728.995 1.663.06 2.818.06z"
            />
          </svg>
        </svg>
      </svg>
    </svg>
  </SvgIcon>
);
