import React, { useEffect, useState } from "react";
import { fetchCountries, getAllCountries } from "../../../slices/countries";
import { useDispatch } from "react-redux";
import RateOfDayCountryItem from "./RateOfDayCountryItem";
import { Country } from "../../../models/countries";

function RateOfDayCountrySelector({
  setSelectedCountry,
  selectedCountry,
}: any) {
  const [countries, setCountries] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const getCountries = async () => {
    const response = await getAllCountries();

    setCountries(response);

    if (response.length != 0) {
      setSelectedCountry(response[0]);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  if (selectedCountry == null) return <div></div>;

  return (
    <div className="RateOfDayCountryItemContainer">
      <div className="RateOfDayCountryItemMain">
        <RateOfDayCountryItem
          country={selectedCountry}
          isMain
          setSelectedCountry={(e: any) => setIsExpanded(!isExpanded)}
        />
      </div>
      {isExpanded && (
        <div className="RateOfDayCountryItemExpanded">
          {countries.map((country: Country) => (
            <div key={country.id}>
              <RateOfDayCountryItem
                country={country}
                setSelectedCountry={(e: Country) => {
                  setSelectedCountry(e);
                  setIsExpanded(false);
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default RateOfDayCountrySelector;
