import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ModalCustom from '../../../../components/modal/index'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { updateUser } from '../../../../slices/users'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridTemplateColumns: '20% 60% 20%',
    color: 'black',
    padding: '0 0vw 0 3vw',
    margin: '3vw 0 8vw',
    '@media (min-width: 700px)': {
      padding: '0 0vw 0 1vw',
      margin: '1vw 0 1vw'
    }
  },
  headerModal: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    '& span': {
      marginLeft: '2vw'
    }
  },
  containerSelectorModal: {
    marginTop: '6vw'
  },
  footerModal: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '6vw',
    '& button': {
      fontWeight: '700',
      fontSize: '4vw',
      border: 'none',
      borderRadius: '5px',
      padding: '2vw 5vw',
      '@media (min-width: 700px)': {
        padding: '1vw 3vw',
        fontSize: '1.2vw'
      }
    }
  },
  btnSolid: {
    color: 'white',
    background: '#003f62'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    padding: '5vw 5vw',
    width: '80vw',
    borderRadius: '22px',
    border: 'none',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@media (min-width: 700px)': {
      padding: '5vw 5vw'
    }
  }
})

export default function RangesEdit ({ user, ranges }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [rangeSelected, setRangeSelected] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showModalRangeSucces, setShowModalRangeSucces] = useState(false)

  const onSubmit = async () => {
    const data = {
      rangeId: rangeSelected.id,
      roleId: rangeSelected.id == 1 ? 4 : 3,
      idToUpdate: user.id
    }

    dispatch(updateUser(data)).then((res) => {
      setShowModal(false)
      setShowModalRangeSucces(true)
      setTimeout(() => {
        setShowModalRangeSucces(false)
      }, 900)
    })

    setShowModal(false)
    setShowModalRangeSucces(true)
    setTimeout(() => {
      setShowModalRangeSucces(false)
    }, 900)
  }

  return (
    <>
      <div className={classes.main}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="FontAwsome_user-circle_"
            data-name="FontAwsome (user-circle)"
            d="M12,8A12,12,0,1,0,24,20,12,12,0,0,0,12,8Zm0,4.645A4.258,4.258,0,1,1,7.742,16.9,4.258,4.258,0,0,1,12,12.645ZM12,29.29a9.272,9.272,0,0,1-7.089-3.3A5.4,5.4,0,0,1,9.677,23.1a1.184,1.184,0,0,1,.344.053A6.407,6.407,0,0,0,12,23.484a6.382,6.382,0,0,0,1.979-.334,1.184,1.184,0,0,1,.344-.053,5.4,5.4,0,0,1,4.766,2.894A9.272,9.272,0,0,1,12,29.29Z"
            transform="translate(0 -8)"
            fill="#003f62"
            opacity="0.247"
          />
        </svg>
        <h3>{user.name}</h3>
        <svg
          onClick={() => setShowModal(true)}
          xmlns="http://www.w3.org/2000/svg"
          width="26.995"
          height="24"
          viewBox="0 0 26.995 24"
        >
          <path
            id="FontAwsome_edit_"
            data-name="FontAwsome (edit)"
            d="M18.855,16.093l1.5-1.5a.377.377,0,0,1,.642.267v6.815a2.25,2.25,0,0,1-2.25,2.25H2.25A2.25,2.25,0,0,1,0,21.675V5.178a2.25,2.25,0,0,1,2.25-2.25H15.068a.377.377,0,0,1,.267.642l-1.5,1.5a.372.372,0,0,1-.267.108H2.25v16.5h16.5v-5.32A.369.369,0,0,1,18.855,16.093Zm7.34-9.458L13.887,18.943l-4.237.469a1.936,1.936,0,0,1-2.137-2.137l.469-4.237L20.289.73a2.735,2.735,0,0,1,3.876,0L26.19,2.755a2.745,2.745,0,0,1,0,3.881ZM21.564,8.084,18.841,5.361l-8.708,8.713-.342,3.06,3.06-.342ZM24.6,4.348,22.576,2.323a.489.489,0,0,0-.694,0L20.435,3.772l2.723,2.723,1.448-1.448A.5.5,0,0,0,24.6,4.348Z"
            transform="translate(0 0.075)"
            fill="#003f62"
          />
        </svg>
      </div>

      {showModalRangeSucces && (
        <ModalCustom>
          <div className={classes.modal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.356"
              height="37.809"
              viewBox="0 0 28.356 37.809"
            >
              <path
                id="FontAwsome_clipboard-check_"
                data-name="FontAwsome (clipboard-check)"
                d="M24.812,4.726H18.9a4.726,4.726,0,0,0-9.452,0H3.545A3.545,3.545,0,0,0,0,8.271V34.264a3.545,3.545,0,0,0,3.545,3.545H24.812a3.545,3.545,0,0,0,3.545-3.545V8.271A3.545,3.545,0,0,0,24.812,4.726ZM14.178,2.954a1.772,1.772,0,1,1-1.772,1.772A1.768,1.768,0,0,1,14.178,2.954Zm8.95,17.117L12.568,30.542a.887.887,0,0,1-1.255-.007l-6.1-6.151a.887.887,0,0,1,.007-1.255l2.1-2.082a.887.887,0,0,1,1.255.007l3.4,3.426L19.8,16.711a.887.887,0,0,1,1.255.007l2.082,2.1A.887.887,0,0,1,23.128,20.071Z"
                fill="#f96123"
              />
            </svg>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Rango asignado con exito
            </Typography>
          </div>
        </ModalCustom>
      )}

      {showModal && (
        <ModalCustom>
          <div>
            <header className={classes.headerModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37.436"
                height="33.27"
                viewBox="0 0 37.436 33.27"
              >
                <path
                  id="FontAwsome_edit_"
                  data-name="FontAwsome (edit)"
                  d="M26.166,5.5l5.862,5.862a.635.635,0,0,1,0,.9L17.834,26.455l-6.031.669a1.264,1.264,0,0,1-1.4-1.4l.669-6.031L25.269,5.5a.635.635,0,0,1,.9,0ZM36.695,4.013,33.523.841a2.542,2.542,0,0,0-3.588,0l-2.3,2.3a.635.635,0,0,0,0,.9L33.5,9.9a.635.635,0,0,0,.9,0l2.3-2.3A2.542,2.542,0,0,0,36.695,4.013ZM24.957,22.594V29.21H4.16V8.413H19.095a.8.8,0,0,0,.552-.227l2.6-2.6a.78.78,0,0,0-.552-1.332H3.12A3.12,3.12,0,0,0,0,7.373V30.25a3.12,3.12,0,0,0,3.12,3.12H26a3.12,3.12,0,0,0,3.12-3.12V19.994a.781.781,0,0,0-1.332-.552l-2.6,2.6A.8.8,0,0,0,24.957,22.594Z"
                  transform="translate(0 -0.1)"
                  fill="#003f62"
                />
              </svg>
              <span>Editar rango</span>
            </header>
            <article className={classes.containerSelectorModal}>
              <Select
                onChange={(e) => setRangeSelected(e)}
                getOptionLabel={(op) => op.name}
                options={ranges}
                defaultValue={ranges.find(op => {
                  return op.id == user.rangeId
                })}
              />
            </article>
            <footer className={classes.footerModal}>
              <button
                onClick={() => setShowModal(false)}
                className={classes.btnLigth}
              >
                Cancelar
              </button>
              <button onClick={onSubmit} className={classes.btnSolid}>
                Aceptar
              </button>
            </footer>
          </div>
        </ModalCustom>
      )}
    </>
  )
}
