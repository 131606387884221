import ModalCustom from '../../components/modal/index'
import { Link } from 'react-router-dom'
import ImageIcon from '@material-ui/icons/Image'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    padding: '5vw 5vw',
    width: '80vw',
    borderRadius: '22px',
    border: 'none',
    display: 'flex',
    justifyContent: 'space-evenly',
    '@media (min-width: 700px)': {
      padding: '5vw 5vw'
    }
  },
  modalLogo: {
    width: '50px'
  /*   width: "50px",
    position: "absolute",
    bottom: "-50px",
    left: "-15px" */
  },
  toggleButtonState: {
    color: '#eee',
    marginRight: '15px',
    marginLeft: '15px',
    borderRadius: '5px'
  },
  state: {
    borderRadius: '3px',
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: '6px',
    color: '#fff'
  },
  header: {
    height: '50px',
    padding: '0px',
    position: 'relative',
    overflow: 'hidden'
  },
  capture: {
    width: '100%',
    height: '50vw'
  }
}))

const Index = ({ transaction, showModal, setShowModal }) => {
  const classes = useStyles()

  return (<>
      {
        showModal &&
        <ModalCustom>
          <div className={classes.modal}>
            <table style={{ width: '100%', position: 'relative' }}>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: 'middle', height: '50px', width: '50px' }}>
                    <img src={ transaction.bssAccount.bank.Logo } className={classes.modalLogo} alt="" />
                  </td>
                  <td style={{ verticalAlign: 'middle', height: '50px', fontSize: '13px' }}>
                    <b>{transaction.userRequested.name}<br /></b>
                    <span style={{ color: '#fd956f' }}>#0000{transaction.id}</span>
                  </td>
                  <td style={{ verticalAlign: 'top', height: '50px', width: '50px', textAlign: 'right' }}>
                    <Link to="#" onClick={() => setShowModal(false)}><CloseIcon /></Link>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ textAlign: 'center' }}>
                    {transaction.capture
                      ? <Zoom><img src={transaction.capture} alt="image" className={classes.capture} /></Zoom>
                      : <ImageIcon style={{ width: '100%', height: '100%' }}/>
                    }
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ textAlign: 'right', fontSize: '16px' }}>
                    <a href={ transaction.capture } download>
                      <b style={{ color: '#fd956f' }}><i className="fas fa-download fa-1x"></i> </b>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ModalCustom>
      }
  </>)
}

export default Index
