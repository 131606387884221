import React from 'react'
import MainLayout from '../../../layouts/main'
import PaymentsContainer from './payments'
import TransactionsContainer from './transactions/index'
import Balance from './balance/index'
import Screenloading from './../../loaders/screenLoading'

function NameUser () {
  return (
    <div>
      <h3
        style={{
          color: 'white',
          textAlign: 'center',
          marginBottom: '2vw',
          fontSize: '7vw',
          background: '#f96123',
          padding: '1vw'
        }}
      >
        Paul McCartney
      </h3>
      <h3 style={{
        background: 'white',
        textAlign: 'center',
        padding: '1vw',
        marginTop: '4vw',
        marginBottom: '2vw',
        color: '#02334e',
        fontSize: '4vw'
      }}> Operador <b style={{ fontWeight: '100' }}>| Rango A</b> </h3>
    </div>
  )
}

export default function UserSpecificDetailsComponent (props) {
  return (
    <MainLayout text="Detalles de usuario" skipPadding={true} goBack={true}>
      {props.loading && <Screenloading></Screenloading>}

      <Balance></Balance>
      <NameUser></NameUser>
      <div style={{
        color: 'white',
        display: 'grid',
        gridTemplateColumns: '47% auto',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '40vw',
          background: '#01052926',
          marginTop: '2vw',
          padding: '3vw 0',
          borderRadius: '5px'

        }}>
          <span style={{
            fontWeight: 'bolder',
            fontSize: '4.6vw',
            color: '#f96123',
            marginBottom: '1vw'
          }}>Tasa actual:</span>
          <span>0.0045</span>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '40vw',
          background: '#01052926',
          marginTop: '2vw',
          padding: '3vw 0',
          borderRadius: '5px'

        }}>
          <span style={{
            fontWeight: 'bolder',
            fontSize: '4.6vw',
            color: '#f96123',
            marginBottom: '1vw'
          }}>Se unio el:</span>
          <span>31/2/2022</span>
        </div>
      </div>
      <div
        style={{
          padding: '3vw 3vw'
        }}
      >
        <TransactionsContainer />
        <PaymentsContainer />
      </div>
    </MainLayout>
  )
}
