import React from 'react'
import { BoolConfig } from './../types/bool'
import { TextConfig } from './../types/string'
import { NumberConfig } from './../types/number'

export function Config ({ config, handleEdit }) {
  const type = config.type

  const renderTypeOption = () => {
    switch (type) {
      case 'boolean':
        return <BoolConfig {...{ config, handleEdit }}/>
      case 'text':
        return <TextConfig {...{ config, handleEdit }}/>
      case 'number':
        return <NumberConfig {...{ config, handleEdit }}/>

      default:
        break
    }
  }

  return <>{
    renderTypeOption()
  }</>
}
