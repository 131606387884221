import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const fetchConfigs = createAsyncThunk('config', async () => {
  return Axios.get(process.env.REACT_APP_API_URL + 'config').then(
    (response) => response.data.data
  )
})

export const getConfig = async (key) => {
  const data = await Axios.get(process.env.REACT_APP_API_URL + 'config').then(
    (response) => response.data.data
  )
  const dataRequested = data.find((dt) => dt.key == key)
  return dataRequested
}

export const createConfig = createAsyncThunk('createConfig', async (data) => {
  await Axios.post(process.env.REACT_APP_API_URL + 'config/store', data).then(
    (response) => response.data
  )
})

export const configsSlice = createSlice({
  name: 'configs',
  initialState: {
    configs: []
  },
  extraReducers: {
    [fetchConfigs.fulfilled]: (state, action) => {
      state.configs = action.payload
    }
  }
})

export default configsSlice.reducer
