import React, { useState, useEffect, useContext } from "react";
import { TextField, Typography, Button } from "@material-ui/core";
import { GenerateRateContext } from "../../../../context/GenerateRateContext";

function Numbers({ setVenDollar, setCurDollar, venDollar, curDollar }) {
  return (
    <div className="InputDollarRow">
      <div className="InputDollar">
        <p>{"Dólar en Venezuela"}</p>
        <input
          value={venDollar}
          type="number"
          onChange={(e) => setVenDollar(e.target.value.replace(",", ""))}
          inputMode="numeric"
        />
      </div>
      <div className="InputDollar">
        <p>{"Dólar en moneda actual"}</p>
        <input
          value={curDollar}
          type="number"
          onChange={(e) => setCurDollar(e.target.value.replace(",", ""))}
          inputMode="numeric"
        />
      </div>
    </div>
  );
}

export function RateOfDayDollarPrice(props) {
  const {
    onSubmit,
    number1: venDollar,
    number2: curDollar,
    setVenDollar,
    setCurDollar,
  } = props;

  // const [venDollar, setVenDollar] = useState(number1);
  // const [curDollar, setCurDollar] = useState(number2);

  useEffect(() => {
    setVenDollar(venDollar);
    setCurDollar(curDollar);
  }, [venDollar, curDollar]);

  return (
    <>
      <Numbers
        venDollar={venDollar}
        curDollar={curDollar}
        setCurDollar={setCurDollar}
        setVenDollar={setVenDollar}
      />
      <Button
        style={{
          width: "92vw",
          height: "44px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          backgroundColor: "rgb(255, 124, 70)",
          marginLeft: "4vw",
          marginBottom: "5vh",
          borderRadius: "25px",
        }}
        type="submit"
        onClick={() => onSubmit(venDollar, curDollar, true)}
      >
        <Typography
          style={{
            color: "#fff",
            fontWeight: 500,
            textTransform: "initial",
          }}
        >
          Actualizar tasa costo
        </Typography>
      </Button>
    </>
  );
}

export default RateOfDayDollarPrice;
