import { useState, useCallback } from "react";
import ModalCustom from "../../components/modal/index";
import { Link } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import WarningIcon from "@material-ui/icons/Warning";
import BlockIcon from "@material-ui/icons/Block";
import { Button, Modal } from "@material-ui/core";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "5vw 5vw",
    width: "90vw",
    borderRadius: "31px",
    border: "none",
    display: "flex",
    justifyContent: "space-evenly",
    minHeight: "50vh",
    overflow: "scroll",
    maxHeight: "90vh",

    "@media (min-width: 700px)": {
      padding: "5vw 5vw",
    },
  },
  modalLogo: {
    width: "24vw",
    marginTop: "-7vw",
  },
  toggleButtonState: {
    color: "#eee",
    marginRight: "15px",
    marginLeft: "15px",
    borderRadius: "5px",
  },
  header: {
    height: "50px",
    padding: "0px",
    position: "relative",
    overflow: "hidden",
  },
  capture: {
    margin: "auto",
    width: "100%",
    border: "10px solid #003350",
    borderRadius: "7px",
  },
  modalConfirm: {
    top: "50%",
    left: "50%",
    width: "87vw",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderRadius: 5,
    padding: "10vw 3vw",
    "& div": {
      display: "flex",
      justifyContent: "space-evenly",
      marginTop: "5vw",
      marginBottom: "2vw",
    },
    "& button": {
      padding: "2vw 9vw",
      borderRadius: 3,
      fontWeight: "bold",
      background: "#003F62",
      border: "none",
      color: "white",
    },
    "& h1": {
      fontSize: "4vw",
      textAlign: "center",
      fontWeight: "lighter",
    },
    "& .no": {
      background: "white",
      color: "#003F62",
      border: "1 solid",
      borderRadius: 5,
    },
  },
}));

export const stateColor = Object.freeze({
  Approved: "#67bb4c",
  Pending: "#f2af3b",
  Rejected: "#d74f4d",
});

export const statePayment = Object.freeze({
  Approved: 1,
  Pending: 2,
  Rejected: 3,
  mantein: 4,
});

const optionsRejected = [
  {
    label: "Cédula errada",
  },
  {
    label: "Cuenta errada",
  },
  {
    label: "Datos errados",
  },
];

function renderInput(state, setText, text, setrazon) {
  switch (state) {
    case statePayment.Approved:
      return (
        <div style={{ marginBottom: "2vw" }}>
          <Input
            value={text}
            onChange={(e) => setText(e.target.value)}
            inputProps={{ "aria-label": "description", maxlength: "50" }}
            style={{ width: "100%" }}
          />
          <small>Ingrese número de referencia</small>
        </div>
      );
    case statePayment.Pending:
      return <></>;
    case statePayment.Rejected:
      return (
        <>
          <Input
            value={text}
            onChange={(e) => setText(e.target.value)}
            inputProps={{ "aria-label": "description", maxlength: "50" }}
            style={{ width: "100%" }}
          />
          <small>Ingrese motivo</small>
        </>
      );
    default:
      <></>;
  }
}

function renderButton(state, text, handleSend, razon) {
  switch (state) {
    case statePayment.Approved:
      return (
        <>
          <Button
            onClick={handleSend}
            variant="contained"
            disabled={!!(isEmpty(text) && isEmpty(razon))}
            color="primary"
            style={{ width: "100%", textTransform: "capitalize" }}
          >
            Confirmar
          </Button>
        </>
      );
    case statePayment.Pending:
      return (
        <>
          <Button
            onClick={handleSend}
            variant="contained"
            color="primary"
            style={{ width: "100%", textTransform: "capitalize" }}
          >
            Confirmar
          </Button>
        </>
      );
    case statePayment.Rejected:
      return (
        <>
          <Button
            onClick={handleSend}
            variant="contained"
            disabled={!!(isEmpty(text) && isEmpty(razon))}
            color="primary"
            style={{ width: "100%", textTransform: "capitalize" }}
          >
            Confirmar
          </Button>
        </>
      );
    default:
      <></>;
  }
}

const Index = ({ payment, handleSendData, showModal, setShowModal }) => {
  const classes = useStyles();

  const [state, setState] = useState(statePayment.Pending);
  const [text, setText] = useState("");
  const [ModalConfirm, setModalConfirm] = useState(false);
  const [razon, setrazon] = useState("");

  const handleSend = () => {
    setModalConfirm(true);
  };

  const handleSendConfirmed = () => {
    setText("");
    setModalConfirm(false);
    setShowModal(false);
    handleSendData(payment.id, state, razon == "" ? text : razon);
  };

  const handlestate = (event, newstate) => {
    setText("");
    setState(newstate);
  };

  const [isZoomed, setIsZoomed] = useState(false);

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <>
      <ToastContainer />

      {showModal && (
        <ModalCustom>
          <div className={classes.modal}>
            <div style={{ width: "80vw" }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td className={classes.header}>
                      <img
                        src={payment.appAccount.parentMethod.Logo}
                        className={classes.modalLogo}
                        alt=""
                      />
                      <Link
                        to="#"
                        onClick={() => setShowModal(false)}
                        style={{ float: "right" }}
                      >
                        <CloseIcon />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <h5
                      style={{
                        color: "#00529a",
                        fontWeight: "lighter",
                        margin: "2vw 0 2vw",
                      }}
                    >
                      {payment.user.name}
                    </h5>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        padding: "0px",
                        verticalAlign: "middle",
                      }}
                    >
                      <small>Selecciona el estado de verificación</small>
                      <br />
                      <ToggleButtonGroup
                        value={state}
                        exclusive
                        onChange={handlestate}
                        aria-label="text state"
                        style={{ marginTop: "15px" }}
                      >
                        <ToggleButton
                          className={classes.toggleButtonState}
                          value={statePayment.Approved}
                          aria-label="left aligned"
                          style={{
                            backgroundColor: stateColor.Approved,
                            color: "#eee",
                            marginRight: "15px",
                            marginLeft: "15px",
                            borderRadius: "5px",
                            WebkitTransform:
                              state == statePayment.Approved
                                ? "scale(1.3)"
                                : "scale(1)",
                            WebkitTransformOrigin: "0 0 0 0",
                            width: "141%",
                            padding: "2vw",
                          }}
                        >
                          <DoneAllIcon />
                        </ToggleButton>
                        <ToggleButton
                          className={classes.toggleButtonState}
                          value={statePayment.Pending}
                          aria-label="centered"
                          style={{
                            backgroundColor: stateColor.Pending,
                            color: "#eee",
                            marginRight: "15px",
                            marginLeft: "15px",
                            borderRadius: "5px",
                            WebkitTransform:
                              state == statePayment.Pending
                                ? "scale(1.3)"
                                : "scale(1)",
                            WebkitTransformOrigin: "0 0 0 0",
                            width: "141%",
                            padding: "2vw",
                          }}
                        >
                          <WarningIcon />
                        </ToggleButton>
                        <ToggleButton
                          className={classes.toggleButtonState}
                          value={statePayment.Rejected}
                          aria-label="right aligned"
                          style={{
                            backgroundColor: stateColor.Rejected,
                            color: "#eee",
                            marginLeft: "15px",
                            borderRadius: "5px",
                            WebkitTransform:
                              state == statePayment.Rejected
                                ? "scale(1.3)"
                                : "scale(1)",
                            WebkitTransformOrigin: "0 0 0 0",
                            width: "141%",
                            padding: "2vw",
                          }}
                        >
                          <BlockIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <div style={{ marginBottom: "5vw" }}></div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <div
                        style={{
                          width: "100%",
                          marginTop: "15px",
                          marginBottom: "4vh",
                        }}
                      >
                        {renderButton(state, text, handleSend, razon)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ width: "100%", marginTop: "15px" }}>
                        {renderInput(state, setText, text, setrazon)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      {payment.capture ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <img
                            onClick={() => {
                              MySwal.fire({
                                html: (
                                  <img
                                    src={payment.capture}
                                    alt="image"
                                    style={{ width: "100%" }}
                                  />
                                ),
                                customClass: {
                                  confirmButton: "swal2-blue-confirm-button",
                                },
                              });
                            }}
                            src={payment.capture}
                            alt="image"
                            className={classes.capture}
                          />

                          <div
                            style={{
                              width: "23%",
                              padding: "3vw 0",
                              borderRadius: "9px",
                              margin: "2vw auto",
                            }}
                            onClick={() => {
                              MySwal.fire({
                                html: (
                                  <img
                                    src={payment.capture}
                                    alt="image"
                                    style={{ width: "100%" }}
                                  />
                                ),
                                customClass: {
                                  confirmButton: "swal2-blue-confirm-button",
                                },
                              });
                            }}
                            className="shadow"
                          >
                            <i
                              className="fa-solid fa-expand"
                              style={{ fontSize: "6vw" }}
                            ></i>
                            <p
                              style={{
                                lineHeight: "1",
                                marginTop: "2vw",
                                fontSize: "3vw",
                                fontWeight: "bold",
                              }}
                            >
                              Ampliar<br></br>imagen
                            </p>
                          </div>
                        </div>
                      ) : (
                        <ImageIcon className={classes.capture} />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Modal
              open={ModalConfirm}
              onClose={setModalConfirm}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className={classes.modalConfirm}>
                <h1>¿Estás seguro de actualizar el estado?</h1>
                <div>
                  <button
                    onClick={() => {
                      setModalConfirm(false);
                    }}
                    className="no"
                  >
                    No
                  </button>
                  <button onClick={handleSendConfirmed}>Sí</button>
                </div>
              </div>
            </Modal>
          </div>
        </ModalCustom>
      )}
    </>
  );
};

export default Index;
