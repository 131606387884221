import React, { useEffect } from "react";
import Logo from "../../assets/error404.png";
import axios from "axios";

function Splash({ setConecctedFunc }) {
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "user/show/1")
      .then(() => setConecctedFunc(true))
      .catch((err) => {
        setConecctedFunc(false);
      });
  }, []);

  return (
    <div
      id="loaderSplash"
      style={{
        textAlign: "center",
        background: "rgb(5 32 52)",
        zIndex: "5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: "0",
        width: "100vw",
        right: "0",
        height: "100vh",
      }}
    >
      <div>
        <img src={Logo} style={{ width: "57%", marginBottom: "4vw" }} />
        <h1 style={{ color: "white" }}>
          No hemos podido conectarnos al servidor.
        </h1>
      </div>
    </div>
  );
}

export default Splash;
