import React, { useEffect, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'

export function TableRowUserNotifications (props) {
  const [checked, setchecked] = useState(false)

  useEffect(() => {
    if (props.listUsersToSend.find(item => item.name == props.row.name)) {
      setchecked(true)
    } else {
      setchecked(false)
    }
  }, [props.listUsersToSend])

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0
        }
      }}
    >
      <TableCell size="small" width="50px" component="th" scope="row">
        {props.allChecked
          ? (
          <Checkbox checked value={true} />
            )
          : (
          <div>
            {
              checked
                ? <Checkbox checked={checked} onClick={(e) => props.selectUser(props.row, false)} />
                : <Checkbox checked={false} onClick={(e) => props.selectUser(props.row, true)} />
            }
          </div>
            )}
      </TableCell>
      <TableCell component="th" scope="row">
        {props.row.name}
      </TableCell>
      <TableCell
        align="left"
        style={{
          textTransform: 'capitalize'
        }}
      >
        {props.row.role.name}
      </TableCell>
    </TableRow>
  )
}
