import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLastTransactions } from "../../slices/transactions";
import Pendings from "../../components/pendings/index";
import { getAllLastPayments } from "../../slices/payments";
import { fetchVerifications } from "./../../slices/verifications";
import io from "socket.io-client";
import AppContext from "./../../context/AppContext";
import { useLocation } from "react-router-dom";

export default function Index() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { emitEvent, addEvent } = useContext(AppContext);

  const user = useSelector((state) => state.auth.user);
  const { pendings, assignedToday } = useSelector(
    (state) => state.transactions.lastTransactions
  );
  const verificationsList = useSelector(
    (state) => state.verifications.verifications
  );
  const { pendings: paymentsPendings, receivedToday: paymentsReceivedToday } =
    useSelector((state) => state.payments);

  const [loading, setloading] = useState(true);
  const [assignedTodayArray, setassignedTodayArray] = useState([]);
  const [pendingsArray, setpendingsArray] = useState([]);
  const [pendingsArrayPayments, setpendingsPaymentsArray] = useState([]);
  const [assignedTodayArrayPayments, setreceivedTodayArrayPayments] = useState(
    []
  );

  useEffect(() => {
    if (location) {
      // console.log('cerrar operator')
      emitEvent("closeSockets");
    }
  }, [location.pathname]);

  const getData = () => {
    try {
      setloading(true);
      setTimeout(() => {
        setloading(false);
      }, 5000);
      dispatch(getAllLastTransactions(dispatch)).then(async () => {
        dispatch(await getAllLastPayments()).then(() => {
          dispatch(fetchVerifications()).then(() => {
            setloading(false);
          });
        });
      });
    } catch (e) {
      setloading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setpendingsArray(pendings.slice().reverse());
  }, [pendings]);

  useEffect(() => {
    setassignedTodayArray(assignedToday.slice().reverse());
  }, [assignedToday]);

  useEffect(() => {
    setpendingsPaymentsArray(paymentsPendings);
  }, [paymentsPendings]);

  useEffect(() => {
    setreceivedTodayArrayPayments(paymentsReceivedToday);
  }, [paymentsReceivedToday]);

  const props = {
    loading,
    pendings: pendingsArray,
    assignedToday: assignedTodayArray,
    paymentsPendings: pendingsArrayPayments,
    paymentsReceivedToday: assignedTodayArrayPayments,
    verificationsList,
    getData,
  };

  return <Pendings {...props} />;
}
