import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../../assets/logo.png'
import { Typography, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import ReactPinField from 'react-pin-field'
import './index.css'
import { verifyCode, sendCode, CodeType } from '../../slices/code'
import { useHistory, Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Alert from '@mui/material/Alert'
import Screenloading from './../loaders/screenLoading'
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#003f62',
    alignItems: 'center',
    paddingTop: '6vw',
    '@media (max-width: 700px)': {
      paddingTop: '15vw'
    }
  },
  signIn: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 60,
    backgroundColor: '#ff7c46',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 0,
    '@media (max-height: 510px)': {
      display: 'none'
    },
    '&:hover, &:focus': {
      backgroundColor: '#ff7c46'
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: '2vw',
    padding: '1vw 0vw 1vw 3vw',
    borderRadius: '7px',

    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 37,
    borderStyle: 'none',
    position: 'relative',
    width: '20vw',
    '@media (max-width: 700px)': {
      marginTop: '11vh',
      width: '90vw'
    }
  },
  message: {
    color: 'white',
    marginTop: '5vw !important',
    marginBottom: '3vw',
    textAlign: 'center'
  },
  pinField: {
    marginTop: '7vw'
  },
  resend: {
    color: '#6fd0ff',
    textAlign: 'center',
    textDecoration: 'none',
    marginTop: '7vw !important'
  }

})

const Index = (props) => {
  const classes = useStyles()
  const objState = useSelector((state) => state.loading.objState)
  const [loading, setloading] = useState(false)

  const { email, type } = useSelector((state) => state.code)

  const errors = useSelector((state) => state.code.errors)
  const history = useHistory()
  const ref = useRef()

  const [code, setCode] = useState('')
  const dispatch = useDispatch()

  const sendVerifyCode = async (e) => {
    e.preventDefault()
    setloading(true)
    dispatch(await verifyCode(email, code, props.codeType, history))
    setloading(false)
  }

  const [invalidCredentials, setInvalidCredentials] = useState(false)

  const send = async (e) => {
    dispatch(await sendCode(email, props.codeType, history))
    setCode('')
    ref.current.inputs.forEach((input) => (input.value = ''))
  }

  useEffect(() => {
    console.log({ errors })
    if (!isEmpty(errors)) {
      setInvalidCredentials(true)
    }
  }, [errors])

  return (
    <div className={classes.container}>  <form
    onSubmit={(e) => {
      sendVerifyCode(e)
    }}
  >
      {loading ? <Screenloading /> : <div></div> }
      <div style={{ textAlign: 'center' }}>
        <img style={{ width: '52%' }} src={Logo} alt="" />
      </div>
      <div className={classes.sectionContainer}>
        {' '}

          <Typography component="p">
            <ReactPinField
              className={classes.pinField}
              ref={ref}
              validate={/^[0-9]$/}
              length="6"
              onChange={(e) => setCode(e)}
            />
          </Typography>
          <Typography component="p" className={classes.message}>
            <span>
              Por favor inserta aquí el código que te enviamos a tu correo
              electrónico para{' '}
              {props.codeType == CodeType.Register
                ? 'activar tu cuenta'
                : 'recuperar tu contraseña'}
            </span>{' '}
            <br />
          </Typography>
          {invalidCredentials
            ? (
            <Alert
              severity="error"
              icon={false}
              style={{ textAlign: 'center' }}
            >
              El código no es correcto. ¿Deseas volver a recibir el código de
              activación de tu cuenta? <br />
              <Link className="" to="#" onClick={send}>
                Click aquí.
              </Link>
            </Alert>
              )
            : (
            <Typography component="p" className={classes.resend}>
              <Link
                className=""
                to="#"
                onClick={send}
                style={{
                  textDecoration: 'underline',
                  color: 'white',
                  fontSize: '2.8vw'
                }}
              >
                Haz click aquí para reenviar el código a tu email
              </Link>
            </Typography>
              )}
      </div>

      <Button className={classes.signIn} style={{ backgroundColor: code.length == 6 ? '#fa7d4a' : '#cacaca' }} type={code.length == 6 ? 'submit' : 'button'}>
        <Typography
          style={{
            color: '#fff',
            fontWeight: 500,
            textTransform: 'capitalize'
          }}
        >
          Cambiar contraseña
        </Typography>
      </Button>
      </form>

    </div>
  )
}

export default Index
