export const passwordIsStrong = (password: string) => {
  const lowercase = /[a-z]/;
  const uppercase = /[A-Z]/;
  const number = /\d/;
  const specialChar =
    // eslint-disable-next-line no-useless-escape
    /[\!\@\#\$\%\^\&\*\(\)\_\+\[\]\{\}\;\'\:\"\,\.\<\>\?\/\|\\]/;

  return (
    password.length >= 8 &&
    lowercase.test(password) &&
    uppercase.test(password) &&
    number.test(password) &&
    specialChar.test(password)
  );
};
