import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  container: {
    padding: '3vw 5vw 3vw 2vw',
    margin: '3vw 0 3vw 0',
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'center',
    borderRadius: 10,
    border: '2px solid #D2D2D2',
    justifyContent: 'space-between',
    width: '100%'
  }
}))

export default function Index ({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div style={{
        color: '#FA7D4A',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        gridTemplateColumns: '39% 61%',
        display: 'grid',
        width: '100%'
      }}>
        <div>
          <span>{data.name} </span>
        </div>
        <div>
          <p style={{
            fontWeight: 'bold',
            width: '100%',
            overflowWrap: 'break-word',
            whiteSpace: 'break-spaces',
            color: '#797979'
          }}>
          {data.email}
          </p>
        </div>

      </div>
    </div>
  )
}
