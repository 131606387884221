import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from '../../slices/users'
import UserDetailsComponent from '../../components/users details/index'

export default function UserDetails () {
  const dispatch = useDispatch()
  const users = useSelector((state) => state.users.users)

  const [numberOfUsers, setnumberOfUsers] = useState(30)

  const defaultParams = {
    params: { limit: numberOfUsers }
  }

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const props = {
    users
  }

  return (
    <UserDetailsComponent {...props}/>
  )
}
