import { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { createTransferenceStatus } from "./../../slices/transactions";
import Select from "react-select";
import Swal from "sweetalert2";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles({
  root: {
    margin: 0,
    display: "flex",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "#ff7c46",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default function ChangeStatus({
  initialState,
  updateStatusTransferenceLive,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const History = useHistory();
  const [open, setOpen] = useState(false);

  const [status, setStatus] = useState(initialState);
  const [razon, setrazon] = useState("");
  const [newStatus, setNewStatus] = useState();

  const optionsRejected = [
    {
      label: "Cédula errada",
    },
    {
      label: "Cuenta errada",
    },
    {
      label: "Datos errados",
    },
  ];

  const changeStatusFunct = async () => {
    const status = {
      Pendiente: 1,
      Enespera: 2,
      Transfiriendo: 3,
      Datoserróneos: 4,
    };
    const statusId = status[newStatus.replace(" ", "")];

    console.log("statusId", statusId);
    console.log("initialState", status[initialState.replace(" ", "")]);

    if (status[initialState.replace(" ", "")] == 4) {
      Swal.fire({
        text: "Los datos son erróneos, deben ser corregidos",
        icon: "error",
      });
    } else {
      dispatch(
        createTransferenceStatus(location.state.transaction.id, statusId, razon)
      ).then((e) => {
        if (statusId == 4) {
          History.push("/operator-dashboard");
        }
        if (statusId == 3) {
          updateStatusTransferenceLive(statusId);
        }
        if (statusId == 2) {
          updateStatusTransferenceLive(statusId);
        }
      });
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      padding: 20,
      background: state.isSelected ? "white" : "white",
    }),
    control: (provied) => ({
      ...provied,
      background: "white",
      border: "none",
    }),
  };

  return (
    <>
      <div className={classes.flex}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Fab
            onClick={() => {
              setNewStatus("En espera");
              setOpen(true);
            }}
            style={
              status == "En espera"
                ? { width: 75, height: 75, backgroundColor: "#F2C371" }
                : { opacity: 0.5, backgroundColor: "#F2C371" }
            }
          >
            <SvgIcon
              viewBox="0 0 28 28"
              style={{ marginLeft: -10, marginTop: -5, overflow: "visible" }}
            >
              <defs>
                <filter
                  id="FontAwsome_pause_"
                  x="0"
                  y="0"
                  width="42"
                  height="42"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="3" result="blur" />
                  <feFlood floodOpacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g
                transform="matrix(1, 0, 0, 1, 0, 0)"
                filter="url(#FontAwsome_pause_)"
              >
                <path
                  id="FontAwsome_pause_2"
                  data-name="FontAwsome (pause)"
                  d="M7.714,55H2.571A2.572,2.572,0,0,1,0,52.429V33.571A2.572,2.572,0,0,1,2.571,31H7.714a2.572,2.572,0,0,1,2.571,2.571V52.429A2.572,2.572,0,0,1,7.714,55ZM24,52.429V33.571A2.572,2.572,0,0,0,21.429,31H16.286a2.572,2.572,0,0,0-2.571,2.571V52.429A2.572,2.572,0,0,0,16.286,55h5.143A2.572,2.572,0,0,0,24,52.429Z"
                  transform="translate(9 -25)"
                  fill="#fff"
                />
              </g>
            </SvgIcon>
          </Fab>
          <Typography style={{ fontSize: 10, color: "#fff", marginTop: 10 }}>
            En espera
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Fab
            onClick={() => {
              setNewStatus("Transfiriendo");
              setOpen(true);
            }}
            style={
              status == "Transfiriendo"
                ? { width: 75, height: 75, backgroundColor: "#4BA96D" }
                : { opacity: 0.5, backgroundColor: "#4BA96D" }
            }
          >
            <SvgIcon
              viewBox="0 0 27.044 27.050"
              style={{ marginLeft: -30, marginTop: -20, overflow: "visible" }}
            >
              <defs>
                <filter
                  id="FontAwsome_paper-plane_"
                  x="0"
                  y="0"
                  width="54.087"
                  height="54.099"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="3" result="blur" />
                  <feFlood floodOpacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g
                transform="matrix(1, 0, 0, 1, 0, 0)"
                filter="url(#FontAwsome_paper-plane_)"
              >
                <path
                  id="FontAwsome_paper-plane_2"
                  data-name="FontAwsome (paper-plane)"
                  d="M33.563.2.89,19.046a1.693,1.693,0,0,0,.155,3.045l7.493,3.144L28.791,7.387a.423.423,0,0,1,.606.585L12.416,28.661v5.675a1.691,1.691,0,0,0,3,1.114L19.888,30l8.783,3.68A1.7,1.7,0,0,0,31,32.4L36.072,1.945A1.692,1.692,0,0,0,33.563.2Z"
                  transform="translate(8.99 6.03)"
                  fill="#fff"
                />
              </g>
            </SvgIcon>
          </Fab>
          <Typography style={{ fontSize: 10, color: "#fff", marginTop: 10 }}>
            Transfiriendo
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Fab
            onClick={() => {
              setNewStatus("Datos erróneos");
              setOpen(true);
            }}
            style={
              status == "Datos erróneos"
                ? { width: 75, height: 75, backgroundColor: "#49B3F6" }
                : { opacity: 0.5, backgroundColor: "#49B3F6" }
            }
          >
            <SvgIcon
              viewBox="0 0 23.886 22.232"
              style={{ marginLeft: -22, marginTop: -20, overflow: "visible" }}
            >
              <defs>
                <filter
                  id="FontAwsome_exclamation-triangle_"
                  x="0"
                  y="0"
                  width="47.772"
                  height="44.464"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="3" result="blur" />
                  <feFlood floodOpacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g
                transform="matrix(1, 0, 0, 1, 0, 0)"
                filter="url(#FontAwsome_exclamation-triangle_)"
              >
                <path
                  id="FontAwsome_exclamation-triangle_2"
                  data-name="FontAwsome (exclamation-triangle)"
                  d="M29.437,22.743a2.482,2.482,0,0,1-2.149,3.721H2.484A2.482,2.482,0,0,1,.335,22.743l12.4-21.5a2.482,2.482,0,0,1,4.3,0l12.4,21.5ZM14.886,18.3a2.378,2.378,0,1,0,2.378,2.378A2.378,2.378,0,0,0,14.886,18.3ZM12.628,9.751l.383,7.029a.62.62,0,0,0,.619.586H16.14a.62.62,0,0,0,.619-.586l.383-7.029a.62.62,0,0,0-.619-.654H13.248a.62.62,0,0,0-.619.654Z"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </SvgIcon>
          </Fab>
          <Typography style={{ fontSize: 10, color: "#fff", marginTop: 10 }}>
            Datos erróneos
          </Typography>
        </div>
      </div>

      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: "900px",
            width: "95vw",
            margin: 0,
            ...(newStatus == "Datos erróneos" ? { height: "55vh" } : {}),
          },
        }}
      >
        <MuiDialogTitle disableTypography className={classes.root}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <SvgIcon viewBox="0 0 24 24">
              <path
                id="FontAwsome_times-circle_"
                data-name="FontAwsome (times-circle)"
                d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm5.884,15.15a.581.581,0,0,1,0,.823l-1.916,1.911a.581.581,0,0,1-.823,0L20,22.71l-3.15,3.174a.581.581,0,0,1-.823,0l-1.911-1.916a.581.581,0,0,1,0-.823L17.29,20l-3.174-3.15a.581.581,0,0,1,0-.823l1.916-1.916a.581.581,0,0,1,.823,0L20,17.29l3.15-3.174a.581.581,0,0,1,.823,0l1.916,1.916a.581.581,0,0,1,0,.823L22.71,20Z"
                transform="translate(-8 -8)"
                fill="#FA7D4A"
              />
            </SvgIcon>
          </IconButton>
        </MuiDialogTitle>

        <DialogContent>
          <Typography
            align="center"
            style={{
              fontSize: 18,
              fontWeight: 500,
              color: "#F96123",
              marginTop: 20,
            }}
          >
            Estas seguro que deseas cambiar el estado de esta transferencia?
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 30,
            }}
          >
            {newStatus == "Transfiriendo" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Fab
                  style={{
                    width: 100,
                    height: 100,
                    backgroundColor: "#4BA96D",
                  }}
                >
                  <SvgIcon
                    viewBox="0 0 27.044 27.050"
                    style={{
                      marginLeft: -30,
                      marginTop: -20,
                      overflow: "visible",
                    }}
                  >
                    <defs>
                      <filter
                        id="FontAwsome_paper-plane_"
                        x="0"
                        y="0"
                        width="54.087"
                        height="54.099"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#FontAwsome_paper-plane_)"
                    >
                      <path
                        id="FontAwsome_paper-plane_2"
                        data-name="FontAwsome (paper-plane)"
                        d="M33.563.2.89,19.046a1.693,1.693,0,0,0,.155,3.045l7.493,3.144L28.791,7.387a.423.423,0,0,1,.606.585L12.416,28.661v5.675a1.691,1.691,0,0,0,3,1.114L19.888,30l8.783,3.68A1.7,1.7,0,0,0,31,32.4L36.072,1.945A1.692,1.692,0,0,0,33.563.2Z"
                        transform="translate(8.99 6.03)"
                        fill="#fff"
                      />
                    </g>
                  </SvgIcon>
                </Fab>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#003F62",
                    marginTop: 10,
                  }}
                >
                  Transfiriendo
                </Typography>
              </div>
            ) : (
              <></>
            )}
            {newStatus == "Pendiente" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Fab
                  onClick={() => setNewStatus("Pendiente")}
                  style={
                    newStatus == "Pendiente"
                      ? { width: 100, height: 100, backgroundColor: "#7B2C2D" }
                      : {
                          opacity: 0.5,
                          width: 100,
                          height: 100,
                          backgroundColor: "#7B2C2D",
                        }
                  }
                >
                  <SvgIcon
                    viewBox="0 0 13.372 22.993"
                    style={{
                      marginLeft: -15,
                      marginTop: -15,
                      overflow: "visible",
                    }}
                  >
                    <defs>
                      <filter
                        id="FontAwsome_exclamation_"
                        x="0"
                        y="0"
                        width="26.745"
                        height="45.986"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#FontAwsome_exclamation_)"
                    >
                      <path
                        id="FontAwsome_exclamation_2"
                        data-name="FontAwsome (exclamation)"
                        d="M24.745,23.613a4.373,4.373,0,1,1-4.373-4.373A4.378,4.378,0,0,1,24.745,23.613ZM16.506,1.377l.743,14.867a1.312,1.312,0,0,0,1.31,1.246h3.626a1.312,1.312,0,0,0,1.31-1.246l.743-14.867A1.312,1.312,0,0,0,22.929,0H17.816A1.312,1.312,0,0,0,16.506,1.377Z"
                        transform="translate(-7 6)"
                        fill="#fff"
                      />
                    </g>
                  </SvgIcon>
                </Fab>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#003F62",
                    marginTop: 10,
                  }}
                >
                  Pendiente
                </Typography>
              </div>
            ) : (
              <></>
            )}
            {newStatus == "Datos erróneos" ? (
              <div>
                <Select
                  onChange={(e) => {
                    setrazon(e.label);
                  }}
                  styles={customStyles}
                  placeholder="Seleccionar razón"
                  getOptionLabel={(e) => e.label}
                  options={optionsRejected}
                />
              </div>
            ) : (
              <></>
            )}

            {newStatus == "En espera" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Fab
                  onClick={() => setNewStatus("En espera")}
                  style={
                    newStatus == "En espera"
                      ? { width: 100, height: 100, backgroundColor: "#F2C371" }
                      : {
                          opacity: 0.5,
                          width: 100,
                          height: 100,
                          backgroundColor: "#F2C371",
                        }
                  }
                >
                  <SvgIcon
                    viewBox="0 0 28 28"
                    style={{
                      marginLeft: -10,
                      marginTop: -5,
                      overflow: "visible",
                    }}
                  >
                    <defs>
                      <filter
                        id="FontAwsome_pause_"
                        x="0"
                        y="0"
                        width="42"
                        height="42"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#FontAwsome_pause_)"
                    >
                      <path
                        id="FontAwsome_pause_2"
                        data-name="FontAwsome (pause)"
                        d="M7.714,55H2.571A2.572,2.572,0,0,1,0,52.429V33.571A2.572,2.572,0,0,1,2.571,31H7.714a2.572,2.572,0,0,1,2.571,2.571V52.429A2.572,2.572,0,0,1,7.714,55ZM24,52.429V33.571A2.572,2.572,0,0,0,21.429,31H16.286a2.572,2.572,0,0,0-2.571,2.571V52.429A2.572,2.572,0,0,0,16.286,55h5.143A2.572,2.572,0,0,0,24,52.429Z"
                        transform="translate(9 -25)"
                        fill="#fff"
                      />
                    </g>
                  </SvgIcon>
                </Fab>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#003F62",
                    marginTop: 10,
                  }}
                >
                  En espera
                </Typography>
              </div>
            ) : (
              <></>
            )}
          </div>
        </DialogContent>

        <MuiDialogActions
          style={{ backgroundColor: "#003F62", justifyContent: "center" }}
          onClick={() => {
            setOpen(false);
            setStatus(newStatus);
            changeStatusFunct(1);
          }}
        >
          <Typography
            style={{ color: "#fff", fontWeight: 600, fontSize: 15, margin: 5 }}
          >
            Confirmar cambio
          </Typography>
        </MuiDialogActions>
      </Dialog>
    </>
  );
}
