import React from 'react'

import {
  IconButton,
  Box,
  Typography,
  Paper
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

export default function listAccount ({ transaction, classes, history }) {
  return (
            <Paper className={classes.transaction} onClick={() => history.push({ pathname: '/transactions/new', state: { contact: transaction } })}>
                <div style={{ display: 'flex' }}>
                    <Box className={classes.circle} boxShadow={3}>
                        <Typography variant="h6" style={{ fontWeight: 800 }}>
                            {transaction.name[0]}{transaction.lastName[0]}
                        </Typography>
                    </Box>
                    <div>
                        <Typography style={{ fontSize: 14, fontWeight: 600, color: '#003f62' }}>
                            {transaction.name} {transaction.lastName}
                        </Typography>
                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#003f62' }}>
                            {transaction.number}
                        </Typography>
                    </div>
                </div>
                <IconButton style={{ color: '#003f62' }}>
                    <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                </IconButton>
            </Paper>
  )
}
