import React from 'react'
import Accounts from '../../components/accounts/index'

export default function Index () {
  return (
        <div>
            <Accounts/>
        </div>
  )
}
