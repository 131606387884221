import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    padding: '3vw 5vw 3vw 2vw',
    margin: '3vw 1vw 3vw 1vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 10,
    border: '2px solid #D2D2D2'
  },
  name: {
    fontSize: '4vw',
    padding: '2vw 0px',
    fontWeight: 'bold'
  },
  email: {
    fontSize: '3vw',
    padding: '2vw 0px',
    color: 'gray'
  },
  containerAmount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'right'
  },
  containerCurrency: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left'
  }
}))

export function ItemDetail ({ data, currency }) {
  const classes = useStyles()
  const date = new Date(data.createdAt)
  const hour = `${date.getHours()}:${date.getMinutes()}`
  const transactionCurrencies = currency.find((curr) => {
    return curr.id === data.currencyId
  })
  return (
    <div className={classes.container}>
        <div className={classes.containerAmount}>
            <p className={classes.name}>{ data.userRequested.name }</p>
            <spam className={classes.email}>{ data.userRequested.email }</spam>
        </div>
        <div className={classes.containerAmount}>
            <p className={classes.name}>{ `$${data.amount}` }</p>
            <spam className={classes.email}>{`#${data.id}`}</spam>
        </div>
        <div className={classes.containerCurrency}>
            <p className={classes.name}>{ transactionCurrencies?.abbr }</p>
            <spam className={classes.email}>{hour}</spam>
        </div>
        {console.log(transactionCurrencies)}
    </div>
  )
}
