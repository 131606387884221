import { makeStyles } from '@material-ui/core/styles'
import Logo from '../assets/logo.png'
import { Typography, Button } from '@material-ui/core'
import Screenloading from './../components/loaders/screenLoading'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#003f62',
    alignItems: 'center',
    paddingTop: '6vw',
    '@media (max-width: 700px)': {
      paddingTop: '15vw'
    }
  },
  signIn: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 60,
    backgroundColor: '#ff7c46',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 0,
    '@media (max-height: 510px)': {
      display: 'none'
    },
    '&:hover, &:focus': {
      backgroundColor: '#ff7c46'
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    marginTop: '2vw',
    padding: '1vw 0vw 1vw 3vw',
    borderRadius: '7px',

    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 37,
    borderStyle: 'none',
    position: 'relative',
    width: '20vw',
    '@media (max-width: 700px)': {
      marginTop: '11vh',
      width: '90vw'
    }
  }
})

const Index = (props) => {
  const classes = useStyles()

  return (
      <div className={classes.container}>
          {props.loading && <Screenloading></Screenloading>}

        <div style={{ textAlign: 'center' }}>
          <img style={{ width: '62vw%' }} src={Logo} alt=""/>
        </div>
        {props.color
          ? <section className={classes.sectionContainer}>
            {props.children}
          </section>
          : <div className={classes.sectionContainer}>
           {props.children}
          </div>
        }

        <Button className={classes.signIn} type='submit'>
          <Typography style={{ color: '#fff', fontWeight: 500, textTransform: 'capitalize' }}>
            { props.label }
          </Typography>
        </Button>

      </div>
  )
}

export default Index
