// Dependencies
import { createSlice } from "@reduxjs/toolkit";
import { Axios } from "../helpers/axios";

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    rows: [],
  },
  reducers: {
    setCountries: (state, action) => {
      state.rows = action.payload;
    },
  },
});

const { setCountries } = countriesSlice.actions;

export const fetchCountries = () => async (dispatch) => {
  try {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "country");
    dispatch(setCountries(response.data.data));
    return response.data.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
export const getAllCountries = async () => {
  try {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "country");
    return response.data.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default countriesSlice.reducer;
