// Dependencies
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

// Components
import MainLayout from "../../layouts/main";
import { CurrencyForm } from "../../components/currencies";

// Slices
import { createCurrency } from "../../slices/currencies";
import { getAllCountries } from "../../slices/countries";

const useStyles = makeStyles({
  mainContainer: {
    minHeight: "90vh",
    paddingTop: "2.5%",
    padding: "0 3.5% 0",
  },
});

const CurrencyCreation = () => {
  // Styles
  const classes = useStyles();

  // History
  const history = useHistory();

  // Country states
  const [countries, setCountries] = useState(null);
  const [created, setCreated] = useState(null);

  useEffect(() => {
    if (!created) {
      getAllCountries().then((value) => {
        setCountries(value);
      });
    } else {
      history.push(`/currencies`);
    }
  }, [created]);

  const currencyFormSubmit = useCallback(async (values) => {
    // Format the data to send it to the server
    const formattedData = {
      name: values.name,
      abbr: values.abbr,
      countryId: values.country.id,
    };

    // Create the currency
    const result = await createCurrency(formattedData);
    setCreated(result);
  }, []);

  return (
    <MainLayout text="$ Monedas" skipPadding={true} goBack={true}>
      <main className={classes.mainContainer}>
        <CurrencyForm countries={countries} onSubmit={currencyFormSubmit} />
      </main>
    </MainLayout>
  );
};

export default CurrencyCreation;
