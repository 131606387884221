import { useState } from 'react'

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import TransactionModalDetail from '../../components/transactions/transactionModalDetail'
import { formatNumber } from './../../helpers/numbers'
const useStyles = makeStyles({
  container: {
    width: '100%',
    flex: '4',
    flexDirection: 'column',
    height: '100vh',
    flexWrap: 'nowrap',
    backgroundColor: '#003f62'
  },
  header: {
    marginTop: -30,
    flex: '1',
    padding: 15,
    color: '#fff'
  },
  content: {
    flex: '3',
    padding: 15,
    backgroundColor: '#fff',
    borderRadius: '5px 0 0 0'
  },
  tasa: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: 25,
    color: '#ff7c46',
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    width: 184
  },
  visibleButton: {
    position: 'absolute',
    top: -10,
    right: -35,
    color: '#fff',
    opacity: 0.5
  },
  tasaVisibleButton: {
    position: 'absolute',
    top: -6,
    right: 7,
    color: '#ff7c46',
    opacity: 0.5
  },
  actions: {
    backgroundColor: '#ff7c46',
    width: 60,
    height: 60,
    borderRadius: '50%',
    marginInline: 8,
    border: '1px solid black'
  },
  fab: {
    marginInline: 8,
    width: 60,
    height: 60,
    backgroundColor: '#ff7c46'
  },
  divider: {
    width: '100%',
    height: 2,
    border: 0,
    borderTop: '2px solid #c9cace'
  },
  transaction: {
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
    borderRadius: '10px',
    border: '0px solid #f8f8f8',
    '&:before': {
      height: 0
    }
  },
  summaryColumn: {
    flexBasis: '33.33%',
    alignSelf: 'center',
    '@media (min-width: 700px)': {
      fontSize: '1.3vw !important'
    }
  },
  subsummaryColumn: {
    '@media (min-width: 700px)': {
      fontSize: '1.3vw !important'
    }

  },
  detailColumn1: {
    position: 'absolute',
    top: 0,
    left: '15vw',
    width: 150
  },
  detailColumn2: {
    position: 'absolute',
    top: 0,
    left: '55vw',
    width: 150
  },
  card: {
    width: '100%',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '23px',
    paddingBottom: '10px',
    /*   borderRadius: "20px",  */
    marginBottom: '10px'
  }
})

const renderLogo = (expanded, transaction, k) => {
  if (expanded == false) {
    return (<td style={{ width: '55px', textAlign: 'left', paddingRight: '8px' }}>
              <img src={ transaction.bssAccount.bank.Logo } alt="" style={{ width: '100%', height: '100%' }}/>
            </td>)
  } else {
    if (expanded == k) {
      return <></>
    } else {
      return (<td style={{ width: '55px', textAlign: 'left', paddingRight: '8px' }}>
              <img src={ transaction.bssAccount.bank.Logo } alt="" style={{ width: '100%', height: '100%' }}/>
            </td>)
    }
  }
}

export default function TransactionCard ({ transaction, expanded, handleChange }) {
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)

  return (<>
      <TransactionModalDetail transaction={ transaction } setShowModal={setShowModal} showModal={showModal} />
      <Accordion
        expanded={expanded == `panel${transaction.id}`}
        onChange={handleChange(`panel${transaction.id}`)}
        className={classes.transaction}
      >
        <AccordionSummary
          aria-controls={`panel${transaction.id}bh-content`}
          id={`panel${transaction.id}bh-header`}
          style={{ height: '65px', borderRadius: '0px', border: 'none' }}
        >
          <table className={classes.card}>
            <tbody>
              <tr>
                { renderLogo(expanded, transaction, `panel${transaction.id}`) }
                <td style={{ color: '#29627e' }}>
                  <b>{transaction.userRequested.name}</b>
                </td>
                <td style={{ width: '80px', textAlign: 'right' }}>
                  <b style={{ fontSize: '16px', color: '#fd956f' }}>{ formatNumber(transaction.bssAmount) } BS</b>
                </td>
              </tr>
            </tbody>
          </table>
        </AccordionSummary>
        <AccordionDetails>
          <div className="containerAccordiontransfer">
            <div className="containerAccordiontransfer-logo">
              <img src={ transaction.bssAccount.bank.Logo } alt="" style={{ width: '100%' }}/>
            </div>
            <div className="containerAccordiontransfer-data">
                <h3>{transaction.bssAccount.bank.name}</h3>
                <h5>{transaction.bssAccount.bank.prefix + '' + transaction.bssAccount.number}</h5>
                <h5>{transaction.bssAccount.name} {transaction.bssAccount.lastName}</h5>
                <h5>V-{transaction.bssAccount.identification}</h5>
                <h5>Cta-Corriente</h5>
                <h3 className="bold">Tasa: <span>{ transaction.rate.rate }</span></h3>
            </div>
            <div className="containerAccordiontransfer-misc">
                <div className="containerMisc">
                  <Link to="#" onClick={(e) => setShowModal(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.75" height="14.812" viewBox="0 0 19.75 14.812">
                        <path id="FontAwsome_image_" data-name="FontAwsome (image)" d="M17.9,64H1.852A1.852,1.852,0,0,0,0,65.852V76.961a1.852,1.852,0,0,0,1.852,1.852H17.9a1.852,1.852,0,0,0,1.852-1.852V65.852A1.852,1.852,0,0,0,17.9,64Zm-.231,12.961H2.083a.231.231,0,0,1-.231-.231V66.083a.231.231,0,0,1,.231-.231H17.667a.231.231,0,0,1,.231.231V76.729A.231.231,0,0,1,17.667,76.961ZM4.937,67.394A1.543,1.543,0,1,0,6.48,68.937,1.543,1.543,0,0,0,4.937,67.394ZM3.7,75.109H16.047V72.023l-3.376-3.376a.463.463,0,0,0-.655,0l-4.61,4.61L5.882,71.733a.463.463,0,0,0-.655,0L3.7,73.258Z" transform="translate(0 -64)" fill="#f96123"/>
                    </svg>
                  </Link>
                  <span>Captura</span>
                </div>
                {/* <div className="containerMisc">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.18" height="17.348" viewBox="0 0 15.18 17.348">
                        <path id="FontAwsome_share-alt_" data-name="FontAwsome (share-alt)" d="M11.927,10.843A3.239,3.239,0,0,0,9.9,11.55L6.428,9.38a3.271,3.271,0,0,0,0-1.412L9.9,5.8a3.247,3.247,0,1,0-1.15-1.839L5.279,6.129a3.253,3.253,0,1,0,0,5.09l3.473,2.17a3.253,3.253,0,1,0,3.176-2.547Z" fill="#f96a30"/>
                    </svg>
                    <span>Compartir</span>
                </div> */}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>)
}
