import { useEffect, useCallback, useState } from "react";

const useProfit = (costRate, rate) => {
  const [profit, setProfit] = useState(0);

  const makeProfit = useCallback(() => {
    const result = ((rate / costRate - 1) * 100 * -1).toFixed(2);

    if (isNaN(result)) setProfit(0);
    else setProfit(result);
  }, [costRate, rate]);

  useEffect(() => {
    if (costRate === 0 && rate !== undefined) setProfit(0);
    else makeProfit();
  }, [costRate, rate]);

  return { profit };
};

export default useProfit;
