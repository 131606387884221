import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  modalRateContainer: {
    color: 'black',
    background: 'rgba(0,0,0,0.4)',
    position: 'fixed',
    top: '0',
    right: '0',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1001
  },
  modalRate: {
    width: '80vw',
    background: 'white',
    padding: '3vw',
    borderRadius: '10px',
    '@media (min-width: 700px)': {
      padding: '3vw',
      width: '45vw'
    }
  }
})

export default function RangesEdit ({ children }) {
  const classes = useStyles()

  return (
        <div className={classes.modalRateContainer}>
            <article className={classes.modalRate}>
                {children}
            </article>
        </div>
  )
}
