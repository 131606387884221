import { createSlice } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const appAccountSlice = createSlice({
  name: 'parentMethods',
  initialState: {
    appAccounts: []
  },
  reducers: {
    setappAccount: (state, action) => {
      state.appAccounts = action.payload
    }
  }
})

export const createAppAccountData = async (appAccountId, field, value) => {
  try {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_URL + 'appaccountdata/store', {
        appAccountId: appAccountId,
        field: field,
        value: value
      }
    )

    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const createAppAccountDataWarning = async (appAccountId, value) => {
  try {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_URL + 'appaccountwarning/store', {
        appAccountId: appAccountId,
        value: value
      }
    )

    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const updateAppAccountData = async (appAccountId, id, field, value) => {
  try {
    const { data } = await Axios.put(
      process.env.REACT_APP_API_URL + 'appaccountdata/update/' + id, {
        appAccountId: appAccountId,
        field: field,
        value: value
      }
    )

    console.log('%cMyProject%cline:25%cdata', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(60, 79, 57);padding:3px;border-radius:2px', data)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const updateAppAccount = async (id, helpImage, methodId) => {
  try {
    const { data } = await Axios.put(
      process.env.REACT_APP_API_URL + 'appaccount/update/' + id, {
        helpImage,
        methodId,
        label: 'label'
      }
    )

    console.log('%cMyProject%cline:25%cdata', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(60, 79, 57);padding:3px;border-radius:2px', data)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const updateAppAccountDataWarning = async (appAccountId, id, value) => {
  try {
    const { data } = await Axios.put(
      process.env.REACT_APP_API_URL + 'appaccountwarning/update/' + id, {
        appAccountId: appAccountId,
        value: value
      }
    )

    return data
  } catch (e) {
    return console.error(e.message)
  }
}

export const deleteAppAccountData = async (id) => {
  try {
    const { data } = await Axios.delete(
      process.env.REACT_APP_API_URL + 'appaccountdata/delete/' + id
    )

    console.log('%cMyProject%cline:25%cdata', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(60, 79, 57);padding:3px;border-radius:2px', data)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const deleteAppAccountDataWarning = async (id) => {
  try {
    const { data } = await Axios.delete(
      process.env.REACT_APP_API_URL + 'appaccountwarning/delete/' + id
    )

    console.log('%cMyProject%cline:25%cdata', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(60, 79, 57);padding:3px;border-radius:2px', data)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}

export const createAppAccount = async (methodId, image) => {
  try {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_URL + 'appaccount/store', {
        methodId: methodId,
        label: 'label',
        description: 'description',
        active: true,
        helpImage: image
      }
    )

    console.log('%cMyProject%cline:25%cdata', 'color:#fff;background:#ee6f57;padding:3px;border-radius:2px', 'color:#fff;background:#1f3c88;padding:3px;border-radius:2px', 'color:#fff;background:rgb(60, 79, 57);padding:3px;border-radius:2px', data)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export default appAccountSlice.reducer
