import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const fetchUserBanks = createAsyncThunk(
  'userBank',
  async () => {
    return Axios.get(process.env.REACT_APP_API_URL + 'userBank').then((response) => response.data.data)
  }
)

export const getUserBank = async (id) => {
  const { data } = await Axios.get(process.env.REACT_APP_API_URL + 'userbank/show/' + id)
  console.log(data)
  return data
}

export const userBanksSlice = createSlice({
  name: 'userBanks',
  initialState: {
    userBanks: []
  },
  extraReducers: {
    [fetchUserBanks.fulfilled]: (state, action) => {
      state.userBanks = action.payload.userBanks
    }
  }
})

export default userBanksSlice.reducer
