import React from 'react'
import Balances from '../../components/balances/index'

export default function Index () {
  return (
        <div>
            <Balances/>
        </div>
  )
}
