import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ItemDetail } from './detail/item-detail'
import { getCurrencies } from '../../../slices/users'

export function DetailDay ({ key, day, loading, setloading }) {
  const transactions = useSelector(state => state.transactions.transactionHistory)
  const [currency, setCurrency] = useState([])
  useEffect(() => {
    getCurrencies()
      .then((res) => {
        setCurrency(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
        <div key={ key } style={{ marginTop: '3vw' }}
        >
            <div>
                {loading === false
                  ? transactions[day].map((tran) => (
                    <div key={ tran.id }>
                        <ItemDetail data={ tran } currency={ currency }/>
                    </div>
                  ))
                  : <h4>No transactions</h4>}
            </div>
            {console.log(transactions[day])}
        </div>
  )
}
