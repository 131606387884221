import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { v4 } from 'uuid' // there are 5 versions. You can use any.

const useStyles = makeStyles({
  containerInput: {
    display: 'flex',
    background: 'white',
    justifyContent: 'space-between',
    padding: '1vw 2vw 1vw 2vw',
    borderRadius: 5,
    '& input': {
      width: '90%',
      border: 'none'
    }
  },
  GridBank: {
    marginTop: '6vw'
  },
  ContainerAccount: {
    background: 'white',
    borderRadius: 10,
    display: 'grid',
    position: 'relative',
    gridTemplateColumns: '30% 70%',
    marginBottom: '4vw',
    alignItems: 'center',
    '& img': {
      width: '100%',
      marginBottom: '6vw'
    },
    '& h4': {
      fontWeight: 'lighter',
      color: '#f96123'
    }
  },
  ContainerIcons: {
    position: 'absolute',
    bottom: '5%',
    display: 'flex',
    right: '5%',
    '& svg': {
      marginLeft: '2vw'
    }
  },
  ContainerSwitch: {
    position: 'relative'
  },
  Switch: {
    top: '60%',
    width: '100%',
    zIndex: 4,
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& span': {
      fontSize: '2.8vw',
      color: '#f96123'
    }
  },
  footerModal: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '2vw',
    '& button': {
      fontWeight: '700',
      fontSize: '5vw',
      border: 'none',
      borderRadius: '5px',
      padding: '2vw 5vw',
      '@media (min-width: 700px)': {
        padding: '1vw 3vw',
        fontSize: '1.2vw'
      }
    }
  },
  btnSolid: {
    color: 'white',
    background: '#f96123'
  },
  root: {
    width: '75vw'
  },
  NewField: {
    display: 'grid',
    gridTemplateColumns: '95% 5%',
    gridGap: '2vw',
    marginBottom: '3vw',
    alignItems: 'center',
    '& input': {
      border: '2 solid #f96123',
      borderRadius: 5,
      padding: '1vw',
      paddingLeft: '2vw'
    }
  },
  AddNewField: {
    display: 'flex',
    marginTop: '3vw',
    marginBottom: '3vw',
    '& span': {
      marginLeft: '2vw'
    }
  }
})

const AddNewField = ({ add }) => {
  const classes = useStyles()

  return (
    <div onClick={add} className={classes.AddNewField}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          id="FontAwsome_plus-square_"
          data-name="FontAwsome (plus-square)"
          d="M21.429,32H2.571A2.572,2.572,0,0,0,0,34.571V53.429A2.572,2.572,0,0,0,2.571,56H21.429A2.572,2.572,0,0,0,24,53.429V34.571A2.572,2.572,0,0,0,21.429,32ZM19.714,45.5a.645.645,0,0,1-.643.643H14.143v4.929a.645.645,0,0,1-.643.643h-3a.645.645,0,0,1-.643-.643V46.143H4.929a.645.645,0,0,1-.643-.643v-3a.645.645,0,0,1,.643-.643H9.857V36.929a.645.645,0,0,1,.643-.643h3a.645.645,0,0,1,.643.643v4.929h4.929a.645.645,0,0,1,.643.643Z"
          transform="translate(0 -32)"
          fill="#f96123"
        />
      </svg>

      <span
        style={{
          fontWeight: 'lighter',
          fontSize: '3vw'
        }}
      >
        Agregar <br /> cambio
      </span>
    </div>
  )
}

function Warnings ({ fields, setfields, account, onDeleteItem }) {
  const fieldsArray = fields
  return (
    <div>
      <h4
        style={{
          color: '#f96123',
          marginTop: '6vw',
          marginBottom: '3vw',
          fontSize: '5vw',
          textAlign: 'center'
        }}
      >
        Agregar advertencia
      </h4>

      {fields && fields.map((e, i) => {
        return (
            <div key={e.id}>
              <NewField
                field={e}
                fieldsArray={fieldsArray}
                setfields={setfields}
                i={i}
                fields={fields}
                onDelete={() => onDeleteItem(e)}
              />
            </div>
        )
      })}

      <AddNewField
        add={() => {
          setfields([...fields, { id: v4() }])
          fieldsArray.push({ order: fieldsArray.length - 1 })
        }}
      />
    </div>
  )
}

const NewField = ({
  setfields,
  field,
  fields,
  i,
  fieldsArray,
  onDelete
}) => {
  const classes = useStyles()
  const [valueTemporally, setvalueTemporally] = useState(field.value)

  const onChange = (e) => {
    const fieldsCopy = JSON.parse(JSON.stringify(fields))
    fieldsCopy[i][e.target.name] = e.target.value

    setfields(fieldsCopy)
  }

  const deleteItem = async () => {
    await onDelete()

    if (field.appAccountId) {
      const fieldsArrayUpdated = fieldsArray.filter(
        (fld) => fld.id != field.id
      )
      fieldsArray = fieldsArrayUpdated
      setfields([...fieldsArrayUpdated])
    } else {
      fieldsArray.splice(i, 1)
      setfields([...fieldsArray])
    }
  }

  return (
      <div className={classes.NewField}>
        <input
          type="text"
          value={valueTemporally}
          placeholder="Valor"
          name="value"
          onChange={(e) => {
            setvalueTemporally(e.target.value)
            onChange(e)
          }}
        />

        {
            fields.length == (i + 1) &&
        <div onClick={deleteItem}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.008"
              height="17.437"
              viewBox="0 0 10.008 11.437"
            >
              <path
                id="FontAwsome_trash-alt_"
                data-name="FontAwsome (trash-alt)"
                d="M.715,10.365a1.072,1.072,0,0,0,1.072,1.072H8.22a1.072,1.072,0,0,0,1.072-1.072V2.859H.715ZM6.791,4.646a.357.357,0,1,1,.715,0v5a.357.357,0,0,1-.715,0Zm-2.144,0a.357.357,0,1,1,.715,0v5a.357.357,0,0,1-.715,0Zm-2.144,0a.357.357,0,1,1,.715,0v5a.357.357,0,0,1-.715,0ZM9.65.715H6.97L6.76.3a.536.536,0,0,0-.48-.3H3.726a.53.53,0,0,0-.478.3l-.21.418H.357A.357.357,0,0,0,0,1.072v.715a.357.357,0,0,0,.357.357H9.65a.357.357,0,0,0,.357-.357V1.072A.357.357,0,0,0,9.65.715Z"
                transform="translate(0 0)"
                fill="#f96123"
              />
            </svg>
          </div>
        </div>
        }

      </div>
  )
}

export default Warnings
