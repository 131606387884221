import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { formatNumber } from "./../../../helpers/numbers";

const useStyles = makeStyles({
  input: {
    borderRadius: 5,
    padding: "3vw 2vw 3vw 2vw",
    width: "75vw",
    fontSize: "5vw",
    border: "none",
    marginLeft: "-1vw",
  },
  UploadImageContainer: {
    background: "#F4F4F4",
    marginTop: "5vw",
    padding: "8vw 4vw 8vw 4vw",
    borderRadius: 5,
    marginBottom: "6vw",
    position: "relative",
  },
  UploadImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h3": {
      fontSize: "4vw",
      fontWeight: "lighter",
    },
    "& svg": {
      width: "20%",
      height: "20%",
      marginBottom: "2vw",
    },
  },
  file: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    zIndex: 3,
  },
  trashIcon: {
    position: "absolute",
    right: "5%",
    top: "5%",
    zIndex: 5,
  },
  closeButton: {},
});

const Createbalance = ({
  userSelected,
  setimage,
  handleClose,
  open,
  path,
  setpath,
  setAmount,
  Amount,
  handleCreateBalance,
}) => {
  const classes = useStyles();

  const fileSelected = () => {
    const file = document.getElementById("file").files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const image = document.createElement("img");
      image.src = e.target.result;
      setimage(e.target.result);
      setpath(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: { width: "95vw", padding: "5vw 5vw 12vw" },
      }}
    >
      <h1 style={{ fontSize: "4vw", textAlign: "center" }}>
        Crear balance{" "}
        {userSelected.user ? `al usuario ${userSelected.user.name}` : ""}
      </h1>
      <div className={classes.input}>
        <input
          style={{
            borderRadius: 5,
            padding: "3vw 2vw 3vw 2vw",
            width: "75vw",
            fontSize: "5vw",
            border: "1px solid #00000021",
            marginLeft: "-1vw",
          }}
          value={Amount}
          onInput={(e) => setAmount(e.target.value.replace(/[^0-9-]/g, ""))}
          type="text"
          placeholder="Monto"
          name="title"
        />
      </div>
      {userSelected.total || userSelected.total == 0 ? (
        <p style={{ fontStyle: "italic" }}>
          Al balance se le{" "}
          {(Amount != "0" || Amount != "") && parseInt(Amount) < 0
            ? "restará"
            : "sumará"}{" "}
          {formatNumber(parseInt(Amount || 0))}, será de $
          {formatNumber(userSelected.total + parseInt(Amount || 0))}{" "}
        </p>
      ) : (
        <div></div>
      )}
      <div className={classes.UploadImageContainer}>
        {path == "" && (
          <div className={classes.UploadImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34.286"
              height="24"
              viewBox="0 0 34.286 24"
            >
              <path
                id="FontAwsome_cloud-upload-alt_"
                data-name="FontAwsome (cloud-upload-alt)"
                d="M28.8,42.425a5.149,5.149,0,0,0-4.8-7,5.117,5.117,0,0,0-2.855.868,8.572,8.572,0,0,0-16,4.275c0,.145.005.289.011.434A7.716,7.716,0,0,0,7.714,56H27.429A6.857,6.857,0,0,0,28.8,42.425Zm-7.725,3.289h-3.5v6a.86.86,0,0,1-.857.857H14.143a.86.86,0,0,1-.857-.857v-6h-3.5a.856.856,0,0,1-.605-1.463l5.646-5.646a.86.86,0,0,1,1.211,0l5.646,5.646A.857.857,0,0,1,21.075,45.714Z"
                transform="translate(0 -32)"
                fill="#707070"
              />
            </svg>
            <h3>Subir captura de la transferencia</h3>
          </div>
        )}
        {path != "" && (
          <div>
            <div className={classes.trashIcon} onClick={() => setpath("")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.056"
                height="23.778"
                viewBox="0 0 12.056 13.778"
              >
                <path
                  id="FontAwsome_trash-alt_"
                  data-name="FontAwsome (trash-alt)"
                  d="M7.212,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H7.212a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,7.212,11.194Zm4.413-9.042H9.407L8.492.627A1.292,1.292,0,0,0,7.384,0H4.671A1.292,1.292,0,0,0,3.564.627L2.648,2.153H.431A.431.431,0,0,0,0,2.583v.431a.431.431,0,0,0,.431.431H.861v9.042a1.292,1.292,0,0,0,1.292,1.292H9.9a1.292,1.292,0,0,0,1.292-1.292V3.444h.431a.431.431,0,0,0,.431-.431V2.583A.431.431,0,0,0,11.625,2.153Zm-7-.783a.161.161,0,0,1,.139-.078h2.53a.161.161,0,0,1,.139.078l.47.783H4.155ZM9.9,12.486H2.153V3.444H9.9ZM4.2,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H4.2a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,4.2,11.194Z"
                />
              </svg>
            </div>
            <img src={path} style={{ width: "100%" }} />
          </div>
        )}
        <input
          className={classes.file}
          type="file"
          accept="image/*"
          id="file"
          onChange={fileSelected}
        />
      </div>

      <div style={{ position: "absolute", bottom: "4%" }}>
        <button
          style={{
            position: "absolute",
            bottom: "0px",
            background: "rgb(255, 124, 70) none repeat scroll 0% 0%",
            width: "74vw",
            padding: "1vw 2vw",
            fontSize: "5vw",
            fontWeight: "lighter",
            color: "white",
            border: "none",
            borderRadius: "5px",
            zIndex: 1000,
          }}
          onClick={handleCreateBalance}
        >
          Crear
        </button>
      </div>
    </Dialog>
  );
};

export default Createbalance;
