/* eslint-disable react/prop-types */
import React from 'react'
import BottomNavigator from '../../layouts/bottomBar/indexAdmin'
import Item from './balance_item/indexAdmin'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Fab } from 'react-tiny-fab'
import InputSearch from './../form/searchField/index'

const useStyles = makeStyles(() => ({
  container: {
    padding: '9vw 5vw 20vh 5vw',
    '& header': {
      textAlign: 'center'
    },
    '& .rtf': {
      right: '0% !important',
      bottom: '15vh !important'
    }
  }
}))

function Header (props) {
  const history = useHistory()

  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <i
        onClick={() => history.push('/dashboard')}
        style={{ fontSize: '7vw', color: '#003f62' }}
        className="fas fa-chevron-left"
      ></i>

      <h1
        style={{
          color: '#FA7D4A',
          fontSize: '8vw',
          marginLeft: '-7vw'
        }}
      >
        Saldos
      </h1>
      <div></div>
    </header>
  )
}

export default function Index ({ selectUser, balances, history, openModal, openCustom, search, setsearch }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Header goBack={history.goBack}></Header>

      <div style={{ marginTop: '6vw', marginBottom: '7vw' }}>
        <form onSubmit={search}>
          <InputSearch onChange={setsearch} onSubmit={search}/>
        </form>
      </div>

      {balances != null && balances.rows
        ? (
            balances.rows.map((e) => {
              return <div key={JSON.stringify(e)} onClick={() => selectUser(e)}><Item data={e}></Item></div>
            })
          )
        : (
        <div></div>
          )}

      <Fab
        icon={<i className="fas fa-plus"></i>}
        alwaysShowTitle={true}
        mainButtonStyles={{
          background: '#f96123'
        }}
        onClick={() => history.push('/all-users-balance')}
      ></Fab>

      <BottomNavigator></BottomNavigator>
    </div>
  )
}
