/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import BottomNavigator from "../../layouts/bottomBar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import { Grid, Typography, Paper } from "@material-ui/core";
import Header from "../../components/operador/Header";
import { fetchTransferences } from "../../slices/transactions";
import { getBankBalancesByUser } from "../../slices/bssbalance";
import { useDispatch, useSelector } from "react-redux";
import PendienteStatus from "./../../components/status/pendiente";
import EnEsperaStatus from "./../../components/status/enespera";
import TransfiriendoStatus from "./../../components/status/transfiriendo";
import DatosErroneosStatus from "./../../components/status/datoserroneos";
import FinalizadoStatus from "./../../components/status/finalizada";
import { formatNumber } from "./../../helpers/numbers";
import Dialog from "@material-ui/core/Dialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import moment from "moment";
import dayjs from "dayjs";

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    paddingBottom: "50vh",
    minHeight: "100vh",
    backgroundColor: "#FA7D4A",
  },
  content: {
    paddingInline: 15,
  },
  transfer: {
    marginTop: 10,
    marginBottom: 10,
    padding: 15,
    borderRadius: 10,
  },
  status: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  statusBtn: {
    width: 122,
    color: "#fff",
    textTransform: "none",
    fontSize: 12,
    marginRight: 10,
    fontWeight: 600,
  },
  containerStatus: {
    background: "#4AA86C",
    textAlign: "center",
    padding: "1vw 3vw 1vw 3vw",
    marginRight: "4vw",
    borderRadius: 5,
  },
  titleStatus: {
    fontSize: "4vw",
    color: "white",
  },
});

function TotalTransferences(props) {
  return (
    <div
      style={{
        background: "#003f62",
        display: "flex",
        marginBottom: "3vw",
        color: "white",
        marginTop: "4vw",
        justifyContent: "space-between",
        padding: "1vw 2vw",
        borderRadius: "5px",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "2.5vw" }}>Total de ganancia en este rango:</p>
      <p
        style={{
          fontWeight: "bold",
        }}
      >
        ${formatNumber(props.total)} COP
      </p>
    </div>
  );
}

function TotalBss(props) {
  return (
    <div
      style={{
        background: "#003f62",
        display: "flex",
        marginBottom: "3vw",
        color: "white",
        justifyContent: "space-between",
        padding: "1vw 2vw",
        borderRadius: "5px",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "2.5vw" }}>Total de bolívares enviados:</p>
      <p
        style={{
          fontWeight: "bold",
        }}
      >
        ${formatNumber(props.total)} BS
      </p>
    </div>
  );
}

function TotalBssBalance(props) {
  return (
    <div
      style={{
        background: "rgb(160, 57, 15) none repeat scroll 0% 0%",
        display: "flex",
        marginBottom: "3vw",
        color: "white",
        justifyContent: "space-between",
        padding: "1vw 2vw",
        borderRadius: "5px",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "2.5vw" }}>Total de Bolívares en cuentas:</p>
      <p
        style={{
          fontWeight: "bold",
        }}
      >
        ${formatNumber(props.total)} BS
      </p>
    </div>
  );
}

function Filters(props) {
  return (
    <div
      style={{
        background: "white",
        display: "grid",
        gridTemplateColumns: "45% auto",
        gridGap: "5%",
        alignItems: "center",
        padding: "4vw 2vw 3vw 2vw",
        borderRadius: "5px",
      }}
    >
      <DatePicker
        onChange={props.setStart}
        label="Fecha inicio"
        value={props.start}
        inputFormat="MM/dd/yyyy"
        renderInput={(params) => <TextField {...params} />}
      />
      <DatePicker
        onChange={props.setEnd}
        value={props.end}
        label="Fecha final"
        renderInput={(params) => <TextField {...params} />}
        inputFormat="MM/dd/yyyy"
      />
    </div>
  );
}

function History() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [start, setStart] = useState(dayjs());
  const [end, setEnd] = useState(dayjs());

  const bssBalances = useSelector((state) => state.bssbalance.rows);
  const historyTransactions = useSelector(
    (state) => state.transactions.Transferences
  );

  const fetchHistory = () => {
    const startDate = start || dayjs();
    const endDate = end || dayjs();

    dispatch(
      fetchTransferences({
        start: moment(startDate["$d"]).format("YYYY-MM-DD"),
        end: moment(endDate["$d"]).format("YYYY-MM-DD"),
      })
    );
  };

  useEffect(() => {
    dispatch(getBankBalancesByUser(dispatch));
  }, []);

  useEffect(() => {
    fetchHistory();
  }, [start]);

  useEffect(() => {
    fetchHistory();
  }, [end]);

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container className={classes.container}>
          <Header text="Historial" />

          <Grid className={classes.content}>
            <Filters
              start={start}
              setStart={setStart}
              end={end}
              setEnd={setEnd}
            ></Filters>

            {historyTransactions && (
              <TotalTransferences total={historyTransactions.length * 300} />
            )}
            <TotalBss
              total={historyTransactions.reduce(
                (partialSum, a) => partialSum + a.bssAmount,
                0
              )}
            />
            {bssBalances && bssBalances.rows && (
              <TotalBssBalance
                total={bssBalances.rows.reduce(
                  (partialSum, a) => partialSum + a.total,
                  0
                )}
              />
            )}
            {historyTransactions.map((transfer) => {
              return (
                <TransactionCard
                  key={JSON.stringify(transfer)}
                  classes={classes}
                  transfer={transfer}
                ></TransactionCard>
              );
            })}
          </Grid>
        </Grid>
        <BottomNavigator></BottomNavigator>
      </LocalizationProvider>
    </>
  );
}

export default History;

function TransactionCard({ classes, transfer }) {
  const [openModalImage, setopenModalImage] = useState(false);

  return (
    <>
      <Paper className={classes.transfer}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "50%",
              marginTop: 20,
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <img style={{ width: "71%" }} src={transfer.bssAccount.bank.Logo} />
          </div>
          <div style={{ width: "100%" }}>
            {transfer.bssAccount.number != "1234567898745632" ? (
              <Typography
                style={{ fontSize: 16, color: "#003F62", lineHeight: 1.3 }}
              >
                {transfer.bssAccount.bank.prefix +
                  "" +
                  transfer.bssAccount.number}
              </Typography>
            ) : (
              <Typography
                style={{ fontSize: 16, color: "#003F62", lineHeight: 1.3 }}
              >
                {transfer.bssAccount.phone}
              </Typography>
            )}
            <Typography
              style={{ fontSize: 16, color: "#003F62", lineHeight: 1.3 }}
            >
              {transfer.bssAccount.name}
            </Typography>
            <Typography
              style={{ fontSize: 16, color: "#003F62", lineHeight: 1.3 }}
            >
              V {transfer.bssAccount.identification}
            </Typography>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: 14, color: "#003F62" }}>
                Monto:
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#003F62",
                }}
              >
                {formatNumber(transfer.bssAmount)} BS
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.status}>
          <a
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              marginRight: "3vw",
            }}
            href={"https://wa.me/" + transfer.userRequested.phone}
            target="_blank"
            rel="noreferrer"
          >
            <span
              style={{
                color: "green",
                fontWeight: "bold",
              }}
            >
              WhatsApp
            </span>
            <i
              className="fab fa-whatsapp"
              style={{
                marginTop: "0vw",
                fontSize: "4vw",
                marginLeft: "2vw",

                color: "green",
              }}
            ></i>
          </a>
          <div style={{ marginTop: "-4vw" }}>
            {transfer.transactionStatus[0].statusId == 1 && (
              <PendienteStatus></PendienteStatus>
            )}
            {transfer.transactionStatus[0].statusId == 2 && (
              <EnEsperaStatus></EnEsperaStatus>
            )}
            {transfer.transactionStatus[0].statusId == 3 && (
              <TransfiriendoStatus></TransfiriendoStatus>
            )}
            {transfer.transactionStatus[0].statusId == 4 && (
              <DatosErroneosStatus></DatosErroneosStatus>
            )}
            {transfer.transactionStatus[0].statusId == 5 && (
              <FinalizadoStatus></FinalizadoStatus>
            )}
          </div>
          {transfer.capture && (
            <i
              onClick={() => setopenModalImage(true)}
              className="fas fa-images"
              style={{
                fontSize: "7vw",
                marginLeft: "3vw",
                color: "#003f62",
              }}
            ></i>
          )}
        </div>
      </Paper>

      <Dialog
        style={{ backgroundColor: "none" }}
        open={openModalImage}
        onClose={() => setopenModalImage(false)}
      >
        <img src={transfer.capture} />
      </Dialog>
    </>
  );
}
