import React from "react";
import Header from "../../components/Header";
import "../../assets/styles/section_layout.scss";

function SectionLayout(props: any) {
  return (
    <div className="section-layout">
      <Header text={props.title} style={{}} returnHeader={props.returnHeader} />
      <div className="blue_rectangle" />
      <div className="container-section">{props.children}</div>
    </div>
  );
}

export default SectionLayout;
