// Dependencies
import { useCallback, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import * as Swal from 'sweetalert2'
import { deleteCurrency, fetchCurrencies } from '../../slices/currencies'
import Modal from '../modal'

import { Button } from '@material-ui/core'
import { CurrenciesContext } from '../../context/CurrenciesContext'

const useDefaultListStyles = makeStyles({
  container: {
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    height: '15vw',
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: '5px',
    padding: '0 5%',
    marginBottom: '4.5%'
  },
  name: {
    fontSize: '4.2vw',
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  itemsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icons: {
    color: '#003353d9',
    marginLeft: '10px',
    fontSize: '4.5vw'
  }
})

const CurrenciesListItem = (props) => {
  const { data, onClickAction, onDeleteAction } = props

  const classes = useDefaultListStyles()

  return (
    <li className={classes.container}>
      <div className={classes.itemsContainer}>
          <div style={{
            display: 'flex'
          }}>
            <img src={data.country.image} style={{
              maxWidth: '30px',
              objectFit: 'contain',
              marginRight: '10px'
            }}/>
            <label className={classes.name}>{data.name} - {data.abbr}</label>
          </div>
      </div>
      <div className={classes.itemsContainer}>
        <div onClick={() => { onClickAction(data.id) }}>
          <i className={`fa-solid fa-pencil ${classes.icons}`}></i>
        </div>
        <div onClick={() => onDeleteAction(data)}>
              <i className={`fa-solid fa-trash-can ${classes.icons}`}></i>
          </div>
      </div>
    </li>
  )
}

export const CurrenciesList = (props) => {
  const { goToDetails } = props

  const dispatch = useDispatch()
  const currencies = useSelector(state => state.currencies.rows)

  const [showModal, setShowModal] = useState(false)
  const [currencyToDelete, setCurrencyToDelete] = useState(null)
  const [filteredCurrencies, setFilteredCurrencies] = useState([])

  const { selectedCountryId } = useContext(CurrenciesContext)

  useEffect(() => {
    setFilteredCurrencies(currencies.filter(el => el.countryId === selectedCountryId))
  }, [selectedCountryId, currencies])

  const openDeleteModal = useCallback(async (currency) => {
    setShowModal(true)
    setCurrencyToDelete(currency)
  }, [])

  const handleCurrencyDelete = useCallback(async () => {
    await deleteCurrency(currencyToDelete.id)
    setShowModal(false)
    dispatch(fetchCurrencies())
  }, [currencyToDelete])

  return (
        <div>
          <ul style={{ listStyle: 'none' }}>
            {
              filteredCurrencies?.length > 0
                ? (
                    filteredCurrencies.map((item) =>
                    <CurrenciesListItem
                      key={item.id}
                      data={item}
                      onClickAction={goToDetails}
                      onDeleteAction={openDeleteModal}/>
                    )
                  )
                : <p style={{ color: 'white', textAlign: 'center' }}>No se encontraron divisas para este país</p>
            }
            {
              showModal && (
                <Modal>
                  <main
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'rgb(0, 51, 83)'
                  }}
                  >
                    <div style={{
                      alignSelf: 'flex-end'
                    }}
                    onClick={() => setShowModal(false)}
                    >
                      <i className="fa-solid fa-xmark" style={{ color: 'rgb(0, 51, 83)' }}></i>
                    </div>
                    <div style={{
                      margin: '2% 4%'
                    }}>
                      <i className="fa-solid fa-trash-can"
                      style={{
                        color: 'rgb(0, 51, 83)',
                        fontSize: '8.5vw',
                        marginBottom: '5%'
                      }}
                      ></i>
                      <p style={{ fontSize: '4vw' }}>¿Estás seguro que deseas eliminar esta moneda?</p>
                      <p style={{
                        fontWeight: '500',
                        color: 'rgb(255, 124, 70)',
                        textTransform: 'uppercase',
                        fontSize: '4vw',
                        marginTop: '2%'
                      }}>{currencyToDelete.name} - {currencyToDelete.abbr}</p>
                      <div style={{
                        marginTop: '7%',
                        display: 'flex',
                        justifyContent: 'space-around'
                      }}>
                        <Button
                        onClick={() => setShowModal(false)}
                        style={{
                          width: '40%',
                          padding: '3.5% 2%',
                          color: 'rgb(0, 51, 83)',
                          textTransform: 'none',
                          borderRadius: '7px'
                        }}>Cancelar</Button>
                        <Button
                        onClick={() => handleCurrencyDelete()}
                        style={{
                          width: '40%',
                          color: '#FFF',
                          padding: '3.5% 2%',
                          backgroundColor: 'rgb(0, 51, 83)',
                          textTransform: 'none',
                          borderRadius: '7px'
                        }}>Aceptar</Button>
                      </div>
                    </div>
                  </main>
                </Modal>
              )
            }
          </ul>
        </div>
  )
}
