/* eslint-disable multiline-ternary */
import { useState, useEffect, useContext } from 'react'
import MainLayout from '../../layouts/main'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Typography, Button } from '@material-ui/core'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from '../../slices/users'
import { createNotification } from './../../slices/notifications'
import Listofusers from './../../components/notifications/ListOfUsers'
import _ from 'lodash'
import { TableRowUserNotifications } from './TableRowUserNotifications'
import Picker from 'emoji-picker-react'
import AppContext from './../../context/AppContext'
import Screenloading from './../../components/loaders/screenLoading'

const useStyles = makeStyles({
  container: {
    paddingTop: '3vw',
    paddingBottom: '30vw'
  },
  title: {
    color: 'white',
    fontSize: '7vw'
  },
  subtitle: {
    color: 'white',
    fontSize: '3vw',
    fontWeight: 'lighter',
    marginTop: '3vw'
  },
  containerInformationData: {
    '& h3': {
      color: 'white',
      marginTop: '10vw',
      marginBottom: '-1vw'
    }
  },
  gridInputs: {
    display: 'flex',
    flexDirection: 'column',
    '& div': {},
    '& input': {
      borderRadius: 5,
      padding: '3vw 2vw 3vw 2vw',
      width: '100%'
    },
    '& textarea': {
      borderRadius: 5,
      padding: '3vw 2vw 3vw 2vw',
      width: '90%',
      border: 'none'
    },
    '& h4': {
      color: '#ff7c46',
      fontWeight: 'bold',
      marginBottom: '2vw',
      marginTop: '4vw'
    }
  },
  table: {
    marginTop: '2vw'
  },
  button: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 60,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 0,
    '&:hover, &:focus': {
      backgroundColor: '#ff7c46'
    }
  },
  Select: {
    padding: '1vw 0vw',
    marginBottom: '1vw'
  }
})

export default function Index () {
  const classes = useStyles()

  const { emitEvent } = useContext(AppContext)

  const [information, setinformation] = useState({})
  const [selectUsers, setselectUsers] = useState([])
  const [loading, setloading] = useState(false)

  const selectUsersArray = selectUsers

  const setinformationFunction = (e) => {
    console.log(e)
    const newObject = {}
    newObject[e.target.name] = e.target.value

    setinformation({ ...information, ...newObject })
  }

  const onSubmit = async () => {
    try {
      setloading(true)

      await createNotification({
        users: selectUsers,
        ...information
      })

      setinformation({
        title: '',
        description: ''
      })

      setselectUsers([])

      emitEvent('emptysetlistUsersToSend')
      setloading(false)
    } catch (error) {
      setloading(false)
    }
  }

  return (
    <MainLayout text="Notificaciones">
      <div className={classes.container}>
      {loading && <Screenloading></Screenloading>}

        <Header classes={classes}></Header>
        <NotificationData
          setinformation={setinformationFunction}
          information={information}
        ></NotificationData>
        <TableUser
          setselectUsers={setselectUsers}
          selectUsers={selectUsers}
          selectUsersArray={selectUsersArray}
        ></TableUser>
        <Button
          className={classes.button}
          style={{
            backgroundColor:
              selectUsers.length &&
              information.title != null && information.title != '' &&
            information.description != null && information.description != ''
                ? '#ff7c46'
                : '#bab0b0'
          }}
          type="submit"
          onClick={
           selectUsers.length &&
            information.title != null && information.title != '' &&
            information.description != null && information.description != ''
             ? onSubmit
             : () => {}
          }
        >
          <Typography
            style={{
              color: '#fff',
              fontWeight: 500,
              textTransform: 'capitalize'
            }}
          >
           Enviar notificaciones
          </Typography>
        </Button>
      </div>
    </MainLayout>
  )
}

function Header (props) {
  return (
    <header>
      <h1 className={props.classes.title}>Enviar notificación</h1>
      <h3 className={props.classes.subtitle}>
        Enviar notificaciones a determinados usuarios
      </h3>
    </header>
  )
}

function NotificationData ({ setinformation, information }) {
  const classes = useStyles()
  const [chosenEmoji, setChosenEmoji] = useState(false)

  const onEmojiClick = (event, emojiObject) => {
    setinformation({
      target: { name: 'description', value: (information.description || '') + emojiObject.emoji }
    })
    setChosenEmoji(false)
  }
  return (
    <div className={classes.containerInformationData}>
      <h3>Información de la notificación</h3>
      <div className={classes.gridInputs}>
        <div>
          <h4>Título</h4>
          <InputText
            label="Título"
            value={information.title}
            setinformation={setinformation}
          />
        </div>
        <div>
          <h4>Contenido</h4>
          <div
            style={{
              display: 'flex',
              background: 'white',
              borderRadius: '5px'
            }}
          >
            <textarea
              placeholder="Contenido"
              name="description"
              value={information.description}
              onChange={setinformation}
              className={classes.textarea}
            />
            <i
              onClick={() => setChosenEmoji(!chosenEmoji)}
                style={{ color: '#ff7c46', fontSize: '6vw', marginTop: '2vw' }}
              className="far fa-laugh-beam"
            ></i>
          </div>
          {chosenEmoji && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                position: 'absolute',
                width: '94vw',
                zIndex: '1000'
              }}
            >
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function InputText ({ label, setinformation, value }) {
  const classes = useStyles()
  const [chosenEmoji, setChosenEmoji] = useState(false)

  const onEmojiClick = (event, emojiObject) => {
    setinformation({
      target: { name: 'title', value: (value || '') + emojiObject.emoji }
    })
    setChosenEmoji(false)
  }
  return (
    <>
      <div
        className={classes.input}
        style={{
          display: 'flex',
          alignItems: 'center',
          background: 'white',
          borderRadius: '5px'
        }}
      >
        <input
          type="text"
          placeholder={label}
          name="title"
          value={value}
          onChange={setinformation}
          style={{ border: 'none', width: '90%' }}
        />
        <i
          onClick={() => setChosenEmoji(!chosenEmoji)}
          style={{ color: '#ff7c46', fontSize: '6vw' }}
          className="far fa-laugh-beam"
        ></i>
      </div>
      {chosenEmoji && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            position: 'absolute',
            width: '94vw',
            zIndex: '1000'
          }}
        >
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      )}
    </>
  )
}

function TableUser ({ setselectUsers, selectUsers, selectUsersArray }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addEvent } = useContext(AppContext)

  const [allChecked, setallChecked] = useState(false)
  const allUsers = useSelector((state) => state.users.users)
  const [numberOfUsers, setnumberOfUsers] = useState(30)
  const [listUsersToSend, setlistUsersToSend] = useState([])
  const [nameToSearch, setnameToSearch] = useState('')

  const defaultParams = {
    params: { limit: numberOfUsers }
  }

  useEffect(() => {
    dispatch(fetchUsers(defaultParams))
  }, [numberOfUsers])

  useEffect(() => {
    dispatch(fetchUsers(defaultParams))
  }, [])

  const roles = [
    {
      name: 'Operadores',
      tag: 'operator',
      value: 2
    },
    {
      name: 'Mayoristas',
      tag: 'middleware',
      value: 3
    },
    {
      name: 'App Users',
      tag: 'appuser',
      value: 4
    },
    {
      name: 'Admins',
      tag: 'admin',
      value: 5
    }
  ]

  addEvent('emptysetlistUsersToSend', (e) => {
    setselectUsers([])
    setlistUsersToSend([])
  })

  const onChangeRole = async (e) => {
    setselectUsers(_.uniq([...selectUsers, ...e.map((it) => it.tag)]))
    setlistUsersToSend([..._.uniq([...listUsersToSend, ...e], 'name')])
  }
  const onChangeRoleSearch = async (e) => {
    if (e.length && e[0].value != 5) {
      const ids = e.map((rol) => rol.value).join()
      const data = {
        params: { roleId: ids }
      }
      dispatch(fetchUsers(data))
    } else {
      dispatch(fetchUsers(defaultParams))
    }
  }

  const selectUser = (e, checked) => {
    if (checked) {
      setselectUsers([...selectUsers, e.id])
      selectUsersArray.push(e.id)
      setlistUsersToSend([...listUsersToSend, e])
    } else {
      selectUsersArray.splice(selectUsers.indexOf(e.id), 1)
      setselectUsers([...selectUsersArray])
      const listOfUsersUpdated = listUsersToSend.filter(
        (item) => item.name != e.name
      )
      setlistUsersToSend([...listOfUsersUpdated])
    }
  }

  const searchByName = (e) => {
    if (e) {
      e.preventDefault()
    }
    dispatch(
      fetchUsers({
        params: { limit: numberOfUsers, query: nameToSearch }
      })
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 20,
      background: state.isSelected ? 'white' : 'white'
    }),
    control: (provied) => ({
      ...provied,
      background: 'white',
      border: 'none'
    })
  }

  const deleteItemList = (e) => {
    const listOfUsersUpdated = listUsersToSend.filter(
      (item) => item.name != e.name
    )
    setlistUsersToSend([...listOfUsersUpdated])
    if (selectUsers.find((slc) => slc == e.tag)) {
      const listToupdate = selectUsers.filter((slc) => slc != e.tag)
      setselectUsers([...listToupdate])
    } else {
      const listToupdate = selectUsers.filter((slc) => slc != e.id)
      setselectUsers([...listToupdate])
    }
  }

  return (
    <div className={classes.table}>
      <div className={classes.Select} style={{ marginTop: '0vw' }}>
        <Listofusers
          {...{ listUsersToSend, deleteItem: deleteItemList }}
        ></Listofusers>

        <h4
          style={{
            color: '#ff7c46',
            fontWeight: 'bold',
            marginBottom: '2vw'
          }}
        >
          Agregar todos los usuarios de un rol
        </h4>
        <div className="hidecss-tj5bde-Svg">
          <Select
            onChange={onChangeRole}
            styles={customStyles}
            placeholder="Seleccionar rol"
            getOptionLabel={(e) => e.name}
            options={roles}
            isMulti={true}
            value={listUsersToSend.filter((list) => list.tag)}
          />
        </div>
      </div>
      <div className={classes.Select} style={{ marginTop: '3vw' }}>
        <h4
          style={{
            color: '#ff7c46',
            fontWeight: 'bold',
            marginBottom: '2vw'
          }}
        >
          Agregar usuarios especificos
        </h4>
        <Select
          onChange={onChangeRoleSearch}
          styles={customStyles}
          placeholder="Seleccionar rol"
          getOptionLabel={(e) => e.label}
          options={[
            { label: 'Operadores', value: 2 },
            { label: 'Mayoristas', value: 3 },
            { label: 'App Users', value: 4 },
            { label: 'Todos', value: 5 }
          ]}
          isMulti={true}
        />
      </div>
      <div>
        <form onSubmit={searchByName}>
          <div
            style={{
              display: 'flex',
              background: 'white',
              justifyContent: 'space-between',
              padding: '1vw 2vw 1vw 2vw',
              borderRadius: 5,
              marginTop: '2vw',
              marginBottom: '3vw'
            }}
          >
            <input
              onChange={(e) => setnameToSearch(e.target.value)}
              type="text"
              placeholder="Nombre del usuario"
              style={{ width: '90%', border: 'none' }}
            />
            <svg
              onClick={searchByName}
              xmlns="http://www.w3.org/2000/svg"
              width="23.996"
              height="24"
              viewBox="0 0 23.996 24"
            >
              <path
                id="FontAwsome_search_"
                data-name="FontAwsome (search)"
                d="M23.67,20.75,19,16.077a1.124,1.124,0,0,0-.8-.328h-.764a9.745,9.745,0,1,0-1.687,1.687V18.2a1.124,1.124,0,0,0,.328.8L20.75,23.67a1.12,1.12,0,0,0,1.589,0l1.326-1.326A1.13,1.13,0,0,0,23.67,20.75Zm-13.921-5a6,6,0,1,1,6-6A6,6,0,0,1,9.749,15.748Z"
                fill="#FA7D4A"
              />
            </svg>
          </div>
        </form>
      </div>
      {allUsers?.data?.rows ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell size="small">
                  {' '}
                  {/* <Checkbox value={allChecked} onChange={(e) => selectAll(e)} /> */}
                </TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Rol</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUsers.data.rows.map((row) => {
                return (
                  <TableRowUserNotifications
                    key={`${row.name}-${row.id}-${row.phone}`}
                    allChecked={allChecked}
                    selectUser={selectUser}
                    listUsersToSend={listUsersToSend}
                    row={row}
                  ></TableRowUserNotifications>
                )
              })}
            </TableBody>
          </Table>

          {allUsers?.data?.rows && allUsers.data.rows.length > 29 && (
            <button
              onClick={() => setnumberOfUsers(numberOfUsers + 30)}
              style={{
                width: '96%',
                border: 'none',
                background: '#f96123',
                color: 'white',
                padding: '1.6vw 2vw',
                fontSize: '4vw',
                fontWeight: 'bold',
                borderRadius: '7px',
                margin: '4vw 2vw 3vw'
              }}
            >
              Cargar más
            </button>
          )}
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  )
}
