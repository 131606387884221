import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionHistory } from "../../../slices/transactions";
import MainLayout from "../../../layouts/main";
import TransactionCard from "../../../components/transactions/transactionCard";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

function renderTransactions(history, setShowCalendar, handleChange, expanded) {
  const items = [];

  for (const key in history) {
    if (Object.hasOwnProperty.call(history, key)) {
      const transactions = history[key];

      items.push(
        <div key={key}>
          <Typography
            style={{
              color: "#222",
              marginTop: "8px",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            <Link
              to="#"
              onClick={() => setShowCalendar(true)}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <i className="far fa-calendar-alt" key={`${key}c`}></i> {key}
            </Link>
          </Typography>
          {transactions.map((transaction) => {
            return (
              <TransactionCard
                key={transaction.id}
                expanded={expanded}
                transaction={transaction}
                handleChange={handleChange}
              />
            );
          })}
        </div>
      );
    }
  }

  return items;
}

function History() {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.transactions.transactionHistory);

  const search = async (e) => {
    e.preventDefault();
    dispatch(await getTransactionHistory(null, null, ""));
  };

  const handleGetTransactionHistory = async () => {
    dispatch(await getTransactionHistory(null, null, ""));
  };

  useEffect(() => {
    handleGetTransactionHistory();
  }, []);

  const breadcrumbs = [
    {
      text: "Inicio",
      href: "/dashboard",
    },
    {
      text: "Historial",
      href: "/history",
    },
  ];

  return (
    <MainLayout
      withoutPhoto={true}
      colorMode="#eee"
      breadcrumbs={breadcrumbs}
    ></MainLayout>
  );
}

export default History;
