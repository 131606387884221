import React from 'react'

const Screenloading = ({ label, dark }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        zIndex: 10000,
        background: dark ? '#000000EF' : '#000000bd',
        width: '100vw',
        height: '100vh',
        left: 0,
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <svg
          style={{
            WebkitAnimation: 'orbit 2s linear infinite',
            animation: 'orbit 2s linear infinite'
          }}
          id="Group_5552"
          data-name="Group 5552"
          xmlns="http://www.w3.org/2000/svg"
          width="75.08"
          height="75.081"
          viewBox="0 0 75.08 75.081"
        >
          <g id="Group_5551" data-name="Group 5551" transform="translate(0 0)">
            <path
              id="Path_3766"
              data-name="Path 3766"
              d="M637.609,682.7a6.889,6.889,0,0,1-4.252,3.262,6.669,6.669,0,0,1-5.316-.7,6.867,6.867,0,0,1-3.3-4.292,6.637,6.637,0,0,1,.7-5.314,37.4,37.4,0,0,0-7.992-47.142,35.294,35.294,0,0,1,6.453,2.932,37.556,37.556,0,0,1,13.71,51.25Z"
              transform="translate(-567.588 -626.388)"
              fill="#fff"
            />
            <path
              id="Path_3767"
              data-name="Path 3767"
              d="M539.578,776.774a37.54,37.54,0,0,1-51.25-13.71,7.011,7.011,0,0,1,2.5-9.593l.023-.013a6.74,6.74,0,0,1,5.352-.7,6.908,6.908,0,0,1,4.292,3.264A36.918,36.918,0,0,0,509,766.143a36.021,36.021,0,0,0,11.072,6.342,40.879,40.879,0,0,0,12.5,2.273,33.657,33.657,0,0,0,12.72-2.092A32.489,32.489,0,0,1,539.578,776.774Z"
              transform="translate(-483.267 -706.752)"
              fill="#fff"
              opacity="0.6"
            />
            <path
              id="Path_3768"
              data-name="Path 3768"
              d="M513.258,636.545a37.474,37.474,0,0,0-36.881,30.5,38.983,38.983,0,0,1-.659-7.039,37.592,37.592,0,0,1,37.54-37.54,7.039,7.039,0,1,1,0,14.078Z"
              transform="translate(-475.718 -622.466)"
              fill="#fff"
              opacity="0.3"
            />
          </g>
        </svg>
        <h3
          style={{
            color: 'white',
            marginTop: '6vw'
          }}
        >
          {label || 'Cargando'}
        </h3>
      </div>
    </div>
  )
}

export default Screenloading
