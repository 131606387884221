const labels = {
  TransactionsInMantenance: 'Transacciones en mantenimieinto',
  PaymentsInMantenance: 'Pagos en mantenimieinto',
  TelegramCronJobOperator: 'Frecuencia de mensajes a operador',
  MaxNotVerifiedAppUserBalance: 'Balance máximo de AppUsers no verificados',
  MaxAppUserBalance: 'Balance máximo de AppUsers',
  MaxNotVerifiedMiddlewareBalance: 'Balance máximo de mayoristas no verificados',
  MaxMiddlewareBalance: 'Balance máximo de mayoristas',
  MaximumRateVariationPercentage: 'Variación máxima de tasa',
  WelcomeText: 'Texto de bienvenida',
  DolarBlue: 'Dolar blue',
  DolarChile: 'Dolar Chíle',
  CostRate: 'Tasa costo'
}

export default labels
