import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const fetchRates = createAsyncThunk(
  'rate',
  async (currencyId = null) => {
    if (currencyId) {
      return Axios.get(process.env.REACT_APP_API_URL + `rate?currencyId=${currencyId}`)
        .then((response) => response.data.data)
    }
    return Axios.get(process.env.REACT_APP_API_URL + 'rate')
      .then((response) => response.data.data)
  }
)

export const updateRate = createAsyncThunk(
  'updateRate',
  async (data) => {
    await Axios.put(process.env.REACT_APP_API_URL + 'rate/update/' + data.id, data).then((response) => response.data)
  }
)

export const createRate = createAsyncThunk(
  'createRate',
  async (data) => {
    const response = await Axios.post(process.env.REACT_APP_API_URL + 'rate/store', data)
    return response.data
  }
)

export const ratesSlice = createSlice({
  name: 'rates',
  initialState: {
    rows: [],
    status: 'idle'
  },
  extraReducers: {
    [fetchRates.pending]: (state, action) => {
      state.status = 'pending'
    },
    [fetchRates.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.rows = action.payload
    }
  }
})

export default ratesSlice.reducer
