import { createSlice } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const BssaccountsSlice = createSlice({
  name: 'Bssaccounts',
  initialState: {
    bssaccounts: []
  },
  reducers: {
    setBssaccounts: (state, action) => {
      state.bssaccounts = action.payload
    }
  }
})

const { setBssaccounts } = BssaccountsSlice.actions

export const fetchBssaccounts = (search) => async dispatch => {
  try {
    // console.log('search', search || '')
    await Axios.get(process.env.REACT_APP_API_URL + 'bssaccount' + (search || ''))
      .then((response) => {
        dispatch(setBssaccounts(response.data))
        return response.data
      })
  } catch (e) {
    return console.error(e.message)
  }
}
export const updateBssaccounts = async (dataForm, id) => {
  try {
    const { data } = await Axios.put(process.env.REACT_APP_API_URL + 'bssaccount/update/' + id, dataForm)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const deleteBssaccounts = async (id) => {
  try {
    const { data } = await Axios.delete(process.env.REACT_APP_API_URL + 'bssaccount/delete/' + id)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const createBssaccounts = async (dataForm) => {
  try {
    const { data } = await Axios.post(process.env.REACT_APP_API_URL + 'bssaccount/store', dataForm)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const createBssaccountsAndUserBank = async (dataForm) => {
  try {
    const { data } = await Axios.post(process.env.REACT_APP_API_URL + 'userbank/store-full', dataForm)
    return data
  } catch (e) {
    return console.error(e.message)
  }
}

export const createClientFunct = async (name, iduser, phone) => {
  try {
    const { data } = await Axios.post(process.env.REACT_APP_API_URL + 'client/store', {
      name: name,
      userId: iduser,
      phone: phone
    })
    return data
  } catch (e) {
    return console.error(e.message)
  }
}

export default BssaccountsSlice.reducer
