import React from 'react'
import { Input, FormControl, InputLabel, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { v4 } from 'uuid'
import { TextValidator } from 'react-material-ui-form-validator'
import FormHelperText from '@material-ui/core/FormHelperText'
import { isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  label: {
    position: 'relative',
    top: '20px',
    left: '33px'
  },
  icon: {
    color: '#666',
    position: 'relative',
    top: '-7px'
  }
}))

const Index = ({ label, icon, type, onBlur, setValue, placeholder, validation = [], onFocus }) => {
  const classes = useStyles()
  const uniqueId = v4()
  return (
      <>
        <FormControl className = {classes.margin}>
          <InputLabel htmlFor = { uniqueId } className = {classes.label}>{label}</InputLabel>
          <Input
            id={ uniqueId }
            onFocus={onFocus}
            onBlur={onBlur}
            startAdornment = {
              <InputAdornment position = "start">
                { icon }
              </InputAdornment>
            }
            placeholder = { placeholder }
            onInput = {e => setValue(e.target.value)}
            type = { type }
            autoComplete = 'off'
          />
          {
            !isEmpty(validation) &&
            <FormHelperText style={{ color: 'red' }}>{ validation.join(',') }</FormHelperText>
          }
        </FormControl>
      </>
  )
}

export default Index
