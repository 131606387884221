import React, { useState, useEffect } from 'react'
import Layout from '../../layouts/main'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@mui/material/Switch'
import { useDispatch, useSelector } from 'react-redux'
import { createConfig, fetchConfigs } from './../../slices/configs'

import { Config } from './Config/index'
import { Typography, Button } from '@material-ui/core'
import Screenloading from './../loaders/screenLoading'
import Swal from 'sweetalert2'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    background: '#ff7c46',
    padding: '2vw 2vw',
    justifyContent: 'space-between',
    borderRadius: 5,
    marginBottom: '3vw',
    marginTop: '3vw'
  },
  button: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 60,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 0,
    '&:hover, &:focus': {
      backgroundColor: '#ff7c46'
    }
  }
})

function AccountMaintenance (props) {
  return (
    <div className={props.grid}>
      <h4 style={{ color: 'white' }}>Cuenta en mantenimiento</h4>
      <Switch
        inputProps={{
          'aria-label': 'ant design'
        }}
      />
    </div>
  )
}

export default function Index () {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { configs } = useSelector((state) => state.configs)
  const [configToCreate, setconfigToCreate] = useState({})
  const [loading, setloading] = useState(true)
  const [test, setteste] = useState(false)

  useEffect(() => {
    dispatch(fetchConfigs()).then(() => {
      setloading(false)
    })
  }, [])

  const onSubmit = async () => {
    const fields = Object.values(configToCreate)

    const mapFields = fields.map(async (field) => {
      await dispatch(createConfig(field))
    })

    await Promise.all(mapFields).then((e) => {
      console.log('wait Promise.all(mapField')
      Swal.fire({
        text: 'Configuraciones actualizadas!',
        icon: 'success',
        customClass: {
          confirmButton: 'swal2-blue-confirm-button'
        }
      })
      fetchConfigs().then(() => {
        setloading(false)
      })
    })
  }

  const handleEdit = (e) => {
    const newE = { ...e.config, value: e.value }
    const object = configToCreate

    object[e.config.key] = newE

    setconfigToCreate(object)
    setteste(!test)
  }

  return (
    <Layout text="Ajustes generales">
      {loading && <Screenloading></Screenloading>}

      <AccountMaintenance grid={classes.grid}></AccountMaintenance>

      {configs.map((config) => (
        <Config key={JSON.stringify(config)} {...{ config, handleEdit }} />
      ))}

      <div style={{ marginTop: '20vh' }}></div>

      <Button
        className={classes.button}
        style={{
          background: Object.values(configToCreate).length ? '#ff7c46' : 'gray'
        }}
        type="submit"
        onClick={Object.values(configToCreate).length ? onSubmit : () => {}}
      >
        <Typography
          style={{
            color: '#fff',
            fontWeight: 500,
            textTransform: 'capitalize'
          }}
        >
          Aceptar
        </Typography>
      </Button>
    </Layout>
  )
}
