import React from "react";
import MainLayout from "../../layouts/main";
import Screenloading from "./../loaders/screenLoading";
import { Fab } from "react-tiny-fab";
import { Link, useHistory } from "react-router-dom";
import { Swal } from "sweetalert2";
import "./contact.scss";
import InputSearch from "./../form/searchField/index";

function ContactItem({ data, deleteAccount }) {
  const history = useHistory();

  return (
    <Link
      to={{
        pathname: "/CreateTransference",
        state: { user: data },
      }}
      style={{
        color: "#00304A",
        textDecoration: "none",
      }}
    >
      <div
        style={{
          background: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2vw 3vw",
          borderRadius: "10px",
          marginBottom: "4vw",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <img style={{ width: "12vw" }} src={data.bank.Logo} />
          <h4
            style={{
              fontSize: "4vw",
              padding: "2vw 0px",
              paddingLeft: "2vw",
              fontWeight: "initial",

              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
          >
            <span>{data.name}</span>
          </h4>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div
            onClick={() => {
              history.push({
                pathname: "/contacts-transferences/edit",
                state: { user: data },
              });
            }}
          >
            <i
              style={{
                color: "#f96123",
                fontSize: "4vw",
                marginLeft: "2vw",
              }}
              className="fa-solid fa-pen-to-square"
            ></i>
          </div>
          <div
            onClick={() => {
              deleteAccount(data.id);
            }}
          >
            <i
              style={{
                color: "rgb(0 51 83)",
                fontSize: "4vw",
                marginLeft: "2vw",
              }}
              className="fa-solid fa-trash"
            ></i>
          </div>
        </div>
      </div>
    </Link>
  );
}

function Index({
  loading,
  bssaccounts,
  deleteAccount,
  createdNow,
  setWordToSearch,
  search,
}) {
  const history = useHistory();
  return (
    <MainLayout goBack={true} text="Cuentas" skipPadding>
      {loading && <Screenloading></Screenloading>}
      <div className="main-div-contacts">
        <div style={{ marginBottom: "4vw" }}>
          <InputSearch onSubmit={search} onChange={setWordToSearch} />
        </div>

        {createdNow && (
          <div
            style={{
              marginBottom: "6vw",
            }}
          >
            <h3
              style={{
                marginBottom: "5vw",
                color: "#ff7c46",
                fontSize: "5vw",
                marginTop: "1vw",
              }}
            >
              Recientemente creada:
            </h3>
            <ContactItem
              key={createdNow.id}
              deleteAccount={deleteAccount}
              data={createdNow}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5vw",
            marginTop: "3vw",
          }}
        >
          <h3
            style={{
              color: "white",
              fontWeight: "300",
              fontSize: "4vw",
            }}
          >
            Selecciona una cuenta a transferir
          </h3>
          <Link
            style={{
              color: "#ff7c46",
              padding: "0vw 2vw",
              fontStyle: "italic",
              textDecoration: "underline",
            }}
            to="/transactions-history"
          >
            Ver más
          </Link>
        </div>
        {bssaccounts?.data &&
          bssaccounts?.data.rows.map((e) => (
            <ContactItem key={e.id} deleteAccount={deleteAccount} data={e} />
          ))}

        <Fab
          icon={<i style={{ fontSize: "7vw" }} className="fas fa-plus"></i>}
          alwaysShowTitle={true}
          mainButtonStyles={{
            background: "#f96123",
            right: "-6vw",
            bottom: "0",
            height: "6vh",
            width: "6vh",
          }}
          onClick={() => history.push("/contacts-transferences/new")}
        ></Fab>
      </div>
    </MainLayout>
  );
}

export default Index;
