export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #c7c6c6',
    color: 'black',
    background: 'white',
    padding: 20
  }),
  menu: base => ({
    ...base,
    zIndex: 100
  })

}
