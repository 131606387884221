import { useCallback, useState, useEffect, useContext } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'

// Components
import MainLayout from '../../layouts/main'
import { Fab } from 'react-tiny-fab'
import { CurrenciesList } from '../../components/listings'
import Modal from '../../components/modal'
import CountrySelect from '../../components/country-select'
import Screenloading from '../../components/loaders/screenLoading'

// Slices
import { getAllCountries } from '../../slices/countries'

// Context
import { CurrenciesContext } from '../../context/CurrenciesContext'
import { useDispatch } from 'react-redux'
import { fetchCurrencies } from '../../slices/currencies'

// Styles
const useStyles = makeStyles({
  mainContainer: {
    minHeight: '90vh',
    paddingTop: '2.5%',
    padding: '0 3.5% 0'
  },
  screenTitle: {
    color: 'white',
    margin: '2% 2% 5.5%',
    fontSize: '4vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

// ScreenHeading

const ScreenHeading = () => {
  // Custom styles
  const classes = useStyles()
  const { setSelectedCountryId, selectedCountryId } = useContext(CurrenciesContext)

  const [showModal, setShowModal] = useState(false)
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)

  useEffect(() => {
    if (countries.length === 0) {
      getAllCountries().then((result) => {
        setCountries(result)
      })
    }
    const result = countries.find((el) => el.id === selectedCountryId)
    setSelectedCountry(result)
  }, [selectedCountryId, countries])

  const handleSubmit = (values) => {
    setSelectedCountryId(values.country.id)
    setShowModal(false)
  }

  return (
        <>
        <div className={classes.screenTitle}>
          <div>
            <span style={{
              fontWeight: '300',
              marginLeft: '2%'
            }}
              >
                País:
            </span>
            <span style={{
              fontWeight: '400',
              marginLeft: '4%'
            }}>
                {selectedCountry?.name || 'Chile'}
            </span>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 512 512"
                onClick={() => setShowModal(true)}
                >
                <path
                    id="FontAwsome_sliders_"
                    data-name="FontAwsome (sliders)"
                    d="M0 416c0-17.7 14.3-32 32-32l54.7 0c12.3-28.3 40.5-48 73.3-48s61 19.7 73.3 48L480 384c17.7 0 32 14.3 32 32s-14.3 32-32 32l-246.7 0c-12.3 28.3-40.5 48-73.3 48s-61-19.7-73.3-48L32 448c-17.7 0-32-14.3-32-32zm192 0c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM384 256c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zm-32-80c32.8 0 61 19.7 73.3 48l54.7 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-54.7 0c-12.3 28.3-40.5 48-73.3 48s-61-19.7-73.3-48L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l246.7 0c12.3-28.3 40.5-48 73.3-48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32s-14.3-32-32-32zm73.3 0L480 64c17.7 0 32 14.3 32 32s-14.3 32-32 32l-214.7 0c-12.3 28.3-40.5 48-73.3 48s-61-19.7-73.3-48L32 128C14.3 128 0 113.7 0 96S14.3 64 32 64l86.7 0C131 35.7 159.2 16 192 16s61 19.7 73.3 48z"
                    fill="#f96123d9"
                />
            </svg>
        </div>
        {
          showModal && (
            <Modal>
              <main
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'rgb(0, 51, 83)'
                  }}
                  >
                    <div style={{
                      alignSelf: 'flex-end'
                    }}
                    onClick={() => setShowModal(false)}
                    >
                      <i className="fa-solid fa-xmark" style={{ color: 'rgb(0, 51, 83)' }}></i>
                    </div>
                    <div style={{
                      margin: '0 4%'
                    }}>
                      <h3 style={{
                        marginBottom: '8%'
                      }}>Filtrar monedas por país</h3>
                    <Formik
                      onSubmit={(values) => handleSubmit(values)}
                      enableReinitialize
                      initialValues={{
                        country: {
                          name: selectedCountry.name || 'Chile',
                          image: selectedCountry.image || 'https://flagcdn.com/w160/cl.png',
                          id: selectedCountry.id || 1
                        }
                      }}>
                        <Form>
                          <CountrySelect countries={countries} name='country'/>
                          <div style={{
                            marginTop: '7%',
                            display: 'flex',
                            justifyContent: 'space-around'
                          }}>
                          <Button
                            onClick={() => setShowModal(false)}
                            style={{
                              width: '40%',
                              padding: '3.5% 2%',
                              color: 'rgb(0, 51, 83)',
                              textTransform: 'none',
                              borderRadius: '7px'
                            }}>Cancelar</Button>
                            <Button
                            type="submit"
                            style={{
                              width: '40%',
                              color: '#FFF',
                              padding: '3.5% 2%',
                              backgroundColor: 'rgb(0, 51, 83)',
                              textTransform: 'none',
                              borderRadius: '7px'
                            }}>Guardar</Button>
                        </div>
                        </Form>
                      </Formik>
                    </div>
              </main>
            </Modal>
          )
        }
        </>
  )
}

const CurrenciesScreen = () => {
  const [selectedCountryId, setSelectedCountryId] = useState(1)

  const dispatch = useDispatch()
  // Custom styles
  const classes = useStyles()

  // React router history
  const history = useHistory()

  const goToDetails = useCallback((id) => {
    history.push(`/currencies/details/${id}`)
  }, [])

  const goToCreate = useCallback(() => {
    history.push('/currencies/create')
  }, [])

  useEffect(() => {
    dispatch(fetchCurrencies())
  }, [])

  return (
        <MainLayout text="Monedas" skipPadding={true}>
            <main className={classes.mainContainer}>
                <CurrenciesContext.Provider
                  value={{
                    selectedCountryId,
                    setSelectedCountryId
                  }}>
                  <ScreenHeading/>
                  <CurrenciesList goToDetails={goToDetails}/>
                </CurrenciesContext.Provider>
                <Fab
                  icon={<i style={{ fontSize: '6vw' }} className="fas fa-plus"></i>}
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    background: '#f96123',
                    right: '-6vw',
                    bottom: '0',
                    height: '7vh',
                    width: '7vh'
                  }}
                  onClick={() => goToCreate()}
                ></Fab>
            </main>
        </MainLayout>
  )
}

export default CurrenciesScreen
