import React from "react";
import { useHistory } from "react-router-dom";

function SectionLayoutReturnHeader({ text }: { text: string }) {
  const history = useHistory();

  return (
    <div className="section_layout_return_header">
      <div className="row">
        <i
          onClick={() => history.goBack()}
          style={{ fontSize: "8vw", marginRight: "3vw" }}
          className="fas fa-chevron-left"
        ></i>
        <span>Volver</span>
      </div>
      <h5>{text}</h5>
    </div>
  );
}

export default SectionLayoutReturnHeader;
