import axios from "axios";
import { validateResponse } from "./index";
const axiosApiInstance = axios.create();

export default function setAuthToken(token, user) {
  user = user ? JSON.parse(user)?.id : "";

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common["user-id"] = user;
  axiosApiInstance.defaults.headers.common.Authorization = "Bearer " + token;
  axiosApiInstance.defaults.headers.common["user-id"] = user;
}

export function axiosConfigInit() {
  axios.defaults.headers.common.requestFrom = "web";
}

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response.data && error.response.data.code) {
      if (
        error.response.data.code == 6 ||
        error.response.data.code == 51 ||
        error.response.data.code == 50
      ) {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        window.location.href = "/logout";
      }
      if (
        (error.response.data.code == 6 && !originalRequest._retry) ||
        (error.response.data.code == 49 && !originalRequest._retry)
      ) {
        originalRequest._retry = true;
        let access_token = "";

        access_token = await refreshAccessToken();

        axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        axiosApiInstance.defaults.headers.common.Authorization =
          "Bearer " + access_token;

        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: "Bearer " + access_token,
        };

        return axiosApiInstance(originalRequest);
      } else {
        validateResponse(error.response.data);
      }
    }

    return error.response;
  }
);

export const Axios = axiosApiInstance;

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (refreshToken == null) window.location.href = "/logout";

  const { data } = await axiosApiInstance.post(
    process.env.REACT_APP_API_URL + "auth/refresh",
    {},
    {
      headers: {
        "refresh-token": "Bearer " + refreshToken,
      },
    }
  );

  console.log("refreshAccessToken", data);

  localStorage.setItem("refreshToken", data.data.refreshToken);
  localStorage.setItem("accessToken", data.data.accessToken);
  return data.data.accessToken;
};
