import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  containerfoote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: '0',
    marginLeft: '-3vw',

    background: 'white',
    width: '100vw',
    '& a': {
      textDecoration: 'none'
    },
    '@media (min-width: 700px)': {
      padding: '0.6vw'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '3vw 5vw',
    color: '#F96123',
    '& i': {
      fontSize: '7vw',
      '@media (min-width: 700px)': {
        fontSize: '1.9vw'
      }
    },
    '@media (min-width: 700px)': {
      padding: '0.4vw'
    }
  },
  active: {
    background: '#f3f3f3'
  }
})

export default function Menu ({ items }) {
  const classes = useStyles()
  const location = useLocation()
  // console.log(location.pathname)

  return (
    <footer className={classes.containerfoote}>
      {items.map((item) => (
        <Link key={item.label} to={{ pathname: item.path }}>
          <div
            className={`${classes.item} ${
              item.path == location.pathname ? classes.active : ''
            }`}
          >
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </div>
        </Link>
      ))}
    </footer>
  )
}
