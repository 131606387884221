import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createRate, fetchRates } from "../../slices/rates";
import { useDispatch } from "react-redux";

import ilustration from "../../assets/Tasacambiada.svg";
import { GenerateRateContext } from "../../context/GenerateRateContext";
import useProfit from "../../hooks/useProfit";
import useVariationPercentage from "../../hooks/useVariationPercentage";

const useStyles = makeStyles({
  inputNumber: {
    color: "black",
    width: "68%",
    border: "1px solid #b8b8b8",
    padding: "1vw 2vw 1vw 2vw",
    fontSize: "5vw",
    fontWeight: "lighter",
    borderRadius: "2px",
    textAlign: "center",
    marginTop: "4vw",
    "&:focus": {
      outline: "none",
    },
  },
});

function LabelRate(props) {
  const { generatedRates, rate } = props;
  const { profit } = useProfit(generatedRates?.costRate, rate.rate);

  return (
    <div
      className="rateId handInHover"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span style={{ textTransform: "capitalize", fontSize: "4vw" }}>
        {props.name}
      </span>
      <div
        style={{
          paddingLeft: "2vw",
          borderLeft: "1px solid #ffffff63",
          marginLeft: "2vw",
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
          }}
        >{`${profit}%`}</p>
        <p
          style={{
            fontWeight: "300",
            fontSize: "3vw",
            color: "#ffffffa8",
          }}
        >
          Ganancia
        </p>
      </div>
    </div>
  );
}

const SuggestedRateAndProfit = ({
  rate,
  generatedRates,
  disableChange,
  profit,
  currencyIsColombia,
}) => {
  if (rate.rangeId == 2) return;

  const profitIsPositive = currencyIsColombia || profit >= 0;

  return (
    <h4 className="suggested-amount">
      <p>
        La tasa sugerida es:{" "}
        <span
          style={{
            fontWeight: "bold",
            color: "#ff7c46",
          }}
        >
          {rate.rangeId == 1
            ? generatedRates
              ? Number(generatedRates.publicRate).toFixed(4)
              : null
            : generatedRates
            ? Number(generatedRates.personalRate).toFixed(4)
            : null}
        </span>
      </p>
      {!disableChange && (
        <p className={"profit-" + profitIsPositive}>
          {profitIsPositive ? "Ganancia" : "Perdida"}:{" "}
          {currencyIsColombia ? profit * -1 : profit}%
        </p>
      )}
    </h4>
  );
};

export const Rate = (props) => {
  const { rangeName, expand, variation, rate, rateValue } = props;

  const { generatedRates, currencyId } = useContext(GenerateRateContext);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [amountToChange, setAmountToChange] = useState(rate.rate);

  const { profit } = useProfit(
    props.costRate,
    amountToChange || rate.rate,
    props.currencyIsColombia
  );
  const { percentages, percentagesNumber } = useVariationPercentage(
    rate,
    variation
  );

  const [error, seterror] = useState(false);
  const [openModalSuccess, setopenModalSuccess] = useState(false);

  const createRateFunct = () => {
    const data = {
      rangeId: rate.rangeId,
      rate: amountToChange,
      currencyId,
    };

    dispatch(createRate(data, dispatch)).then((response) => {
      setOpenModal(false);
      if (response.payload.code == 56) {
        cancelChange();
        return;
      }
      setopenModalSuccess(true);
    });
  };

  const changeAmount = (e) => {
    console.table({
      normal: e.target.value,
      filter: e.target.value.replace(",", ""),
    });
    const value = e.target.value.replace(",", "");
    setAmountToChange(value);
    const float = parseFloat(value);

    if (
      float < percentagesNumber.percentageMinus ||
      float > percentagesNumber.percentagePlus
    ) {
      seterror(true);
    } else {
      seterror(false);
    }
  };

  const cancelChange = () => {
    setOpenModal(false);
    setAmountToChange(rate.rate);
  };

  const openModalConfirm = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    setAmountToChange(rate.rate);
  }, [rate.rate]);

  useEffect(() => {
    setAmountToChange(rateValue);
  }, [rateValue]);

  const disableChange = amountToChange?.length == 0 || amountToChange == "0";

  return (
    <div className="rate-day-container">
      <div className="input">
        <input
          value={amountToChange}
          type="number"
          inputMode="numeric"
          onChange={changeAmount}
          disabled={props.rangeName === "David"}
        />
        {props.name != "David" ? (
          <button
            style={{
              background: disableChange ? "#b8b8b8" : null,
              backgroundColor: props.rangeName === "David" ? "#fff" : "#003353",
              height: "6.5vh",
            }}
            disabled={disableChange || props.rangeName === "David"}
            onClick={openModalConfirm}
          >
            Actualizar
          </button>
        ) : (
          <div
            style={{
              height: "6.5vh",
            }}
          />
        )}
        <p>{props.name}</p>
      </div>

      <SuggestedRateAndProfit
        rate={rate}
        disableChange={disableChange}
        profit={profit}
        currencyIsColombia={props.currencyIsColombia}
        generatedRates={generatedRates}
      />

      {expand && openModal && (
        <div className="modalRateContainer">
          <article className="modalRate" style={{ borderRadius: "22px" }}>
            <span
              onClick={() => setOpenModal(false)}
              className="closeModal"
              style={{ color: "#ff5b28", fontSize: "6vw" }}
            >
              <i className="fas fa-times-circle"></i>
            </span>
            <div>
              <h2
                style={{
                  color: "#003f62",
                  fontWeight: "bold",
                  fontSize: "4vw",
                  textAlign: "center",
                  paddingLeft: "0",
                  marginBottom: "0",
                  marginTop: "7vw",
                }}
              >
                Tasa{" "}
                <span style={{ color: "#ff5b28", marginLeft: "0" }}>
                  {rangeName}
                </span>{" "}
                actual
              </h2>
            </div>
            <h5
              style={{
                marginBottom: "1vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#003f62",
                  fontWeight: "800",
                  fontSize: "10vw",
                }}
              >
                {isNaN(rate.rate) ? "0.00" : rate.rate}
              </span>{" "}
              <span>La nueva sera: {amountToChange}</span>
            </h5>

            <div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "3vw",
                  color: "black",
                  fontWeight: "bold",
                  marginBottom: "-2vw",
                }}
              >
                Ganancia <span style={{ color: "#ff5b28" }}>{profit} %</span>
              </p>
            </div>

            <p
              style={{
                color: error ? "#8e1313" : "#ff5722",
                marginTop: "5vw",
                fontWeight: "bold",
                marginBottom: "7vw",
                textAlign: "center",
              }}
            >
              Solo puede variar {variation}% <br /> (
              {percentages.percentageMinus}, {percentages.percentagePlus})
            </p>

            <div className="containerButtons">
              <button onClick={cancelChange} className="b1">
                Cancelar
              </button>
              <button
                onClick={error ? null : createRateFunct}
                className="b2"
                style={{
                  background: error ? "#efefef" : "#003f62",
                  color: error ? "black" : "white",
                }}
              >
                Aceptar
              </button>
            </div>
          </article>
          <div></div>
        </div>
      )}

      {openModalSuccess && (
        <div className="modalRateContainer">
          <article
            className="modalRate"
            style={{ borderRadius: "22px", textAlign: "center" }}
          >
            <span
              onClick={() => {
                // fetchrates()
                setopenModalSuccess(false);
              }}
              className="closeModal"
              style={{ color: "#ff5b28", fontSize: "6vw" }}
            >
              <i className="fas fa-times-circle"></i>
            </span>

            <img src={ilustration} />

            <p
              style={{
                color: error ? "#8e1313" : "#ff5722",
                marginTop: "5vw",
                fontWeight: "bold",
                marginBottom: "1vw",
                textAlign: "center",
              }}
            >
              Se ha cambiado el valor de la <b>tasa actual</b>
            </p>

            <h5
              style={{
                marginBottom: "2vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#003f62",
                  fontWeight: "800",
                  fontSize: "10vw",
                }}
              >
                {amountToChange}
              </span>
            </h5>

            <p
              style={{
                color: "#686868",
                fontSize: "3vw",
                textAlign: "center",
                marginBottom: "4vw",
                fontStyle: "italic",
                fontWeight: "lighter",
              }}
            >
              Estará disponible en el próximo minúto 0
            </p>

            <div className="containerButtons">
              <button
                onClick={() => {
                  setopenModalSuccess(false);
                }}
                className="b2"
                style={{
                  background: "#003f62",
                  color: "white",
                  width: "47vw",
                  fontSize: "5vw",
                }}
              >
                ok
              </button>
            </div>
          </article>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default Rate;
