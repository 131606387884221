import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { formatNumber } from './../../../helpers/numbers'

const useStyles = makeStyles(() => ({
  container: {
    padding: '3vw 5vw 3vw 2vw',
    margin: '3vw 0 3vw 0',
    display: 'grid',
    gridTemplateColumns: '20% 75% 5%',
    alignItems: 'center',
    borderRadius: 10,
    border: '2px solid #D2D2D2'
  }
}))

export default function Index ({ data }) {
  const history = useHistory()
  const classes = useStyles()
  return (
    <div
      className={classes.container}
      onClick={() =>
        history.push({
          pathname: '/operator-account',
          state: {
            bssBalance: data
          }
        })
      }
    >
      <div style={{ marginRight: '4vw' }}>
          <img src={data.userBank.bank.Logo} style={{ width: '100%' }} />
      </div>

      <div>
        <h4 style={{ color: '#FA7D4A', fontWeight: 'bold' }}>
          {data.userBank.name}
        </h4>
        <h5 style={{ color: '#797979', fontWeight: 'bold' }}>
          {formatNumber(data.total)} {data.currency ? data.currency.abbr : 'BS'}
        </h5>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.565"
        height="24"
        viewBox="0 0 14.565 24"
      >
        <path
          id="FontAwsome_chevron-left_"
          data-name="FontAwsome (chevron-left)"
          d="M27.879,48.73,38.56,38.049a1.319,1.319,0,0,1,1.865,0l1.246,1.246a1.319,1.319,0,0,1,0,1.863l-8.465,8.5,8.465,8.5a1.319,1.319,0,0,1,0,1.863l-1.246,1.246a1.319,1.319,0,0,1-1.865,0L27.879,50.595A1.319,1.319,0,0,1,27.879,48.73Z"
          transform="translate(42.058 61.663) rotate(-180)"
          fill="#FA7D4A"
        />
      </svg>
    </div>
  )
}
