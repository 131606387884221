import React from 'react'
import { formatDate } from '../../../../helpers/dates'
import MainLayout from '../../../../layouts/main'
import { formatNumber } from '../../../../helpers/numbers'

function PaymentItem () {
  return (
    <article
      style={{
        background: 'rgb(249 97 35 / 66%)',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '20% 54% auto',
        padding: '3vw 2vw',
        borderRadius: '5px',
        marginBottom: '3vw'

      }}
    >
      <div
        style={{
          width: '4vw',
          height: '4vw',
          background: 'white',
          marginLeft: '2vw'
        }}
      ></div>
      <div style={{ color: 'white' }}>{formatNumber(10000)} BS</div>
      <div style={{ color: 'white' }}>{formatDate('2022-03-10T20:07:58.000Z')}</div>
    </article>
  )
}

export default function PaymentsContainer () {
  return (
    <div>
      <h3
        style={{
          marginTop: '5vw',
          marginBottom: '3vw',
          color: '#f96123'
        }}
      >
        Pagos realizadas:
      </h3>
      <h4 style={{
        background: 'white',
        marginBottom: '4vw',
        padding: '1vw',
        borderRadius: '2px',
        paddingLeft: '2vw',
        fontWeight: '400'
      }}>Pagos realizados: <b>120 (100, 80%)</b></h4>

      <PaymentItem></PaymentItem>
      <PaymentItem></PaymentItem>
      <PaymentItem></PaymentItem>
      <PaymentItem></PaymentItem>
    </div>
  )
}
