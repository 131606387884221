import React, { useEffect } from 'react'
import BottomNavigator from '../../layouts/bottomBar'
import Item from './balance_item/index'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { getBankBalancesByUser } from './../../slices/bssbalance'
import { useHistory } from 'react-router-dom'
import { Fab } from 'react-tiny-fab'

const useStyles = makeStyles(() => ({
  container: {
    padding: '9vw 5vw 14vh 5vw',
    '& header': {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '35% 50%'
    }
  }
}))

function Header (props) {
  return (
    <header>
      <div onClick={props.goBack}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14.565"
          height="24"
          viewBox="0 0 14.565 24"
        >
          <path
            id="FontAwsome_chevron-left_"
            data-name="FontAwsome (chevron-left)"
            d="M27.879,48.73,38.56,38.049a1.319,1.319,0,0,1,1.865,0l1.246,1.246a1.319,1.319,0,0,1,0,1.863l-8.465,8.5,8.465,8.5a1.319,1.319,0,0,1,0,1.863l-1.246,1.246a1.319,1.319,0,0,1-1.865,0L27.879,50.595A1.319,1.319,0,0,1,27.879,48.73Z"
            transform="translate(-27.493 -37.662)"
            fill="#FA7D4A"
          />
        </svg>
      </div>
      <h1
        style={{
          color: '#FA7D4A',
          fontSize: '8vw'
        }}
      >
        Saldos
      </h1>
    </header>
  )
}

export default function Index () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userBanks = useSelector((state) => state.bssbalance.rows)
  const history = useHistory()

  useEffect(() => {
    dispatch(getBankBalancesByUser(dispatch))
  }, [])

  return (
    <div className={classes.container}>
      <Header goBack={() => history.push('/operator-dashboard')}></Header>

      <div
        style={{ textAlign: 'center', marginTop: '10vw', marginBottom: '8vw' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="103.327"
          height="58.324"
          viewBox="0 0 133.327 88.324"
        >
          <g
            id="Group_5657"
            data-name="Group 5657"
            transform="translate(-75.573 -166.971)"
          >
            <g
              id="Group_5590"
              data-name="Group 5590"
              transform="translate(-955.598 -3087.426)"
            >
              <g
                id="Group_634"
                data-name="Group 634"
                transform="translate(1031.171 3254.397)"
              >
                <g
                  id="Group_628"
                  data-name="Group 628"
                  transform="translate(57.323 0)"
                >
                  <g
                    id="Group_506"
                    data-name="Group 506"
                    transform="translate(21.732 21.769)"
                  >
                    <path
                      id="Path_1036"
                      data-name="Path 1036"
                      d="M1667.367,2831.265a.928.928,0,0,1-.89-.667l-4.874-16.616-4.834,7.386a.927.927,0,1,1-1.552-1.016l5.934-9.066a.928.928,0,0,1,1.667.248l4.8,16.379,7.562-14.024a.926.926,0,0,1,1.559-.115l5.58,7.46,9.172-20.207a.927.927,0,0,1,1.715.065l6.846,18.736,7.567-12.149a.927.927,0,1,1,1.574.98l-8.573,13.764a.927.927,0,0,1-1.658-.172l-6.72-18.392-8.883,19.57a.926.926,0,0,1-1.586.172l-5.65-7.552-7.944,14.731A.927.927,0,0,1,1667.367,2831.265Z"
                      transform="translate(-1655.066 -2800.483)"
                      fill="#e6e6e6"
                    />
                  </g>
                  <g
                    id="Group_510"
                    data-name="Group 510"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Group_507"
                      data-name="Group 507"
                      transform="translate(0.684)"
                    >
                      <path
                        id="Path_1037"
                        data-name="Path 1037"
                        d="M1622.223,2774.339a11.7,11.7,0,0,1,14.222-7.379,10.962,10.962,0,0,1,2.72,1.158l-6.211,9.266Z"
                        transform="translate(-1622.223 -2766.516)"
                        fill="#e6e6e6"
                      />
                    </g>
                    <g
                      id="Group_508"
                      data-name="Group 508"
                      transform="translate(5.805 2.389)"
                    >
                      <path
                        id="Path_1038"
                        data-name="Path 1038"
                        d="M1630.214,2789.624l6.211-9.265,6.782-10.118a11.689,11.689,0,0,1-12.993,19.382Z"
                        transform="translate(-1630.214 -2770.242)"
                        fill="#e6e6e6"
                      />
                    </g>
                    <g
                      id="Group_509"
                      data-name="Group 509"
                      transform="translate(0 9.182)"
                    >
                      <path
                        id="Path_1039"
                        data-name="Path 1039"
                        d="M1621.449,2780.842l10.289,2.918-5.955,8.883A11.477,11.477,0,0,1,1621.449,2780.842Z"
                        transform="translate(-1621.152 -2780.842)"
                        fill="#e6e6e6"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_514"
                    data-name="Group 514"
                    transform="translate(32.568 64.044)"
                  >
                    <g
                      id="Group_511"
                      data-name="Group 511"
                      transform="translate(4.203 0)"
                    >
                      <path
                        id="Path_1040"
                        data-name="Path 1040"
                        d="M1678.532,2866.57a5.36,5.36,0,0,1,6.294,3.766,5.036,5.036,0,0,1,.2,1.338l-5.1-.193Z"
                        transform="translate(-1678.532 -2866.446)"
                        fill="#e6e6e6"
                      />
                    </g>
                    <g
                      id="Group_512"
                      data-name="Group 512"
                      transform="translate(0 5.472)"
                    >
                      <path
                        id="Path_1041"
                        data-name="Path 1041"
                        d="M1671.974,2874.984l5.1.192,5.572.21a5.351,5.351,0,0,1-10.674-.4Z"
                        transform="translate(-1671.974 -2874.984)"
                        fill="#e6e6e6"
                      />
                    </g>
                    <g
                      id="Group_513"
                      data-name="Group 513"
                      transform="translate(0.023 0.3)"
                    >
                      <path
                        id="Path_1042"
                        data-name="Path 1042"
                        d="M1675.567,2866.914l1.335,4.71-4.893-.185a5.254,5.254,0,0,1,3.557-4.525Z"
                        transform="translate(-1672.01 -2866.914)"
                        fill="#e6e6e6"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_518"
                    data-name="Group 518"
                    transform="translate(42.276 6.212)"
                  >
                    <g
                      id="Group_515"
                      data-name="Group 515"
                      transform="translate(4.204 0)"
                    >
                      <path
                        id="Path_1043"
                        data-name="Path 1043"
                        d="M1693.68,2776.332a5.36,5.36,0,0,1,6.293,3.767,5.031,5.031,0,0,1,.2,1.337l-5.1-.192Z"
                        transform="translate(-1693.68 -2776.209)"
                        fill="#e6e6e6"
                      />
                    </g>
                    <g
                      id="Group_516"
                      data-name="Group 516"
                      transform="translate(0 5.471)"
                    >
                      <path
                        id="Path_1044"
                        data-name="Path 1044"
                        d="M1687.121,2784.746l5.1.192,5.572.211a5.351,5.351,0,0,1-10.674-.4Z"
                        transform="translate(-1687.121 -2784.745)"
                        fill="#e6e6e6"
                      />
                    </g>
                    <g
                      id="Group_517"
                      data-name="Group 517"
                      transform="translate(0.025 0.3)"
                    >
                      <path
                        id="Path_1045"
                        data-name="Path 1045"
                        d="M1690.716,2776.676l1.336,4.71-4.892-.185A5.253,5.253,0,0,1,1690.716,2776.676Z"
                        transform="translate(-1687.16 -2776.676)"
                        fill="#e6e6e6"
                      />
                    </g>
                  </g>
                </g>
                <g
                  id="Group_629"
                  data-name="Group 629"
                  transform="translate(75.935 49.862)"
                >
                  <rect
                    id="Rectangle_118"
                    data-name="Rectangle 118"
                    width="43.831"
                    height="5.128"
                    rx="2.564"
                    transform="translate(43.831 38.462) rotate(180)"
                    fill="#ccc"
                  />
                  <rect
                    id="Rectangle_119"
                    data-name="Rectangle 119"
                    width="38.372"
                    height="3.048"
                    rx="1.524"
                    transform="translate(51.812 0.66) rotate(102.5)"
                    fill="#e6e6e6"
                  />
                </g>
                <g
                  id="Group_631"
                  data-name="Group 631"
                  transform="translate(13.745 46.958)"
                >
                  <path
                    id="Path_937"
                    data-name="Path 937"
                    d="M1112.633,2461.96a.914.914,0,0,0,.262-.038l4.422-1.372a.9.9,0,0,0,.631-.827l.112-3.539a17.348,17.348,0,0,0,3.843-3.191l3.5.538a.894.894,0,0,0,.931-.47l2.155-4.091a.9.9,0,0,0-.14-1.032l-2.422-2.579a17.318,17.318,0,0,0,.458-4.974l2.856-2.094a.9.9,0,0,0,.326-.991l-1.37-4.419a.9.9,0,0,0-.827-.63l-3.54-.112a17.331,17.331,0,0,0-3.191-3.843l.54-3.5a.894.894,0,0,0-.472-.93l-4.09-2.155a.9.9,0,0,0-1.031.139l-2.58,2.422a17.209,17.209,0,0,0-4.975-.456l-2.093-2.856a.89.89,0,0,0-.991-.325l-4.417,1.368a.9.9,0,0,0-.631.827l-.112,3.541a17.35,17.35,0,0,0-3.843,3.191l-3.5-.538a.9.9,0,0,0-.929.47l-2.156,4.092a.894.894,0,0,0,.141,1.032l2.422,2.578a17.362,17.362,0,0,0-.458,4.975l-2.856,2.094a.9.9,0,0,0-.327.986l1.372,4.422a.9.9,0,0,0,.826.631l3.541.111a17.326,17.326,0,0,0,3.192,3.842l-.541,3.5a.9.9,0,0,0,.468.93l4.094,2.155a.891.891,0,0,0,1.031-.139l2.579-2.423a17.349,17.349,0,0,0,4.975.458l2.094,2.856A.9.9,0,0,0,1112.633,2461.96Zm4.161-2.462-4.062,1.261-2.359-3.218-.32.022a16.148,16.148,0,0,1-5.234-.481l-.309-.081-2.906,2.73-3.763-1.982.607-3.942-.242-.21a16.192,16.192,0,0,1-3.358-4.042l-.162-.276-3.988-.127-1.261-4.061,3.218-2.359-.023-.319a16.2,16.2,0,0,1,.482-5.236l.08-.31-2.729-2.905,1.982-3.763,3.942.607.209-.241a16.188,16.188,0,0,1,4.044-3.359l.276-.163.125-3.988,4.062-1.258,2.359,3.218.32-.023a16.123,16.123,0,0,1,5.234.48l.309.082,2.907-2.73L1120,2424.8l-.606,3.941.241.209a16.155,16.155,0,0,1,3.358,4.044l.162.276,3.988.126,1.261,4.062-3.217,2.359.022.319a16.176,16.176,0,0,1-.482,5.234l-.08.309,2.729,2.908-1.982,3.762-3.941-.606-.21.242a16.18,16.18,0,0,1-4.043,3.356l-.276.162Zm-15.025.319Zm15.2-.375Zm8.329-6.924Zm-34.549-3.186Zm-1.467-4.13Zm1.091-11.073Zm2.156-4.094Zm27.624-5.143h0Zm-14.876-3.15Zm-.29-.1Zm4.009,31.362a11.743,11.743,0,1,0-11.3-14.9l1.116.31a10.581,10.581,0,1,1,1.157,8.345l-.991.6a11.78,11.78,0,0,0,10.019,5.641Z"
                    transform="translate(-1088.239 -2420.594)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_938"
                    data-name="Path 938"
                    d="M1123.186,2460.243c.086,0,.17,0,.256-.007a4.688,4.688,0,1,0-3.738-7.815h0a4.689,4.689,0,0,0,3.482,7.822Zm.008-8.216a3.527,3.527,0,1,1-2.627,1.168,3.527,3.527,0,0,1,2.627-1.168Z"
                    transform="translate(-1102.511 -2434.871)"
                    fill="#e6e6e6"
                  />
                </g>
                <g
                  id="Group_632"
                  data-name="Group 632"
                  transform="translate(0 14.228)"
                >
                  <path
                    id="Path_939"
                    data-name="Path 939"
                    d="M1030.039,2391.759a.668.668,0,0,0-.122.159l-1.679,3.087a.681.681,0,0,0,.1.784l1.813,1.981a13.188,13.188,0,0,0-.4,3.771l-2.186,1.559a.679.679,0,0,0-.257.749l.995,3.365a.68.68,0,0,0,.623.487l2.682.121a13.145,13.145,0,0,0,2.387,2.947l-.445,2.65a.683.683,0,0,0,.347.711l3.085,1.677a.685.685,0,0,0,.784-.095l1.982-1.815a13.135,13.135,0,0,0,3.771.4l1.559,2.188a.679.679,0,0,0,.749.256l3.366-.994a.681.681,0,0,0,.487-.622l.121-2.683a13.088,13.088,0,0,0,2.947-2.387l2.651.445a.676.676,0,0,0,.711-.348l1.678-3.083a.683.683,0,0,0-.1-.784l-1.814-1.984a13.151,13.151,0,0,0,.4-3.77l2.187-1.56a.685.685,0,0,0,.257-.748l-.995-3.366a.676.676,0,0,0-.623-.487l-2.682-.121a13.183,13.183,0,0,0-2.387-2.948l.443-2.65a.681.681,0,0,0-.344-.71l-3.088-1.679a.68.68,0,0,0-.783.095l-1.984,1.814a13.173,13.173,0,0,0-3.77-.4l-1.559-2.187a.683.683,0,0,0-.747-.258l-3.368,1a.676.676,0,0,0-.487.622l-.12,2.683a13.178,13.178,0,0,0-2.948,2.387l-2.651-.444a.681.681,0,0,0-.586.186Zm-.959,3.542,1.542-2.838,2.986.5.162-.18a12.273,12.273,0,0,1,3.1-2.51l.211-.119.135-3.023,3.095-.916,1.757,2.466.242-.014a12.277,12.277,0,0,1,3.966.421l.235.064,2.235-2.043,2.838,1.542-.5,2.987.18.162a12.293,12.293,0,0,1,2.51,3.1l.12.211,3.023.135.915,3.1-2.465,1.756.014.242a12.282,12.282,0,0,1-.421,3.968l-.063.235,2.043,2.234-1.542,2.835-2.988-.5-.162.18a12.2,12.2,0,0,1-3.1,2.51l-.211.12-.135,3.024-3.1.914-1.756-2.465-.242.014a12.261,12.261,0,0,1-3.967-.421l-.235-.065-2.236,2.043-2.835-1.542.5-2.987-.18-.161a12.29,12.29,0,0,1-2.509-3.1l-.12-.211-3.024-.135-.914-3.1,2.464-1.757-.014-.242a12.294,12.294,0,0,1,.422-3.966l.064-.234Zm8-8.129Zm-8.07,8.254Zm-.863,8.176Zm20.475-16.561Zm2.984,1.469Zm5.27,6.6Zm1,3.369Zm-12.3,17.422Zm9.758-6.163Zm.209-.1Zm-18.785-14.933a8.913,8.913,0,1,0,14.035,2.117l-.772.422a8.033,8.033,0,1,1-5.048-3.925l.22-.852a8.938,8.938,0,0,0-8.435,2.237Z"
                    transform="translate(-1027.275 -2385.302)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_940"
                    data-name="Path 940"
                    d="M1051.4,2409.357c-.045.045-.09.09-.135.14a3.558,3.558,0,1,0,6.17,2.271h0a3.558,3.558,0,0,0-6.035-2.411Zm4.345,4.472a2.677,2.677,0,1,1,.811-2.027,2.677,2.677,0,0,1-.811,2.027Z"
                    transform="translate(-1038.146 -2396.173)"
                    fill="#e6e6e6"
                  />
                </g>
                <g
                  id="Group_633"
                  data-name="Group 633"
                  transform="translate(37.232 23.231)"
                >
                  <path
                    id="Path_941"
                    data-name="Path 941"
                    d="M1107.642,2358.228a.557.557,0,0,0,.012-.159l-.23-2.772a.54.54,0,0,0-.405-.478l-2.062-.525a10.405,10.405,0,0,0-1.372-2.669l.77-1.982a.537.537,0,0,0-.155-.607l-2.119-1.8a.537.537,0,0,0-.624-.052l-1.828,1.085a10.4,10.4,0,0,0-2.859-.915l-.856-1.948a.54.54,0,0,0-.538-.32l-2.771.229a.54.54,0,0,0-.477.406l-.526,2.062a10.405,10.405,0,0,0-2.67,1.373l-1.982-.771a.536.536,0,0,0-.607.156l-1.8,2.119a.54.54,0,0,0-.053.623l1.085,1.828a10.34,10.34,0,0,0-.914,2.859l-1.948.856a.533.533,0,0,0-.319.54l.228,2.77a.541.541,0,0,0,.405.478l2.063.525a10.434,10.434,0,0,0,1.372,2.67l-.77,1.982a.541.541,0,0,0,.155.606l2.12,1.8a.537.537,0,0,0,.624.052l1.828-1.085a10.407,10.407,0,0,0,2.859.916l.856,1.948a.54.54,0,0,0,.536.32l2.773-.229a.541.541,0,0,0,.477-.4l.525-2.063a10.423,10.423,0,0,0,2.669-1.373l1.982.771a.541.541,0,0,0,.607-.154l1.8-2.122a.535.535,0,0,0,.053-.623l-1.086-1.829a10.442,10.442,0,0,0,.916-2.859l1.947-.855A.54.54,0,0,0,1107.642,2358.228Zm-.9-2.762.211,2.546-2.195.966-.028.19a9.706,9.706,0,0,1-.963,3.009l-.09.171,1.224,2.06-1.652,1.95-2.234-.869-.154.115a9.718,9.718,0,0,1-2.808,1.444l-.183.06-.592,2.323-2.547.211-.965-2.194-.19-.028a9.734,9.734,0,0,1-3.009-.963l-.172-.087-2.06,1.223-1.95-1.651.869-2.233-.114-.154a9.726,9.726,0,0,1-1.445-2.809l-.06-.183-2.323-.592-.21-2.547,2.195-.966.028-.19a9.669,9.669,0,0,1,.962-3.008l.09-.171-1.223-2.061,1.652-1.949,2.234.868.154-.114a9.711,9.711,0,0,1,2.809-1.444l.183-.059.592-2.323,2.547-.211.965,2.195.19.028a9.737,9.737,0,0,1,3.008.963l.171.087,2.061-1.223,1.95,1.652-.869,2.233.115.155a9.72,9.72,0,0,1,1.441,2.808l.059.183Zm-1.767,8.856Zm1.757-8.969Zm-2.979-5.787Zm-6.362,19.855Zm-2.612.323Zm-6.355-2.081ZM1086.3,2365.867Zm.576-16.875Zm-3.783,8.318Zm-.095.157Zm18.92,1.727a7.056,7.056,0,1,0-10.21,4.692l.327-.614a6.358,6.358,0,1,1,5.044.405l.225.66a7.081,7.081,0,0,0,4.613-5.145Z"
                    transform="translate(-1082.397 -2345.088)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_942"
                    data-name="Path 942"
                    d="M1111.8,2372.353c.011-.05.021-.1.029-.151a2.817,2.817,0,1,0-5.071,1.176h0a2.817,2.817,0,0,0,5.041-1.025Zm-4.819-1.073a2.119,2.119,0,1,1,.343,1.694,2.119,2.119,0,0,1-.343-1.694Z"
                    transform="translate(-1096.418 -2359.11)"
                    fill="#e6e6e6"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>

      {userBanks?.rows != null
        ? (
            userBanks.rows.map((e) => {
              return <Item key={JSON.stringify(e)} data={e}></Item>
            })
          )
        : (
        <div></div>
          )}

      <Fab
        icon={<i style={{ fontSize: '11vw' }} className="fas fa-plus"></i>}
        alwaysShowTitle={true}
        mainButtonStyles={{
          background: '#f96123',
          right: '-6vw',
          bottom: '11vh',
          height: '9vh',
          width: '9vh'
        }}
        onClick={() => history.push('/new_account')}
      ></Fab>

      <BottomNavigator></BottomNavigator>
    </div>
  )
}
