import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logInUser } from "../slices/auth";
import { Mail } from "@material-ui/icons";
import InputField from "../components/form/textField/index";
import PasswordField from "../components/form/passwordField/index";
import Layout from "../layouts/outside";
import Validator from "validatorjs";
import { devMode, emailDevMode, passwordDevMode } from "../devMode";

const useStyles = makeStyles({
  dontHaveAccount: {
    color: "white",
    cursor: "pointer",
    "& span": {
      color: "#ff7c46",
      fontWeight: "700",
    },
  },
  containerFooterOption: {
    position: "absolute",
    bottom: "-80px",
    marginLeft: "auto",
    marginRight: "auto",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    textAlign: "center",
  },
  icon: {
    color: "#666",
    position: "relative",
    top: "-7px",
  },
  recoveryPassword: {
    textDecoration: "none",
    color: "#ff7c46",
    position: "absolute",
    top: "-30px",
    right: "0px",
  },
});

function Login() {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setloading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [form, setForm] = useState({
    email: devMode ? emailDevMode : "",
    password: devMode ? passwordDevMode : "",
    remember: false,
  });
  const emptyArray: string[] = [];
  const [error, setError] = useState({
    email: emptyArray,
    password: emptyArray,
    remember: emptyArray,
  });
  const rules = {
    email: "required|email",
    password: "required",
  };

  const validation = new Validator(form, rules);

  const dispatch = useDispatch();

  const loginUserFunct = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      validation.passes();
      validation.fails();

      setError({
        email: validation.errors.get("email"),
        password: validation.errors.get("password"),
        remember: [],
      });

      if (!validation.fails()) {
        setloading(true);
        await dispatch(await logInUser(form, history));

        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  const handleSetEmail = (value: string) => {
    setForm({ ...form, email: value.trim() });
  };

  const handleSetPassword = (value: string) => {
    setForm({ ...form, password: value });
  };

  return (
    <form onSubmit={(e) => loginUserFunct(e)}>
      <Layout
        label="Iniciar Sesión"
        loading={loading}
        color={true}
        hideButton={false}
      >
        <Link
          to={{ pathname: "/recovery" }}
          className={classes.recoveryPassword}
        >
          ¿Olvidaste tu contraseña?
        </Link>
        <InputField
          label="Correo electrónico"
          icon={<Mail className={classes.icon} />}
          type="text"
          setValue={handleSetEmail}
          placeholder="Correo electrónico"
          validation={error.email}
          onBlur={undefined}
          onFocus={undefined}
        />
        <PasswordField
          setPassword={handleSetPassword}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
          validation={error.password}
          onBlur={undefined}
          onFocus={undefined}
        />
      </Layout>
    </form>
  );
}

export default Login;
