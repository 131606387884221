import React, { useState } from 'react'
import EventEmitter from 'events'

const initialState = {
  usersSelected: [],
  ee: new EventEmitter()
}

const useInitialState = () => {
  const [state, setstate] = useState(initialState)

  const addUserSelected = (payload) => {
    setstate({
      ...state,
      usersSelected: [...state.usersSelected, payload]
    })
  }

  const addEvent = (name, payload) => {
    state.ee.on(name, payload)
  }

  const emitEvent = (name, data) => {
    state.ee.emit(name, data)
  }

  return {
    state,
    addUserSelected,
    addEvent,
    emitEvent
  }
}

export default useInitialState
