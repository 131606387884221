import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContactsComponent from "../../components/contacts/index";
import { fetchBssaccounts, deleteBssaccounts } from "../../slices/bssaccount";
import Swal from "sweetalert2";

function Contacts() {
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [createdNow, setCreatedNow] = useState(null);
  const [wordToSearch, setWordToSearch] = useState("");

  const bssaccounts = useSelector((state) => state.bssaccounts.bssaccounts);

  const deleteAccount = async (id) => {
    const confirmation = await Swal.fire({
      icon: "info",
      showDenyButton: true,
      confirmButtonText: "Sí",
      text: "Seguro de eliminar esta cuenta?",
      customClass: {
        confirmButton: "swal2-blue-confirm-button",
      },
    });

    if (confirmation.isConfirmed) {
      setloading(true);

      const response = await deleteBssaccounts(id);
      dispatch(fetchBssaccounts());

      setloading(false);
    }
  };

  const search = async (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log(wordToSearch);

    setloading(true);
    dispatch(fetchBssaccounts("/?query=" + wordToSearch)).then(async (e) => {
      setloading(false);
    });
  };

  useEffect(() => {
    setloading(true);
    try {
      dispatch(fetchBssaccounts()).then(async (e) => {
        setloading(false);
      });
    } catch (error) {
      setloading(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let userCreatedNow = localStorage.getItem("contactCreatedNow");
      if (userCreatedNow && bssaccounts?.data?.rows) {
        userCreatedNow = JSON.parse(userCreatedNow);
        const userCreated = bssaccounts.data.rows.find(
          (e) => e.id == userCreatedNow.id
        );
        if (userCreated) {
          setCreatedNow(userCreated);
          localStorage.removeItem("contactCreatedNow");
        }
      }
    }, 100);
  }, [bssaccounts]);

  const props = {
    loading,
    bssaccounts,
    deleteAccount,
    createdNow,
    setWordToSearch,
    search,
  };

  return <ContactsComponent {...props} />;
}

export default Contacts;
