import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  SvgIcon,
  Divider,
  IconButton,
  Dialog,
} from "@material-ui/core";
import ChangeStatus from "../../components/operador/ChangeStatus";
import ModalTransferirDetails from "../../components/transactions/details/modalTransferir";
import { useSelector, useDispatch } from "react-redux";
import { uploadFile } from "../../slices/files";

import { finishTransaction } from "./../../slices/transactions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  getBankBalancesByUser,
  createBalance,
} from "./../../slices/bssbalance";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { formatNumber } from "./../../helpers/numbers";
import Screenloading from "../../components/loaders/screenLoading";
import AppContext from "./../../context/AppContext";
import { ToastContainer, toast } from "react-toastify";

const MySwal = withReactContent(Swal);
const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    minHeight: "100vh",
    backgroundColor: "#FA7D4A",
  },
  content: {
    padding: 30,
    paddingTop: 15,
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bankLogo: {
    width: "30%",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 60,

    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
});

function Detail() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { emitEvent, addEvent } = useContext(AppContext);

  const bssBalances = useSelector((state) => state.bssbalance.rows);

  useEffect(() => {
    dispatch(getBankBalancesByUser(dispatch));
  }, []);

  const [loading, setloading] = useState(false);
  const [finishTranferDialog, setFinishTransferDialog] = useState(false);
  const [image, setimage] = useState("");
  const [bssBalance, setBssBalance] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const [allowFinish, setallowFinish] = useState(false);
  const History = useHistory();
  const [loadingBalanceChange, setloadingBalanceChange] = useState(false);

  const updateAmount = async (updateAmount, bssBalance) => {
    const total = updateAmount - bssBalance.total;
    setloadingBalanceChange(true);
    dispatch(
      createBalance(user.id, bssBalance.userBank.id, total, () => {
        dispatch(getBankBalancesByUser(dispatch));
        setloadingBalanceChange(false);
        emitEvent("changeBssBalanceTotal", updateAmount);
      })
    );
  };

  addEvent("updateTransferencesLive", (e) => {
    onUpdateTransferencesStatus(e);
  });

  const onUpdateTransferencesStatus = (e) => {
    const ids = [
      ...e.pendings.map((trans) => trans.id),
      ...e.assignedToday.map((trans) => trans.id),
    ];
    const idsNow = localStorage.getItem("transactionIdDetails");

    if (idsNow != null && !ids.includes(parseInt(idsNow))) {
      localStorage.removeItem("transactionIdDetails");

      History.push("/operator-dashboard");
      /* Swal.fire({
        text: 'La transferencia ya no esta asignada',
        icon: 'info'
      }) */
    }
  };

  const handleSubmit = async () => {
    setimage("");
    setBssBalance({});
    setFinishTransferDialog(false);
    setloading(true);
    dispatch(uploadFile(image)).then((e) => {
      if (e?.payload) {
        const path = e.payload.original.location;
        dispatch(
          finishTransaction(
            location.state.transaction.id,
            path,
            bssBalance.userBankId,
            History
          )
        ).then((e) => setloading(true));
      } else {
        console.log("else");
        setloading(false);
      }
    });
  };

  const updateStatusTransferenceLive = (id) => {
    setallowFinish(!allowFinish);
    setallowFinish(id == 3);
  };

  useEffect(() => {
    setallowFinish(
      location?.state?.transaction?.transactionStatus[0]?.statusId == 3
    );
  }, []);

  const ButtonCopy = ({ text }) => {
    const copyvalue = () => {
      var textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      toast.success("Copiado!", {
        position: "top-right",
        toastId: "success",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    return (
      <>
        <ToastContainer />
        <button
          onClick={copyvalue}
          style={{
            border: "none",
            background: "#003350",
            color: "white",
            padding: "1vw 3vw",
            borderRadius: "5px",
            fontWeight: "bold",
            marginTop: "2vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <i
            className="far fa-copy"
            style={{ color: "#FFFFFF", paddingRight: "2vw" }}
          ></i>
          <span>Copiar</span>
        </button>
      </>
    );
  };

  const ItemCopy = ({ text }) => {
    const copyvalue = () => {
      var textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      toast.success("Copiado!", {
        position: "top-right",
        toastId: "success",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ToastContainer />
        <Typography
          style={{
            fontSize: 16,
            lineHeight: 1.2,
            fontWeight: 600,
            color: "#fff",
          }}
        >
          {text}
        </Typography>
        <div
          onClick={copyvalue}
          style={{
            border: "none",
            color: "white",
            padding: "1vw 3vw",
            borderRadius: "5px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <i
            className="far fa-copy"
            style={{ color: "#FFFFFF", paddingRight: "2vw" }}
          ></i>
        </div>
      </div>
    );
  };

  return (
    <>
      <Grid container className={classes.container}>
        <div onClick={() => history.goBack()} style={{ padding: 15 }}>
          <SvgIcon viewBox="0 0 14.565 24">
            <path
              id="FontAwsome_chevron-left_"
              data-name="FontAwsome (chevron-left)"
              d="M27.879,48.73,38.56,38.049a1.319,1.319,0,0,1,1.865,0l1.246,1.246a1.319,1.319,0,0,1,0,1.863l-8.465,8.5,8.465,8.5a1.319,1.319,0,0,1,0,1.863l-1.246,1.246a1.319,1.319,0,0,1-1.865,0L27.879,50.595A1.319,1.319,0,0,1,27.879,48.73Z"
              transform="translate(-27.493 -37.662)"
              fill="#fff"
            />
          </SvgIcon>
        </div>
        <Grid className={classes.content}>
          <div className={classes.flex}>
            <div>
              {location.state.transaction.bssAccount.number !=
              "1234567898745632" ? (
                <ItemCopy
                  text={
                    location.state.transaction.bssAccount.bank.prefix +
                    "" +
                    location.state.transaction.bssAccount.number
                  }
                />
              ) : (
                <ItemCopy text={location.state.transaction.bssAccount.phone} />
              )}
              <ItemCopy text={location.state.transaction.bssAccount.name} />
              <ItemCopy
                text={location.state.transaction.bssAccount.identification}
              />

              {location.state.transaction.bssAccount.number !=
                "1234567898745632" && (
                <ItemCopy
                  text={
                    "Cta - " +
                    location.state.transaction.bssAccount.bssAccountType.name
                  }
                />
              )}
            </div>
            <div className={classes.bankLogo}>
              <img src={location.state.transaction.bssAccount.bank.Logo}></img>
            </div>
          </div>

          <ButtonCopy
            text={[
              location.state.transaction.bssAccount.number != "1234567898745632"
                ? "Número de cuenta: " +
                  location.state.transaction.bssAccount.bank.prefix +
                  "" +
                  location.state.transaction.bssAccount.number
                : "Pago movíl: " + location.state.transaction.bssAccount.phone,
              "Nombre: " + location.state.transaction.bssAccount.name,
              "Documento: " +
                location.state.transaction.bssAccount.identification,
              "Tipo de cuenta: Cta-" +
                location.state.transaction.bssAccount.bssAccountType.name,
            ]}
          />

          <Divider
            style={{
              marginTop: 15,
              marginBottom: 15,
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            }}
          />

          <div className={classes.flex}>
            <Typography
              style={{
                fontSize: 20,
                lineHeight: 1.2,
                fontWeight: 600,
                color: "#fff",
              }}
            >
              Monto:
            </Typography>
            <Typography
              style={{
                fontSize: 20,
                lineHeight: 1.2,
                fontWeight: 600,
                color: "#fff",
              }}
            >
              {formatNumber(location.state.transaction.bssAmount)} BS
            </Typography>
          </div>

          <Divider
            style={{
              marginTop: 15,
              marginBottom: 15,
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            }}
          />
          <div
            style={{
              marginTop: 50,
              backgroundColor: "#FB9269",
              width: "100vw",
              marginLeft: -30,
              padding: "15px 30px",
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                lineHeight: 1.2,
                fontWeight: 600,
                color: "#fff",
                marginBottom: 15,
              }}
            >
              Actualizar estado
            </Typography>

            <ChangeStatus
              updateStatusTransferenceLive={updateStatusTransferenceLive}
              initialState={
                location.state.transaction.transactionStatus[0].status.name
              }
            />
          </div>

          <Dialog
            onClose={() => {
              setimage("");
              setBssBalance({});
              setFinishTransferDialog(false);
            }}
            aria-labelledby="customized-dialog-title"
            open={finishTranferDialog}
            PaperProps={{
              style: { maxWidth: "900px", width: "95vw", margin: 0 },
            }}
          >
            <MuiDialogTitle
              disableTypography
              className={classes.root}
              style={{
                display: "flex",
                justifyContent: "end",
                padding: 0,
              }}
            >
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  setimage("");
                  setBssBalance({});
                  setFinishTransferDialog(false);
                }}
              >
                <SvgIcon viewBox="0 0 24 24">
                  <path
                    id="FontAwsome_times-circle_"
                    data-name="FontAwsome (times-circle)"
                    d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm5.884,15.15a.581.581,0,0,1,0,.823l-1.916,1.911a.581.581,0,0,1-.823,0L20,22.71l-3.15,3.174a.581.581,0,0,1-.823,0l-1.911-1.916a.581.581,0,0,1,0-.823L17.29,20l-3.174-3.15a.581.581,0,0,1,0-.823l1.916-1.916a.581.581,0,0,1,.823,0L20,17.29l3.15-3.174a.581.581,0,0,1,.823,0l1.916,1.916a.581.581,0,0,1,0,.823L22.71,20Z"
                    transform="translate(-8 -8)"
                    fill="#FA7D4A"
                  />
                </SvgIcon>
              </IconButton>
            </MuiDialogTitle>
            <ModalTransferirDetails
              setBssBalance={setBssBalance}
              bssBalances={bssBalances.rows}
              update={updateAmount}
              setimage={setimage}
              loadingBalanceChange={loadingBalanceChange}
            ></ModalTransferirDetails>

            <MuiDialogActions
              style={{
                backgroundColor:
                  image == "" || bssBalance?.userBankId == null
                    ? "#b8b8b8"
                    : "#003F62",
                justifyContent: "center",
              }}
              onClick={
                image == "" || bssBalance?.userBankId == null
                  ? () => {}
                  : handleSubmit
              }
            >
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: 600,
                  fontSize: 15,
                  margin: 5,
                }}
              >
                Confirmar transferencia
              </Typography>
            </MuiDialogActions>
          </Dialog>
          <div
            className={classes.footer}
            style={{
              backgroundColor: !allowFinish ? "#bcbcbc" : "#003F62",
            }}
            onClick={() =>
              !allowFinish ? () => {} : setFinishTransferDialog(true)
            }
          >
            <Typography style={{ color: "#fff", fontWeight: 500 }}>
              {!allowFinish
                ? "Transferencia en espera"
                : "Finalizar Transferencia"}
            </Typography>
          </div>
        </Grid>
      </Grid>

      {loading ? <Screenloading></Screenloading> : <></>}
    </>
  );
}

export default Detail;
