import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  containerPending: {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
    width: '90%',
    margin: 'auto',
    marginBottom: '4vw',
    borderRadius: '15px',
    paddingTop: '3vw',
    paddingLeft: '4vw',
    paddingBottom: '2vw',
    paddingRight: '4vw',
    '@media (min-width: 700px)': {
      padding: '1vw 2vw',
      marginBottom: '1vw'
    },
    '& h3': {
      fontWeight: '200'
    },
    '& h5': {
      marginBottom: '1.5vw',
      '@media (min-width: 700px)': {
        marginBottom: '0.5vw'
      }
    }
  },
  phone: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      marginRight: '2vw'
    }
  }
})

export default function Index ({ setModalSetStatus, setUserSelected, user }) {
  const classes = useStyles()

  const handleClick = () => {
    setUserSelected(user)
    setModalSetStatus(true)
  }

  return (
    <article className={classes.containerPending} onClick={handleClick}>
      <div>
        <h3>{user.user.name}</h3>
        <h5>
          <b>ID</b>
          <span> #{user.user.id} </span>
        </h5>
      </div>
      <div>
        <a
          href={'https://wa.me/' + user.phone}
          target="_blank"
          rel="noreferrer"
        >
          <h5 className={classes.phone}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7.192"
              height="14.392"
              viewBox="0 0 7.192 14.392"
            >
              <path
                id="iphone"
                d="M17.554,4.5H12.142a.9.9,0,0,0-.892.9V18a.894.894,0,0,0,.892.892H17.55A.894.894,0,0,0,18.442,18V5.4A.888.888,0,0,0,17.554,4.5ZM14.248,5.845a.146.146,0,0,1,.146-.146h.911a.146.146,0,0,1,.146.146v.011A.146.146,0,0,1,15.305,6h-.911a.146.146,0,0,1-.146-.146ZM13.8,5.7a.15.15,0,1,1-.15.15A.15.15,0,0,1,13.8,5.7Zm1.05,12.6a.6.6,0,1,1,.6-.6A.6.6,0,0,1,14.848,18.3Zm3-1.8h-6V6.9h6Z"
                transform="translate(-11.25 -4.5)"
                opacity="0.5"
              />
            </svg>

            <span> {user.phone} </span>
          </h5>
        </a>
      </div>
    </article>
  )
}
