// Dependencies
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'
import Swal from 'sweetalert2'

export const fetchUsers = createAsyncThunk('user', async (data) => {
  return Axios
    .get(process.env.REACT_APP_API_URL + 'user', {
      params: data?.params ? data.params : {}
    })
    .then((response) => response.data)
})
export const fetchUsersByRole = async (id) => {
  return await Axios
    .get(process.env.REACT_APP_API_URL + 'user/range/' + id)
    .then((response) => response.data)
}

export const updateUser = createAsyncThunk(
  'updateUser',
  async (data) => {
    return Axios
      .put(`${process.env.REACT_APP_API_URL}user/update/${data.idToUpdate}`, data)
      .then((response) => response.data)
  }
)

export const createUserRole = async (userData) => {
  try {
    const { data } = await Axios.post(
      process.env.REACT_APP_API_URL + 'user/admin-operator-store',
      userData
    )

    if (data.errors) {
      data.errors.map(e => {
        Swal.fire({
          text: e.msg,
          icon: 'error',
          customClass: {
            confirmButton: 'swal2-blue-confirm-button'
          }
        })

        return true
      })
    }

    return data
  } catch (err) {
    console.log('err', err)
    return err
  }
}

export const deleteUser = async (id) => {
  try {
    const response = await Axios.delete(
      process.env.REACT_APP_API_URL + 'user/delete/' + id
    )

    return response
  } catch (error) {
    return false
  }
}
export const changeActiveUser = async (data, id) => {
  try {
    const response = await Axios.put(
      process.env.REACT_APP_API_URL + 'user/update-active/' + id,
      data
    )

    return response.data
  } catch (e) {
    return e
  }
}

export const updateAllUser = async (id, dataUser) => {
  try {
    const { data } = await Axios.put(
      process.env.REACT_APP_API_URL + 'user/update/' + id,
      dataUser
    )

    return data
  } catch (e) {
    return e
  }
}
export const userToMiddleware = async (id, dataUser) => {
  try {
    const { data } = await Axios.put(
      process.env.REACT_APP_API_URL + 'user/to-middleware/' + id,
      dataUser
    )

    return data
  } catch (e) {
    return false
  }
}

export const getAllUsers = async () => {
  try {
    const response = await Axios.get(process.env.REACT_APP_API_URL + 'user', {
      params: {
        limit: 1000
      }
    })
    return response.data.data.rows
  } catch (e) {
    return false
  }
}
export const getCurrencies = async () => {
  try {
    const response = await Axios.get(process.env.REACT_APP_API_URL + 'currency')
    return response.data.data
  } catch (e) {
    return false
  }
}
export const getUserCurrencies = async (userId) => {
  try {
    const response = await Axios.get(process.env.REACT_APP_API_URL + 'user/user-currencies/' + userId)
    return response.data.data
  } catch (e) {
    return false
  }
}

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    usersByRange: [],
    allUsers: []
  },
  extraReducers: {
    [fetchUsers.fulfilled]: (state, action) => {
      state.users = action.payload
    }
  }
})

export default usersSlice.reducer
