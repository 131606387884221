import { makeStyles } from '@material-ui/core'
import { useField } from 'formik'
import { useEffect } from 'react'
import CurrencyInput from 'react-currency-input-field'

// Custom Styles
const useStyles = makeStyles({
  inputContainer: {
    backgroundColor: '#FFF',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.18)',
    padding: '0 2.5%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '40px',
    fontSize: '4vw'
  },
  input: {
    backgroundColor: 'inherit',
    border: 'none',
    outline: 'none',
    margin: '0 5%',
    width: '100%',
    fontSize: '4vw',
    padding: '2.5% 0'
  },
  fieldContainer: {
    marginBottom: '5%'
  }
})

export const FormikCurrencyInput = (props) => {
  const classes = useStyles()
  const [field, meta, { setValue }] = useField(props)
  const { abbr, label } = props

  const handleOnChange = (value) => {
    setValue({
      ...field.value,
      value: value
    })
  }

  return (
        <div className={classes.fieldContainer}>
            <h5
                style={{
                  marginBottom: '2vw',
                  fontSize: '4vw',
                  fontWeight: '500'
                }}
            >{label}</h5>
            <div className={classes.inputContainer}>
                <label style={{
                  fontWeight: '500'
                }}>$</label>
                <CurrencyInput
                    value={field.value.value}
                    onValueChange={handleOnChange}
                    name={field.name}
                    onBlur={field.onBlur}
                    className={classes.input}
                    placeholder="Ingrese un monto"
                    step={1}
                    decimalsLimit={3}
                />
                <label style={{
                  fontWeight: '500'
                }}>{abbr}</label>
            </div>
            {meta.error && meta.touched
              ? <label style={{
                marginBottom: '2vw',
                fontSize: '4vw'
              }}>{meta.error?.value} </label>
              : null}
        </div>
  )
}
