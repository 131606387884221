import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IconButton, Typography, Avatar, Divider } from "@material-ui/core";
import ExitToAppRounded from "@material-ui/icons/ExitToAppRounded";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  header: {
    zIndex: 1,
    width: "100vw",
    padding: 15,
    paddingBottom: 0,
    backgroundColor: "#FA7D4A",
  },
  menuButtons: {
    position: "relative",
    backgroundColor: "#fff",
    color: "#f47436",
    fontSize: 12,
  },
});

function Header({ text, onTapReload }) {
  const history = useHistory();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  return (
    <div className={classes.header}>
      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <Avatar
          alt={user.name}
          src="https://material-ui.com/static/images/avatar/1.jpg"
          style={{ width: 80, height: 80 }}
        />
        <div style={{ marginLeft: 15 }}>
          <Typography style={{ fontSize: 24, fontWeight: 600, color: "#fff" }}>
            {user.name}
          </Typography>
          <Typography style={{ fontSize: 18, color: "#fff" }}>
            Operador
          </Typography>
        </div>
        <div style={{ position: "absolute", top: 5, right: 0 }}>
          <IconButton
            aria-label="logout"
            style={{ color: "#fff", padding: 0 }}
            onClick={() => history.push("/logout")}
          >
            <ExitToAppRounded />
          </IconButton>
        </div>
      </div>

      <Divider
        style={{
          marginTop: 10,
          backgroundColor: "#fff",
          width: "102%",
          marginLeft: "-1%",
        }}
      />

      <Typography
        style={{
          fontSize: 20,
          fontWeight: 600,
          color: "#fff",
          marginTop: 15,
          marginBottom: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {text}

        {onTapReload && (
          <i
            onClick={onTapReload}
            className="fa-solid fa-rotate-right"
            style={{ color: "white", fontSize: "5vw", marginLeft: "4vw" }}
          ></i>
        )}
      </Typography>
    </div>
  );
}

export default Header;
