import { useState } from 'react'
import Layout from '../../layouts/outside'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { changePassword } from '../../slices/auth'
import { useDispatch, useSelector } from 'react-redux'
import PasswordField from '../../components/form/passwordField/index'
import * as Validator from 'validatorjs'
import Screenloading from './../../components/loaders/screenLoading'

const useStyles = makeStyles({
  recoveryInput: {
    marginTop: 70,
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 10,
    width: '100%',
    fontSize: 16
  },
  dontHaveAccount: {
    color: 'white',
    cursor: 'pointer',
    '& span': {
      color: '#ff7c46',
      fontWeight: '700'
    }
  },
  containerFooterOption: {
    position: 'absolute',
    bottom: '-80px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    textAlign: 'center',
    '@media (max-height: 510px)': {
      display: 'none'
    }
  }
})

const Index = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const { email } = useSelector(state => state.code)
  const { code } = useSelector(state => state.code.code)
  const [loading, setloading] = useState(false)

  const [error, setError] = useState({
    password: []
  })

  const rules = {
    password: ['required', 'regex:^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))']
  }

  const [form, setForm] = useState({
    password: ''
  })

  const validation = new Validator(form, rules, { required: 'Este campo es obligatorio', regex: 'La contraseña debe ser más fuerte' })

  const sendChangePassword = async (e) => {
    e.preventDefault()
    validation.passes()
    validation.fails()

    setError({
      password: validation.errors.get('password')
    })

    if (!validation.fails()) {
      setloading(true)
      dispatch(await changePassword(email, parseInt(localStorage.getItem('codeRecovery')), form.password, history))
      setloading(false)
    }
  }
  return (
    <form onSubmit={(e) => sendChangePassword(e) }>
      {loading ? <Screenloading /> : <div></div> }

      <Layout text="Recuperar" label="Cambiar contraseña" color={false}>
      <PasswordField
          setPassword={(e) => {
            setShowPassword(e)
            setForm({ password: e })
          }}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
          validation={error.password}
        />
        <div className={classes.containerFooterOption}>
          <span className={classes.dontHaveAccount}>¿Ya la recordaste?  <Link to={{ pathname: '/login' }} style={{ textDecoration: 'none' }}> <span>Iniciar sesión </span></Link></span>
        </div>
      </Layout>
    </form>
  )
}

export default Index
