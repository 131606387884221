import React from 'react'

export const CustomBalanceContext = React.createContext({
  error: {},
  path: '',
  image: '',
  step: 1,
  balance: {
    amount: '',
    currencyId: 0,
    userId: '',
    capture: ''
  },
  currency: [],
  userSelected: {},
  userCurrencies: [],
  serBalance: () => { },
  setImage: () => { },
  setPath: () => { },
  setStep: () => { }
})
