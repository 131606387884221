import { useState } from 'react'
import Layout from '../../layouts/outside'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { sendCode, CodeType } from '../../slices/code'
import { useDispatch } from 'react-redux'
import { Mail } from '@material-ui/icons'
import InputField from '../../components/form/textField/index'
import Screenloading from './../../components/loaders/screenLoading'

const useStyles = makeStyles({
  recoveryInput: {
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 10,
    width: '100%',
    fontSize: 16,
    marginTop: '10vw'

  },
  dontHaveAccount: {
    color: 'white',
    cursor: 'pointer',
    '& span': {
      color: '#ff7c46',
      fontWeight: '700'
    }
  },
  containerFooterOption: {
    position: 'absolute',
    bottom: '-80px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    textAlign: 'center',
    '@media (max-height: 510px)': {
      display: 'none'
    }
  },
  title: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 18,
    marginTop: 50
  }
})

export default function Index () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [loading, setloading] = useState(false)
  const send = async (e) => {
    e.preventDefault()
    setloading(true)
    dispatch(await sendCode(email.trim(), CodeType.Recovery, history))
    setloading(false)
  }
  return (
    <form onSubmit={(e) => send(e) }>
      <Layout text="Recuperar" loading={loading} label="Enviar código" color={false}>
      <InputField
          label="Correo electrónico"
          icon={<Mail className={classes.icon} />}
          type="text"
          setValue={setEmail}
          placeholder="joedoeh@mail.com"
        />
            {loading ? <Screenloading /> : <div></div> }
        <div className={classes.containerFooterOption}>
          <span className={classes.dontHaveAccount}>¿Ya la recordaste?  <Link to={{ pathname: '/login' }} style={{ textDecoration: 'none' }}> <span>Iniciar sesión </span></Link></span>
        </div>
      </Layout>
    </form>
  )
}
