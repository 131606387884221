/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-control-regex */

import capitalizeString from '../helpers/strings'

const detectDataBssAccount = (e) => {
  const numbers = []

  const getAccountTogether = (array, i, string, indexToDelete, parts) => {
    if (/^\d+$/.test(array[i])) {
      indexToDelete.push(i)
      string = string + array[i]
      if (string.length <= 20) {
        if (
          indexToDelete.length == 1 &&
          string.length > 5 &&
          string.length < 10 &&
          parts.document == null
        ) {
          parts.document = string
          array.splice(i, 1)
          return getAccountTogether(array, i, '', [], parts)
        }
        if (string.length == 20) {
          indexToDelete.reverse().map((index) => {
            array.splice(index, 1)
            return ''
          })
          array.splice(0, 0, string)
          return getAccountTogether(array, i + 1, '', [], parts)
        }
        if (typeof array[i + 1] !== 'undefined') {
          return getAccountTogether(array, i + 1, string, indexToDelete, parts)
        } else {
          return {
            data: array,
            parts
          }
        }
      } else {
        return {
          data: array,
          parts
        }
      }
    } else {
      // si tiene letras despues de la cedula volver a checkear
      if (/^\d+.+$/.test(array[i]) || /^\D{3,}\d+/.test(array[i])) {
        array[i] = array[i].replace(/\D/g, '')
        return getAccountTogether(array, i, '', [], parts)
      }

      // continuar a la siguiente iteracion
      if (typeof array[i + 1] !== 'undefined') {
        return getAccountTogether(array, i + 1, '', [], parts)
      } else {
        return {
          data: array,
          parts
        }
      }
    }
  }
  e = e.trim().replaceAll(new RegExp(/[^A-Za-z0-9ñáéíúó \r\n]/g), '')

  let parts = {}
  const originalData = e.split(new RegExp(' '))

  const dataAux = getAccountTogether(originalData, 0, '', [], {})
  const data = dataAux.data
  parts = dataAux.parts

  if (e.split(RegExp(/[\r\n]+/g)).length > 3) {
    data.length = 3
  }

  const removeDiacritics = (str) => {
    const withDia =
      'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
    const withoutDia =
      'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

    for (let i = 0; i < withDia.length; i++) {
      str = str.replaceAll(withDia[i], withoutDia[i])
    }
    return str.replaceAll('.', '')
  }

  const wordsBlocked = [
    'CI',
    'CC',
    'BSS',
    'CTA',
    'CTE',
    'BANESCO',
    'VZLA',
    'AHORRO',
    'AHORROS',
    'CORRIENTE',
    'TIPO',
    'VES',
    'TITULAR',
    ' A ',
    'BICENTENARIO',
    'NOMBRE',
    ' DE ',
    'CEDULA',
    'RIF',
    'NOMBRE',
    'CHILENOS',
    'PESOS',
    'BOD',
    ' BOD ',
    'BS',
    'BANCO',
    'MERCANTIL',
    'DE',
    'VENEZUELA',
    'PROVINCIAL',
    'NUMERO',
    'CUENTA'
  ]
  const regExpNumberDocument = new RegExp(/[1-9]/g)
  const regExpOnlyNumber = new RegExp(/[^0-9\.]+/g)

  for (let i = 0; i < data.length; i++) {
    data[i] = data[i].trim()

    // tipo de documento (con la letra separada)
    if (data[i].length == 1 || data[i].toString().toUpperCase() == 'CI') {
      parts.typeDocument = data[i].toUpperCase()
    }

    // Numero de cuenta
    if (data[i].replaceAll(regExpOnlyNumber, '').match(regExpNumberDocument)) {
      if (data[i].replaceAll(regExpOnlyNumber, '').length == 20) {
        parts.numberAccount = data[i].replaceAll(regExpOnlyNumber, '')
      }
    }

    // Pago movil
    if (
      data[i] != null &&
      data[i].length == 11 &&
      data[i].replaceAll(RegExp(/[^0-9]'/g), '').length == 11
    ) {
      parts.pagoMovil = data[i].replaceAll(RegExp(/[^0-9]/g), '')
    }

    // nombre
    if (
      data[i] != null &&
      data[i].match(regExpNumberDocument) == null &&
      data[i].length != 1 &&
      !wordsBlocked.includes(
        removeDiacritics(data[i]).toString().trim().toUpperCase()
      )
    ) {
      parts.name = capitalizeString(`${parts.name != null ? parts.name : ''} ${data[i]}`.trim())
    }

    // tipo de cuenta
    if (['AHORRO'].includes(data[i].toString().toUpperCase())) {
      parts.typeAccount = { typeAccount: 'Ahorro' }
    }

    if (['CORRIENTE'].includes(data[i].toString().toUpperCase())) {
      parts.typeAccount = { typeAccount: 'Corriente' }
    }

    // Numero documento
    if (
      data[i] != null &&
      /^\d+$/.test(data[i]) &&
      data[i].length < 11 &&
      data[i].length >= 6 &&
      parts.document == null
    ) {
      parts.document = data[i]
    }
    // Numero y tipo de documentos juntos
    if (data[i]) {
      const lastletters = data[i].substring(2, data[i].length)

      if (
        /^\d+$/.test(data[i].substring(0, 1)) == false &&
        /^\d+$/.test(lastletters) &&
        parts.document == null
      ) {
        parts.document = data[i]
          .substring(1, data[i].length)
          .replaceAll(RegExp(/[^0-9]+/g), '')
        parts.typeDocument =
          (data[i][0] + data[i][1]).toUpperCase() == 'CI' ? 'CI' : data[i][0]
      }
    }
  }

  return parts
}

export default detectDataBssAccount
