import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '2.5vw 1.6vw',
    fontSize: '3.4vw',
    color: 'white',
    '@media (min-width: 700px)': {
      fontSize: '1.4vw',
      padding: '0.9vw'
    },
    '& i': {
      marginBottom: '1vw',
      fontSize: '8vw',
      '@media (min-width: 700px)': {
        fontSize: '2vw'
      }
    },
    '&:hover': {
      transform: 'scale(1.1)'
    }
  }
})

export default function StatusButton ({ onClick, label, icon, color, active }) {
  const classes = useStyles()

  const styleActive = {
    transform: 'scale(1.3)',
    marginBottom: '5vw',
    marginTop: '5vw'
  }

  return (
    <div className={classes.container} onClick={onClick} style={{ background: color, ...(active ? styleActive : {}) }}>
      <i className={icon}></i>
      {label}
    </div>
  )
}
