import React, { useState } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import StatusButton from './StatusButton/StatusButton'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Select from 'react-select'

const useStyles = makeStyles({
  title: {
    fontWeight: '300',
    textAlign: 'center',
    marginTop: '6vw',
    fontSize: '3.5vw',
    '@media (min-width: 700px)': {
      fontSize: '1.5vw'
    }
  },
  status: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '6vw',
    '@media (min-width: 700px)': {
      justifyContent: 'space-around',
      marginTop: '3vw'
    }
  },
  close: {
    position: 'absolute',
    top: '5%',
    right: '5%',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)'
    }
  },
  gallery: {
    marginTop: '5vw',
    '@media (min-width: 700px)': {
      width: '80%',
      margin: 'auto'
    }
  }
})

export default function Index ({ onSubmit, user, handleClose, open }) {
  const classes = useStyles()

  const [statusSelected, setstatusSelected] = useState(
    user.verificationState.id
  )
  const [motiveSelected, setmotiveSelected] = useState([])

  const images = [
    {
      original: user.frontPhoto
    },
    {
      original: user.documentPhoto
    }
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 20,
      background: state.isSelected ? 'white' : 'white'
    }),
    control: (provied) => ({
      ...provied,
      background: 'white',
      border: 'none'
    })
  }

  const motives = [
    { value: 'Imagen borrosa', text: 'Imagen borrosa' },
    { value: 'Documento no legible', text: 'Documento no legible' },
    { value: 'Foto de perfil no aceptable', text: 'Foto de perfil no aceptable' }
  ]

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: { width: '85vw', margin: 0, padding: '0vw 5vw 5vw' }
      }}
    >

      <div>
        <h3 className={classes.title}>
          Selecciona el estado de verificacion de: <br />
          <b>{user.name}</b>
        </h3>

        <div className={classes.status}>
          <StatusButton
            color="#66B84D"
            onClick={() => setstatusSelected(1)}
            label="Aprobado"
            icon="fas fa-check-double"
            id={1}
            active={statusSelected == 1}
          />
          <StatusButton
            color="#EEAE39"
            onClick={() => setstatusSelected(3)}
            label="Pendiente"
            icon="fas fa-exclamation-triangle"
            id={3}
            active={statusSelected == 3}
          />
          <StatusButton
            color="#D4544C"
            onClick={() => setstatusSelected(2)}
            label="Rechazado"
            icon="fas fa-ban"
            id={2}
            active={statusSelected == 2}
          />
        </div>
        {statusSelected == 2 && (
          <div style={{ marginTop: '3vw', marginBottom: '4vw' }}>
            <Select
              styles={customStyles}
              onChange={(e) => {
                setmotiveSelected(e.map(val => val.text))
              }}
              getOptionValue={(op) => op.text}
              getOptionLabel={(op) => op.text}
              options={motives}
              isMulti={true}
              placeholder='Selecciona una razón'
            />
          </div>
        )}

        <div>
          <button
            onClick={() => {
              if (motiveSelected.length > 0 || statusSelected != 2) {
                onSubmit(statusSelected, motiveSelected)
              }
            }
            }
            style={{
              width: '100%',
              marginTop: '3vw',
              background: motiveSelected.length > 0 || statusSelected != 2 ? '#003353' : 'grey',
              border: 'none',
              color: 'white',
              fontWeight: 'bold',
              padding: '3vw 2vw',
              borderRadius: '5px',
              fontSize: '4vw'
            }}
          >
            Guardar
          </button>
        </div>
      </div>

      <div className={classes.gallery}>
        <ImageGallery
          showPlayButton={false}
          showFullscreenButton={false}
          items={images}
        />
      </div>
    </Dialog>
  )
}
