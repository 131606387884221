import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import "./index.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import HouseIcon from "../../assets/house.svg";

const useStyles = makeStyles({
  header: {
    height: 62,
    width: "100%",
    color: "#eee",
    paddingTop: "13px",
    paddingBottom: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    position: "fixed",
    backgroundColor: "#003353",
    zIndex: 3,
  },
  headerPhoto: {
    height: 70,
    width: "100vw",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1vw 3vw 1vw",
    backgroundColor: "#003f62",
    color: "#fff",
    zIndex: 3,
    marginTop: "10px",
  },
  responsiveSvg: {
    width: "6vw",
    maxWidth: "40px",
    overflow: "visible",
  },
});

const renderBreadCrumbs = (links = []) => {
  const items = [];

  if (!isEmpty(links)) {
    const current = links.pop();
    links.map((link, key) => {
      items.push(
        <Link
          key={key}
          style={{ color: "#888" }}
          color="inherit"
          to={{ pathname: link.href }}
        >
          {link.text}
        </Link>
      );

      return true;
    });

    items.push(
      <Typography
        key={links.length + 1}
        style={{ color: "#eee", fontSize: "13px" }}
      >
        {current.text}
      </Typography>
    );
  }

  return items;
};

const Index = ({ text, style, breadcrumbs, goBack, house = false, suffix }) => {
  const history = useHistory();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <Grid className={classes.header}>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "relative",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5vh",
              }}
            >
              <i
                onClick={() =>
                  goBack ? history.goBack() : history.push("/dashboard")
                }
                style={{ marginRight: "1vw", fontSize: "3vw" }}
                className="fas fa-chevron-left"
              ></i>

              <h3 style={{ fontSize: "3vw", fontWeight: "300" }}>Volver</h3>
            </div>
            <h2 style={{ fontSize: "5vw" }}>{text}</h2>
          </div>
          {house && (
            <div className="houseIconContainer">
              <img
                onClick={() => {
                  if (user.roleId != "2") {
                    history.push("/dashboard");
                  } else {
                    history.push("/operator-dashboard");
                  }
                }}
                src={HouseIcon}
                style={{
                  width: "6vw",
                }}
              />
              <p>Inicio</p>
            </div>
          )}
          {suffix}
        </Grid>
      </Grid>
    </>
  );
};

export default Index;
