import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'
import { validateResponse } from '../helpers/index'

export const bssbalanceSlice = createSlice({
  name: 'bssbalance',
  initialState: {
    count: [],
    rows: []
  },
  reducers: {
    setBankBalances: (state, action) => {
      state.rows = action.payload
    }
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

const { setBankBalances } = bssbalanceSlice.actions

export const getBankBalancesByUser = createAsyncThunk('getBankBalancesByUser', async dispatch => {
  try {
    const { data: responseDTO } = await Axios.get(process.env.REACT_APP_API_URL + 'bssbalance')

    dispatch(setBankBalances(responseDTO.data))
    return responseDTO.data
  } catch (error) {
    if (error.response != undefined) {
      validateResponse(error.response.data)
    }
  }
})

export const createBalance = async (operatorId, userBankId, amount, onDone, capture) => {
  try {
    await Axios.post(process.env.REACT_APP_API_URL + 'bssbalance/store',
      {
        operatorId: operatorId,
        userBankId: userBankId,
        amount: parseFloat(amount),
        capture: capture || ''
      })
      .then((response) => {
        onDone()
      })
  } catch (e) {
    return console.error(e.message)
  }
}

export default bssbalanceSlice.reducer
