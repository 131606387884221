import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import Loading from "../components/loaders/loading";
import Header from "../components/operador/Header";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    overflow: "auto",
  },
  row: {
    display: "block",
    width: "100%",
  },
  image: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "15px",
  },
});

const Index = ({ children, colorMode = "#003f62", onTapReload }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} style={{ backgroundColor: colorMode }}>
      <Loading />
      <div className={classes.row}>
        <Header text="Tranferencias" onTapReload={onTapReload} />
      </div>
      <div className={classes.row}>
        <Container>{children}</Container>
      </div>
    </Grid>
  );
};

export default Index;
