import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import OperatorLayout from "../../layouts/operator";
import { getAllLastTransactions } from "../../slices/transactions";
import Typography from "@material-ui/core/Typography";
import TransactionCard from "../../components/operador/transactionCard";
import { isEmpty } from "lodash";
import BottomNavigator from "./../../layouts/bottomBar/index";
import PullToRefresh from "react-simple-pull-to-refresh";
import LoadingIcons from "react-loading-icons";
import io from "socket.io-client";
import AppContext from "./../../context/AppContext";
import { Fab } from "react-tiny-fab";
import TotalsContainerOperator from "./components/totals";
import { getBankBalancesByUser } from "../../slices/bssbalance";
import Screenloading from "../../components/loaders/screenLoading";

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    minHeight: "100vh",
    backgroundColor: "#FA7D4A",
  },
  content: {
    paddingInline: 15,
    marginTop: 220,
  },
  transfer: {
    marginTop: 10,
    marginBottom: 10,
    padding: 15,
    borderRadius: 10,
  },
  status: {
    display: "flex",
    justifyContent: "flex-end",
  },
  statusBtn: {
    width: 122,
    color: "#fff",
    textTransform: "none",
    fontSize: 12,
    fontWeight: 600,
  },
  card: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "13px",
    paddingBottom: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
});
function Dashboard() {
  const history = useHistory();
  const { addEvent } = useContext(AppContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { emitEvent } = useContext(AppContext);
  const [transferenceNowId, settransferenceNowId] = useState(0);

  const bssBalances = useSelector((state) => state.bssbalance.rows);
  const { pendings, assignedToday } = useSelector(
    (state) => state.transactions.lastTransactions
  );

  const [loading, setLoading] = useState(false);
  const [pendingReversed, setpendingReversed] = useState([]);
  const [pendingsArray, setpendingsArray] = useState([]);
  const [assignedTodayArray, setassignedTodayArray] = useState([]);

  const getData = async () => {
    setLoading(true);
    dispatch(getBankBalancesByUser(dispatch)).then(() => {
      dispatch(getAllLastTransactions(dispatch)).then(() => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setpendingsArray(pendings);
  }, [pendings]);

  useEffect(() => {
    setassignedTodayArray(assignedToday);
  }, [assignedToday]);

  const refresh = async () => {
    await dispatch(await getAllLastTransactions(dispatch));
  };

  return (
    <OperatorLayout colorMode="#FA7D4A" onTapReload={getData}>
      {loading && <Screenloading label="Cargando"></Screenloading>}

      <div>
        <PullToRefresh
          refreshingContent={refreshingContent}
          onRefresh={refresh}
        >
          <div style={{ paddingBottom: "49vh" }}>
            <TotalsContainerOperator
              bssBalances={bssBalances}
              transactions={assignedTodayArray}
            />

            <Typography
              style={{ color: "#fff", marginTop: "3vw", marginBottom: "2vw" }}
            >
              Transferencias pendientes
            </Typography>

            {isEmpty(pendingsArray) ? (
              <table
                className={classes.card}
                style={{ backgroundColor: "#fff" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        verticalAlign: "middle",
                        color: "#175775",
                        textAlign: "center",
                      }}
                    >
                      Sin transferencias pendientes
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              pendingsArray.map((transaction) => {
                return (
                  <TransactionCard
                    key={JSON.stringify(transaction)}
                    onClick={(id) => settransferenceNowId(id)}
                    transaction={transaction}
                  />
                );
              })
            )}
            <br />
            <Typography
              style={{ color: "#fff", marginTop: "3vw", marginBottom: "2vw" }}
            >
              Transferencias realizadas Hoy
            </Typography>
            {isEmpty(assignedTodayArray) ? (
              <table
                className={classes.card}
                style={{ backgroundColor: "#fff" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        verticalAlign: "middle",
                        color: "#175775",
                        textAlign: "center",
                      }}
                    >
                      Sin transferencias realizadas hoy
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              assignedTodayArray.map((transaction) => {
                return (
                  <TransactionCard
                    key={JSON.stringify(transaction)}
                    finished={true}
                    transaction={transaction}
                  />
                );
              })
            )}
          </div>
        </PullToRefresh>

        <Fab
          icon={<i style={{ fontSize: "11vw" }} className="fas fa-plus"></i>}
          alwaysShowTitle={true}
          style={{
            bottom: "13vh",
            right: "0vw",
          }}
          mainButtonStyles={{
            background: "#f96123",

            height: "9vh",
            width: "9vh",
          }}
          onClick={() => history.push("/contacts-transferences")}
        ></Fab>
      </div>
      <BottomNavigator></BottomNavigator>
    </OperatorLayout>
  );
}

const refreshingContent = <LoadingIcons.TailSpin />;

export default Dashboard;
