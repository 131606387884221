import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import UserSpecificDetailsComponent from '../../../components/users details/oneUser'

export default function UserSpecificDetails () {
  const dispatch = useDispatch()
  const location = useLocation()

  const [loading, setloading] = useState(false)
  const [user, setuser] = useState({})

  useEffect(() => {
    const { user } = location
    setuser(user)
  }, [])

  const props = {
    user,
    loading
  }

  return (
    <UserSpecificDetailsComponent {...props}/>
  )
}
