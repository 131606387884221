import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    objState: {
      show: false
    }
  },
  reducers: {
    setShow: (state, action) => {
      state.objState.show = action.payload.show
    }
  }
})

const { setShow } = loadingSlice.actions

export const setEvent = () => (dispatch) => {
  (() => {
    /*    const origOpen = XMLHttpRequest.prototype.open
    XMLHttpRequest.prototype.open = function () {
      dispatch(setShow({ show: true }))
      this.addEventListener('load', function (e) {
        dispatch(setShow({ show: false }))
      }, true)
      origOpen.apply(this, arguments)
    } */
  })()
}

export default loadingSlice.reducer
