import "./index.scss";
import React, { useEffect, useState, useCallback } from "react";
import Header from "../../../components/admin/Header";
import { useDispatch, useSelector } from "react-redux";
import Screenloading from "./../../../components/loaders/screenLoading";
import { Generator } from "./Generator";
import { Button } from "@mui/material";
import Select from "react-select";
import { GenerateRateContext } from "../../../context/GenerateRateContext";
import { makeStyles, Typography } from "@material-ui/core";
import { fetchRates } from "../../../slices/rates";
import RateOfDayCountrySelector from "./RateOfDayCountrySelector";
import RateOfDayCurrencySelector from "./RateOfDayCurrency";

const Index = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [generatedRates, setGeneratedRates] = useState({
    costRate: 0,
    publicRate: 0,
    personalRate: 0,
  });

  const currencies = useSelector((state) => state.currencies.rows);

  const [requestedCurrency, setRequestedCurrency] = useState(null);
  const [currencyName, setCurrencyName] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const action = useCallback(
    (values) => {
      if (values.id) {
        setLoading(true);
        setCurrencyName(values.name);
        setRequestedCurrency(values.id);
        dispatch(fetchRates(values.id));
      }
    },
    [requestedCurrency]
  );

  useEffect(() => {
    setLoading(true);
    if (currencies.length > 0) {
      // Set by default the currencyId from the chilean peso '1'
      const chileanPeso = currencies.find((el) => el.abbr === "CLP");
      setCurrencyName(chileanPeso.name);
      setRequestedCurrency(chileanPeso.id);
      dispatch(fetchRates(chileanPeso.id));
    }
  }, [currencies]);

  return (
    <div className="dayratescreen">
      <GenerateRateContext.Provider
        value={{
          loading,
          currencyName,
          currencyId: requestedCurrency,
          setRequestedCurrency: setRequestedCurrency,
          setLoading,
          generatedRates,
          setGeneratedRates,
        }}
      >
        <div>
          {loading && <Screenloading dark></Screenloading>}
          <Header
            text="Tasa del día"
            withoutPhoto={true}
            suffix={
              <RateOfDayCountrySelector
                setSelectedCountry={setSelectedCountry}
                selectedCountry={selectedCountry}
              />
            }
          />
          <main
            className="containerRates"
            style={{ height: "100vh", paddingTop: "8vh" }}
          >
            <RateOfDayCurrencySelector
              action={action}
              country={selectedCountry}
            />
            <Generator />
          </main>
        </div>
      </GenerateRateContext.Provider>
    </div>
  );
};

export default Index;
