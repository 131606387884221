import React from 'react'
import Verifications from '../../components/verifications/index.js'

export default function Index () {
  return (
        <>
            <Verifications/>
        </>
  )
}
