// Dependencies
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

// Layouts
import BottomNavigator from "../../layouts/bottomBar/indexAdmin";

// Components
import Item from "./balance_item/item-users";
import InputSearch from "./../form/searchField/index";
import Header from "./Header";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    padding: "9vw 5vw 20vh 5vw",
    "& header": {
      textAlign: "center",
    },
    "& .rtf": {
      right: "0% !important",
      bottom: "15vh !important",
    },
  },
}));

export const AllUserScreen = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const { users, selectUser, search } = props;

  return (
    <>
      <div className={classes.container}>
        <Header goBack={history.goBack} title="Usuarios"></Header>

        <div style={{ marginTop: "6vw", marginBottom: "7vw" }}>
          <InputSearch onSubmit={search} onChange={search} />
        </div>

        {users != null ? (
          users?.map((e) => {
            return (
              <div key={e.id} onClick={() => selectUser(e)}>
                <Item data={e}></Item>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
      <BottomNavigator></BottomNavigator>
    </>
  );
};
