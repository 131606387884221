import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, SvgIcon } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Screenloading from "../../components/loaders/screenLoading";
import Select from "react-select";
import { fetchBanks } from "../../slices/banks";
import { DataAccountInputs } from "./DataAccountInputs";
import { createBssaccountsAndUserBank } from "./../../slices/bssaccount";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import detectDataBssAccount from "./../../utils/detectDataBssAccount";

const fieldRequired = "Este campo es requerido";

const schema = z.object({
  nombre_del_titular: z.string().nonempty({ message: fieldRequired }),
  tag: z.string().nonempty({ message: fieldRequired }),
  documento: z.string().nonempty({ message: fieldRequired }),
  numero_de_cuenta: z
    .string()
    .min(16, { message: "Debe contener 16 caracteres" }),
});

const DOCTypes = [
  { value: "1", title: "V", origin: "Venezolano" },
  { value: "1", title: "CI", origin: "Venezolano" },
  { value: "2", title: "E", origin: "Extranjero" },
  { value: "3", title: "J", origin: "RIF" },
  { value: "4", title: "G", origin: "Gubernamental" },
];

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    minHeight: "100vh",
    backgroundColor: "#FA7D4A",
  },
  content: {
    padding: 30,
    paddingTop: 15,
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bankLogo: {
    width: "30%",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 60,

    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  bancoheader: {
    display: "flex",
    alignItems: "center",
    marginLeft: "5vw",
    marginTop: "5vw",
    "& h4": {
      color: "white",
      marginLeft: "3vw",
    },
  },
  input: {
    border: "white",
    fontSize: "4vw",
    marginBottom: "2vw",
    marginTop: "2vw",
    paddingBottom: "1.2vw",
    borderBottom: "1px solid #a9d9f4",
  },
});

function HeaderReturn(props) {
  return (
    <div
      onClick={() => props.goBack()}
      style={{
        display: "flex",
        padding: 15,
        alignItems: "center",
        color: "white",
        "& svg": {
          width: "4%",
          height: "10%",
        },
      }}
    >
      <SvgIcon viewBox="0 0 14.565 24">
        <path
          id="FontAwsome_chevron-left_"
          data-name="FontAwsome (chevron-left)"
          d="M27.879,48.73,38.56,38.049a1.319,1.319,0,0,1,1.865,0l1.246,1.246a1.319,1.319,0,0,1,0,1.863l-8.465,8.5,8.465,8.5a1.319,1.319,0,0,1,0,1.863l-1.246,1.246a1.319,1.319,0,0,1-1.865,0L27.879,50.595A1.319,1.319,0,0,1,27.879,48.73Z"
          transform="translate(-27.493 -37.662)"
          fill="#fff"
        />
      </SvgIcon>
      <h4
        style={{
          marginLeft: 9,
          fontWeight: "lighter",
        }}
      >
        Volver
      </h4>
    </div>
  );
}

function InputTextWithoutBackground({ register, errors }) {
  return (
    <div>
      <input
        autoComplete="off"
        type="text"
        className="inputCreateAccountOperators"
        placeholder="Ej: Cuenta de Mary"
        {...register("tag")}
        style={{
          width: "92vw",
          marginLeft: "4vw",
          marginRight: "4vw",
          marginTop: "8vw",
          marginBottom: "2vw",
          background: "none",
          padding: "0 0 1vw",
          fontStyle: "italic",
          fontWeight: "bold",
          border: "none",
          borderBottom: "2.5px solid #ffffffb3",
          color: "#DEDEDE",
          fontSize: "7vw",
        }}
      />
      <small
        style={{
          marginLeft: "4vw",
          color: "#003f62",
        }}
      >
        Etiqueta de la cuenta
      </small>
      <p
        style={{
          marginTop: "2vw",
          fontSize: "3vw",
          color: "#c82f2f",
          marginLeft: "4vw",
        }}
      >
        {errors?.tag?.message}
      </p>
    </div>
  );
}

function OptionComponent({ innerRef, innerProps, data }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "15% 70%",
        padding: "1.5vw 4vw 1vw 3vw",
        alignItems: "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      }}
    >
      <img src={data.Logo} style={{ width: "100%" }}></img>
      <h1 style={{ fontSize: 15, marginLeft: "3vw", fontWeight: "normal" }}>
        {data.alias}
      </h1>
    </div>
  );
}

function SelectBank({ data, onChange, bankSelected }) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      padding: 20,
      background: state.isSelected ? "white" : "white",
    }),
    control: (provied) => ({
      ...provied,
      background: "white",
      border: "none",
    }),
  };

  const [menuIsOpen, setmenuIsOpen] = useState(false);

  const OptionComponent = ({ innerRef, innerProps, data }) => {
    return (
      <div
        onClick={() => {
          setmenuIsOpen(!menuIsOpen);
          onChange(data);
        }}
        style={{
          display: "grid",
          gridTemplateColumns: "15% 70%",
          padding: "1.5vw 4vw 1vw 3vw",
          alignItems: "center",
          borderRadius: "6px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
        }}
      >
        <img src={data.Logo} style={{ width: "100%" }}></img>
        <h1 style={{ fontSize: 15, marginLeft: "3vw", fontWeight: "normal" }}>
          {data.alias}
        </h1>
      </div>
    );
  };

  const Control = () => {
    return (
      <div
        onClick={() => setmenuIsOpen(!menuIsOpen)}
        style={{
          display: "grid",
          backgroundColor: "white",
          gridTemplateColumns: "15% 70%",
          padding: "1.5vw 4vw 1vw 3vw",
          borderRadius: "6px",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
        }}
      >
        {data != null && data[0] != null ? (
          <>
            <img
              src={bankSelected.Logo || data[0].Logo}
              style={{ width: "100%" }}
            ></img>
            <h1
              style={{ fontSize: 15, marginLeft: "3vw", fontWeight: "normal" }}
            >
              {bankSelected.alias || data[0].alias}
            </h1>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        setmenuIsOpen(!menuIsOpen);
        console.log(menuIsOpen);
      }}
      style={{
        padding: "4vw",
      }}
    >
      <div>
        <Select
          onChange={(e) => onChange(e)}
          getOptionLabel={(e) => e.alias}
          placeholder="Seleccionar banco"
          options={data}
          menuIsOpen={menuIsOpen}
          components={{ Option: OptionComponent, Control }}
          styles={customStyles}
        />
      </div>
    </div>
  );
}

function ButtonBottom(props) {
  return (
    <Grid className={props.classes.content}>
      <button
        type="submit"
        className={props.classes.footer}
        style={{
          backgroundColor: "#003F62",
        }}
      >
        <Typography
          style={{
            color: "#fff",
            fontWeight: 500,
          }}
        >
          Guardar cuenta
        </Typography>
      </button>
    </Grid>
  );
}

function Title(props) {
  return (
    <div className={props.bancoheader}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.036"
        height="30"
        viewBox="0 0 33.036 30"
      >
        <g id="bank-solid-badged" transform="translate(-1.964 -1)">
          <path
            id="Path_3502"
            data-name="Path 3502"
            d="M32.85,27H32v-.85A1.15,1.15,0,0,0,30.85,25H28V17.63H24V25H20V17.63H16V25H12V17.63H8V25H5.15A1.15,1.15,0,0,0,4,26.15V27H3.15A1.15,1.15,0,0,0,2,28.15V31H34V28.15A1.15,1.15,0,0,0,32.85,27Z"
            fill="#fff"
          />
          <path
            id="Path_3503"
            data-name="Path 3503"
            d="M30,13.5A7.47,7.47,0,0,1,24.39,11H22.15l-3.9-2.24L14.35,11H11.14l6.72-3.86a.8.8,0,0,1,.8,0l5,2.87A7.45,7.45,0,0,1,22.5,6V5.52L18,2.92,2.5,11.83a1,1,0,1,0,1,1.73L5,12.68V16H31V13.42a7.53,7.53,0,0,1-1,.08Z"
            fill="#fff"
          />
          <path
            id="Path_3504"
            data-name="Path 3504"
            d="M31.94,13.24l.56.32a1,1,0,0,0,1.44-1.19,7.45,7.45,0,0,1-2,.87Z"
            fill="#fff"
          />
          <path
            id="Path_3505"
            data-name="Path 3505"
            d="M35,6a5,5,0,1,1-5-5,5,5,0,0,1,5,5Z"
            fill="#fff"
          />
        </g>
        <path
          id="add"
          d="M8.5,3A5.5,5.5,0,1,0,14,8.5,5.5,5.5,0,0,0,8.5,3Zm2.75,6.05H9.05v2.2H7.95V9.05H5.75V7.95h2.2V5.75h1.1v2.2h2.2Z"
          transform="translate(19.478 -3)"
          fill="#e30000"
        />
      </svg>

      <h4>Crear nueva cuenta</h4>
    </div>
  );
}

function Detail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const History = useHistory();

  const banks = useSelector((state) => state.banks.banks);
  const user = useSelector((state) => state.auth.user);

  const [loading, setloading] = useState(false);
  const [formData, setformData] = useState({ typeAccount: "Corriente" });
  const [bankSelected, setbankSelected] = useState({});
  const [documentTypeSelected, setdocumentTypeSelected] = useState({
    value: "1",
    title: "V",
    origin: "Venezolano",
  });
  const [numberFourBank, setnumberFourBank] = useState("");

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchBanks());
  }, []);

  const createBssAccountFunct = async (hookForm) => {
    setloading(true);

    const data = {
      userId: user.id,
      bankId: bankSelected.id || 10,
      userBank: {
        name: hookForm.tag,
      },
      bssAccount: {
        clientId: null,
        idTypeId: documentTypeSelected.value || 1,
        name: hookForm.nombre_del_titular
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim(),
        lastName: " ",
        email: "email@gmail.com",
        identification: hookForm.documento,
        number: hookForm.numero_de_cuenta,
        bssAccountTypeId: formData.typeAccount == "Ahorro" ? 1 : 2,
        phone: "11111111111",
      },
    };

    const response = await createBssaccountsAndUserBank(data);

    History.push("/balances");
    setloading(false);
  };

  const onSelectBank = (e) => {
    setbankSelected(e);
    setnumberFourBank(e.prefix.substring(0, 4));
  };

  const onChangeInputNumberAccount = (value) => {
    console.log("value", value.length);
    if (value && value.length > 20) {
      const parts = detectDataBssAccount(value);
      console.log("parts", parts);

      setValue("nombre_del_titular", parts.name);
      setValue("documento", parts.document);

      if (parts.typeDocument) {
        const documentSelected = DOCTypes.find(
          (e) => e.title == parts.typeDocument.toUpperCase()
        );
        setdocumentTypeSelected(documentSelected);
      }

      console.log("parts", parts);

      if (parts.typeAccount) {
        setformData({ ...formData, ...parts.typeAccount });
      }

      if (parts.numberAccount) {
        const eCut = parts.numberAccount.substring(0, 4);
        setValue(
          "numero_de_cuenta",
          parts.numberAccount.substring(4, parts.numberAccount.length)
        );
        setnumberFourBank(eCut);

        const bankSelected = banks.find((bank) => bank.prefix == eCut);

        if (bankSelected) {
          setbankSelected(bankSelected);
        }
      } else {
        setValue("numero_de_cuenta", "");
      }
    } else if (value && value.replace(/[^0-9]/g, "").length > 16) {
      setValue(
        "numero_de_cuenta",
        value.replace(/[^0-9]/g, "").substring(0, 16)
      );
    } else {
      console.log("se paso");
      // setValue('numero_de_cuenta', value.replace(/[^0-9]/g, ''))
      setValue("numero_de_cuenta", value.replace(/[^0-9]/g, ""));
    }
    /*
       } else if (value && value.length > 16) {
      setValue('numberAccount', value.substring(0, 16))
    } else {
      console.log("value.replace(/[^0-9]/g, '')", value.replace(/[^0-9]/g, ''))

    } */
  };

  return (
    <>
      <Grid container className={classes.container}>
        <HeaderReturn goBack={History.goBack}></HeaderReturn>

        <Title bancoheader={classes.bancoheader}></Title>

        <div
          style={{
            marginTop: 10,
            backgroundColor: "rgba(255, 255, 255, 0.36)",
            width: "102%",
            height: "1px",
            marginLeft: "-1%",
          }}
        />

        <form onSubmit={handleSubmit((d) => createBssAccountFunct(d))}>
          <InputTextWithoutBackground
            errors={errors}
            register={register}
          ></InputTextWithoutBackground>

          <SelectBank
            data={banks}
            bankSelected={bankSelected}
            onChange={onSelectBank}
          ></SelectBank>

          <DataAccountInputs
            formData={formData}
            setFormData={setformData}
            classes={classes}
            numberFourBank={numberFourBank}
            register={register}
            onChangeInputNumberAccount={onChangeInputNumberAccount}
            errors={errors}
            documentTypeSelected={documentTypeSelected}
            setdocumentTypeSelected={setdocumentTypeSelected}
          ></DataAccountInputs>

          <ButtonBottom classes={classes}></ButtonBottom>
        </form>
      </Grid>

      {loading ? <Screenloading></Screenloading> : <></>}
    </>
  );
}

export default Detail;
