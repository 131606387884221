import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Axios } from "../helpers/axios";

export const fetchVerifications = createAsyncThunk("verification", async () => {
  return Axios.get(process.env.REACT_APP_API_URL + "verification").then(
    (response) => {
      return response.data.data;
    }
  );
});

export const updateVerification = async (data) => {
  return await Axios.put(
    process.env.REACT_APP_API_URL + "verification/update/" + data.id,
    data
  ).then((response) => response.data);
};

export const createVerification = createAsyncThunk(
  "createVerification",
  async (data) => {
    await Axios.post(
      process.env.REACT_APP_API_URL + "verification/store",
      data
    ).then((response) => response.data);
  }
);

export const verificationsSlice = createSlice({
  name: "verifications",
  initialState: {
    verifications: [],
  },
  extraReducers: {
    [fetchVerifications.fulfilled]: (state, action) => {
      state.verifications = action.payload;
    },
  },
});

export default verificationsSlice.reducer;
