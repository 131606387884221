import React, { useEffect, useState } from 'react'
import Balances from '../../../components/balances/indexAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getBalances, createBalances } from '../../../slices/balance'
import Createbalance from './../../../components/balances/modals/createBalance'
import { uploadFile } from './../../../slices/files'
import Screenloading from './../../../components/loaders/screenLoading'

export default function Index () {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector((state) => state.auth.user)
  const balances = useSelector((state) => state.balance.balances)

  const [showModalCreate, setshowModalCreate] = useState(false)
  const [path, setpath] = useState('')
  const [Amount, setAmount] = useState('')
  const [image, setimage] = useState('')
  const [loading, setloading] = useState(false)
  const [search, setsearch] = useState('')
  const [userSelected, setuserSelected] = useState({})

  useEffect(() => {
    setloading(true)
    dispatch(getBalances()).then((e) => {
      setloading(false)
    })
  }, [])

  const handleCreateBalance = async (hookForm) => {
    setshowModalCreate(false)
    setloading(true)

    dispatch(uploadFile(image)).then(async (e) => {
      let pathURL = ' '
      if (e?.payload?.original) {
        pathURL = e.payload.original.location
      }

      const data = {
        userId: userSelected.user ? userSelected.user.id : user.id,
        paymentId: null,
        currencyId: userSelected.currencyId ? userSelected.currencyId : 1,
        amount: parseInt(Amount),
        capture: pathURL
      }

      await createBalances(data)

      setAmount(0)
      setpath('')
      setimage('')

      dispatch(getBalances())
      setuserSelected({ a: '' })

      setloading(false)
    })
  }

  const onSearch = async (e) => {
    e.preventDefault()

    setloading(true)
    dispatch(getBalances({ query: search })).then((e) => {
      setloading(false)
    })
  }

  const selectUser = async (e) => {
    console.log('e', e)
    setshowModalCreate(true)
    setuserSelected(e)
  }

  const props = {
    dispatch,
    balances,
    history,
    openModal: setshowModalCreate,
    search: onSearch,
    selectUser: selectUser,
    setsearch
  }

  const handleClose = () => {
    setshowModalCreate(false)
    setuserSelected({ a: '' })
  }

  const propsModal = {
    handleClose,
    open: showModalCreate,
    handleCreateBalance,
    path,
    setpath,
    setAmount,
    Amount,
    setimage,
    userSelected
  }

  return (
    <div>
      {loading && <Screenloading></Screenloading>}

      <Balances {...props} />
      <Createbalance {...propsModal}></Createbalance>
    </div>
  )
}
