import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Grid, Typography, Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "../components/menu/Menu";
import { useHistory } from "react-router-dom";
import SectionLayoutReturnHeader from "../layouts/section_layout/section_layout_return_header";

const useStyles = makeStyles({
  header: {
    height: 70,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    backgroundColor: "#003f62",
    color: "#fff",
    "@media (min-width: 700px)": {
      width: "98vw",
    },
  },
});

function Header({ text, style, returnHeader }) {
  const history = useHistory();
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Grid container className={classes.header} style={style || {}}>
      <Grid item style={{ display: "flex", alignItems: "center" }}>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Menu setOpenDrawer={setOpenDrawer} />
        </Drawer>
        {!returnHeader ? (
          <IconButton
            aria-label="menu"
            style={{ color: "#fff", padding: 0, marginRight: 15 }}
            onClick={() => setOpenDrawer(true)}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        ) : (
          <SectionLayoutReturnHeader text={text} />
        )}
        {text && !returnHeader && (
          <Typography style={{ fontSize: 20, fontWeight: 500 }}>
            {text}
          </Typography>
        )}
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
}

export default Header;
