import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreatecontactComponent from '../../components/contacts/create'
import { fetchBanks } from '../../slices/banks'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import {
  createBssaccounts,
  updateBssaccounts
} from './../../slices/bssaccount'
import detectDataBssAccount from './../../utils/detectDataBssAccount'
import { useHistory, useLocation } from 'react-router-dom'
import { getBalances } from './../../slices/balance'
import capitalizeString from '../../helpers/strings'

const fieldRequired = 'Este campo es requerido'
const DOCTypes = [
  { value: '1', title: 'V', origin: 'Venezolano' },
  { value: '1', title: 'CI', origin: 'Venezolano' },
  { value: '2', title: 'E', origin: 'Extranjero' },
  { value: '3', title: 'J', origin: 'RIF' },
  { value: '4', title: 'G', origin: 'Gubernamental' }
]

const schema = z.object({
  nombre_del_titular: z.string().nonempty({ message: fieldRequired }),
  documento: z.string().nonempty({ message: fieldRequired }),
  phone: z.string().nonempty({ message: fieldRequired }),
  numberAccount: z
    .string()
    .length(16, { message: 'Debe contener 16 caracteres' })
})

function Createcontact () {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const [loading, setloading] = useState(false)
  const [bankSelected, setbankSelected] = useState({})
  const [pagoMovil, setpagoMovil] = useState('')

  const [formData, setFormData] = useState({ typeAccount: 'Corriente' })
  const [documentTypeSelected, setdocumentTypeSelected] = useState(DOCTypes[0])
  const [numberFourBank, setnumberFourBank] = useState('0134')
  const [allowPagoMovil, setAllowPagoMovil] = useState(false)
  const [isEditing, setisEditing] = useState(false)

  const banks = useSelector((state) => state.banks.banks)
  const user = useSelector((state) => state.auth.user)
  const bssAccountToEdit = location?.state?.user

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(schema)
  })

  useEffect(() => {
    if (location.pathname == '/contacts-transferences/edit') {
      setisEditing(true)

      if (bssAccountToEdit.number.substring(0, 16) == '1234567898745632') {
        setAllowPagoMovil(true)
        setValue('phone', bssAccountToEdit.phone)
        setpagoMovil(bssAccountToEdit.phone)
      } else {
        setbankSelected(bssAccountToEdit.bank)
        setnumberFourBank(bssAccountToEdit.bank.prefix)
        setValue('numberAccount', bssAccountToEdit.number.substring(0, 16))
      }
      setValue('documento', bssAccountToEdit.identification)
      setValue('nombre_del_titular', bssAccountToEdit.name)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    let number = '1234567898745632'
    let phone = '11111111111'

    let globalResult = await trigger(['documento', 'nombre_del_titular'])

    if (allowPagoMovil) {
      globalResult = await trigger([
        'documento',
        'nombre_del_titular',
        'phone'
      ])
      phone = getValues().phone
    } else {
      number = getValues().numberAccount
      globalResult = await trigger([
        'documento',
        'nombre_del_titular',
        'numberAccount'
      ])
    }

    if (globalResult) {
      setloading(true)
      try {
        const data = {
          idTypeId: documentTypeSelected.value || 1,
          name: capitalizeString(
            getValues()
              .nombre_del_titular.normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .trim()
          ),
          lastName: ' ',
          identification: getValues().documento,
          number,
          bssAccountTypeId: formData.typeAccount == 'Ahorro' ? 1 : 2,
          phone,
          bankId: bankSelected.id || 10,
          userId: user.id
        }
        console.log('data', data)

        if (isEditing) {
          const response = await updateBssaccounts(data, bssAccountToEdit.id)
        } else {
          const response = await createBssaccounts(data)
          localStorage.setItem(
            'contactCreatedNow',
            JSON.stringify(response.data)
          )
        }
        history.push('/contacts-transferences')
        setloading(false)
      } catch (error) {
        console.log(error)
        setloading(false)
      }
    }
  }

  const onSelectBank = (e) => {
    setbankSelected(e)
    setnumberFourBank(e.prefix.substring(0, 4))
  }

  const onChangeInputNumberAccount = (e) => {
    const value = e.target.value

    if (value && value.length > 20) {
      const parts = detectDataBssAccount(value)

      setValue('nombre_del_titular', parts.name)
      setValue('documento', parts.document)

      if (parts.pagoMovil) {
        setpagoMovil(parts.pagoMovil)
        setAllowPagoMovil(true)
      } else {
        setAllowPagoMovil(false)
      }

      if (parts.typeDocument) {
        const documentSelected = DOCTypes.find(
          (e) => e.title == parts.typeDocument.toUpperCase()
        )
        setdocumentTypeSelected(documentSelected)
      }

      if (parts.typeAccount) {
        setFormData({ ...formData, ...parts.typeAccount })
      }

      if (parts.numberAccount) {
        const eCut = parts.numberAccount.substring(0, 4)
        setValue(
          'numberAccount',
          parts.numberAccount.substring(4, parts.numberAccount.length)
        )
        setnumberFourBank(eCut)

        const bankSelected = banks.find((bank) => bank.prefix == eCut)

        if (bankSelected) {
          setbankSelected(bankSelected)
        }
      }
    } else if (value && value.length > 16) {
      setValue('numberAccount', value.substring(0, 16))
    } else {
      console.log("value.replace(/[^0-9]/g, '')", value.replace(/[^0-9]/g, ''))

      setValue('numberAccount', value.replace(/[^0-9]/g, ''))
    }
  }

  const handleSetAllowPagoMovil = (e) => {
    setAllowPagoMovil(e)
  }

  const props = {
    loading,
    banks,
    onSubmit,
    bankSelected,
    onSelectBank,
    formData,
    documentTypeSelected,
    setdocumentTypeSelected,
    setFormData,
    numberFourBank,
    handleSubmit,
    getValues,
    register,
    errors,
    allowPagoMovil,
    setAllowPagoMovil: handleSetAllowPagoMovil,
    onChangeInputNumberAccount,
    pagoMovil,
    setpagoMovil
  }

  return <CreatecontactComponent {...props} />
}

export default Createcontact
