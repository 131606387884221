import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { formatNumber } from './../../../helpers/numbers'

const useStyles = makeStyles(() => ({
  container: {
    border: '2px solid #D2D2D2',
    margin: '3vw 0 3vw 0',
    display: 'flex',
    padding: '3vw 5vw 3vw 2vw',
    borderRadius: '10px',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%'
  }
}))

export default function Index ({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {data[0] && data[0].operator && <>
      <div>
        <h4 style={{ color: '#FA7D4A', fontWeight: 'bold' }}>
          {data[0].operator.name}
        </h4>
      </div>
      <div style={{ width: '100%' }}>
        {data.map((bssbalance) => {
          return (
            <div
            key={JSON.stringify(bssbalance)}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1vw',
                marginTop: '1vw'
              }}
            >
              {' '}
              <span>{bssbalance.bankAlias}</span>{' '}
              <span>$ {formatNumber(bssbalance.total)}</span>{' '}
            </div>
          )
        })}
      </div>
      </>}
    </div>
  )
}
