import React from 'react'
import Accountsbank from '../../components/accounts/accountsbnak'

export default function Index () {
  return (
        <div>
            <Accountsbank></Accountsbank>
        </div>
  )
}
