import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import labels from './../labels'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    background: '#ffffff1a',
    padding: '2vw 2vw',
    justifyContent: 'space-between',
    borderRadius: 5,
    marginBottom: '3vw'
  }
})

export function NumberConfig ({ config, handleEdit }) {
  const classes = useStyles()
  const [value, setvalue] = useState(config.value)

  const onChange = e => {
    // eslint-disable-next-line prefer-regex-literals
    setvalue(e.replaceAll(RegExp(/[^0-9]+/g), ''))
    handleEdit({ value: e, config: config })
  }

  useEffect(() => {
    setvalue(config.value)
  }, [config])

  return (
    <div className={classes.grid}>
      <div style={{ width: '100vw' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h4 style={{ color: '#ff7c46', width: '69%' }}>{labels[config.key]}</h4>
          <div
            style={{
              display: 'flex',
              background: 'white none repeat scroll 0% 0%',
              borderRadius: '5px',
              padding: '1vw 2vw 1vw 3vw',
              width: '25vw'
            }}
          >
            <input
              type="text"
              placeholder="Nuevo valor..."
              name="title"
              inputMode="number"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              style={{ border: 'none', width: '90%' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
