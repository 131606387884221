// Dependencies
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Components
import MainLayout from '../../layouts/main'

// Slices
import { getCurrencyDetails, updateCurrency } from '../../slices/currencies'
import { getAllCountries } from '../../slices/countries'
import { CurrencyForm, CurrencyValuesForm } from '../../components/currencies'

const useStyles = makeStyles({
  mainContainer: {
    minHeight: '90vh',
    paddingTop: '2.5%',
    padding: '0 3.5% 0'
  }
})

const CurrencyDetails = () => {
  // Styles
  const classes = useStyles()

  // React router params
  const params = useParams()

  // States
  // Currency States
  const [currency, setCurrency] = useState(null)

  // Country states
  const [countries, setCountries] = useState(null)

  useEffect(() => {
    if (!currency) {
      // Fetch the currency details
      getCurrencyDetails(params.id).then((value) => {
        setCurrency(value)
      })
    }
    if (!countries) {
      // Fetch the available countries
      getAllCountries().then((value) => {
        setCountries(value)
      })
    }
  }, [params.id])

  const currencyFormSubmit = useCallback(async (values) => {
    // Format the data to send it to the server
    const formattedData = {
      name: values.name,
      abbr: values.abbr,
      countryId: values.country.id,
      id: params.id
    }

    // Update the currency
    const result = await updateCurrency(formattedData)
    setCurrency(prevState => {
      return {
        ...prevState,
        name: result.name,
        abbr: result.abbr,
        countryId: result.countryId
      }
    })
  }, [])

  return (
    <MainLayout text="Detalles de la moneda" skipPadding={true} goBack={true}>
    <main className={classes.mainContainer}>
      <CurrencyForm currency={currency} countries={countries} isEdit onSubmit={currencyFormSubmit}/>
      <CurrencyValuesForm currency={currency}/>
    </main>
    </MainLayout>
  )
}

export default CurrencyDetails
