import { Transference } from "../../models/transference";
import { getMonthAndDayFromDate } from "../date/date_utils";

export const firstTransferenceWithDate = (transferences: Transference[]) => {
  const firstTransferenceWithDate: any = {};

  for (const transference of transferences) {
    const dateId = getMonthAndDayFromDate(new Date(transference.createdAt!));

    if (firstTransferenceWithDate[dateId] == null) {
      firstTransferenceWithDate[dateId] = transference.id;
    }
  }

  return firstTransferenceWithDate;
};
