import { makeStyles } from "@material-ui/core/styles";
import {
  Input,
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import { Lock, Visibility, VisibilityOff } from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  label: {
    position: "relative",
    top: "20px",
    left: "33px",
  },
  icon: {
    color: "#666",
    position: "relative",
    top: "-7px",
  },
}));

const Index = ({
  setPassword,
  onBlur,
  setShowPassword,
  showPassword,
  validation = [],
  onFocus,
  repeatPassword = false,
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.margin}>
      <InputLabel htmlFor="input-with-icon-adornment" className={classes.label}>
        {repeatPassword ? "Confirmar contraseña" : "Contraseña"}
      </InputLabel>
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        id="input-with-icon-adornment3"
        startAdornment={
          <InputAdornment position="start">
            <Lock className={classes.icon} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
              style={{ color: "#ff7c46" }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Contraseña"
        type={showPassword ? "text" : "password"}
        autoComplete="off"
      />
      {!isEmpty(validation) && (
        <FormHelperText style={{ color: "red", fontWeight: "lighter" }}>
          {validation.join(",")}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Index;
