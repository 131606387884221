import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@mui/material/Switch'
import labels from './../labels'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    background: '#ff7c46',
    padding: '2vw 2vw',
    justifyContent: 'space-between',
    borderRadius: 5,
    marginBottom: '3vw'
  }
})

export function BoolConfig ({ config, handleEdit }) {
  const classes = useStyles()

  const [active, setactive] = useState(config.value == '1')

  useEffect(() => {
    setactive(config.value == '1')
  }, [config])

  const onChange = () => {
    setactive(!active)
    handleEdit({ value: !active ? '1' : '0', config: config })
  }

  return (
    <div className={classes.grid}>
      <h4 style={{ color: 'white', width: '69%' }}>{labels[config.key]}</h4>
      <Switch
        checked={active}
        onChange={(e) => {
          onChange()
        }}
        inputProps={{
          'aria-label': 'ant design'
        }}
      />
    </div>
  )
}
