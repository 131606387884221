import React, { useEffect, useState } from 'react'
import Balances from '../../../components/balances/bssbalancesAdmin.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getBankBalancesByUser, createBalance } from '../../../slices/bssbalance'
import { uploadFile } from './../../../slices/files'
import CreateBssBalance from './../../../components/balances/modals/createBssBalance'
import { fetchUsers } from './../../../slices/users'
import { fetchUserBanks } from './../../../slices/userbanks'

import Screenloading from './../../../components/loaders/screenLoading'

export default function Index () {
  const dispatch = useDispatch()
  const history = useHistory()

  const userBanks = useSelector((state) => state.bssbalance.rows)

  const [showModalCreate, setshowModalCreate] = useState(false)
  const [path, setpath] = useState('')
  const [Amount, setAmount] = useState(0)
  const [image, setimage] = useState('')
  const [loading, setloading] = useState(false)
  const [operatorsList, setoperatorsList] = useState([])
  const [operatorSelected, setoperatorSelected] = useState({})
  const [Rate, setRate] = useState(0)
  const [userBankSelected, setuserBankSelected] = useState({})
  const [userBankList, setuserBankList] = useState([])

  useEffect(() => {
    try {
      setloading(true)

      dispatch(getBankBalancesByUser(dispatch))

      dispatch(fetchUserBanks()).then((users) => {
        setuserBankList(users.payload.userBanks)
      })

      dispatch(fetchUsers({ params: { roleId: 2 } })).then((users) => {
        setoperatorsList(users.payload.data.rows)
        setloading(false)
      })
    } catch (error) {
      setloading(false)
      console.log(error)
    }
  }, [])

  const handleCreateBalance = async (hookForm) => {
    setshowModalCreate(false)
    setloading(true)

    dispatch(uploadFile(image)).then(async (e) => {
      let pathURL = 'http://image.com'
      if (e?.payload?.original) {
        pathURL = e.payload.original.location
      }

      const data = {
        operatorId: operatorSelected.id,
        userBankId: userBankSelected.id,
        amount: Amount,
        capture: pathURL
      }

      await createBalance(data.operatorId, data.userBankId, data.amount, () => {}, data.capture)

      setAmount(0)
      setpath('')
      setimage('')

      dispatch(getBankBalancesByUser(dispatch))

      setloading(false)
    })
  }

  const props = {
    dispatch,
    balances: Object.values(userBanks),
    history,
    openModal: setshowModalCreate
  }

  const propsModal = {
    handleClose: () => setshowModalCreate(false),
    open: showModalCreate,
    handleCreateBalance,
    path,
    setpath,
    setAmount,
    Amount,
    setimage,
    operatorsList,
    operatorSelected,
    onSelectOperator: setoperatorSelected,
    Rate,
    setRate,
    userBankSelected,
    setuserBankSelected,
    userBankList
  }

  return (
    <div>
      {loading && <Screenloading></Screenloading>}
      <Balances {...props} />
      <CreateBssBalance {...propsModal}></CreateBssBalance>
    </div>
  )
}
