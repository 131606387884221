import { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AccountCircle, Mail } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { signUpUser } from '../../slices/auth'
import { useHistory, Link } from 'react-router-dom'
import './index.css'
import InputField from '../../components/form/textField/index'
import PasswordField from '../../components/form/passwordField/index'
import Layout from '../../layouts/outside'
import { CodeType, setEmailAndType } from '../../slices/code'

const useStyles = makeStyles({
  dontHaveAccount: {
    color: 'white',
    cursor: 'pointer',
    '& span': {
      color: '#ff7c46',
      fontWeight: '700'
    }
  },
  containerFooterOption: {
    position: 'absolute',
    bottom: '-80px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    textAlign: 'center'
  },
  icon: {
    color: '#666',
    position: 'relative',
    top: '-7px'
  },
  recoveryPassword: {
    textDecoration: 'none',
    color: '#ff7c46',
    position: 'absolute',
    top: '-30px',
    right: '0px'
  }
})

function SignUp () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [showPassword, setShowPassword] = useState(false)
  const [accountName, setAccountName] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const signUpFunct = async (e) => {
    e.preventDefault()
    const data = {
      name: accountName,
      email,
      password
    }
    dispatch(setEmailAndType(email, CodeType.Register))
    dispatch(await signUpUser(data, history))
  }

  return (
      <form onSubmit={ (e) => { signUpFunct(e) } } >
        <Layout label="Registrarse" color={true}>
          <InputField label="Nombre y Apellido" icon={<AccountCircle className={classes.icon}/>} type="text" setValue={setAccountName} placeholder='Joe Doeh' />
          <InputField label="Correo electrónico" icon={<Mail className={classes.icon}/>} type="email" setValue={setEmail} placeholder='joedoeh@mail.com' />
          <PasswordField setPassword={setPassword} setShowPassword={setShowPassword} showPassword={showPassword} />
          <div className={classes.containerFooterOption}>
            <span className={classes.dontHaveAccount}>Ya tienes cuenta?  <Link to={{ pathname: '/login' }} style={{ textDecoration: 'none' }}> <span>Logueáte </span></Link></span>
          </div>
        </Layout>
      </form>
  )
}

export default SignUp
