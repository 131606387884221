// Dependencies
import { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

// Screens
import Unauthorized from "../screens/errors/unauthorized";

// Helpers
import setAuthToken from "../helpers/axios.js";

// Hooks
import useLocalStorage from "../hooks/useLocalStorage";

// Slices
import { setUserData } from "../slices/auth";

export const RoleId = Object.freeze({
  SuperAdmin: 1,
  OperatorUser: 2,
  MiddlewareUser: 3,
  AppUser: 4,
  AdminUser: 5,
});

export const PrivateRoute = (props) => {
  const { roles, path, component, children } = props;
  const location = useLocation();
  const allowedRoutes = ["/account-activation"];

  const [authToken] = useLocalStorage("accessToken");
  const [user] = useLocalStorage("user");

  const [allowedRoles, setAllowedRoles] = useState([]);
  const [userRole, setUserRole] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      setAuthToken(authToken, user);

      // parse string to JSON
      const userJSON = JSON.parse(user);
      setUserRole(userJSON.roleId);

      // Update the user in the redux store
      dispatch(setUserData(userJSON));

      if (!roles) setAllowedRoles([userJSON.roleId]);
      else setAllowedRoles(roles);
    }
  }, [authToken, user]);

  if (allowedRoutes.includes(location.pathname)) return children;

  return (
    <>
      {
        !authToken ? (
          <Redirect to="/login"></Redirect>
        ) : // The super admin will always have access to every route
        allowedRoles.includes(userRole) || userRole === RoleId.SuperAdmin ? (
          children
        ) : null
        // : <Route path={path} component={Unauthorized} />
      }
    </>
  );
};

export const SuperAdminRoutes = ({ children }) => {
  return (
    <PrivateRoute roles={[RoleId.SuperAdminRoutes]}>{children}</PrivateRoute>
  );
};

export const AdminRoutes = ({ children }) => {
  return <PrivateRoute roles={[RoleId.AdminUser]}>{children}</PrivateRoute>;
};

export const OperatorRoutes = ({ children }) => {
  return <PrivateRoute roles={[RoleId.OperatorUser]}>{children}</PrivateRoute>;
};
