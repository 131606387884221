import { configureStore } from '@reduxjs/toolkit'
import menuReducer from './components/menu/MenuSlice'
import banksReducer from './slices/banks'
import balanceReducer from './slices/balance'
import transactionsReducer from './slices/transactions'
import bssaccountsReducer from './slices/bssaccount'
import authSliceReducer from './slices/auth'
import ratesSliceReducer from './slices/rates'
import rangesSliceReducer from './slices/ranges'
import usersSliceReducer from './slices/users'
import codeSliceReducer from './slices/code'
import loadingSliceReducer from './slices/loading'
import filesSliceReducer from './slices/files'
import userBanksReducer from './slices/userbanks'
import paymentsReducer from './slices/payments'
import parentMethodsReducer from './slices/parentmethods'
import configSliceReducer from './slices/configs'
import bassbalanceSliceReducer from './slices/bssbalance'
import verificationReducer from './slices/verifications'
import currenciesReducer from './slices/currencies'
import countriesReducer from './slices/countries'

export default configureStore({
  reducer: {
    menu: menuReducer,
    banks: banksReducer,
    balance: balanceReducer,
    transactions: transactionsReducer,
    bssaccounts: bssaccountsReducer,
    auth: authSliceReducer,
    rates: ratesSliceReducer,
    ranges: rangesSliceReducer,
    users: usersSliceReducer,
    configs: configSliceReducer,
    code: codeSliceReducer,
    loading: loadingSliceReducer,
    payments: paymentsReducer,
    bssbalance: bassbalanceSliceReducer,
    files: filesSliceReducer,
    userBanks: userBanksReducer,
    parentMethods: parentMethodsReducer,
    verifications: verificationReducer,
    currencies: currenciesReducer,
    countries: countriesReducer
  }
})
