import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const useStyles = makeStyles({
  root: {
    margin: 0,
    display: 'flex'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#ff7c46'
  },
  bankPaper: {
    width: 90,
    height: 83,
    display: 'flex',
    justifyContent: 'center'
  }
})

const style = {
  width: '100%'
}
export default function BankRechargeDialog ({ bank, notExpand }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
        <Paper className={classes.bankPaper} style={bank.style ? bank.style : {}}>
            <img onClick={() => setOpen(true)} src={bank.Logo} alt={bank.name} style={style}></img>
        </Paper>
        {
          notExpand != false &&
          <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title" open={open}
              PaperProps={{
                style: { maxWidth: '900px', width: '95vw', margin: 0 }
              }}
          >
              <MuiDialogTitle disableTypography className={classes.root}>
                  <Typography style={{ fontWeight: 600, fontSize: 18, color: '#003F62' }}>
                      DATOS DE LA CUENTA
                  </Typography>

                  <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <SvgIcon viewBox="0 0 24 24">
                      <path id="FontAwsome_times-circle_" data-name="FontAwsome (times-circle)" d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm5.884,15.15a.581.581,0,0,1,0,.823l-1.916,1.911a.581.581,0,0,1-.823,0L20,22.71l-3.15,3.174a.581.581,0,0,1-.823,0l-1.911-1.916a.581.581,0,0,1,0-.823L17.29,20l-3.174-3.15a.581.581,0,0,1,0-.823l1.916-1.916a.581.581,0,0,1,.823,0L20,17.29l3.15-3.174a.581.581,0,0,1,.823,0l1.916,1.916a.581.581,0,0,1,0,.823L22.71,20Z" transform="translate(-8 -8)" fill="#003f62"/>
                    </SvgIcon>
                  </IconButton>
              </MuiDialogTitle>

              <Divider variant="middle" />

              <DialogContent>
                <div style={{ display: 'flex', marginBottom: 10 }}>
                  <div style={{ marginInline: 20, marginTop: 40 }}>
                    {bank.logo}
                  </div>

                  <div style={{ marginLeft: 50 }}>
                    <div style={{ marginBottom: 10 }}>
                      <Typography style={{ fontSize: 15, fontWeight: 600, color: '#003F62' }}>
                        Número de cuenta
                      </Typography>
                      <Typography style={{ fontSize: 15, color: '#003F62' }}>
                        0277-123123-435435-545
                      </Typography>
                    </div>

                    <div style={{ marginBottom: 10 }}>
                      <Typography style={{ fontSize: 15, fontWeight: 600, color: '#003F62' }}>
                        Nombre
                      </Typography>
                      <Typography style={{ fontSize: 15, color: '#003F62' }}>
                        David Pascuale
                      </Typography>
                    </div>

                    <div>
                      <Typography style={{ fontSize: 15, fontWeight: 600, color: '#003F62' }}>
                        DNI
                      </Typography>
                      <Typography style={{ fontSize: 15, color: '#003F62' }}>
                        123123124
                      </Typography>
                    </div>
                  </div>
                </div>

                <Divider />

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15, marginBottom: 15 }}>
                  <Typography style={{ fontSize: 15, fontWeight: 600, color: '#003F62' }}>
                    Monto a recargar:
                  </Typography>
                  <Typography style={{ fontSize: 15, fontWeight: 600, color: '#003F62' }}>
                    $<span style={{ fontWeight: 400 }}>130.000</span> COP
                  </Typography>
                </div>

                <Divider />

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 20 }}>
                  <Typography style={{ fontSize: 15, fontWeight: 600, color: '#003F62', maxWidth: '50%' }}>
                    Subir comprobante de recarga
                  </Typography>
                  <IconButton style={{ position: 'relative', marginRight: 25 }}>
                    <SvgIcon viewBox="0 0 22.997 17.887" style={{ overflow: 'visible', position: 'absolute', top: 5 }}>
                      <path id="FontAwsome_images_" data-name="FontAwsome (images)" d="M28.746,55v.958a2.875,2.875,0,0,1-2.875,2.875h-23A2.875,2.875,0,0,1,0,55.955V40.624a2.875,2.875,0,0,1,2.875-2.875h.958V50.206A4.8,4.8,0,0,0,8.624,55ZM34.5,50.206V34.875A2.875,2.875,0,0,0,31.621,32h-23a2.875,2.875,0,0,0-2.875,2.875V50.206a2.875,2.875,0,0,0,2.875,2.875h23A2.875,2.875,0,0,0,34.5,50.206ZM15.331,37.749a2.875,2.875,0,1,1-2.875-2.875A2.875,2.875,0,0,1,15.331,37.749ZM9.582,46.373l3.325-3.325a.719.719,0,0,1,1.016,0l2.366,2.366L24.405,37.3a.719.719,0,0,1,1.016,0l5.241,5.241v6.707H9.582Z" transform="translate(0 -32)" fill="#003f62"/>
                    </SvgIcon>
                  </IconButton>
                </div>

              </DialogContent>

              <MuiDialogActions style={{ backgroundColor: '#F96123', justifyContent: 'center' }}>
                  <Typography style={{ color: '#fff', fontWeight: 600, fontSize: 15, margin: 5 }}>
                    Notificar transferencia
                  </Typography>
              </MuiDialogActions>
          </Dialog>
        }

    </div>
  )
}
