import React, { useState } from 'react'

export function InputDataAccount ({
  label,
  input,
  maxlength,
  register,
  onChange,
  errors,
  onlynumbers
}) {
  const [value, setvalue] = useState('')

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <input
        type="text"
        {...register(label.replaceAll(' ', '_').toLowerCase())}
        name={label.replaceAll(' ', '_').toLowerCase()}
        className={input}
       // value={value}
        maxLength={maxlength}
        onInput={e => {
          setvalue(onlynumbers ? e.target.value.replace(/[^0-9]/g, '') : e.target.value)
          if (onChange) {
            onChange(e.target.value)
          }
        }}
      />
      <small
        style={{
          fontSize: '3.2vw',
          fontWeight: 'lighter',
          color: '#003f62'
        }}
      >
        {label}
      </small>
      <p style={{ marginTop: '2vw', fontSize: '3vw', color: '#c82f2f' }}>
        {errors[label.replaceAll(' ', '_').toLowerCase()]?.message}
      </p>
    </div>
  )
}
