/* eslint-disable semi */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateTransferenceComponent from "../../components/createTransference/index";
import { getBalancesFunct } from "../../slices/balance";
import { createTransference } from "../../slices/transactions";
import { getBankBalancesByUser } from "../../slices/bssbalance";
import { formatNumber } from "./../../helpers/numbers";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { getConfig } from "../../utils/configs";
import { Modalassignoperator } from "./../../components/modal/transferences/modalAssignOperator";
import { isNaN } from "lodash";

function CreateTransference() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [loading, setloading] = useState(false);

  const [dollarRate, setdollarRate] = useState(0);
  const [dollarPreviewBss, setdollarPreviewBss] = useState(0);
  const [costrate, setCostRate] = useState(0.5);
  const [rate, setrate] = useState(0.5);
  const [errorRate, seterrorRate] = useState(false);
  const [amountPesos, setamountPesos] = useState("");
  const [amountBolivares, setamountBolivares] = useState("");
  const [showAssignModal, setshowAssignModal] = useState(false);
  const [operatorSelected, setoperatorSelected] = useState({});

  const user = useSelector((state) => state.auth.user);
  const bssbalances = useSelector((state) => state.bssbalance.rows);

  const getBalance = async () => {
    const response = await getBalancesFunct();

    if (response.code == 0) {
      if (response.data.costRate) {
        setCostRate(parseFloat(response.data.costRate[0].value));
        setrate(parseFloat(response.data.publicRate.rates[0].rate));
      }

      if (response.data?.configs.length > 0) {
        const dolarBlue = getConfig(response.data.configs, "DolarBlue");
        setdollarRate(parseFloat(dolarBlue.value));
      }
    }
  };

  useEffect(() => {
    getBalance();
    dispatch(getBankBalancesByUser(dispatch));
  }, []);

  useEffect(() => {
    if (user.roleId != "2") {
      dispatch(getBankBalancesByUser(dispatch));
    }
  }, [user]);

  const onChangeAmountPesos = (e) => {
    if (e != 0) {
      setamountPesos(e);
      if (parseFloat(e)) {
        setdollarPreviewBss(parseFloat(e) / dollarRate);
        setamountBolivares(parseFloat(e) * rate);
      } else {
        setdollarPreviewBss(0);
        setamountBolivares("");
      }
    }
  };

  const onChangeAmountBolivares = (e) => {
    // console.log('onChangeAmountBolivares', e)
    if (rate != 0 && e != 0) {
      setamountBolivares(e);
      if (parseFloat(e)) {
        console.log("here 2", parseFloat(e), dollarRate);
        setdollarPreviewBss(parseFloat(e) / dollarRate);
        setamountPesos(parseFloat(e) / rate);
      } else {
        setdollarPreviewBss(0);
        setamountPesos("");
      }
    }
  };

  const onChangeRate = (e) => {
    if (e && e.floatValue) {
      if (e.floatValue <= costrate) {
        setrate(e.floatValue);
        seterrorRate(false);

        const parseFloatClean = amountBolivares || "0";

        setamountPesos(
          parseFloat(parseFloatClean.toString().replaceAll(",", "") || "0") /
            e.floatValue
        );
      } else {
        seterrorRate(true);
      }
    } else {
      setrate(0);
      setamountPesos("0");
    }
  };

  const onSubmit = async () => {
    setloading(true);

    const { user: bssAccount } = location.state;

    const data = {
      bssAccountId: bssAccount.id || 1,
      requestedBy: user.id,
      operatorId: operatorSelected.id || null,
      currencyId: 1,
      bssAmount: parseFloat(amountBolivares).toFixed(2),
      amount: amountPesos,
      profit: null,
      rateValue: rate,
    };

    const response = await createTransference(data);
    if (response.data.code == 0) {
      if (user.roleId != "2") {
        history.push("/dashboard");
      } else {
        history.push("/operator-dashboard");
      }
      Swal.fire({
        text: "Transferencia realizada!",
        icon: "success",
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
      });
    } else {
      if (response.data.code == 39) {
        Swal.fire({
          text: "Este operador no tiene un Bot configurado",
          icon: "error",
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      } else {
        Swal.fire({
          text: "Algo salío mal.",
          icon: "error",
          customClass: {
            confirmButton: "swal2-blue-confirm-button",
          },
        });
      }
    }
    setloading(false);
  };

  const onSelectOperator = (e) => {
    setshowAssignModal(true);
  };

  const handleChangeAssignOperator = (e, operator) => {
    setshowAssignModal(false);
    if (operator && operator[0]) {
      setoperatorSelected(operator[0].operator);
    }
  };

  const handleSendAssignOperator = (e) => {};

  const ModalAssignOperator = () => {
    return (
      <Modalassignoperator
        {...{
          selectedValue: 1,
          assigned: false,
          setShowModal: setshowAssignModal,
          handleChange: handleChangeAssignOperator,
          transactionSelected: null,
          handleSendForm: handleSendAssignOperator,
          operators: bssbalances,
        }}
      />
    );
  };

  const props = {
    loading,
    rate,
    amountPesos:
      typeof amountPesos === "number" ? amountPesos.toFixed(2) : amountPesos,
    amountBolivares:
      typeof amountBolivares === "number"
        ? amountBolivares.toFixed(2)
        : amountBolivares,
    onChangeAmountBolivares,
    onChangeAmountPesos,
    onChangeRate,
    onSubmit,
    bssbalances,
    dollarPreviewBss,
    costrate,
    errorRate,
    onSelectOperator,
    ModalAssignOperator,
    showAssignModal,
    user,
    operatorSelected,
  };

  return <CreateTransferenceComponent {...props} />;
}

export default CreateTransference;
