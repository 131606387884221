import React from "react";

function InputSearch({ onChange, onSubmit }) {
  return (
    <form onSubmit={onSubmit}>
      <div
        style={{
          display: "flex",
          background: "white",
          justifyContent: "space-between",
          padding: "2vw 4vw",
          borderRadius: 25,
        }}
        className="shadow"
      >
        <input
          type="text"
          style={{ width: "90%", border: "none" }}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Buscar"
        />
        <div onClick={onSubmit} style={{ width: "5%" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 23.996 24"
          >
            <path
              id="FontAwsome_search_"
              data-name="FontAwsome (search)"
              d="M23.67,20.75,19,16.077a1.124,1.124,0,0,0-.8-.328h-.764a9.745,9.745,0,1,0-1.687,1.687V18.2a1.124,1.124,0,0,0,.328.8L20.75,23.67a1.12,1.12,0,0,0,1.589,0l1.326-1.326A1.13,1.13,0,0,0,23.67,20.75Zm-13.921-5a6,6,0,1,1,6-6A6,6,0,0,1,9.749,15.748Z"
              fill="#CCCCCC"
            />
          </svg>
        </div>
      </div>
    </form>
  );
}

export default InputSearch;
