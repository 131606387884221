import { createSlice } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const balanceSlice = createSlice({
  name: 'parentMethods',
  initialState: {
    balance: {},
    balances: []
  },
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload
    },
    setBalances: (state, action) => {
      state.balances = action.payload
    }
  }
})

const { setBalance, setBalances } = balanceSlice.actions

export const fetchBalance = () => async dispatch => {
  try {
    await Axios.get(process.env.REACT_APP_API_URL + 'balance/show/1')
      .then((response) => {
        dispatch(setBalance(response.data))
      })
  } catch (e) {
    return console.error(e.message)
  }
}
export const getBalances = (params) => async dispatch => {
  try {
    await Axios.get(process.env.REACT_APP_API_URL + 'balance', { params })
      .then((response) => {
        dispatch(setBalances(response.data.data.balances))
      })
  } catch (e) {
    return console.error(e.message)
  }
}
export const getBalancesFunct = async (params) => {
  try {
    const { data } = await Axios.get(process.env.REACT_APP_API_URL + 'balance', { params })
    return data
  } catch (e) {
    return console.error(e.message)
  }
}
export const createBalances = async (payload) => {
  try {
    const { data } = await Axios.post(process.env.REACT_APP_API_URL + 'balance/store', payload)

    return data
  } catch (e) {
    return console.error(e.message)
  }
}

export default balanceSlice.reducer
