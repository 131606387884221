// Dependencies
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Slices
import { getTransactionHistory } from "../../../slices/transactions";

// Components
import Screenloading from "./../../../components/loaders/screenLoading";
import { DetailDay } from "./detail-day";
import { Item } from "./detail/item-transactions";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  containerInputs: {
    display: "flex",
    flexDirection: "column",
    margin: "2vw",
  },
  inputs: {
    borderRadius: 5,
    padding: "3vw 2vw 3vw 2vw",
    width: "45vw",
    fontSize: "5vw",
    border: "1px solid #00000021",
  },
  button: {
    display: "block",
    margin: "0 auto",
    background: "rgb(255, 124, 70) none repeat scroll 0% 0%",
    width: "93vw",
    padding: "1vw 2vw",
    fontSize: "5vw",
    fontWeight: "lighter",
    color: "white",
    border: "none",
    borderRadius: "5px",
  },
}));

function Header(props) {
  const history = useHistory();

  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "5vw 2vw 5vw 2vw",
      }}
    >
      <i
        onClick={() => props.funcion()}
        style={{ fontSize: "7vw", color: "#003f62", marginLeft: "3vw" }}
        className="fas fa-chevron-left"
      ></i>

      <h1
        style={{
          color: "#FA7D4A",
          fontSize: "8vw",
          marginLeft: "-7vw",
        }}
      >
        {props.text}
      </h1>
      <div></div>
    </header>
  );
}
export function TransactionsHistory({ transactions }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [selectDay, setselectDay] = useState("");
  const history = useHistory();
  const [date, setdate] = useState({
    start: "",
    end: "",
  });
  const transactionHistory = useSelector(
    (state) => state.transactions.transactionHistory
  );

  const fetchTransactionHistory = async () => {
    dispatch(await getTransactionHistory("2022-07-24", "2022-08-24")).then(
      (res) => {
        setloading(false);
      }
    );
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  // convertir el objeto a un array
  const arrayFor = (transaction) => {
    const array = [];
    for (const day in transaction) {
      array.push(day);
    }
    return array;
  };
  const days = arrayFor(transactionHistory);

  const navigateTo = () => {
    history.goBack();
  };

  const handleDate = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setdate({ ...date, [name]: value });
    console.log(date);
  };

  const searchDate = async () => {
    setloading(true);
    dispatch(await getTransactionHistory(date.start, date.end))
      .then((res) => {
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <div>
      {selectDay === "" ? (
        <div>
          <Header
            text={"Historial"}
            funcion={() => {
              navigateTo();
            }}
          ></Header>
          <div className={classes.container}>
            <div className={classes.containerInputs}>
              <span>Desde:</span>
              <input
                type="date"
                value={date.start}
                name="start"
                className={classes.inputs}
                onChange={(e) => {
                  handleDate(e);
                }}
              />
            </div>
            <div className={classes.containerInputs}>
              <span>Desde:</span>
              <input
                type="date"
                value={date.end}
                className={classes.inputs}
                name="end"
                onChange={(e) => {
                  handleDate(e);
                }}
              />
            </div>
          </div>
          <button
            className={classes.button}
            onClick={() => {
              searchDate();
            }}
          >
            Buscar
          </button>
          {loading === false ? (
            days.map((day) => (
              <div
                key={day}
                onClick={() => {
                  setselectDay(day);
                }}
              >
                <Item data={{ day, amount: transactionHistory[day].length }}>
                  {day}
                </Item>
              </div>
            ))
          ) : (
            <Screenloading />
          )}
        </div>
      ) : (
        <div>
          <Header text={selectDay} funcion={() => setselectDay("")}></Header>
          <DetailDay day={selectDay} loading={loading} />
        </div>
      )}
    </div>
  );
}
