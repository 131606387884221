import { formatNumber } from './../../../helpers/numbers'

function TotalTransferences (props) {
  return (
    <div
      style={{
        background: '#003f62',
        display: 'flex',
        marginBottom: '3vw',
        color: 'white',
        marginTop: '4vw',
        justifyContent: 'space-between',
        padding: '1vw 2vw',
        borderRadius: '5px',
        alignItems: 'center'
      }}
    >
      <p style={{ fontSize: '2.5vw' }}>Total de transferencias hechas hoy:</p>
      <p
        style={{
          fontWeight: 'bold'
        }}
      >
        {props.total}
      </p>
    </div>
  )
}

function TotalBssBalance (props) {
  return (
    <div
      style={{
        background: 'rgb(160, 57, 15) none repeat scroll 0% 0%',
        display: 'flex',
        marginBottom: '3vw',
        color: 'white',
        justifyContent: 'space-between',
        padding: '1vw 2vw',
        borderRadius: '5px',
        alignItems: 'center'
      }}
    >
      <p style={{ fontSize: '2.5vw' }}>Total de Bolívares en cuentas:</p>
      <p
        style={{
          fontWeight: 'bold'
        }}
      >
        ${formatNumber(props.total)} BS
      </p>
    </div>
  )
}

const TotalsContainerOperator = ({ transactions, bssBalances }) => {
  return (
    <div>
      {transactions && (
        <>
          <TotalTransferences total={transactions.length} />
        </>
      )}
      {bssBalances && bssBalances.rows && (
        <TotalBssBalance
          total={bssBalances.rows.reduce(
            (partialSum, a) => partialSum + a.total,
            0
          )}
        />
      )}
    </div>
  )
}

export default TotalsContainerOperator
