import React from "react";
import { TransactionStatus } from "../../../models/transference_items";

function NewTransactionStatus(props: any) {
  let statusId = 1;
  const statusList: TransactionStatus[] = props.statusList ?? [];

  if (statusList.length > 0) {
    statusId = statusList[0].statusId ?? 1;
  }

  const getTitle = (): string => {
    switch (statusId) {
      case 1:
        return "Pendiente";
      case 2:
        return "En espera";
      case 3:
        return "Transfiriendo";
      case 4:
        return "Datos erroneos";
      case 5:
        return "Finalizado";
      case 6:
        return "Cancelada";
      default:
        return "Error";
    }
  };

  const getColor = (): string => {
    switch (statusId) {
      case 1:
        return "#F2C371";
      case 2:
        return "#c9963c";
      case 3:
        return "#FA7D4A";
      case 4:
        return "#3271e6";
      case 5:
        return "#278549";
      case 6:
        return "red";
      default:
        return "black";
    }
  };

  return (
    <div style={{ backgroundColor: getColor() }} className="transaction-status">
      {getTitle()}
    </div>
  );
}

export default NewTransactionStatus;
