import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRanges } from '../../../../slices/ranges'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import RangeComp from './rangesEdit'
import { fetchUsers, fetchUsersByRole } from '../../../../slices/users'
import Menu from '../../../../components/footer/menu'
import { customStyles } from '../../../../assets/styles/select'
import MainLayout from '../../../../layouts/main'

const useStyles = makeStyles({
  main: {
    paddingTop: '8vw',
    minHeight: '100vh',
    color: 'white',
    background: '#003f62',
    '@media (min-width: 700px)': {
      paddingTop: '0vw'
    }
  },
  section: {
    background: 'none',
    display: 'grid',
    justifyContent: 'space-between',
    width: '100%',

    alignItems: 'center',
    margin: 'auto',
    gridTemplateColumns: '56% 37%'
  },
  containerRanges: {
    background: 'white',
    padding: '3vw 2vw',
    width: '100%',
    overflow: 'auto',
    margin: 'auto',
    marginTop: '4vw',
    borderRadius: '5px',
    '@media (min-width: 700px)': {
      marginTop: '2vw',
      padding: '1vw 0vw 1vw 2vw'
    },
    marginBottom: '17vh'
  }
})

export default function Index () {
  const dispatch = useDispatch()
  const classes = useStyles()

  const ranges = useSelector((state) => state.ranges.ranges)
  const users = useSelector((state) => state.users.users)

  const [usersArray, setusersArray] = useState([])
  const [numberOfUsers, setnumberOfUsers] = useState(30)
  const [nameToSearch, setnameToSearch] = useState('')

  const data = {
    params: { limit: numberOfUsers, roleId: '3,4' }
  }

  useEffect(() => {
    if (nameToSearch != null && nameToSearch.length == 0) {
      dispatch(fetchUsers({ params: { limit: numberOfUsers, query: nameToSearch, roleId: '3,4' } }))
    }
  }, [nameToSearch])
  useEffect(() => {
    setusersArray(users.data?.rows)
  }, [users])

  useEffect(() => {
    dispatch(fetchRanges())
    dispatch(fetchUsers(data))
  }, [])

  useEffect(() => {
    dispatch(fetchUsers(data))
  }, [numberOfUsers])

  const itemsMenu = [
    {
      label: 'Asignados',
      icon: 'fas fa-check-double',
      path: '/ranges/assignations'
    },
    {
      label: 'Rangos',
      icon: 'fas fa-sort-amount-up',
      path: '/ranges/list'
    }
  ]

  const searchByRange = async (e) => {
    const data = await fetchUsersByRole(e.id)

    setusersArray(data.data.rows)
  }

  const searchByName = (e) => {
    if (e) {
      e.preventDefault()
    }

    console.log({ nameToSearch })
    dispatch(fetchUsers({ params: { limit: numberOfUsers, query: nameToSearch, roleId: '3,4' } }))
  }

  return (
    <>
      <MainLayout text="Rangos" withoutPhoto={true}>
        <form onSubmit={searchByName}>
          <div
            style={{
              width: '100%',
              marginBottom: '4vw',
              fontSize: '5vw',
              padding: '0vw 3vw 0vw 3vw',
              borderRadius: '5px',
              background: 'white',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <input
                value={nameToSearch}
              onChange={e => setnameToSearch(e.target.value)}
              placeholder="Busca una cuenta"
              style={{ padding: '3vw 2vw', border: 'none', fontSize: '5vw' }}
            />
            <i onClick={searchByName} className="fas fa-search"></i>
          </div>
        </form>
        <section className={classes.section}>
          <h3 style={{ color: '#eee' }}>Selecciona el rango:</h3>
          <Select
            styles={customStyles}
            onChange={(e) => searchByRange(e)}
            getOptionLabel={(op) => op.name}
            options={ranges}
          />
        </section>
        <div className={classes.containerRanges}>
          {usersArray &&
            usersArray.map((user) => (
              <RangeComp key={user.id} user={user} ranges={ranges} />
            ))}
          {usersArray && usersArray.length > 29 && (
            <button
              onClick={() => setnumberOfUsers(numberOfUsers + 30)}
              style={{
                width: '100%',
                border: 'none',
                background: '#f96123',
                color: 'white',
                padding: '2vw 2vw 2vw',
                fontSize: '5vw',
                fontWeight: 'bold',
                borderRadius: '7px'
              }}
            >
              Cargar más
            </button>
          )}
        </div>
      </MainLayout>
      <div style={{ marginLeft: '3vw' }}>
        <Menu items={itemsMenu} />
      </div>
    </>
  )
}
