import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRanges, createRanges, updateRanges, deleteRanges } from '../../../../slices/ranges'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import { fetchUsers } from '../../../../slices/users'
import Menu from '../../../../components/footer/menu'
import Range from './range'
import ModalCustom from '../../../../components/modal/index'
import { TextField } from '@material-ui/core'
import { customStyles } from '../../../../assets/styles/select'
import MainLayout from '../../../../layouts/main'

const useStyles = makeStyles({
  main: {
    paddingTop: '8vw',
    minHeight: '100vh',
    color: 'white',
    background: '#003f62',
    '@media (min-width: 700px)': {
      paddingTop: '2vw'
    }
  },
  section: {
    background: 'none',
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: 'auto',
    gridTemplateColumns: '56% 37%'
  },
  containerRanges: {
    background: '#024970',
    width: '100%',
    margin: 'auto',
    marginTop: '4vw',
    borderRadius: '5px',
    padding: '5vw 2vw 1vw',
    '@media (min-width: 700px)': {
      padding: '2vw 2vw 1vw'
    }
  },
  headerModal: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    '& span': {
      marginLeft: '2vw'
    }
  },
  containerSelectorModal: {
    marginTop: '6vw'
  },
  footerModal: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '6vw',
    '& button': {
      fontWeight: '700',
      fontSize: '4vw',
      border: 'none',
      borderRadius: '5px',
      padding: '2vw 5vw',
      '@media (min-width: 700px)': {
        padding: '1vw 3vw',
        fontSize: '1.2vw'
      }
    }
  },
  btnSolid: {
    color: 'white',
    background: '#003f62'
  },
  headerOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10vw',
    '@media (min-width: 700px)': {
      marginBottom: '2vw'
    },
    '& span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '5vw',
      '& svg': {
        marginRight: '2vw'
      }
    },
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#F96123',
      marginRight: '4vw',
      '& svg': {
        marginRight: '0vw'
      },
      '& span': {
        marginLeft: '2vw'
      }
    }
  },
  textDeleteModal: {
    fontSize: '4vw',
    fontWeight: '100',
    marginBottom: '4vw',
    '@media (min-width: 700px)': {
      fontSize: '2vw'
    }
  },
  textDeleteModalName: {
    fontSize: '5vw',
    color: '#ff5722',
    '@media (min-width: 700px)': {
      fontSize: '2vw'
    }
  }
})

export default function Index () {
  const dispatch = useDispatch()
  const classes = useStyles()
  const ranges = useSelector(state => state.ranges.ranges)
  const [modalNewRange, setModalNewRange] = useState(false)
  const [modalEditRange, setModalEditRange] = useState(false)
  const [modalDeleteRange, setModalDeleteRange] = useState(false)
  const [nameRangeToCreate, setNameRangeToCreate] = useState('')
  const [rangeSelectedToAction, setrangeSelectedToAction] = useState({})

  useEffect(() => {
    dispatch(fetchRanges())
    dispatch(fetchUsers())
  }, [])

  const onSubmit = () => {
    const data = {
      name: nameRangeToCreate
    }
    dispatch(createRanges(data)).then(() => {
      dispatch(fetchRanges()).then(() => setModalNewRange(false))
    })
  }
  const onEditRange = () => {
    const data = {
      name: nameRangeToCreate,
      id: rangeSelectedToAction.id
    }
    dispatch(updateRanges(data)).then(() => {
      dispatch(fetchRanges()).then(() => setModalEditRange(false))
    })
  }
  const onDeleteRange = () => {
    const data = {
      id: rangeSelectedToAction.id
    }
    dispatch(deleteRanges(data)).then(() => {
      dispatch(fetchRanges()).then(() => setModalDeleteRange(false))
    })
  }

  const itemsMenu = [
    {
      label: 'Asignados',
      icon: 'fas fa-check-double',
      path: '/ranges/assignations'
    },
    {
      label: 'Rangos',
      icon: 'fas fa-sort-amount-up',
      path: '/ranges/list'
    }
  ]

  return (
      <>
        <MainLayout text="Rangos" withoutPhoto={true}>
          <section className={classes.section}>
            <h3 style={{ color: '#eee' }}>Selecciona el rango:</h3>
            <Select styles={customStyles} getOptionLabel={op => op.name} options={ranges} />
          </section>

          <div className={classes.containerRanges}>

              <div className={classes.headerOptions}>
                  <span style={{ color: '#eee' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path id="FontAwsome_layer-group_" data-name="FontAwsome (layer-group)" d="M.579,6.931,11.5,11.884a1.2,1.2,0,0,0,1,0L23.415,6.931a1.047,1.047,0,0,0,0-1.876L12.5.1a1.2,1.2,0,0,0-1,0L.579,5.054A1.048,1.048,0,0,0,.579,6.931Zm22.836,4.138L20.693,9.835,13.116,13.27a2.7,2.7,0,0,1-2.237,0L3.3,9.835.579,11.069a1.046,1.046,0,0,0,0,1.875L11.5,17.894a1.2,1.2,0,0,0,1,0l10.919-4.949a1.046,1.046,0,0,0,0-1.875Zm0,5.991L20.7,15.83l-7.587,3.439a2.7,2.7,0,0,1-2.237,0L3.292,15.83.579,17.06a1.046,1.046,0,0,0,0,1.875L11.5,23.884a1.2,1.2,0,0,0,1,0l10.919-4.949A1.046,1.046,0,0,0,23.416,17.06Z" transform="translate(0.002 0.007)" fill="#fff" />
                      </svg>
                      RANGOS
                  </span>
                  <div onClick={() => setModalNewRange(true)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path id="FontAwsome_plus-circle_" data-name="FontAwsome (plus-circle)" d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm6.968,13.355a.582.582,0,0,1-.581.581H21.935v4.452a.582.582,0,0,1-.581.581h-2.71a.582.582,0,0,1-.581-.581V21.935H13.613a.582.582,0,0,1-.581-.581v-2.71a.582.582,0,0,1,.581-.581h4.452V13.613a.582.582,0,0,1,.581-.581h2.71a.582.582,0,0,1,.581.581v4.452h4.452a.582.582,0,0,1,.581.581Z" transform="translate(-8 -8)" fill="#f96123" />
                      </svg>
                      <span>NUEVO <br /> RANGO</span>
                  </div>
              </div>

              {
                  ranges.map(range => (
                      <Range key={range.id} range={range} setModalEditRange={setModalEditRange} setModalDeleteRange={setModalDeleteRange} setrangeSelectedToAction={setrangeSelectedToAction}/>
                  ))
              }
          </div>
          {
                modalNewRange &&
                <ModalCustom>
                      <div>
                        <header className={classes.headerModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.48" height="26.669" viewBox="0 0 30.48 26.669">
                              <path id="FontAwsome_sort-amount-up_" data-name="FontAwsome (sort-amount-up)" d="M18.091,54.859h-3.81a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952h3.81a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,18.091,54.859ZM.946,39.62H3.8v18.1a.952.952,0,0,0,.952.952h1.9a.952.952,0,0,0,.952-.952V39.62h2.857a.953.953,0,0,0,.673-1.626L6.382,32.279a.952.952,0,0,0-1.347,0L.273,37.994A.953.953,0,0,0,.946,39.62Zm24.764,0H14.281a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952h11.43a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,25.711,39.62ZM21.9,47.24h-7.62a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952H21.9a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,21.9,47.24ZM29.521,32H14.281a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952h15.24a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,29.521,32Z" transform="translate(0.007 -32)" fill="#f96123"/>
                            </svg>
                            <span>Añadir nuevo rango</span>
                        </header>
                        <article className={classes.containerSelectorModal}>
                            <TextField
                                style={{ width: '100%' }}
                                id="outlined-password-input"
                                type="text"
                                onChange={e => setNameRangeToCreate(e.target.value)}
                                />
                        </article>
                        <footer className={classes.footerModal}>
                            <button onClick={() => setModalNewRange(false)} className={classes.btnLigth}>Cancelar</button>
                            <button onClick={onSubmit} className={classes.btnSolid}>Aceptar</button>
                        </footer>
                    </div>
                </ModalCustom>
            }
            {
                modalEditRange &&
                <ModalCustom>
                      <div>
                        <header className={classes.headerModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.48" height="26.669" viewBox="0 0 30.48 26.669">
                              <path id="FontAwsome_sort-amount-up_" data-name="FontAwsome (sort-amount-up)" d="M18.091,54.859h-3.81a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952h3.81a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,18.091,54.859ZM.946,39.62H3.8v18.1a.952.952,0,0,0,.952.952h1.9a.952.952,0,0,0,.952-.952V39.62h2.857a.953.953,0,0,0,.673-1.626L6.382,32.279a.952.952,0,0,0-1.347,0L.273,37.994A.953.953,0,0,0,.946,39.62Zm24.764,0H14.281a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952h11.43a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,25.711,39.62ZM21.9,47.24h-7.62a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952H21.9a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,21.9,47.24ZM29.521,32H14.281a.952.952,0,0,0-.952.952v1.9a.952.952,0,0,0,.952.952h15.24a.952.952,0,0,0,.952-.952v-1.9A.952.952,0,0,0,29.521,32Z" transform="translate(0.007 -32)" fill="#f96123"/>
                            </svg>
                            <span>Editar rango</span>
                        </header>
                        <article className={classes.containerSelectorModal}>
                            <TextField
                                style={{ width: '100%' }}
                                id="outlined-password-input"
                                type="text"
                                onChange={e => setNameRangeToCreate(e.target.value)}
                                />
                        </article>
                        <footer className={classes.footerModal}>
                            <button onClick={() => setModalEditRange(false)} className={classes.btnLigth}>Cancelar</button>
                            <button onClick={onEditRange} className={classes.btnSolid}>Aceptar</button>
                        </footer>
                    </div>
                </ModalCustom>
            }
            {
                modalDeleteRange &&
                <ModalCustom>
                      <div>
                        <header className={classes.headerModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.44" height="35.099" viewBox="0 0 29.44 35.099">
                               <path id="FontAwsome_trash-alt_" data-name="FontAwsome (trash-alt)" d="M2.1,31.808A3.225,3.225,0,0,0,5.257,35.1H24.183a3.225,3.225,0,0,0,3.154-3.291V8.775H2.1Zm17.874-17.55a1.052,1.052,0,1,1,2.1,0V29.615a1.052,1.052,0,1,1-2.1,0Zm-6.308,0a1.052,1.052,0,1,1,2.1,0V29.615a1.052,1.052,0,1,1-2.1,0Zm-6.308,0a1.052,1.052,0,1,1,2.1,0V29.615a1.052,1.052,0,1,1-2.1,0ZM28.388,2.194H20.5L19.885.912A1.572,1.572,0,0,0,18.472,0H10.961A1.554,1.554,0,0,0,9.555.912L8.937,2.194H1.051A1.075,1.075,0,0,0,0,3.29V5.484a1.075,1.075,0,0,0,1.051,1.1H28.388a1.075,1.075,0,0,0,1.051-1.1V3.29A1.075,1.075,0,0,0,28.388,2.194Z" transform="translate(0 0)" fill="#003f62"/>
                            </svg>
                            <span>Eliminar rango</span>
                        </header>
                        <article className={classes.containerSelectorModal}>
                            <h3 className={classes.textDeleteModal}>
                                 Estas seguro que deseas eliminar este rango?
                            </h3>
                            <h2 className={classes.textDeleteModalName}>{rangeSelectedToAction.name}</h2>
                        </article>
                        <footer className={classes.footerModal}>
                            <button onClick={() => setModalDeleteRange(false)} className={classes.btnLigth}>Cancelar</button>
                            <button onClick={onDeleteRange} className={classes.btnSolid}>Aceptar</button>
                        </footer>
                    </div>
                </ModalCustom>
            }
        </MainLayout>
        <div style={{ marginLeft: '3vw' }}>
            <Menu items={itemsMenu} />
        </div>
      </>
  )
}
