import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Picker from 'emoji-picker-react'
import labels from './../labels'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    background: '#011f32',
    padding: '2vw 2vw',
    justifyContent: 'space-between',
    borderRadius: 5,
    marginBottom: '3vw'
  }
})

export function TextConfig ({ config, handleEdit }) {
  const classes = useStyles()
  const [chosenEmoji, setChosenEmoji] = useState(false)
  const [value, setvalue] = useState(config.value)

  const onEmojiClick = (event, emojiObject) => {
    onChange((value || '') + emojiObject.emoji)
    setChosenEmoji(false)
  }

  useEffect(() => {
    setvalue(config.value)
  }, [config])

  const onChange = (e) => {
    if (e.length < 251) {
      setvalue(e)
      handleEdit({ value: e, config: config })
    }
  }

  return (
    <div className={classes.grid}>
      <div style={{ width: '100vw' }}>
        <div style={{}}>
          <h4
            style={{
              color: '#ff7c46',
              width: '69%',
              marginBottom: '3vw',
              marginTop: '1vw'
            }}
          >
            {labels[config.key]}
          </h4>
          <div
            style={{
              display: 'flex',
              background: 'white none repeat scroll 0% 0%',
              borderRadius: '5px',
              width: '90vw'
            }}
          >
            <textarea
              placeholder="Contenido"
              name="description"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              style={{
                display: 'flex',
                background: 'white none repeat scroll 0% 0%',
                borderRadius: '5px',
                padding: '3vw 2vw 1vw 3vw',
                width: '80vw',
                border: 'none',
                height: '12vh'
              }}
            />
            <i
              onClick={() => setChosenEmoji(!chosenEmoji)}
              style={{ color: '#ff7c46', fontSize: '6vw', marginTop: '2vw' }}
              className="far fa-laugh-beam"
            ></i>
          </div>
          <h5 style={{ marginTop: '2vw', fontWeight: 'lighter' }}>
            {value.length}/250
          </h5>
          {chosenEmoji && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                position: 'absolute',
                width: '94vw',
                zIndex: '1000'
              }}
            >
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
