import React, { useState } from 'react'
import Layout from '../../../layouts/main'
import Pending from '../pending/index'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '../../footer/menu'
import Modal from '../modal/index'
import { itemsMenu } from '../nav'

const useStyles = makeStyles({
  containerPendings: {
    marginTop: '5vw',
    marginBottom: '20vh'

  },
  title: {
    color: '#D4544C',
    fontWeight: '300',
    paddingLeft: '5vw'
  }
})

export default function Index ({ handleSubmit, open, modalSetStatus, setModalSetStatus, userSelected, setUserSelected, verificationsList }) {
  const classes = useStyles()

  return (
        <Layout text="Verificaciones">
            <h3 className={classes.title}>Usuarios rechazados</h3>

            <div className={classes.containerPendings}>
                {
                    verificationsList.map(user => (
                        <Pending key={user.id} user={user} setUserSelected={setUserSelected} setModalSetStatus={setModalSetStatus}/>
                    ))
                }
            </div>

            {
                modalSetStatus &&
                <Modal {...{ handleClose: () => setModalSetStatus(false), open }} onSubmit={handleSubmit} user={userSelected} setModalSetStatus={setModalSetStatus}/>
            }

            <Menu items={itemsMenu}/>
        </Layout>
  )
}
