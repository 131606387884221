import React from "react";
import { formatNumber } from "./../../helpers/numbers";
import { IconButton, Grid, Typography, SvgIcon, Fab } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../Header";

import TransactionCard from "../transactions/transactionCard";

const useStyles = makeStyles({
  container: {
    width: "100%",
    flex: "4",
    flexDirection: "column",
    height: "100vh",
    flexWrap: "nowrap",
    backgroundColor: "#003f62",
  },
  header: {
    marginTop: -30,
    flex: "1",
    padding: 15,
    color: "#fff",
  },
  content: {
    flex: "3",
    padding: 15,
    backgroundColor: "#fff",
    borderRadius: "5px 0 0 0",
  },
  tasa: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: 25,
    color: "#ff7c46",
    padding: 2,
    display: "flex",
    justifyContent: "center",
    width: 184,
  },
  visibleButton: {
    position: "absolute",
    top: -10,
    right: -35,
    color: "#fff",
    opacity: 0.5,
  },
  tasaVisibleButton: {
    position: "absolute",
    top: -6,
    right: 7,
    color: "#ff7c46",
    opacity: 0.5,
  },
  actions: {
    backgroundColor: "#ff7c46",
    width: 60,
    height: 60,
    borderRadius: "50%",
    marginInline: 8,
    border: "1px solid black",
  },
  fab: {
    marginInline: 8,
    width: 60,
    height: 60,
    backgroundColor: "#ff7c46",
  },
  divider: {
    width: "100%",
    height: 2,
    border: 0,
    borderTop: "2px solid #c9cace",
  },
  transaction: {
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 15,
    border: "1px solid #f8f8f8",
    "&:before": {
      height: 0,
    },
  },
  summaryColumn: {
    flexBasis: "33.33%",
    alignSelf: "center",
    "@media (min-width: 700px)": {
      fontSize: "1.3vw !important",
    },
  },
  detailColumn1: {
    position: "absolute",
    top: 0,
    left: "15vw",
    width: 150,
  },
  detailColumn2: {
    position: "absolute",
    top: 0,
    left: "55vw",
    width: 150,
  },
});

export default function Home({
  transactions,
  showBalance,
  balance,
  showRate,
  history,
  setShowRate,
  setShowBalance,
}) {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.container}>
        <Header />

        <Grid container className={classes.header}>
          <Grid
            container
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            <Typography>Saldo</Typography>
            <span style={{ position: "relative", width: 206 }}>
              {showBalance ? (
                <Typography variant="h3">
                  <b>${formatNumber(balance.amount)}</b>
                </Typography>
              ) : (
                <Typography variant="h3">
                  <b>$*******</b>
                </Typography>
              )}
              <IconButton
                className={classes.visibleButton}
                onClick={() => setShowBalance(!showBalance)}
              >
                <VisibilityIcon style={{ fontSize: 20 }} />
              </IconButton>
            </span>
            <span className={classes.tasa}>
              {showRate ? (
                <Typography style={{ fontWeight: 800 }}>
                  <span style={{ fontWeight: 500 }}>Tasa:</span> 0.00135
                </Typography>
              ) : (
                <Typography style={{ fontWeight: 800 }}>*********</Typography>
              )}
              <IconButton
                className={classes.tasaVisibleButton}
                onClick={() => setShowRate(!showRate)}
              >
                <VisibilityIcon style={{ fontSize: 15 }} />
              </IconButton>
            </span>
          </Grid>

          <Grid container style={{ justifyContent: "center", marginTop: 15 }}>
            <Fab
              className={classes.fab}
              aria-label="send"
              onClick={() => history.push("/bank-account")}
            >
              <SvgIcon
                viewBox="0 0 19.994 20"
                style={{
                  overflow: "visible",
                  marginRight: 10,
                  marginBottom: 5,
                }}
              >
                <path
                  id="FontAwsome_paper-plane_"
                  data-name="FontAwsome (paper-plane)"
                  d="M22.318.121.6,12.653a1.126,1.126,0,0,0,.1,2.025l4.982,2.09L19.145,4.9a.281.281,0,0,1,.4.389L8.258,19.045v3.773a1.124,1.124,0,0,0,1.992.74l2.976-3.623,5.839,2.446a1.128,1.128,0,0,0,1.547-.853L23.986,1.283A1.125,1.125,0,0,0,22.318.121Z"
                  transform="translate(-0.01 0.031)"
                  fill="#fff"
                />
              </SvgIcon>
            </Fab>
            <Fab
              className={classes.fab}
              aria-label="profit"
              onClick={() => history.push("/balance/recharge")}
            >
              <SvgIcon
                viewBox="0 0 22.243 15.766"
                style={{
                  overflow: "visible",
                  marginBottom: 10,
                  marginRight: 13,
                }}
              >
                <path
                  id="FontAwsome_money-bill-wave_"
                  data-name="FontAwsome (money-bill-wave)"
                  d="M33.276,33.2A15.864,15.864,0,0,0,27.04,32c-6.6,0-13.2,3.34-19.795,3.34A16.254,16.254,0,0,1,2.282,34.6a1.832,1.832,0,0,0-.554-.087A1.7,1.7,0,0,0,0,36.222v17A1.7,1.7,0,0,0,1.009,54.8,15.855,15.855,0,0,0,7.246,56c6.6,0,13.2-3.34,19.8-3.34A16.254,16.254,0,0,1,32,53.395a1.832,1.832,0,0,0,.554.087,1.7,1.7,0,0,0,1.728-1.7v-17a1.706,1.706,0,0,0-1.01-1.579Zm-30.7,4.166a18.643,18.643,0,0,0,3.36.478,3.433,3.433,0,0,1-3.36,2.761Zm0,15.268v-2.56a3.427,3.427,0,0,1,3.413,3.289A12.942,12.942,0,0,1,2.571,52.637Zm14.571-3.494c-2.367,0-4.286-2.3-4.286-5.143s1.919-5.143,4.286-5.143,4.286,2.3,4.286,5.143S19.509,49.143,17.143,49.143Zm14.571,1.488a18.419,18.419,0,0,0-2.91-.452,3.421,3.421,0,0,1,2.91-2.64Zm0-12.649a3.422,3.422,0,0,1-2.99-3.3,12.878,12.878,0,0,1,2.99.678Z"
                  transform="matrix(1, -0.017, 0.017, 1, -0.558, -31.397)"
                  fill="#fff"
                />
              </SvgIcon>
            </Fab>
            <Fab
              className={classes.fab}
              aria-label="calculator"
              onClick={() => history.push("/calculator")}
            >
              <SvgIcon
                viewBox="0 0 21.373 24.427"
                style={{ overflow: "visible", marginBottom: 5, marginRight: 5 }}
              >
                <path
                  id="FontAwsome_calculator_"
                  data-name="FontAwsome (calculator)"
                  d="M22.9,0H2.748A2.818,2.818,0,0,0,0,2.748V26.564a2.818,2.818,0,0,0,2.748,2.748H22.9a2.818,2.818,0,0,0,2.748-2.748V2.748A2.818,2.818,0,0,0,22.9,0ZM7.328,24.915a.788.788,0,0,1-.733.733H4.4a.788.788,0,0,1-.733-.733v-2.2a.788.788,0,0,1,.733-.733H6.6a.788.788,0,0,1,.733.733Zm0-7.328a.788.788,0,0,1-.733.733H4.4a.788.788,0,0,1-.733-.733v-2.2a.788.788,0,0,1,.733-.733H6.6a.788.788,0,0,1,.733.733Zm7.328,7.328a.788.788,0,0,1-.733.733h-2.2a.788.788,0,0,1-.733-.733v-2.2a.788.788,0,0,1,.733-.733h2.2a.788.788,0,0,1,.733.733v2.2Zm0-7.328a.788.788,0,0,1-.733.733h-2.2a.788.788,0,0,1-.733-.733v-2.2a.788.788,0,0,1,.733-.733h2.2a.788.788,0,0,1,.733.733v2.2Zm7.328,7.328a.788.788,0,0,1-.733.733h-2.2a.788.788,0,0,1-.733-.733V15.389a.788.788,0,0,1,.733-.733h2.2a.788.788,0,0,1,.733.733v9.526Zm0-14.656a.788.788,0,0,1-.733.733H4.4a.788.788,0,0,1-.733-.733V4.4A.788.788,0,0,1,4.4,3.664H21.251a.788.788,0,0,1,.733.733Z"
                  fill="#fff"
                />
              </SvgIcon>
            </Fab>
            <Fab className={classes.fab} aria-label="file">
              <SvgIcon
                viewBox="0 0 16.418 21.896"
                style={{
                  overflow: "visible",
                  marginBottom: 10,
                  marginRight: 2,
                }}
              >
                <path
                  id="FontAwsome_file_"
                  data-name="FontAwsome (file)"
                  d="M20.561,5.347,15.9.684A2.668,2.668,0,0,0,14.013-.1H2.668A2.677,2.677,0,0,0,0,2.574V25.7a2.669,2.669,0,0,0,2.668,2.668H18.676A2.669,2.669,0,0,0,21.344,25.7V7.237a2.681,2.681,0,0,0-.784-1.89Zm-2.1,1.673h-4.23V2.79ZM2.668,25.7V2.574h8.893V8.354A1.331,1.331,0,0,0,12.9,9.688h5.781V25.7Z"
                  transform="translate(0 0.1)"
                  fill="#fff"
                />
              </SvgIcon>
            </Fab>
          </Grid>
        </Grid>

        <Grid container className={classes.content}>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography style={{ fontSize: 18, fontWeight: 800 }}>
              Últimas transacciones
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                color: "#a2e0f7",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={() => history.push("/balance")}
            >
              Ver más
            </Typography>
          </Grid>
        </Grid>

        <hr className={classes.divider} />
        <div
          style={{ background: "white", padding: "1vw", paddingBottom: "10vw" }}
        >
          {transactions.map((transaction) => (
            <TransactionCard
              key={transaction.id}
              classes={classes}
              transaction={transaction}
            />
          ))}
        </div>
      </Grid>
    </>
  );
}
