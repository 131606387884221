import React from "react";

export const GenerateRateContext = React.createContext({
  loading: true,
  generatedRates: null,
  currencyId: null,
  currencyName: null,
  setGeneratedRates: () => {},
  setRequestedCurrency: (e) => {},
  setLoading: () => {},
});
