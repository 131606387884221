import React from 'react'

export default function ItemList ({ item, deleteItem }) {
  return (
    <div
      style={{
        color: 'white',
        background: '#ff7c46',
        margin: '1vw',
        fontSize: '4vw',
        padding: '1px 2vw 1vw 2vw',
        borderRadius: '2px',
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'grid',
        gridTemplateColumns: '75% 25%',
        alignItems: 'center',
        gridGap: '7%'
      }}
    >
      <span
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {item.name}
      </span>{' '}
      <i onClick={deleteItem} style={{ marginTop: '0.3vh' }} className="fas fa-times-circle"></i>
    </div>
  )
}
