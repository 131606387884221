import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import Select from 'react-select'

const useStyles = makeStyles({
  input: {
    borderRadius: 5,
    padding: '3vw 0vw 3vw 2vw',
    width: '100%',
    fontSize: '5vw',
    border: 'none'
  },
  UploadImageContainer: {
    background: '#F4F4F4',
    marginTop: '5vw',
    padding: '8vw 4vw 8vw 4vw',
    borderRadius: 5,
    marginBottom: '6vw',
    position: 'relative'
  },
  UploadImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h3': {
      fontSize: '4vw',
      fontWeight: 'lighter'
    },
    '& svg': {
      width: '20%',
      height: '20%',
      marginBottom: '2vw'
    }
  },
  file: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: 3
  },
  trashIcon: {
    position: 'absolute',
    right: '5%',
    top: '5%',
    zIndex: 5
  },
  closeButton: {}
})

const Createbalance = ({
  operatorSelected,
  onSelectOperator,
  operatorsList,
  setimage,
  handleClose,
  open,
  path,
  setpath,
  setAmount,
  Amount,
  Rate,
  setRate,
  handleCreateBalance,
  userBankSelected,
  setuserBankSelected,
  userBankList
}) => {
  const classes = useStyles()

  const [menuIsOpenUserBank, setmenuIsOpenUserBank] = useState(false)
  const [menuIsOpen, setmenuIsOpen] = useState(false)

  const fileSelected = () => {
    const file = document.getElementById('file').files[0]
    const reader = new FileReader()
    reader.onload = function (e) {
      const image = document.createElement('img')
      image.src = e.target.result
      setimage(e.target.result)
      setpath(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  const Control = () => {
    return (
      <div
        onClick={() => setmenuIsOpen(!menuIsOpen)}
        style={{
          display: 'grid',
          backgroundColor: 'white',
          padding: '2.5vw 4vw 2vw 3vw',
          borderRadius: '6px',
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.16)',
          marginTop: '4vw'
        }}
      >
        {operatorsList != null && operatorsList[0] != null
          ? (
          <>
            <h1 style={{ fontSize: 15, fontWeight: 'normal' }}>
              {operatorSelected.name || 'Selecciona un operador'}</h1>
          </>
            )
          : (
          <></>
            )}
      </div>
    )
  }

  const ControlUSerBank = () => {
    return (
      <div
        onClick={() => {
          setmenuIsOpenUserBank(!menuIsOpenUserBank)
        }}
        style={{
          display: 'grid',
          backgroundColor: 'white',
          padding: '2.5vw 4vw 2vw 3vw',
          borderRadius: '6px',
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.16)',
          marginTop: '4vw'
        }}
      >
        {operatorsList != null && operatorsList[0] != null
          ? (
          <>
            <h1 style={{ fontSize: 15, fontWeight: 'normal' }}>
              {userBankSelected.name || 'Selecciona una cuenta del operador'}
            </h1>
          </>
            )
          : (
          <></>
            )}
      </div>
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 20,
      background: state.isSelected ? 'white' : 'white'
    }),
    control: (provied) => ({
      ...provied,
      background: 'white',
      border: 'none'
    })
  }

  const selectOperators = (provied) => {
    setmenuIsOpen(false)
    onSelectOperator(provied)
    setuserBankSelected({})
  }

  const selectUserBank = (provied) => {
    setmenuIsOpenUserBank(false)
    setuserBankSelected(provied)
  }

  function OptionComponent ({ innerRef, innerProps, data }) {
    return (
      <div
        style={{
          display: 'grid',
          padding: '1.5vw 4vw 1vw 3vw',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.16)'
        }}
        onClick={() => selectOperators(data)}
      >
        <h1
          style={{
            padding: '2vw 0vw',
            fontSize: 15,
            marginLeft: '3vw',
            fontWeight: 'normal'
          }}
        >
          {data.name}
        </h1>
      </div>
    )
  }

  function OptionComponentUserBank ({ innerRef, innerProps, data }) {
    return (
      <div
        style={{
          display: 'grid',
          padding: '1.5vw 4vw 1vw 3vw',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.16)'
        }}
        onClick={() => selectUserBank(data)}
      >
        <h1
          style={{
            padding: '2vw 0vw',
            fontSize: 15,
            marginLeft: '3vw',
            fontWeight: 'normal'
          }}
        >
          {data.name}
        </h1>
      </div>
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: { width: '95vw', padding: '5vw 5vw 12vw' }
      }}
    >
      <h1 style={{ fontSize: '4vw', textAlign: 'center' }}>Crear balance</h1>

      <h5
        style={{
          marginBottom: '1vw',
          marginTop: '3vw',
          fontWeight: 'lighter'
        }}
      >
        Operador:
      </h5>
      <Select
        onChange={(e) => onSelectOperator(e)}
        getOptionLabel={(e) => e.alias}
        placeholder="Seleccionar banco"
        options={operatorsList}
        menuIsOpen={menuIsOpen}
        components={{ Option: OptionComponent, Control }}
        styles={customStyles}
      />

      <h5
        style={{
          marginBottom: '1vw',
          marginTop: '3vw',
          fontWeight: 'lighter'
        }}
      >
        UserBank:
      </h5>

      <Select
        onChange={(e) => onSelectOperator(e)}
        getOptionLabel={(e) => e.alias}
        placeholder="Seleccionar userbnak"
        options={userBankList.filter(
          (userbank) => userbank.userId == operatorSelected.id
        )}
        menuIsOpen={menuIsOpenUserBank}
        components={{
          Option: OptionComponentUserBank,
          Control: ControlUSerBank
        }}
        styles={customStyles}
      />

      <h5
        style={{
          marginBottom: '-2vw',
          marginTop: '3vw',
          fontWeight: 'lighter'
        }}
      >
        Monto:
      </h5>
      <div className={classes.input}>
        <input
          style={{
            borderRadius: 5,
            padding: '3vw 2vw 3vw 2vw',
            width: '100%',
            fontSize: '5vw',
            border: '1px solid #00000021',
            marginLeft: '-1vw'
          }}
          value={Amount}
          onInput={(e) => setAmount(e.target.value.replace(/[^0-9]/g, ''))}
          type="text"
          placeholder="Monto"
          name="title"
        />
      </div>

      <div className={classes.UploadImageContainer}>
        {path == '' && (
          <div className={classes.UploadImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34.286"
              height="24"
              viewBox="0 0 34.286 24"
            >
              <path
                id="FontAwsome_cloud-upload-alt_"
                data-name="FontAwsome (cloud-upload-alt)"
                d="M28.8,42.425a5.149,5.149,0,0,0-4.8-7,5.117,5.117,0,0,0-2.855.868,8.572,8.572,0,0,0-16,4.275c0,.145.005.289.011.434A7.716,7.716,0,0,0,7.714,56H27.429A6.857,6.857,0,0,0,28.8,42.425Zm-7.725,3.289h-3.5v6a.86.86,0,0,1-.857.857H14.143a.86.86,0,0,1-.857-.857v-6h-3.5a.856.856,0,0,1-.605-1.463l5.646-5.646a.86.86,0,0,1,1.211,0l5.646,5.646A.857.857,0,0,1,21.075,45.714Z"
                transform="translate(0 -32)"
                fill="#707070"
              />
            </svg>
            <h3>Subir captura de la transferencia</h3>
          </div>
        )}
        {path != '' && (
          <div>
            <div className={classes.trashIcon} onClick={() => setpath('')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.056"
                height="23.778"
                viewBox="0 0 12.056 13.778"
              >
                <path
                  id="FontAwsome_trash-alt_"
                  data-name="FontAwsome (trash-alt)"
                  d="M7.212,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H7.212a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,7.212,11.194Zm4.413-9.042H9.407L8.492.627A1.292,1.292,0,0,0,7.384,0H4.671A1.292,1.292,0,0,0,3.564.627L2.648,2.153H.431A.431.431,0,0,0,0,2.583v.431a.431.431,0,0,0,.431.431H.861v9.042a1.292,1.292,0,0,0,1.292,1.292H9.9a1.292,1.292,0,0,0,1.292-1.292V3.444h.431a.431.431,0,0,0,.431-.431V2.583A.431.431,0,0,0,11.625,2.153Zm-7-.783a.161.161,0,0,1,.139-.078h2.53a.161.161,0,0,1,.139.078l.47.783H4.155ZM9.9,12.486H2.153V3.444H9.9ZM4.2,11.194h.646a.323.323,0,0,0,.323-.323V5.059a.323.323,0,0,0-.323-.323H4.2a.323.323,0,0,0-.323.323v5.812A.323.323,0,0,0,4.2,11.194Z"
                />
              </svg>
            </div>
            <img src={path} style={{ width: '100%' }} />
          </div>
        )}
        {menuIsOpen == false && menuIsOpenUserBank == false && (
          <input
            className={classes.file}
            type="file"
            accept="image/*"
            id="file"
            onChange={fileSelected}
          />
        )}
      </div>

      <div style={{ position: 'absolute', bottom: '4%' }}>
        <button
          style={{
            position: 'absolute',
            bottom: '0px',
            background: operatorSelected.id != null && userBankSelected.id != null ? 'rgb(255, 124, 70) none repeat scroll 0% 0%' : '#bfbebe ',
            width: '74vw',
            padding: '1vw 2vw',
            fontSize: '5vw',
            fontWeight: 'lighter',
            color: 'white',
            border: 'none',
            borderRadius: '5px'
          }}
          onClick={operatorSelected.id != null && userBankSelected.id != null ? handleCreateBalance : () => {}}
        >
          Crear
        </button>
      </div>
    </Dialog>
  )
}

export default Createbalance
