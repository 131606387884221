import "./index.scss";
import React, { useEffect, useState, useCallback, useContext } from "react";
import Header from "../../../components/admin/Header";
import { useDispatch, useSelector } from "react-redux";
import Screenloading from "./../../../components/loaders/screenLoading";
import { Generator } from "./Generator";
import { Button } from "@mui/material";
import Select from "react-select";
import { GenerateRateContext } from "../../../context/GenerateRateContext";
import { makeStyles, Typography } from "@material-ui/core";
import { fetchRates } from "../../../slices/rates";
import RateOfDayCountrySelector from "./RateOfDayCountrySelector";

function RateOfDayCurrencySelector({ action, country }: any) {
  const currencies = useSelector((state: any) => state.currencies.rows);
  const { setRequestedCurrency } = useContext(GenerateRateContext);

  const useStyles = makeStyles({
    selectContainer: {
      margin: "4%",
      marginBottom: "10%",
    },
    selectInput: {
      marginBottom: "4%",
    },
    inputFlag: {
      width: "8%",
      marginRight: "3%",
    },
    optionContainer: {
      fontSize: "4vw",
    },
    buttonSubmit: {
      width: "100%",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      backgroundColor: "rgb(255, 124, 70)",
      margin: "4%",
      borderRadius: "5px",
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
  const [currenciesFromCountry, setCurrenciesFromCountry] = useState(null);

  useEffect(() => {
    if (country && currencies) {
      if (country == null) return;

      const currenciesOfCountry = currencies.filter(
        (el: any) => el.countryId === country.id
      );

      setCurrenciesFromCountry(currenciesOfCountry);

      if (currenciesOfCountry.length == 0) {
        setSelectedCurrency({
          id: null,
          name: "Selecciona una moneda",
        });
      } else {
        setSelectedCurrency({
          id: currenciesOfCountry[0].id,
          name: currenciesOfCountry[0].name,
        });

        const fetchRatesFunct: any = fetchRates;
        dispatch(fetchRatesFunct(currenciesOfCountry[0].id));
      }
    }
  }, [country, currencies]);

  const handleCurrencyChange = useCallback(
    (values: any) => {
      if (currenciesFromCountry) {
        const currency = currencies.find(
          (currency: any) => currency.id === values.id
        );
        setSelectedCurrency({
          id: currency?.id,
          name: currency?.name,
        });

        const fetchRatesFunct: any = fetchRates;
        dispatch(fetchRatesFunct(currency?.id));
      }
    },
    [currenciesFromCountry]
  );

  useEffect(() => {
    if (selectedCurrency?.id != null) {
      setRequestedCurrency(selectedCurrency?.id);
    }
  }, [selectedCurrency]);

  return (
    <div className={"rate-of-day-screen " + classes.selectContainer}>
      <h3 className="title-rate-country">
        Tasa actual de {country?.name ?? ""}
      </h3>
      <Select
        options={currenciesFromCountry || []}
        formatOptionLabel={(currency) => {
          return (
            <div className={classes.optionContainer}>
              <span>{currency.name}</span>
            </div>
          );
        }}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={handleCurrencyChange}
        className={classes.selectInput}
        value={selectedCurrency}
      />
      {/* <Button
          type="submit"
          onClick={() => action(selectedCurrency)}
          style={{
            width: "100%",
            height: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "rgb(255, 124, 70)",
            color: "#FFF",
            borderRadius: "5px",
            textTransform: "capitalize",
          }}
        >
          <Typography
            style={{
              color: "#fff",
              fontWeight: 500,
              textTransform: "capitalize",
            }}
          >
            Ver tasas de la moneda
          </Typography>
        </Button> */}
    </div>
  );
}

export default RateOfDayCurrencySelector;
