import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  IconButton,
  SvgIcon,
  Grid,
  Typography,
  FormControl,
  Input,
  Paper,
  FormHelperText,
  Switch
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Header from '../components/Header'
import { BankLogo } from '../assets/banks/BankLogo'
import { useLocation } from 'react-router-dom'
const OrangeSwitch = withStyles({
  switchBase: {
    color: '#3d3938',
    '&$checked': {
      color: '#ff7c46'
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: 'rgba(255,255,255,1)'
    }
  },
  checked: {
  },
  track: {
    opacity: 1,
    backgroundColor: 'rgba(255,255,255,1)'
  }
})(Switch)

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    backgroundColor: '#003f62'
  },
  content: {
    flex: '1',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    paddingInline: 15,
    color: '#fff',
    position: 'relative'

  },
  accountDetails: {
    marginTop: 5,
    display: 'flex',
    height: 110,
    borderRadius: 10,
    color: 'rgba(0, 63, 98, 0.5)',
    position: 'relative'
  },
  editAccount: {
    position: 'absolute',
    bottom: 5,
    right: 10
  },
  input: {
    color: '#fff',
    fontSize: 28,
    borderBottom: '2px solid rgba(255,255,255,0.7)',
    '&:before': {
      borderBottom: '2px solid transparent'
    },
    '&:after': {
      borderBottom: '2px solid transparent'
    }
  },
  paperTasa: {
    width: 150,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    padding: 5,
    paddingInline: 15,
    color: '#ff7c46'
  },
  verifyTransaction: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 60,
    backgroundColor: '#ff7c46',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
  }
})

const formDefault = {
  montoPesos: '',
  montoBolivares: '',
  ganancia: '',
  tasa: '',
  venetasa: true,
  mitasa: false
}

function NewTransaction (props) {
  const location = useLocation()
  const [Contact, setContact] = useState({})

  useEffect(() => {
    setContact(location.state.contact)
  }, [location])

  const classes = useStyles()
  const history = useHistory()
  const [gananciaVisibility, setGananciaVisibility] = useState(true)
  const [form, setForm] = useState(formDefault)

  function handleChangeNumber (e, field) {
    // Validate to be a number
    if (e.target.value.match(/^[0-9.,]*$/)) {
      // divide integer part and decimal part, add dots to integer part and concat with decimal
      const splitted = e.target.value.split(',')
      const valueWithNoDots = splitted[0].replaceAll('.', '')
      const integerValue = valueWithNoDots.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      const fullValue = typeof splitted[1] == 'string' ? integerValue.concat(',' + splitted[1]) : integerValue
      setForm({ ...form, [field]: fullValue })
    }
  }

  function handleChangeSwitch () {
    setForm({
      ...form,
      venetasa: !form.venetasa,
      mitasa: !form.mitasa
    })
  }

  return (
    <>
        <Grid className={classes.container}>
            <Header text="Nueva Transferencia" />

            <Grid container className={classes.content}>
                <Paper elevation={0} className={classes.accountDetails}>
                    <div style={{ padding: 15 }}>
                        {
                            Contact.bank &&
                            <div style={{ width: '9%' }}>
                                <img src={Contact.bank.Logo} style={{ width: '100%' }} alt={Contact.bank.name} ></img>
                            </div>
                        }
                    </div>
                    <div style={{ marginLeft: 75, paddingTop: 10 }}>
                        <Typography variant="body2" style={{ fontSize: 16 }}>
                            {Contact.number} <br />
                            {`${Contact.name} ${Contact.lastName || ''}`} <br/>
                            V 24725832 <br/>
                            Cta - {Contact.bssAccount.bssAccountType.name}
                        </Typography>
                    </div>
                    <div className={classes.editAccount}>
                    <IconButton onClick={() => history.push('/bank-account/new')}>
                        <SvgIcon viewBox="0 0 27.005 24">
                            <path id="FontAwsome_edit_" data-name="FontAwsome (edit)" d="M18.876,4,23.1,8.225a.458.458,0,0,1,0,.647L12.865,19.112l-4.351.483a.912.912,0,0,1-1.008-1.008l.483-4.351L18.229,4a.458.458,0,0,1,.647,0Zm7.6-1.074L24.183.634a1.834,1.834,0,0,0-2.588,0l-1.66,1.66a.458.458,0,0,0,0,.647L24.164,7.17a.458.458,0,0,0,.647,0l1.66-1.66A1.834,1.834,0,0,0,26.471,2.922ZM18,16.327V21.1H3V6.1H13.775a.576.576,0,0,0,.4-.164l1.875-1.875a.563.563,0,0,0-.4-.961H2.25A2.251,2.251,0,0,0,0,5.346v16.5A2.251,2.251,0,0,0,2.25,24.1h16.5A2.251,2.251,0,0,0,21,21.85v-7.4a.564.564,0,0,0-.961-.4l-1.875,1.875A.576.576,0,0,0,18,16.327Z" transform="translate(0 -0.1)" fill="#FA7D4A"/>
                        </SvgIcon>
                    </IconButton>
                    </div>
                </Paper>

                <FormControl style={{ marginTop: 10 }}>
                    <Input
                        id="monto-pesos"
                        endAdornment={<div style={{ fontSize: 18, color: 'rgba(255,255,255,0.5)' }}>COL$</div>}
                        aria-describedby="monto-pesos"
                        inputProps={{
                          'aria-label': 'Monto en pesos'
                        }}
                        onChange={(e) => handleChangeNumber(e, 'montoPesos')}
                        value={form.montoPesos}
                        className={classes.input}
                    />
                    <FormHelperText id="monto-pesos" style={{ color: 'rgba(255,255,255,0.5)' }}>
                        Monto en pesos
                    </FormHelperText>
                </FormControl>

                <FormControl style={{ marginTop: 10 }}>
                    <Input
                        id="monto-bolivares"
                        endAdornment={<div style={{ fontSize: 18, color: 'rgba(255,255,255,0.5)' }}>BS</div>}
                        aria-describedby="monto-bolivares"
                        inputProps={{
                          'aria-label': 'Monto en bolivares según la tasa'
                        }}
                        onChange={(e) => handleChangeNumber(e, 'montoBolivares')}
                        value={form.montoBolivares}
                        className={classes.input}
                    />
                    <FormHelperText id="monto-pesos" style={{ color: 'rgba(255,255,255,0.5)' }}>
                        Monto en bolivares según la tasa
                    </FormHelperText>
                </FormControl>

                <FormControl style={{ marginTop: 10 }}>
                    <Input
                        id="ganancia-pesos"
                        endAdornment={
                        <IconButton onClick={() => setGananciaVisibility(!gananciaVisibility)}>
                            <VisibilityIcon style={gananciaVisibility ? { color: '#fff' } : { color: 'rgba(255,255,255,0.5)' }}/>
                        </IconButton>}
                        aria-describedby="ganancia-pesos"
                        inputProps={{
                          'aria-label': 'Ganancia en pesos'
                        }}
                        className={classes.input}
                        onChange={(e) => handleChangeNumber(e, 'ganancia')}
                        value={form.ganancia}
                        style={{ color: 'rgba(255,255,255,0.5)', fontSize: 16 }}
                        type={gananciaVisibility ? 'string' : 'password'}
                    />
                    <FormHelperText id="ganancia-pesos" style={{ color: 'rgba(255,255,255,0.5)' }}>
                        Ganancia en pesos
                    </FormHelperText>
                </FormControl>

                <FormControl style={{ marginTop: 10 }}>
                    <Input
                        id="tasa-actual"
                        aria-describedby="tasa-actual"
                        inputProps={{
                          'aria-label': 'Tasa actual'
                        }}
                        className={classes.input}
                        onChange={(e) => handleChangeNumber(e, 'tasa')}
                        value={form.tasa}
                        style={{ color: 'rgba(255,255,255,0.5)', fontSize: 16 }}
                    />
                    <FormHelperText id="monto-pesos" style={{ color: 'rgba(255,255,255,0.5)' }}>
                        Tasa actual
                    </FormHelperText>
                </FormControl>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30, marginBottom: 80 }}>
                    <div style={{ marginLeft: 15 }}>
                        <Paper className={classes.paperTasa}>
                            <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                                0.00150
                            </Typography>
                            <VisibilityIcon style={{ marginLeft: 5, marginTop: 5, alignSelf: 'center', fontSize: 16 }} />
                        </Paper>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, justifyContent: 'space-between' }}>
                            <Typography style={{ color: 'rgba(255,255,255,0.5)', fontSize: 16 }}>
                                VeneTasa
                            </Typography>
                            <OrangeSwitch checked={form.venetasa} onChange={handleChangeSwitch} />
                        </div>
                    </div>
                    <div style={{ marginRight: 15 }}>
                        <Paper className={classes.paperTasa}>
                            <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                                0.00135
                            </Typography>
                        </Paper>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, justifyContent: 'space-between' }}>
                            <Typography style={{ color: 'rgba(255,255,255,0.5)', fontSize: 16 }}>
                                Mi Tasa
                            </Typography>
                            <OrangeSwitch checked={form.mitasa} onChange={handleChangeSwitch}/>
                        </div>
                    </div>
                </div>
            </Grid>

            <div className={classes.verifyTransaction}>
                <Typography style={{ fontWeight: 500 }}>
                    Verificar Transferencia
                </Typography>
                <ArrowForwardIosIcon style={{ position: 'absolute', right: 10 }} />
            </div>
        </Grid>
    </>
  )
}

export default NewTransaction
