import { formatNumber } from './../../../helpers/numbers'
import { useHistory } from 'react-router-dom'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

function PendingItem (props) {
  const History = useHistory()

  return (
    <p
    onClick={() => {
      History.push({ pathname: '/payments', state: { payment: props.payment } })
    }}
      style={{
        fontWeight: 'lighter',
        fontSize: '3vw',
        marginBottom: '1vw',
        color: 'rgb(227, 227, 227)',
        width: '92%',
        display: 'grid',
        marginLeft: '7vw',
        gridTemplateColumns: '75% 25%'
      }}
    >
      <span>{props.name}</span>{' '}
      <span style={{ textAlign: 'right' }}>${formatNumber(props.amount)}</span>
    </p>
  )
}

export function Payments ({ paymentsPendings, paymentsReceivedToday }) {
  const percentaje =
    ((paymentsReceivedToday.length / (paymentsPendings.length + paymentsReceivedToday.length)) * 100).toFixed()

  const History = useHistory()

  return (
    <div
      className="handInHover"
      style={{
        background: 'rgb(5, 40, 62) none repeat scroll 0% 0%',
        padding: '3vw 4vw',
        marginTop: '5vw',
        borderRadius: '9px',
        boxShadow:
          '2.8 2.8 2.2 rgba(0, 0, 0, 0.02),   6.7 6.7 5.3 rgba(0, 0, 0, 0.028),   12.5 12.5 10 rgba(0, 0, 0, 0.035),   22.3 22.3 17.9 rgba(0, 0, 0, 0.042),   41.8 41.8 33.4 rgba(0, 0, 0, 0.05),   100 100 80 rgba(0, 0, 0, 0.07)'
      }}
    >
      <h3 style={{ fontSize: '4.4vw' }}>
      <b>{paymentsPendings ? paymentsPendings.length : 0}</b> Pago{ paymentsPendings.length == 1 ? '' : 's'} por aprobar.
      </h3>

      {
        paymentsPendings.length != 0 &&
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '29% 60%',
          alignItems: 'center',
          marginTop: '3vw'
        }}
      >
        <div style={{ position: 'relative' }}>
          <CircularProgressbar
            value={percentaje}
            text={''}
            styles={buildStyles({
              textColor: '#f000',
              pathColor: '#86fb82',
              trailColor: 'black'
            })}
          />
          <h3
            style={{
              position: 'absolute',
              top: '17%',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <span style={{ fontSize: '2vw', fontWeight: 'lighter' }}>
               Aprobados:
            </span>

            <br />
            {percentaje}%
          </h3>
        </div>

        <div>
           {paymentsPendings.map((pending) => (
            <PendingItem
              key={JSON.stringify(pending)}
              payment={pending}
              name={pending.user.name}
              amount={pending.amount}
            ></PendingItem>
           ))}
        </div>
      </div>
      }

    </div>
  )
}
