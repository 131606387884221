import React from "react";
import MainLayout from "../../layouts/main";
import "./styles/rates-of-the-day-styles.scss";
import { TextField, Typography, Button } from "@material-ui/core";
import RatesOfTheDayDollars from "./elements/rates-of-the-day-dollars";

function RatesOfTheDayScreen() {
  return (
    <MainLayout text="Tasa del día" skipPadding>
      <div className="rates-of-the-day-screen">
        <h3>Tasa actual de Chile</h3>
        <RatesOfTheDayDollars />
        <Button>Actualizar valores</Button>
      </div>
    </MainLayout>
  );
}

export default RatesOfTheDayScreen;
