import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./screens/Home";
import ListAccounts from "./screens/bankAccounts/ListAccounts";
import NewAccount from "./screens/bankAccounts/NewAccount";
import NewTransaction from "./screens/NewTransaction";
import HistoryBalance from "./screens/HistoryBalance";
import RechargeBalance from "./screens/RechargeBalance";
import Login from "./screens/Login";
import Logout from "./screens/Logout";
import SignUp from "./screens/signUp/index";
import Contacts from "./screens/Contacts/index";
import Createcontact from "./screens/Contacts/create.jsx";
import CreateTransference from "./screens/transferences/index";
import CodeRegister from "./screens/code/register";
import CodeRecovery from "./screens/code/recovery";
import ChangePassword from "./screens/recovery/changePassword";
import Recovery from "./screens/recovery/index";
import Notifications from "./screens/Notifications";
import ProfitCalculator from "./screens/ProfitCalculator";
import Dashboard from "./screens/admins/Home/dashboard";
import DashboardHistory from "./screens/admins/Home/history";
import generalSettings from "./screens/admins/generalOptions";
import OperatorDashboard from "./screens/operators/Dashboard";
import Verifications from "./screens/verifications/index.js";
import Balances from "./screens/balances/index";
import sendNotifications from "./screens/sendNotifications/index";
import Accounts from "./screens/accounts/index";
import TeamsIndex from "./screens/teams/index";
import AccountsBank from "./screens/accounts/accountsbank";
import Payments from "./screens/payments/index";
import PaymentHistory from "./screens/payments/history";
import OperatorAccount from "./screens/operator account/index";
import DetailOperator from "./screens/operators/Details";
import HistoryOperator from "./screens/operators/History";
import NewAccountOperator from "./screens/operators/new_account";
import {
  AdminRoutes,
  OperatorRoutes,
  SuperAdminRoutes,
} from "./routes/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import Pendings from "./screens/pendings/index";
import RangesAssignations from "./screens/admins/ranges/RangesAssignations/index";
import RangesList from "./screens/admins/ranges/RangesList/index";
import Dayrate from "./screens/admins/Dayrate/index.jsx";
import BalanceAdmin from "./screens/admins/balance/index.js";
import BssBalanceAdmin from "./screens/admins/balance/indexBss";
import { setEvent } from "./slices/loading";
import Error404 from "./components/loaders/404.jsx";
import { axiosConfigInit } from "./helpers/axios";
import AppContext from "./context/AppContext";
import useInitialState from "./hooks/useInitalState";
import UserDetails from "./screens/users_details/index.js";
import UserSpecificDetails from "./screens/users_details/User";
import Currencies from "./screens/currencies";
import CurrenciesDetails from "./screens/currencies/details";

import { AllUsers } from "./screens/admins/all-users/index.jsx";
import { TransactionsHistory } from "./screens/admins/transactions/history";
import { DetailDay } from "./screens/admins/transactions/detail-day";
import CurrencyCreation from "./screens/currencies/create";
import { fetchCountries } from "./slices/countries";
import { fetchCurrencies } from "./slices/currencies";
import AccountActivation from "./screens/account-activation/account-activation";
import RatesOfTheDayScreen from "./screens/rates-of-the-day/rates-of-the-day-screen";

import { io } from "socket.io-client";
import HistoryTransfernecesScreen from "./screens/transferences/history_transferneces";

export const RoleId = Object.freeze({
  SuperAdmin: 1,
  OperatorUser: 2,
  MiddlewareUser: 3,
  AppUser: 4,
  AdminUser: 5,
});

function App() {
  axiosConfigInit();

  const initialState = useInitialState();

  const [connected, setConeccted] = useState(true);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(setEvent());
    const accessToken = localStorage.getItem("accessToken");

    if (Object.keys(user).length > 0 && accessToken != null) {
      dispatch(fetchCurrencies());
      dispatch(fetchCountries());
    }
  }, [user]);

  return (
    <>
      <AppContext.Provider value={initialState}>
        {!connected && <Error404 setConecctedFunc={setConeccted} />}
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signUp">
              <SignUp />
            </Route>
            <Route path="/code-register">
              <CodeRegister />
            </Route>
            <Route path="/code-recovery">
              <CodeRecovery />
            </Route>
            <Route path="/change-password">
              <ChangePassword />
            </Route>
            <Route path="/recovery">
              <Recovery />
            </Route>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/account-activation">
              <AccountActivation />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
            <Route
              exact
              path="/transactions-history"
              component={TransactionsHistory}
            ></Route>
            <Route
              path="/transactions-history/details"
              component={DetailDay}
            ></Route>
            <Route
              path="/history/transference"
              component={HistoryTransfernecesScreen}
            />
          </Switch>
          <OperatorRoutes>
            <Switch>
              <Route path="/operator-dashboard" component={OperatorDashboard} />
              <Route path="/operator/history" component={HistoryOperator} />
              <Route path="/operador/:id" component={DetailOperator} />
              <Route path="/operator-account" component={OperatorAccount} />
              <Route path="/balances" component={Balances} />
              <Route path="/new_account" component={NewAccountOperator} />
              <Route path="/contacts-transferences">
                <Switch>
                  <Route
                    exact
                    path="/contacts-transferences"
                    component={Contacts}
                  />
                  <Route
                    exact
                    path="/contacts-transferences/new"
                    component={Createcontact}
                  />
                  <Route
                    exact
                    path="/contacts-transferences/edit"
                    component={Createcontact}
                  />
                </Switch>
              </Route>
              <Route
                path="/createTransference"
                component={CreateTransference}
              />
            </Switch>
          </OperatorRoutes>
          <AdminRoutes>
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/history" component={DashboardHistory} />
              <Route
                exact
                path="/rates-of-the-day"
                component={RatesOfTheDayScreen}
              />
              <Route path="/ranges">
                <Switch>
                  <Route exact path="/ranges" component={RangesAssignations} />
                  <Route
                    path="/ranges/assignations"
                    component={RangesAssignations}
                  />
                  <Route path="/ranges/list" component={RangesList} />
                </Switch>
              </Route>
              <Route path="/generalSettings" component={generalSettings} />
              <Route path="/pendings" component={Pendings} />
              <Route path="/verifications">
                <Switch>
                  <Route
                    exact
                    path="/verifications"
                    component={() => Verifications(3)}
                  />
                  <Route
                    path="/verifications/approved"
                    component={() => Verifications(1)}
                  />
                  <Route
                    path="/verifications/rejected"
                    component={() => Verifications(2)}
                  />
                </Switch>
              </Route>
              <Route path="/balances-admin" component={BalanceAdmin} />
              <Route path="/bssbalances-admin" component={BssBalanceAdmin} />
              <Route path="/transactions/new" component={NewTransaction} />
              <Route path="/users">
                <Switch>
                  <Route exact path="/users" component={UserDetails} />
                  <Route
                    path="/users/details"
                    component={UserSpecificDetails}
                  />
                </Switch>
              </Route>
              <Route path="/accounts">
                <Switch>
                  <Route exact path="/accounts" component={Accounts}></Route>
                  <Route path="/accounts/:id" component={AccountsBank}></Route>
                </Switch>
              </Route>
              <Route path="/teams" component={TeamsIndex} />
              <Route path="/dayrate" component={Dayrate} />
              <Route path="/payments">
                <Switch>
                  <Route exact path="/payments" component={Payments}></Route>
                  <Route
                    path="/payments/history"
                    component={PaymentHistory}
                  ></Route>
                </Switch>
              </Route>
              <Route path="/all-users-balance" component={AllUsers} />
              <Route
                path="/createTransference"
                component={CreateTransference}
              />
              <Route path="/contacts-transferences">
                <Switch>
                  <Route
                    exact
                    path="/contacts-transferences"
                    component={Contacts}
                  />
                  <Route
                    exact
                    path="/contacts-transferences/new"
                    component={Createcontact}
                  />
                  <Route
                    exact
                    path="/contacts-transferences/edit"
                    component={Createcontact}
                  />
                </Switch>
              </Route>

              <Route path="/notifications">
                <Switch>
                  <Route
                    exact
                    path="/notifications"
                    component={Notifications}
                  />
                  <Route
                    exact
                    path="/notifications/send"
                    component={sendNotifications}
                  />
                </Switch>
              </Route>
              <Route path="/calculator" component={ProfitCalculator} />
              <Route path="/bank-account">
                <Switch>
                  <Route exact path="/bank-account" component={ListAccounts} />
                  <Route path="/bank-account/new" component={NewAccount} />
                </Switch>
              </Route>
              <Route path="/balance">
                <Switch>
                  <Route exact path="/balance" component={HistoryBalance} />
                  <Route path="/balance/recharge" component={RechargeBalance} />
                </Switch>
              </Route>
            </Switch>
          </AdminRoutes>
          <SuperAdminRoutes>
            <Switch>
              <Route path="/currencies">
                <Switch>
                  <Route exact path="/currencies" component={Currencies} />
                  <Route
                    path="/currencies/details/:id"
                    component={CurrenciesDetails}
                  />
                  <Route
                    path="/currencies/create"
                    component={CurrencyCreation}
                  />
                </Switch>
              </Route>
            </Switch>
          </SuperAdminRoutes>
        </Router>
      </AppContext.Provider>
    </>
  );
}

export default App;
