import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Box, Grid, InputBase, Paper, CircularProgress } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import Header from '../../components/Header'
import ListAccount from '../../components/listAccount/listAccount'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBssaccounts } from '../../slices/bssaccount'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    backgroundColor: '#003f62'
  },
  content: {
    flex: '1',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    paddingInline: 15,
    color: '#fff',
    position: 'relative'
  },
  search: {
    padding: '1px 10px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 10
  },
  input: {
    color: '#003f62',
    marginLeft: 5,
    flex: 1
  },
  iconButton: {
    padding: 0,
    color: '#FA7D4A'
  },
  transaction: {
    backgroundColor: '#fcfcfc',
    paddingInline: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 60,
    borderRadius: 10,
    marginTop: 10
  },
  circle: {
    backgroundColor: '#fff',
    width: 40,
    height: 40,
    borderRadius: '50%',
    border: '2px solid rgba(250, 125, 74, .6)',
    color: 'rgba(250, 125, 74, .6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15
  },
  addBtn: {
    position: 'fixed',
    bottom: 40,
    right: 30,
    backgroundColor: '#FA7D4A',
    width: 60,
    height: 60,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

function ListAccounts () {
  const classes = useStyles()
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const bssaccounts = useSelector(state => state.bssaccounts.bssaccounts)

  useEffect(() => {
    dispatch(fetchBssaccounts())
  }, [])

  function handleSearchChange (e) {
    setSearch(e.target.value)
    if (e.target.value.length >= 3) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <>
        <div className={classes.container}>
            <Header text="Transferencias" />

            <div style={{ margin: '10px 15px' }}>
                <Paper component="form" className={classes.search}>
                    <InputBase
                        className={classes.input}
                        placeholder="Buscar cuenta"
                        inputProps={{ 'aria-label': 'buscar cuenta' }}
                        onChange={handleSearchChange}
                        value={search}
                    />
                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                        <SearchIcon fontSize="large" />
                    </IconButton>
                </Paper>
            </div>

            <Grid container className={classes.content}>
                <Grid container style={{ justifyContent: 'center' }}>
                    {loading
                      ? (
                        <CircularProgress style={{ marginTop: 50, color: '#fff' }}/>
                        )
                      : bssaccounts.map(transaction => (
                       <ListAccount key={transaction.id} transaction={transaction} classes={classes} history={history}/>
                      ))}
                </Grid>

                <Box className={classes.addBtn} boxShadow={3}>
                    <IconButton aria-label="add" style={{ color: '#fff' }} onClick={() => history.push('/bank-account/new')}>
                        <AddIcon style={{ fontSize: 40 }} />
                    </IconButton>
                </Box>
            </Grid>
        </div>
    </>
  )
}

export default ListAccounts
