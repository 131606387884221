import React from "react";
import { Country } from "../../../models/countries";

function RateOfDayCountryItem(props: any) {
  const country: Country = props.country;

  return (
    <div
      className={"RateOfDayCountryItem"}
      onClick={() => props.setSelectedCountry(country)}
    >
      {country.image && <img src={country.image} />}
      {country.name}
    </div>
  );
}

export default RateOfDayCountryItem;
