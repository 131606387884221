import React from "react";
import MainLayout from "../../layouts/main";
import { Transactions } from "./transactions/index";
import { Payments } from "./Payments/index";
import { Verifications } from "./Verifications/index";
import Screenloading from "./../loaders/screenLoading";

function Index({
  loading,
  pendings,
  assignedToday,
  paymentsReceivedToday,
  paymentsPendings,
  verificationsList,
  getData,
}) {
  return (
    <MainLayout text="Pendientes">
      <div
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: "5vw",
          marginTop: "5vw",
        }}
      >
        {loading && <Screenloading></Screenloading>}

        <h3
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "5vw",
            marginTop: "5vw",
          }}
        >
          <span>Asuntos pendientes</span>
          <i
            onClick={getData}
            className="fa-solid fa-rotate-right"
            style={{ color: "#f96123", fontSize: "5vw", marginLeft: "4vw" }}
          ></i>
        </h3>
        <Transactions pendings={pendings} assignedToday={assignedToday} />
        <Payments
          paymentsReceivedToday={paymentsReceivedToday}
          paymentsPendings={paymentsPendings}
        />
        <Verifications verificationsList={verificationsList} />
      </div>{" "}
    </MainLayout>
  );
}

export default Index;
