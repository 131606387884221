import { useHistory } from 'react-router-dom'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

function PendingItem (props) {
  const History = useHistory()

  return (
    <p
    onClick={() => {
      History.push({ pathname: '/verifications', state: { verification: props.verification } })
    }}
      style={{
        fontWeight: 'lighter',
        fontSize: '3vw',
        marginBottom: '1vw',
        color: 'rgb(227, 227, 227)',
        width: '92%',
        display: 'grid',
        gridTemplateColumns: '66% auto'
      }}
    >
      <span>{props.name}</span>{' '}
      <h5><i className="fas fa-phone"></i> {props.phone}</h5>{' '}
    </p>
  )
}

export function Verifications ({ verificationsList }) {
  const percentaje = ((verificationsList.filter(pending => pending.verificationStateId != 3).length / (verificationsList.filter(pending => pending.verificationStateId == 3).length + verificationsList.filter(pending => pending.verificationStateId != 3).length)) * 100).toFixed()
  const History = useHistory()

  return (
    <div
    className="handInHover"
      style={{
        background: '#e64808',
        padding: '3vw 4vw',
        marginTop: '4vw',
        borderRadius: '9px',
        boxShadow:
          '2.8 2.8 2.2 rgba(0, 0, 0, 0.02),   6.7 6.7 5.3 rgba(0, 0, 0, 0.028),   12.5 12.5 10 rgba(0, 0, 0, 0.035),   22.3 22.3 17.9 rgba(0, 0, 0, 0.042),   41.8 41.8 33.4 rgba(0, 0, 0, 0.05),   100 100 80 rgba(0, 0, 0, 0.07)'
      }}
    >
      <h3 style={{ fontSize: '4.4vw' }}>
      <b>{verificationsList.filter(pending => pending.verificationStateId == 3).length}</b> Verificacion{ verificationsList.filter(pending => pending.verificationStateId == 3).length == 1 ? '' : 'es'} por aprobar.
      </h3>

      {verificationsList.filter(pending => pending.verificationStateId == 3).length != 0 &&
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '70% 30%',
            alignItems: 'center',
            marginTop: '3vw'
          }}
        >
          <div>

          {verificationsList.filter(pending => pending.verificationStateId == 3).map((pending) => (
              <PendingItem
                key={JSON.stringify(pending)}
                verification={pending}
                name={pending.user.name}
                phone={pending.user.phone}
              ></PendingItem>
          ))}
          </div>

          <div style={{ position: 'relative' }}>
            <CircularProgressbar
              value={percentaje}
              text={''}
              styles={buildStyles({
                textColor: '#f000',
                pathColor: '#86fb82',
                trailColor: 'black'
              })}
            />
            <h3
              style={{
                position: 'absolute',
                top: '17%',
                width: '100%',
                textAlign: 'center'
              }}
            >
              <span style={{ fontSize: '2vw', fontWeight: 'lighter' }}>
                Resueltas:
              </span>

              <br />
              {percentaje}%
            </h3>
          </div>
        </div>
      }
    </div>
  )
}
