import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHistory, checkPayment } from "../../slices/payments";
import { makeStyles } from "@material-ui/core/styles";
import PaymentCard from "../../components/payments/paymentCard";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isEmpty } from "lodash";
import EmptyIcon from "../../assets/emptyicon.svg";
import SearchForm from "../../components/form/searchForm";
import MainLayout from "../../layouts/main";

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    minHeight: "100vh",
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  daterangepickerControlSection: {
    maxWidth: "246px",
    margin: "30px auto",
    paddingTop: "50px",
  },
  input: {
    marginTop: 10,
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 10,
    width: "100%",
    fontSize: 16,
  },
});

function renderPayment(history, handleSend, setShowCalendar) {
  const items = [];

  for (const key in history) {
    if (Object.hasOwnProperty.call(history, key)) {
      const payments = history[key];

      items.push(
        <>
          <Typography
            style={{
              color: "#222",
              marginTop: "8px",
              marginBottom: "8px",
              textAlign: "center",
            }}
            key={`${key}b`}
          >
            <Link
              style={{ color: "white" }}
              to="#"
              onClick={() => setShowCalendar(true)}
              key={`${key}a`}
            >
              <i className="far fa-calendar-alt" key={`${key}c`}></i> {key}
            </Link>
          </Typography>
          {payments.map((payment) => {
            return (
              <PaymentCard
                payment={payment}
                key={payment.id}
                handleSendData={handleSend}
                detail={true}
              />
            );
          })}
        </>
      );
    }
  }

  return items;
}

const Index = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { history } = useSelector((state) => state.payments);
  const [value, setValue] = useState([null, null]);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const search = async (e) => {
    e.preventDefault();
    const [start, end] = value;
    dispatch(await getPaymentHistory(start, end, query));
  };

  const handleSend = async (paymentId, state, text) => {
    dispatch(await checkPayment(paymentId, state, text));
  };

  const handleGetPaymentHistory = async () => {
    const [start, end] = value;
    dispatch(await getPaymentHistory(start, end, query));
  };

  useEffect(() => {
    handleGetPaymentHistory();
  }, []);

  return (
    <MainLayout
      colorMode={isEmpty(history) ? "#003f62" : "#eee"}
      goBack={true}
      withoutPhoto={true}
      text="Pagos pendientes"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText="Check-in"
          endText="Check-out"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(startProps, endProps) => <></>}
          onAccept={async () => await handleGetPaymentHistory()}
        />
      </LocalizationProvider>
      {!isEmpty(history) ? (
        <>
          <SearchForm submit={search} setQuery={setQuery} />
          {renderPayment(history, handleSend, setOpen)}
        </>
      ) : (
        <>
          <table style={{ width: "100%", height: "100vw" }}>
            <tbody>
              <tr>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <SearchForm
                    submit={search}
                    setQuery={setQuery}
                    query={query}
                  />
                  <Link
                    to="#"
                    onClick={() => setOpen(true)}
                    style={{
                      color: "#eee",
                      marginTop: "4vh",
                      display: "block",
                      marginBottom: "7vh",
                    }}
                  >
                    Seleccione un rango de fechas
                  </Link>
                  <img src={EmptyIcon}></img>
                  <br />
                  <br />
                  <br />
                  <span style={{ color: "#eee" }}>
                    {" "}
                    Aún no tiene pagos realizados{" "}
                  </span>
                  <br />
                  <Link to="/payments" style={{ color: "#eee" }}>
                    Ingrese aquí para ir a pagos
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </MainLayout>
  );
};

export default Index;
