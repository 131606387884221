import { useState } from 'react'

const useLocalStorage = (key, initialValue = '') => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item || initialValue
    } catch (err) {
      return initialValue
    }
  })

  const setValue = (value) => {
    try {
      setStoredValue(value)
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      console.log(e)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
