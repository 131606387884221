import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logOut } from '../slices/auth'

function Login () {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logOut(history))
  }, [])

  return (
        <div>

        </div>
  )
}

export default Login
