import React from 'react'
import { Input, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 10,
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 10,
    width: '100%',
    fontSize: 16
  }
}))

const Index = ({ submit, setQuery, query }) => {
  const classes = useStyles()
  return (
      <form onSubmit={(e) => { submit(e) }}>
        <Input
          value={query}
          variant="outlined"
          placeholder="Buscar..."
          style={{ width: '100%' }}
          className={ classes.input }
          endAdornment = {
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              style={{ color: 'orange' }}
              type='submit'
            >
              <SearchIcon></SearchIcon>
            </IconButton>
          }
          disableUnderline={true}
          onChange={(e) => setQuery(e.target.value)}
        />
      </form>
  )
}

export default Index
