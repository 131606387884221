import { createSlice } from '@reduxjs/toolkit'
import { Axios } from '../helpers/axios'

export const banksSlice = createSlice({
  name: 'parentMethods',
  initialState: {
    banks: []
  },
  reducers: {
    setBanks: (state, action) => {
      state.banks = action.payload
    }
  }
})

const { setBanks } = banksSlice.actions

export const fetchBanks = () => async dispatch => {
  console.log('fetchBanks')
  try {
    const response = await Axios.get(process.env.REACT_APP_API_URL + 'parentbank')

    const banks = response.data.data

    banks.map(bank => {
      const name = bank.name

      switch (name) {
        case 'Banesco Banco Universal S.A.C.A.':
          bank.order = 1
          break
        case 'Banco de Venezuela S.A.C.A. Banco Universal':
          bank.order = 2
          break
        case 'Banco Provincial, S.A. Banco Universal':
          bank.order = 3
          break
        case 'Banco Mercantil, C.A S.A.C.A. Banco Universal':
          bank.order = 4
          break
        case 'BOD - Banco Occidental de Descuento, Banco Universal C.A.':
          bank.order = 5
          break
        case 'BNC - Banco Nacional de Crédito, C.A. Banco Universal':
          bank.order = 6
          break
        case 'Banco Bicentenario Banco Universal C.A.':
          bank.order = 7
          break
        case 'Bancaribe C.A. Banco Universal':
          bank.order = 8
          break
        default:
          bank.order = 100
      }

      return true
    })

    banks.sort(function (a, b) {
      if (a.order > b.order) {
        return 1
      }
      if (a.order < b.order) {
        return -1
      }
      // a must be equal to b
      return 0
    })

    dispatch(setBanks(banks))

    return banks
  } catch (e) {
    return console.error(e.message)
  }
}

export default banksSlice.reducer
