import React from 'react'
import ItemList from './ItemList'

const Listofusers = ({ listUsersToSend, deleteItem }) => {
  return (
    <div
    className="ContainerTagsUsersNotifications"
      style={{
        display: 'grid',
        background: 'rgba(255, 255, 255, 0.21) none repeat scroll 0% 0%',
        borderRadius: '5px',
        marginBottom: '4vw',
        marginTop: '0vw',
        gridTemplateColumns: 'repeat(3,1fr)'
      }}
    >
      {listUsersToSend.map((item) => (
        <ItemList key={JSON.stringify(item)} deleteItem={() => deleteItem(item)} {...{ item }}/>
      ))}
    </div>
  )
}

export default Listofusers
