import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  main: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5vw',
    padding: '0 2vw',
    '@media (min-width: 700px)': {
      marginBottom: '1vw'
    },
    '& svg': {
      marginRight: '2vw'
    },
    '& h4': {
      fontSize: '5vw',
      fontWeight: '300',
      '@media (min-width: 700px)': {
        fontSize: '2vw'
      }
    }
  }
})

export default function Range ({ range, setModalEditRange, setrangeSelectedToAction, setModalDeleteRange }) {
  const classes = useStyles()

  return (
        <div className={classes.main}>
            <h4>{range.name}</h4>
            <div>
                <svg onClick={() => { setModalEditRange(true); setrangeSelectedToAction(range) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path id="FontAwsome_pencil-alt_" data-name="FontAwsome (pencil-alt)" d="M23.363,6.733,21.2,8.894a.563.563,0,0,1-.8,0l-5.2-5.2a.563.563,0,0,1,0-.8L17.362.732a2.255,2.255,0,0,1,3.183,0L23.363,3.55A2.246,2.246,0,0,1,23.363,6.733ZM13.345,4.75,1.035,17.06l-.994,5.7a1.126,1.126,0,0,0,1.3,1.3l5.7-1L19.35,10.75a.563.563,0,0,0,0-.8l-5.2-5.2a.569.569,0,0,0-.8,0ZM5.84,16a.654.654,0,0,1,0-.928l7.219-7.219a.656.656,0,1,1,.928.928L6.768,16a.654.654,0,0,1-.928,0ZM4.148,19.947H6.4v1.7l-3.024.53L1.916,20.721l.53-3.024h1.7Z" transform="translate(-0.024 -0.075)" fill="#fff" />
                </svg>
                <svg onClick={() => { setModalDeleteRange(true); setrangeSelectedToAction(range) }} xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24">
                    <path id="FontAwsome_trash-alt_" data-name="FontAwsome (trash-alt)" d="M1.5,21.75A2.25,2.25,0,0,0,3.75,24h13.5a2.25,2.25,0,0,0,2.25-2.25V6H1.5Zm12.75-12a.75.75,0,0,1,1.5,0v10.5a.75.75,0,0,1-1.5,0Zm-4.5,0a.75.75,0,1,1,1.5,0v10.5a.75.75,0,0,1-1.5,0Zm-4.5,0a.75.75,0,0,1,1.5,0v10.5a.75.75,0,0,1-1.5,0Zm15-8.25H14.625L14.184.623A1.125,1.125,0,0,0,13.177,0H7.819a1.112,1.112,0,0,0-1,.623L6.375,1.5H.75A.75.75,0,0,0,0,2.25v1.5a.75.75,0,0,0,.75.75h19.5A.75.75,0,0,0,21,3.75V2.25A.75.75,0,0,0,20.25,1.5Z" transform="translate(0 0)" fill="#fff" />
                </svg>
            </div>
        </div>
  )
}
