export const dateDiff = (date?: Date) => {
  if (date == null) return "";

  const currentDate = new Date();

  const difference = currentDate.getTime() - new Date(date).getTime();
  const resultInMinutes = Math.round(difference / 60000);

  if (resultInMinutes < 2) {
    return "Justo ahora";
  }
  if (resultInMinutes > 60) {
    return `Hace ${(resultInMinutes / 60).toFixed(0)} ${
      (resultInMinutes / 60).toFixed(0) == "1" ? "hora" : "horas"
    } `;
  }
  if (resultInMinutes > 1440) {
    return `Hace ${(resultInMinutes / 1440).toFixed(0)} ${
      (resultInMinutes / 1440).toFixed(0) == "1" ? "día" : "días"
    }`;
  }
  if (resultInMinutes < 60 && resultInMinutes > 2) {
    return `Hace ${resultInMinutes} minutos`;
  }
};

export const getMonthAndDayFromDate = (date: Date): string => {
  const month = date.getMonth();
  const day = date.getDate();

  return `${month + 1}-${day}`;
};
