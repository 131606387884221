import React from "react";
import ClockIcon from "../../assets/clock.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function SummaryNewDashboard({ assignedTodayArray, pendingsArray }: any) {
  const icon: any = ClockIcon;
  const history = useHistory();

  return (
    <>
      <h5 className="summary-new-dashboard-title">Estatus del sistema</h5>

      <div className="summary-new-dashboard">
        <div className="pending">
          <h4>Transacciones pendientes</h4>
          <div className="pending-number-container">
            <div className="circle" />
            <h1>{pendingsArray.length}</h1>
            <h4>Sin asignar</h4>
          </div>
        </div>
        <div
          className="last handInHover"
          onClick={() => history.push("/history/transference")}
        >
          <h4>Asignadas hoy</h4>
          <div>
            <img src={icon} />
            <h4>{assignedTodayArray.length}</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default SummaryNewDashboard;
