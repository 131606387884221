import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import IlustrationWithouthOperators from "../../../assets/ilustrations/Sinoperadoresempty.svg";
import ModalCustom from "../../../components/modal/index";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { formatNumber } from "./../../../helpers/numbers";
import { cancelTransference } from "./../../../slices/transactions";
import Swal from "sweetalert2";
import { isNumber } from "lodash";

const useStyles = makeStyles({
  flexContainer: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f1f1f1",
    padding: 10,
    "& div": {
      flex: "1",
      color: "#222",
      width: "100px",
      lineHeight: "35px",
      height: "55px",
      paddingTop: 5,
      paddingBottom: "60px",
    },
  },
  logo: {
    width: 50,
    height: 50,
    position: "relative",
  },
  modal: {
    position: "absolute",
    top: "10%",
    overflowY: "scroll",
    left: "10%",
    minHeight: "39vh",
    maxHeight: "80vh",
    borderRadius: "22px",
    background: "white",
    padding: "4vw 4vw",
    width: "80vw",
    border: "none",
    display: "flex",
    justifyContent: "space-evenly",
    "@media (min-width: 700px)": {
      padding: "4vw 4vw",
    },
  },
  card: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "13px",
    paddingBottom: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
});

export function Modalassignoperator({
  selectedValue,
  assigned,
  setShowModal,
  handleChange,
  transactionSelected,
  handleSendForm,
  operators,
  cancelTransaction,
}) {
  const classes = useStyles();

  const ItemDataAccount = ({ text }) => {
    const copyvalue = () => {
      var textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      toast.success("Copiado!", {
        position: "top-right",
        toastId: "success",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    return (
      <h5>
        {" "}
        <span>{text}</span>{" "}
        <i
          className="far fa-copy"
          onClick={copyvalue}
          style={{ color: "#bbb5b5", paddingLeft: "1vw" }}
        ></i>
      </h5>
    );
  };
  const ButtonCopyDataAccount = ({ text }) => {
    const copyvalue = () => {
      var textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      toast.success("Copiado!", {
        position: "top-right",
        toastId: "success",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    return (
      <button
        onClick={copyvalue}
        style={{
          border: "none",
          background: "#003350",
          color: "white",
          padding: "1vw 3vw",
          borderRadius: "5px",
          fontWeight: "bold",
          marginTop: "2vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <i
          className="far fa-copy"
          style={{ color: "#FFFFFF", paddingRight: "2vw" }}
        ></i>
        <span>Copiar</span>
      </button>
    );
  };

  console.log("====================================");
  console.log("====================================");

  const bssAccountType = transactionSelected.bssAccount.bssAccountType;
  const bssAccountTypeId = transactionSelected.bssAccount.bssAccountTypeId;
  return (
    <ModalCustom>
      <div className={classes.modal}>
        {!assigned ? (
          <div style={{ width: "100%", fontSize: "13px" }}>
            {transactionSelected && (
              <div>
                <div>
                  <div
                    style={{
                      heght: "20px",
                      paddingTop: "0px",
                      paddingBottom: "10px",
                      fontSize: "14px",
                    }}
                    colSpan={2}
                  >
                    <Link
                      to="#"
                      onClick={() => setShowModal(false)}
                      style={{ float: "right" }}
                    >
                      <CloseIcon />
                    </Link>
                    Detalles de la transacción <br />
                    <span style={{ color: "rgb(249, 97, 35)" }}>
                      {transactionSelected.userRequested.name}
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "22vw" }}>
                    <img
                      src={transactionSelected.bssAccount.bank.Logo}
                      alt="Image bank"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: "7vw",
                      width: "70vw",
                      fontWeight: "200",
                      color: "#003350",
                    }}
                  >
                    <div>
                      <b style={{ fontWeight: "bold", fontSize: "3.7vw" }}>
                        {transactionSelected.bssAccount.bank.alias}
                      </b>{" "}
                      <br />
                      {transactionSelected.bssAccount.number !=
                      "1234567898745632" ? (
                        <div>
                          <ItemDataAccount
                            text={
                              transactionSelected.bssAccount.bank.prefix +
                              "" +
                              transactionSelected.bssAccount.number
                            }
                          />
                          <ItemDataAccount
                            text={transactionSelected.bssAccount.name}
                          />
                          <ItemDataAccount
                            text={transactionSelected.bssAccount.identification}
                          />
                          <ItemDataAccount
                            text={
                              "Cta-" + bssAccountTypeId || bssAccountType.name
                            }
                          />
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                              marginTop: "1vw",
                            }}
                            href={
                              "https://wa.me/" +
                              transactionSelected.userRequested.phone
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span
                              style={{
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              WhatsApp
                            </span>
                            <i
                              className="fab fa-whatsapp"
                              style={{
                                marginTop: "0vw",
                                fontSize: "4vw",
                                marginLeft: "2vw",

                                color: "green",
                              }}
                            ></i>
                          </a>
                          {bssAccountTypeId == null && (
                            <ButtonCopyDataAccount
                              text={[
                                "*Número de cuenta:* " +
                                  transactionSelected.bssAccount.bank.prefix +
                                  "" +
                                  transactionSelected.bssAccount.number,
                                "*Nombre:* " +
                                  transactionSelected.bssAccount.name,
                                "*Documento:* " +
                                  transactionSelected.bssAccount.identification,
                                "*Tipo de cuenta:* Cta-" +
                                  transactionSelected.bssAccount.bssAccountType
                                    .name,
                              ]}
                            />
                          )}
                        </div>
                      ) : (
                        <div>
                          <ItemDataAccount
                            text={transactionSelected.bssAccount.phone}
                          />
                          <ItemDataAccount
                            text={transactionSelected.bssAccount.name}
                          />
                          <ItemDataAccount
                            text={transactionSelected.bssAccount.identification}
                          />
                          <ButtonCopyDataAccount
                            text={[
                              "*Télefono:* " +
                                transactionSelected.bssAccount.phone,
                              "*Nombre:* " +
                                transactionSelected.bssAccount.name,
                              "*Documento:* " +
                                transactionSelected.bssAccount.identification,
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    paddingBottom: "1vw",
                    paddingTop: "10px",
                  }}
                  colSpan={2}
                >
                  <i
                    className="far fa-money-bill-alt"
                    style={{ color: "#003350" }}
                  ></i>{" "}
                  Recargas hechas:
                  <span style={{ float: "right", color: "rgb(249, 97, 35)" }}>
                    <b>{transactionSelected.approvedPaymentsTotalCounter}</b>
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    paddingBottom: "1vw",
                    paddingTop: "2px",
                  }}
                  colSpan={2}
                >
                  <i
                    className="far fa-money-bill-alt"
                    style={{ color: "#003350" }}
                  ></i>{" "}
                  Pesos recargados:
                  <span style={{ float: "right", color: "rgb(249, 97, 35)" }}>
                    <b>
                      {formatNumber(
                        transactionSelected.approvedPaymentsTotalAmount
                      )}
                    </b>
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      fontSize: "16px",
                      paddingBottom: "1vw",
                      paddingTop: "4px",
                    }}
                    colSpan={2}
                  >
                    <b>
                      <i
                        className="far fa-money-bill-alt"
                        style={{ color: "#003350" }}
                      ></i>{" "}
                      Monto:
                    </b>
                    <span style={{ float: "right", color: "rgb(249, 97, 35)" }}>
                      <b>{formatNumber(transactionSelected.bssAmount)} BS</b>
                    </span>
                  </div>
                </div>
                <div>
                  <div colSpan={2}>
                    <hr
                      style={{
                        color: "rgba(204, 204, 204, 0.12)",
                        marginTop: "2vw",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {Object.values(operators).length ? (
              Object.values(operators).map((operator) => {
                return (
                  <div key={JSON.stringify(operator)}>
                    <div colSpan={12} style={{ width: "100%" }}>
                      <div style={{}}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            checked={selectedValue == operator[0].operator.id}
                            onChange={(e) => handleChange(e, operator)}
                            value={operator[0].operator.id}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                            style={{
                              paddingLeft: "0",
                            }}
                          />
                          {operator[0].operator.profilePhoto ? (
                            <img
                              src={operator[0].operator.profilePhoto}
                              style={{ width: "10px", height: "10px" }}
                            />
                          ) : (
                            <AccountCircleIcon
                              style={{
                                color: "#3ec31d",
                              }}
                            />
                          )}
                          <span
                            style={{
                              fontSize: "3vw",
                              color: "black",
                              marginLeft: "2vw",
                            }}
                          >
                            {operator[0].operator.name}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                          }}
                        >
                          {operator.map((balance) => {
                            return (
                              <h5
                                key={JSON.stringify(balance)}
                                style={{
                                  fontSize: "3.5vw",
                                  fontWeight: "normal",
                                  marginBottom: "1vw",
                                  color: "rgb(132, 130, 128)",
                                  display: "grid",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  width: "100%",
                                  gridTemplateColumns: "35% 50%",
                                }}
                              >
                                <div>{balance.bankAlias}</div>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "black",
                                    textAlign: "right",
                                    paddingRight: "3%",

                                    marginLeft: "1vw",
                                  }}
                                >
                                  ${formatNumber(balance.total)}
                                </span>
                              </h5>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div colSpan={2}>
                  <div>
                    <img
                      style={{ width: "96%", marginTop: "3vw" }}
                      src={IlustrationWithouthOperators}
                    />
                    <small
                      style={{
                        fontStyle: "italic",
                        textAlign: "center",
                        display: "inherit",
                        marginTop: "1vw",
                        color: "#00335099",
                        marginBottom: "4vw",
                      }}
                    >
                      No hay operadores disponibles actualmente
                    </small>
                  </div>
                </div>
              </div>
            )}

            <div>
              {cancelTransaction && (
                <Button
                  variant="contained"
                  onClick={cancelTransaction}
                  style={{
                    textTransform: "capitalize",
                    marginTop: "7vw",
                    width: "100%",
                    background: "#a44141 none repeat scroll 0% 0%",
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0.1px 0px 2.2px, rgba(0, 0, 0, 0.027) 0.3px 0px 5.3px, rgba(0, 0, 0, 0.035) 0.5px 0px 10px, rgba(0, 0, 0, 0.043) 0.9px 0px 17.9px, rgba(0, 0, 0, 0.05) 1.7px 0px 33.4px, rgba(0, 0, 0, 0.07) 4px 0px 80px",
                    color: "white",
                    fontSize: "3vw",
                    padding: "1vw",
                    marginBottom: "2vw",
                  }}
                >
                  Cancelar transferencia
                </Button>
              )}
            </div>

            <div>
              <div colSpan={2}>
                <Button
                  variant="contained"
                  onClick={() => setShowModal(false)}
                  style={{
                    float: "left",
                    textTransform: "capitalize",
                    marginTop: "2vw",
                    width: "47%",
                    background: "white",
                    boxShadow:
                      "0.1px 0px 2.2px rgba(0, 0, 0, 0.02),\n  0.3px 0px 5.3px rgba(0, 0, 0, 0.028),\n  0.5px 0px 10px rgba(0, 0, 0, 0.035),\n  0.9px 0px 17.9px rgba(0, 0, 0, 0.042),\n  1.7px 0px 33.4px rgba(0, 0, 0, 0.05),\n  4px 0px 80px rgba(0, 0, 0, 0.07)",
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSendForm}
                  color={selectedValue ? "primary" : "grey"}
                  style={{
                    float: "right",
                    textTransform: "capitalize",
                    marginTop: "2vw",
                    width: "47%",
                    color: selectedValue ? null : "rgb(0, 51, 83)",
                  }}
                  disabled={!selectedValue}
                >
                  {" "}
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {assigned && transactionSelected.transactionStatus[0].statusId == 5 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              width: "100%",
            }}
          >
            <div>
              <Link
                to="#"
                onClick={() => setShowModal(false)}
                style={{ float: "right" }}
              >
                <CloseIcon />
              </Link>
            </div>
            <img
              src={transactionSelected.capture}
              style={{
                width: "100%",
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </ModalCustom>
  );
}
