import { useField } from 'formik'
import { makeStyles, TextField } from '@material-ui/core'

const useStyles = makeStyles({
  field: {
    marginBottom: '4%'
  }
})

export const FormikMuiTextField = (props) => {
  const [field, meta] = useField(props)
  const { variant, label } = props

  const classes = useStyles()

  return (
    <TextField
      className={classes.field}
        {...field}
        label={label}
        variant={variant}
        // eslint-disable-next-line no-self-compare
        error={meta.error && meta.touched}
        InputLabelProps={{ shrink: true }}
        helperText={meta.error && meta.touched ? meta.error : ''}
    />
  )
}
