import React from 'react'
import ModalCustom from '../../components/modal/index'
import { Link } from 'react-router-dom'
import ImageIcon from '@material-ui/icons/Image'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { formatNumber } from './../../helpers/numbers'

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    padding: '5vw 5vw',
    width: '80vw',
    borderRadius: '22px',
    border: 'none',
    display: 'flex',
    justifyContent: 'space-evenly',
    '@media (min-width: 700px)': {
      padding: '5vw 5vw'
    }
  },
  modalLogo: {
    width: '150px',
    position: 'absolute',
    bottom: '-50px',
    left: '-15px'
  },
  toggleButtonState: {
    color: '#eee',
    marginRight: '15px',
    marginLeft: '15px',
    borderRadius: '5px'
  },
  state: {
    borderRadius: '3px',
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: '6px',
    color: '#fff'
  },
  header: {
    height: '50px',
    padding: '0px',
    position: 'relative',
    overflow: 'hidden'
  },
  capture: {
    width: '100%',
    height: '100%'
  }
}))

export const stateColor = Object.freeze({
  Approved: '#67bb4c',
  Pending: '#f2af3b',
  Rejected: '#d74f4d'
})

export const paymentState = Object.freeze({
  Pending: null,
  Accepted: true,
  Rejected: false
})

const getLabelApproved = (approved) => {
  switch (approved) {
    case paymentState.Pending:
    case paymentState.Accepted:
      return 'Número de referencia'
    case paymentState.Rejected:
      return 'Razón de rechazo'
    default:
      break
  }
}

const Index = ({ payment, showModal, setShowModal, getStateColor, getStateLabel }) => {
  const classes = useStyles()

  return (<>
      {
        showModal &&
        <ModalCustom>
          <div className={classes.modal}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td className={classes.header} colSpan={2}>
                    <img src={ payment.appAccount.parentMethod.Logo } className={classes.modalLogo} alt="" />
                    <Link to="#" onClick={() => setShowModal(false)} style={{ float: 'right' }}><CloseIcon /></Link>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {payment.capture
                      ? <Zoom><img src={payment.capture} alt="image" className={classes.capture} /></Zoom>
                      : <ImageIcon style={{ width: '100%', height: '100%' }}/>
                    }
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0px', verticalAlign: 'middle', fontSize: '13px' }} colSpan={2}>
                    <b style={{ color: '#29627e' }}>Monto:</b> {formatNumber(payment.amount)} { payment.currency.abbr }
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '55%', fontSize: '13px' }}>
                    <b style={{ color: '#29627e' }}>{ getLabelApproved(payment.approved) }:</b><br />
                    { payment.approved == paymentState.Accepted ? payment.reference : payment.failed }
                  </td>
                  <td className={ classes.state } style={ { backgroundColor: getStateColor(payment.approved), fontSize: '13px' } }>
                    <b>{ getStateLabel(payment.approved) }</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ModalCustom>
      }
  </>)
}

export default Index
