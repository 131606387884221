import React, { useState, useEffect } from 'react'
import { InputDataAccount } from './InputDataAccount'
import Checkbox from '@mui/material/Checkbox'
import Select from 'react-select'

function CheckboxInput ({ name, onChange, val }) {
  return (
    <h5
      style={{
        color: '#003f62',
        marginBottom: '-4vw'
      }}
    >
      <Checkbox
        checked={val}
        onClick={(e, d) => onChange(e, name)}
        inputProps={{ 'aria-label': 'controlled' }}
      ></Checkbox>
      {name}
    </h5>
  )
}

function NumberBankInput (props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <input
        type="text"
        value={props.numberFourBank || '0134'}
        className={props.input}
        disabled
      />
      <small
        style={{
          fontSize: '2.7vw',
          fontWeight: 'lighter',
          color: '#003f62'
        }}
      >
        Banco
      </small>
    </div>
  )
}

function CheckboxsContainer (props) {
  let ahorroActive = props.formData.typeAccount == 'Ahorro'
  const [val, setVal] = useState({ Ahorro: ahorroActive, Corriente: !ahorroActive })

  useEffect(() => {
    ahorroActive = props.formData.typeAccount == 'Ahorro'
    setVal({ Ahorro: ahorroActive, Corriente: !ahorroActive })
  }, [props.formData])

  const setFormValue = (e) => {
    const newValue = { typeAccount: e }
    props.setFormData({ ...props.formData, ...newValue })
  }

  const onChange = (e, value) => {
    setVal({
      Ahorro: value == 'Ahorro',
      Corriente: value != 'Ahorro'
    })
    setFormValue(value)
  }

  return (
    <div>
      <h5
        style={{
          marginLeft: '4vw'
        }}
      >
        Tipo de cuenta
      </h5>
      <CheckboxInput
        dataForm={props.formData}
        onChange={onChange}
        val={val.Ahorro}
        name="Ahorro"
      ></CheckboxInput>
      <CheckboxInput
        dataForm={props.formData}
        onChange={onChange}
        val={val.Corriente}
        name="Corriente"
      ></CheckboxInput>
    </div>
  )
}

function SelectDocument ({ onChange, value }) {
  const DOCTypes = [
    { value: '1', title: 'V', origin: 'Venezolano' },
    { value: '2', title: 'E', origin: 'Extranjero' },
    { value: '3', title: 'J', origin: 'RIF' },
    { value: '4', title: 'G', origin: 'Gubernamental' }
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 20,
      background: state.isSelected ? 'white' : 'white'
    }),
    control: (provied) => ({
      ...provied,
      background: 'white'
    })
  }

  return (
    <div
      style={{
        marginTop: '8vw'
      }}
    >
      <Select
        placeholder="Tipo de documento"
        value={value}

        options={DOCTypes}
        styles={customStyles}
        onChange={onChange}
        getOptionLabel={(e) => e.origin}
        defaultValue={(e) => {
          return { origin: 'Venezolano' }
        }}
      />
    </div>
  )
}

export function DataAccountInputs ({
  classes,
  formData,
  setFormData,
  register,
  errors,
  documentTypeSelected,
  numberFourBank,
  setdocumentTypeSelected,
  onChangeInputNumberAccount
}) {
  return (
    <div
      style={{
        background: 'white',
        padding: '5vw 4vw',
        margin: '4vw',
        borderRadius: 10,
        marginTop: '0vw'

      }}
    >
      <h3
        style={{
          color: '#FA7D4A'
        }}
      >
        Datos de la cuenta
      </h3>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '28% 66%',
          gridGap: '6%',
          marginTop: '11vw'
        }}
      >
        <NumberBankInput
          input={classes.input}
          numberFourBank={numberFourBank}
        ></NumberBankInput>
        <InputDataAccount
          register={register}
          errors={errors}
          label="Numero de cuenta"
          onlynumbers
          onChange={onChangeInputNumberAccount}
          input={classes.input}
        ></InputDataAccount>
      </div>
      <div
        style={{
          marginTop: '3vw'
        }}
      >
        <InputDataAccount
          errors={errors}
          register={register}
          label="Nombre del titular"
          input={classes.input}
        ></InputDataAccount>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '28% 66%',
          gridGap: '6%',
          marginTop: '3vw'
        }}
      >
        <InputDataAccount
          register={register}
          errors={errors}
          label="Documento"
          maxlength='10'
          onlynumbers
          input={classes.input}
        ></InputDataAccount>
        <CheckboxsContainer
          formData={formData}
          setFormData={setFormData}
        ></CheckboxsContainer>
      </div>

      <SelectDocument
      value={documentTypeSelected}
            onChange={setdocumentTypeSelected}
          ></SelectDocument>
    </div>
  )
}
