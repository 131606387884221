// Dependencies
import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Axios } from "../helpers/axios";
import { validateResponse } from "../helpers/";

// Assets
import Alert from "../assets/alert.svg";
import Success from "../assets/checklist.svg";

const MySwal = withReactContent(Swal);

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState: {
    rows: [],
  },
  reducers: {
    setCurrencies: (state, action) => {
      state.rows = action.payload;
    },
  },
});

const { setCurrencies } = currenciesSlice.actions;

export const fetchCurrencies = () => async (dispatch) => {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_API_URL + "currency"
    );
    dispatch(setCurrencies(response.data.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCurrencyDetails = async (currencyId) => {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_API_URL + "currency/show-with-details/" + currencyId
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const createCurrency = async (data, modalResponse = true) => {
  try {
    const response = await Axios.post(
      process.env.REACT_APP_API_URL + "currency/store",
      data
    );
    console.log("response", response.data);
    if (modalResponse) validateResponse(response.data);
    return response.data.data;
  } catch (error) {
    console.log("error", error);
    return false;
  }
};

export const updateCurrency = async (data, modalResponse = true) => {
  try {
    const response = await Axios.put(
      process.env.REACT_APP_API_URL + `currency/update/${data.id}`,
      data
    );
    if (modalResponse) validateResponse(response[0].data);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteCurrency = async (id, modalResponse = true) => {
  try {
    const { data: response } = await Axios.delete(
      process.env.REACT_APP_API_URL + `currency/delete/${id}`
    );

    const { errors, code } = response;

    // There are transactions associated with this currency
    if (!code && errors) {
      MySwal.fire({
        width: 315,
        html:
          `<img style="margin: 6% 0; width: 65px; display: block" src=${Alert}> </img> ` +
          '<p style="text-align: left; color: #003353; font-size: 4vw">Esta moneda no se puede eliminar porque está relacionada con una transacción.</p>',
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
        confirmButtonText: "Aceptar",
      });
    } else if (code === 0) {
      MySwal.fire({
        width: 315,
        html:
          `<img style="width: 65px; margin: 6% 0;" src=${Success}> </img> ` +
          '<p style="text-align: center; color: #003353; font-size: 4vw">Proceso completado con éxito.</p>',
        customClass: {
          confirmButton: "swal2-blue-confirm-button",
        },
        confirmButtonText: "Aceptar",
      });
    }

    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateCurrencyValues = async (data, modalResponse = true) => {
  try {
    const response = await Promise.all(
      data.map(({ id, value }) =>
        Axios.put(
          process.env.REACT_APP_API_URL + `currencyValue/update/${id}`,
          { value }
        )
      )
    );

    const errors = response.filter(({ status }) => status !== 200);

    if (errors.length > 0) throw new Error(errors);
    if (modalResponse) validateResponse(response[0].data);
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const createCurrencyValues = async (data, modalResponse = true) => {
  try {
    const response = await Promise.all(
      data.map((data) =>
        Axios.post(process.env.REACT_APP_API_URL + "currencyvalue/store/", data)
      )
    );

    const errors = response.filter(({ status }) => status !== 200);

    if (errors.length > 0) throw new Error(errors);

    if (modalResponse) validateResponse(response[0].data);
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default currenciesSlice.reducer;
