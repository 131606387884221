import React, { useEffect, useState } from "react";
import Layout from "../../layouts/outside";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { activateOperatorFunct } from "../../slices/auth";
import PasswordField from "../../components/form/passwordField/index";
import { passwordIsStrong } from "../../utils/validations/validations";

function AccountActivation() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: [],
    confirmPassword: [],
  });

  const onWritePassword = (e) => {
    setError({
      password: passwordIsStrong(e)
        ? []
        : [
            "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial",
          ],
      confirmPassword:
        e != form.password && form.password.length != 0
          ? ["Las contraseñas no coinciden"]
          : error.confirmPassword,
    });

    setForm({ password: e, confirmPassword: form.confirmPassword });
  };

  const onWriteConfirmPassword = (e) => {
    setError({
      confirmPassword:
        e != form.password ? ["Las contraseñas no coinciden"] : [],
      password: error.password,
    });
    setForm({ confirmPassword: e, password: form.password });
  };

  const signUpFunct = async (e) => {
    e.preventDefault();

    console.log(
      [...error.password, ...error.confirmPassword].length > 0,
      form.password.length == 0,
      form.confirmPassword.length == 0
    );

    if (
      [...error.password, ...error.confirmPassword].length > 0 ||
      form.password.length == 0 ||
      form.confirmPassword.length == 0
    )
      return;

    console.log("signUpFunct");

    setIsLoading(true);

    try {
      const searchParams = new URLSearchParams(window.location.search);
      const email = searchParams.get("email");
      const code = searchParams.get("code");

      console.log("email", email);
      console.log("code", code);

      await dispatch(
        await activateOperatorFunct(
          {
            email: email,
            password: form.password,
            code: parseInt(code),
          },
          history
        )
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        signUpFunct(e);
      }}
    >
      <Layout label={"Activar cuenta"} loading={isLoading} color={true}>
        <PasswordField
          setPassword={onWritePassword}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
          validation={error.password}
        />
        <PasswordField
          setPassword={onWriteConfirmPassword}
          setShowPassword={setShowConfirmPassword}
          showPassword={showConfirmPassword}
          validation={error.confirmPassword}
          repeatPassword
        />
      </Layout>
    </form>
  );
}

export default AccountActivation;
