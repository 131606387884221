import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  SvgIcon,
  IconButton,
} from "@material-ui/core";
import Header from "../components/Header";
import CalculatorDialog from "../components/CalculatorDialog";

const useStyles = makeStyles({
  container: {
    minHeight: "100vh",
    backgroundColor: "#003350",
  },
  content: {
    flex: "1",
    flexDirection: "column",
    flexWrap: "nowrap",
    paddingInline: 15,
    color: "#fff",
    position: "relative",
  },
  paperTasa: {
    backgroundColor: "#003f62",
    width: 170,
    height: 65,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: 10,
  },
  paperGanancia: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#003f62",
    height: 75,
    marginTop: 20,
    borderRadius: 10,
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 60,
    backgroundColor: "#003f62",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
});

function RechargeBalance() {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Header
        text="Calculadora de ganancias"
        style={{ backgroundColor: "#003350" }}
      />

      <Grid container className={classes.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Paper className={classes.paperTasa}>
            <Typography
              style={{ fontSize: 20, fontWeight: 600, color: "#F86A30" }}
            >
              Venetasa
            </Typography>
            <Typography
              style={{ fontSize: 18, fontWeight: 600, color: "#fff" }}
            >
              0.00150
            </Typography>
          </Paper>
          <Paper className={classes.paperTasa}>
            <Typography
              style={{ fontSize: 20, fontWeight: 600, color: "#F86A30" }}
            >
              Mi Tasa
            </Typography>
            <Typography
              style={{ fontSize: 18, fontWeight: 600, color: "#fff" }}
            >
              0.00135
            </Typography>
            <IconButton style={{ position: "absolute", right: 0, bottom: 0 }}>
              <SvgIcon
                viewBox="0 0 24.337 23.504"
                style={{ overflow: "visible" }}
              >
                <defs>
                  <filter
                    id="FontAwsome_edit_"
                    x="0"
                    y="0"
                    width="36.505"
                    height="35.256"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                  filter="url(#FontAwsome_edit_)"
                >
                  <path
                    id="FontAwsome_edit_2"
                    data-name="FontAwsome (edit)"
                    d="M12.934,2.9l2.9,3.041a.341.341,0,0,1,0,.465L8.816,13.769l-2.981.347a.641.641,0,0,1-.691-.725l.331-3.128L12.491,2.9a.3.3,0,0,1,.443,0Zm5.2-.772L16.571.484a1.215,1.215,0,0,0-1.773,0L13.661,1.678a.341.341,0,0,0,0,.465l2.9,3.041a.3.3,0,0,0,.443,0L18.139,3.99A1.365,1.365,0,0,0,18.139,2.129Zm-5.8,9.638V15.2H2.056V4.411H9.439a.386.386,0,0,0,.273-.118L11,2.945a.407.407,0,0,0-.273-.691H1.542A1.582,1.582,0,0,0,0,3.872V15.738a1.582,1.582,0,0,0,1.542,1.618H12.851a1.582,1.582,0,0,0,1.542-1.618V10.419a.384.384,0,0,0-.659-.287L12.449,11.48A.425.425,0,0,0,12.337,11.767Z"
                    transform="translate(9 5.9)"
                    fill="#FA7D4A"
                  />
                </g>
              </SvgIcon>
            </IconButton>
          </Paper>
        </div>

        <Paper className={classes.paperGanancia}>
          <div>
            <Typography
              style={{ fontSize: 24, fontWeight: 600, color: "#fff" }}
            >
              Ganancia:
            </Typography>
            <Typography style={{ fontSize: 10, color: "#fff" }}>
              Por cada 100.000 COP
            </Typography>
          </div>

          <Typography style={{ fontSize: 30, fontWeight: 600, color: "#fff" }}>
            10.000 COP
          </Typography>

          <CalculatorDialog />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default RechargeBalance;
