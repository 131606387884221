import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    aligItems: 'center'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 60,
    backgroundColor: '#003f62',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
  }
})

export default function BankRechargeDialog ({ bank }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
        <div className={classes.footer} onClick={() => setOpen(true)}>
            <Typography style={{ fontWeight: 500 }}>
                Definir tasa del día
            </Typography>
        </div>

        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title" open={open}
            PaperProps={{
              style: { width: '75vw', margin: 0 }
            }}
        >
          <div style={{ backgroundColor: '#003F62' }}>
            <DialogTitle disableTypography className={classes.root}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                  <SvgIcon viewBox="0 0 24 24">
                    <path id="FontAwsome_times-circle_" data-name="FontAwsome (times-circle)" d="M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm5.884,15.15a.581.581,0,0,1,0,.823l-1.916,1.911a.581.581,0,0,1-.823,0L20,22.71l-3.15,3.174a.581.581,0,0,1-.823,0l-1.911-1.916a.581.581,0,0,1,0-.823L17.29,20l-3.174-3.15a.581.581,0,0,1,0-.823l1.916-1.916a.581.581,0,0,1,.823,0L20,17.29l3.15-3.174a.581.581,0,0,1,.823,0l1.916,1.916a.581.581,0,0,1,0,.823L22.71,20Z" transform="translate(-8 -8)" fill="#fff"/>
                  </SvgIcon>
                </IconButton>
            </DialogTitle>

            <DialogContent clasName={classes.dialogContent}>
              <Typography align="center" style={{ fontSize: 18, fontWeight: 600, color: '#fff', marginTop: 30 }}>
                SEGURO QUE DESEAS DEFINIR ESTA TASA DEL DÍA?
              </Typography>

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <Button variant="contained" style={{ backgroundColor: '#FA7D4A', color: '#fff', marginRight: 10 }}>
                  Si
                </Button>
                <Button variant="contained" style={{ backgroundColor: '#C13D3D', color: '#fff', marginLeft: 10 }}>
                  No
                </Button>
              </div>
            </DialogContent>
          </div>
        </Dialog>
    </div>
  )
}
