// Dependencies
import { useHistory } from 'react-router-dom'

const Header = (props) => {
  const history = useHistory()
  const { title } = props

  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <i
        onClick={() => history.push('/dashboard')}
        style={{ fontSize: '7vw', color: '#003f62' }}
        className="fas fa-chevron-left"
      ></i>

      <h1
        style={{
          color: '#FA7D4A',
          fontSize: '8vw',
          marginLeft: '-7vw'
        }}
      >
        {title}
      </h1>
      <div></div>
    </header>
  )
}

export default Header
