import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { isEmpty } from "lodash";
import { Link, useLocation } from "react-router-dom";
import ModalCustom from "../../components/modal/index";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import { formatNumber } from "./../../helpers/numbers";
import PendienteStatus from "../status/pendiente";
import EnEsperaStatus from "../status/enespera";
import TransfiriendoStatus from "../status/transfiriendo";
import DatosErroneosStatus from "../status/datoserroneos";
import FinalizadoStatus from "../status/finalizada";
import Dialog from "@material-ui/core/Dialog";
import IlustrationWithouthOperators from "../../assets/ilustrations/Sinoperadoresempty.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/es";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Modalassignoperator } from "./../modal/transferences/modalAssignOperator";
import NewTransactionStatus from "../transactions/misc/new_transaction_status";
import NewTransactionReceipt from "../transactions/misc/new-transaction-receipt";
import { dateDiff } from "../../utils/date/date_utils";

const useStyles = makeStyles({
  flexContainer: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f1f1f1",
    padding: 10,
    "& div": {
      flex: "1",
      color: "#222",
      width: "100px",
      lineHeight: "35px",
      height: "55px",
      paddingTop: 5,
      paddingBottom: "60px",
    },
  },
  logo: {
    width: 50,
    height: 50,
    position: "relative",
  },
  modal: {
    position: "absolute",
    top: "10%",
    overflowY: "scroll",
    left: "10%",
    minHeight: "39vh",
    maxHeight: "80vh",
    borderRadius: "22px",
    background: "white",
    padding: "4vw 4vw",
    width: "80vw",
    border: "none",
    display: "flex",
    justifyContent: "space-evenly",
    "@media (min-width: 700px)": {
      padding: "4vw 4vw",
    },
  },
  card: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "13px",
    paddingBottom: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
});

function formatDate(dateTime) {
  moment.locale("es");
  const date = moment(dateTime);
  date.locale("es");
  return `${moment(dateTime).calendar()}`;
}

const Index = ({
  transaction,
  message,
  operators = [],
  sendForm,
  transactionSelected,
  settransactionSelected,
  showModal,
  setShowModal,
  cancelTransaction,
  assigned = false,
  allowModal = true,
  removeOperatorFromTransference,
}) => {
  const [operatorId, setOperatorId] = useState(null);
  const [showModalRemoveOperator, setshowModalRemoveOperator] = useState(false);
  const [setshowModalDetails, setSetshowModalDetails] = useState(false);
  const location = useLocation();

  const handleChange = (event) => {
    setOperatorId(event.target.value);
  };

  const handleChangeOperator = async () => {
    console.log("hereeee");
    await sendForm(transactionSelected.id, operatorId);
    setTimeout(() => {
      setShowModal(false);
      setSetshowModalDetails(false);
    }, 700);
  };

  const classes = useStyles();

  const hasOperator = assigned || transaction?.operator?.name;
  const isFinalized = transaction.transactionStatus[0].statusId == 5;

  return (
    <>
      <ToastContainer />
      {setshowModalDetails && transactionSelected && (
        <Modalassignoperator
          {...{
            cancelTransaction,
            selectedValue: operatorId,
            assigned,
            setShowModal: setSetshowModalDetails,
            handleChange,
            transactionSelected,
            handleSendForm: handleChangeOperator,
            operators,
          }}
        />
      )}
      <Dialog
        onClose={() => showModalRemoveOperator(false)}
        aria-labelledby="customized-dialog-title"
        open={showModalRemoveOperator}
        PaperProps={{
          style: {
            maxWidth: "900px",
            width: "75vw",
            margin: "0px",
            padding: "11vw 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          },
        }}
      >
        <h3 style={{ fontSize: "4vw", width: "62vw", marginBottom: "5vw" }}>
          ¿Estas seguro que deseas remover el operador de esta transferencia?
        </h3>
        <div
          style={{
            marginTop: "5vw",
            width: "79%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              fontSize: "4vw",
              width: "45%",
              border: "none",
              borderRadius: "5px",
              padding: "2vw",
              background: "#212160",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => setshowModalRemoveOperator(false)}
          >
            No
          </button>
          <button
            style={{
              fontSize: "4vw",
              width: "45%",
              border: "none",
              borderRadius: "5px",
              padding: "2vw",
              background: "#ff6c00",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => {
              setshowModalRemoveOperator(false);
              removeOperatorFromTransference(transaction);
            }}
          >
            Sí
          </button>
        </div>
      </Dialog>
      {isEmpty(message) ? (
        <Link
          to="#"
          style={{ textDecoration: "none" }}
          onClick={() => {
            console.log("on click", allowModal);
            if (!hasOperator) {
              settransactionSelected(transaction);
              setSetshowModalDetails(true);
            }
          }}
        >
          <div className="new_transaction">
            <div className="logo">
              <img src={transaction.bssAccount?.bank?.Logo} alt="logo" />
            </div>
            <div className="data">
              <div className="top">
                <h4>{transaction?.bssAccount?.name ?? ""}</h4>

                <div className="flex">
                  {hasOperator && !isFinalized && (
                    <i
                      onClick={() => {
                        setshowModalRemoveOperator(true);
                      }}
                      className="fa-solid fa-user-slash hover"
                      style={{ marginRight: "1vw", fontSize: "2.5vw" }}
                    ></i>
                  )}
                  <h5>${transaction?.bssAmount ?? ""} BS</h5>
                </div>
              </div>
              <div className="medium">
                <h4>
                  <b>Operador:</b>{" "}
                  {transaction?.operator?.name ?? "Sin asignar"}
                </h4>
                <div className="right">
                  <h4>{moment(transaction.createdAt).format("DD/MM/YY")}</h4>
                  <h4>
                    <i>{dateDiff(transaction.createdAt)}</i>
                  </h4>
                </div>
              </div>
              <div className="bottom">
                <NewTransactionStatus
                  statusList={transaction.transactionStatus}
                />
                <NewTransactionReceipt transaction={transaction} />
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <table className={classes.card} style={{ backgroundColor: "#fff" }}>
          <tbody>
            <tr>
              <td
                style={{
                  verticalAlign: "middle",
                  color: "#175775",
                  textAlign: "center",
                }}
              >
                {message}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default Index;
